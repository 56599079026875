import { writePDF } from '../../../utils/pdf';
import addNotification from '../app/addNotification';

export default (pdfDoc, bookmark, documentNumber) => (dispatch) => {
  dispatch(addNotification({
    id: 'download-pdf' + documentNumber,
    type: 'progress',
    message: `Generating PDF "${documentNumber}"...`
  }));
  writePDF(pdfDoc, bookmark.page, bookmark.endPage)
    .then((pdf) => {
      const link = document.createElement('a');
      link.setAttribute('href', pdf.toBlobURL('application/pdf'));
      link.setAttribute('download', bookmark.title);
      document.body.appendChild(link);
      link.click();
      dispatch(addNotification({
        id: 'download-pdf' + documentNumber,
        type: 'success',
        message: `Generating PDF "${documentNumber}"...`
      }));
    })
    .catch(() => {
      dispatch(addNotification({
        id: 'download-pdf' + documentNumber,
        type: 'error',
        message: `Generating PDF "${documentNumber}"...`
      }));
    });
};
