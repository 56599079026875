import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Modal, Button } from 'react-bootstrap';
import FieldGroup from '../shared/FieldGroup';
import Field from '../shared/Field';
import ClientInput from '../client/ClientInput';
import MatterInput from '../matter/MatterInput';
import Immutable from 'immutable';

export default class AssistantModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    navigateApp: PropTypes.func.isRequired,
    fetchTodos: PropTypes.func.isRequired,
    saveBatchMatterUpdate: PropTypes.func.isRequired,
    selectClientAndMatter: PropTypes.func.isRequired,
    clientList: PropTypes.instanceOf(Immutable.List),
    matterList: PropTypes.instanceOf(Immutable.Map),
    fetchMatterListIfNeeded: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      createMatter: false,
      clientNumber: '',
      matterNumber: '',
      applicationNumber: ''
    };
  }

  fetchMatterList() {
    if (!this.state.clientNumber) {
      return;
    }

    const client = this.props.clientList.find((client) => client.get('clientNumber') === this.state.clientNumber);
    if (!client) {
      return;
    }

    this.props.fetchMatterListIfNeeded(client.get('id'));
  }

  getMatterList() {
    if (!this.state.clientNumber) {
      return Immutable.List();
    }

    const client = this.props.clientList.find((client) => client.get('clientNumber') === this.state.clientNumber);
    if (!client) {
      return Immutable.List();
    }

    return this.props.matterList.get(client.get('id')) || Immutable.List();
  }

  onClickSkip() {
    this.props.onClose();
  }

  onSearchApplication() {
    if (!this.state.applicationNumber) {
      this.setState({
        createMatter: true
      });
      return;
    }

    const params = {
      term: this.state.applicationNumber,
      filterType: 'appnumber',
      show: 'all'
    };

    this.props.fetchTodos(params, true).then((response) => {
      if (response.entities.todoList.length) {
        this.props.navigateApp(params);
        this.props.onClose();
      } else {
        this.setState({
          createMatter: true
        });
      }
    });
  }

  onCreateMatter() {
    const matters = [{
      clientNumber: this.state.clientNumber,
      matterNumber: this.state.matterNumber,
      applicationNumber: this.state.applicationNumber
    }];

    this.props.saveBatchMatterUpdate(matters, true)
      .then((matters) => {
        this.props.navigateApp({
          term: [this.state.clientNumber, this.state.matterNumber].join('|'),
          filterType: 'smart',
          show: 'all'
        });

        return this.props.selectClientAndMatter({
          clientId: matters[0].clientId,
          matterId: matters[0].id
        });
      })
      .then(() => this.props.onClose());
  }

  onClickContinue() {
    if (this.state.createMatter) {
      this.onCreateMatter();
    } else {
      this.onSearchApplication();
    }
  }

  onChangeApplication(e) {
    this.setState({ applicationNumber: e.target.value });
  }

  onClearClient() {
    this.setState({ clientNumber: '' });
  }

  onChangeClient(clientNumber) {
    this.setState({ clientNumber }, () => this.fetchMatterList());
  }

  onClearMatter() {
    this.setState({ matterNumber: '' });
  }

  onChangeMatter(matterNumber) {
    this.setState({ matterNumber });
  }

  renderApplication() {
    return (
      <div>
        <h4>What U.S. Application are you working on?</h4>
        <FieldGroup
          type='text'
          value={this.state.applicationNumber}
          placeholder='17/123,456 or CN202301234'
          onChange={this.onChangeApplication}
        />
      </div>
    );
  }

  renderCreateMatter() {
    return (
      <div>
        <h4>Search for or create Attorney Docket No.</h4>

        <div className='row'>
          <div className='col-xs-6'>
            <Field label='Client/BU ID'>
              <ClientInput
                id='assistant-client-id'
                value={this.state.clientNumber}
                clientList={this.props.clientList}
                onClear={this.onClearClient}
                onChange={this.onChangeClient}
                onSubmit={this.onChangeClient}
              />
            </Field>
          </div>
          <div className='col-xs-6'>
            <span
              style={{ position: 'absolute', left: '-12px', top: '36px' }}
              className='fa fa-minus'
            />
            <Field label='Matter ID'>
              <MatterInput
                id='assistant-matter-id'
                value={this.state.matterNumber}
                matterList={this.getMatterList()}
                onClear={this.onClearMatter}
                onChange={this.onChangeMatter}
                onSubmit={this.onChangeMatter}
              />
            </Field>
          </div>
        </div>
      </div>
    );
  }

  renderBody() {
    return this.state.createMatter ? this.renderCreateMatter() : this.renderApplication();
  }

  renderSkip() {
    return (
      <Button
        style={{ width: '120px' }}
        variant='secondary'
        onClick={this.onClickSkip}
      >
        {this.state.createMatter ? 'Close' : 'Skip & Close'}
      </Button>
    );
  }

  renderContinue() {
    const disabled = this.state.createMatter
      ? !this.state.clientNumber || !this.state.matterNumber
      : false;

    return (
      <Button
        style={{ width: '120px' }}
        variant='primary'
        disabled={disabled}
        onClick={this.onClickContinue}
      >
        Continue
      </Button>
    );
  }

  renderFooter() {
    return (
      <div>
        {this.renderSkip()}
        {this.renderContinue()}
      </div>
    );
  }

  render() {
    return (
      <Modal show onHide={this.onClickSkip}>
        <Modal.Header closeButton>
          <Modal.Title>
          SyncIDS Assistant
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderFooter()}
        </Modal.Footer>
      </Modal>
    );
  }
};
