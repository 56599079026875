import { createAction } from 'redux-actions';
import Cookies from 'js-cookie';
import axios from 'axios';
import navigateApp from '../app/navigateApp';
import { loginUrl } from '../../constants/urls';
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_MESSAGE,
  AUTH_LOGIN_PROGRESS
} from '../../constants/actions';

const authLoginSuccess = createAction(AUTH_LOGIN_SUCCESS);
const authLoginMessage = createAction(AUTH_LOGIN_MESSAGE);
const authLoginProgress = createAction(AUTH_LOGIN_PROGRESS);

export default (email, password, rememberMe) => (dispatch, getState) => {
  const data = {
    email,
    password,
    ref: window.sessionStorage.getItem('syncids-ref') || ''
  };

  dispatch(authLoginProgress());

  return axios.post(loginUrl, data)
    .then((response) => {
      if (rememberMe) {
        Cookies.set('syncids-remember', response.data.email, { expires: 365 });
        Cookies.set('syncids-session', response.data.token);
      } else {
        Cookies.remove('syncids-remember');
        Cookies.remove('syncids-session');
      }

      dispatch(navigateApp());

      dispatch(authLoginSuccess({
        ...response.data,
        rememberMe
      }));

      return response.data;
    })
    .catch(err => {
      dispatch(authLoginMessage({
        type: 'error',
        message: err.response.data.error
      }));
    });
};
