import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button } from 'react-bootstrap';

export default class BatchInputLink extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    docType: PropTypes.string.isRequired,
    label: PropTypes.string,
    bsStyle: PropTypes.string,
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  isDisabled() {
    return this.props.docType !== 'US' && this.props.features.limitedDocs;
  }

  onClick() {
    this.props.onClick(this.props.docType);
  }

  render() {
    return (
      <Button
        disabled={this.isDisabled()}
        style={{ position: 'relative', top: '-12px', margin: '0 4px' }}
        size='sm'
        variant={this.props.bsStyle || 'primary'}
        onClick={this.onClick}
      >
        {this.props.label || this.props.children}
      </Button>
    );
  }
};
