import blobStream from 'blob-stream';
import pdfKitUrl from '../files/pdfkit.js';

const loadScript = (url, callback) => {
  const script = document.createElement('script');
  script.src = url;
  script.onload = callback;
  document.body.appendChild(script);
};

const usePdfJs = (callback) => {
  if (window.pdfjsDistBuildPdf) {
    callback(window.pdfjsDistBuildPdf);
  } else {
    const libUrl = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.267/pdf.min.js';
    const workerUrl = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.267/pdf.worker.min.js';
    loadScript(libUrl, () => {
      window.pdfjsDistBuildPdf.workerSrc = workerUrl;
      callback(window.pdfjsDistBuildPdf);
    });
  }
};

export const getBookmarks = (url, callback) => {
  return new Promise((resolve, reject) => {
    usePdfJs((pdf) => {
      const bookmarks = [];
      pdf.getDocument(url).then((pdfDoc) => {
        pdfDoc.getOutline().then((data) => {
          if (!data) {
            resolve({ pdfDoc, bookmarks });
            return;
          }
          let counter = data.length;
          data.forEach((bookmark, bookmarkIndex) => {
            pdfDoc.getPageIndex(bookmark.dest[0]).then(page => {
              if (bookmarkIndex > 0) {
                bookmarks[bookmarkIndex - 1].endPage = page;
              }
              bookmarks.push({
                title: bookmark.title,
                page: page + 1,
                endPage: page + 1
              });
              if (--counter === 0) {
                resolve({ pdfDoc, bookmarks });
              }
            })
              .catch(reject);
          });
        })
          .catch(reject);
      }).catch(reject);
    });
  });
};

export const renderPage = (pdfDoc, page, canvas, scaleFactor = 1) => {
  return pdfDoc.getPage(page).then((page) => {
    const viewport = page.getViewport(scaleFactor);
    const canvasContext = canvas.getContext('2d');
    canvas.style.height = `${viewport.height}pt`;
    canvas.style.width = `${viewport.width}pt`;
    const size = canvas.getBoundingClientRect();
    canvas.height = size.height;
    canvas.width = size.width;

    return page.render({
      canvasContext,
      viewport
    });
  });
};

export const writePDF = (pdfDoc, page, endPage) => new Promise((resolve, reject) => {
  const delay = () => new Promise((resolve) => setTimeout(resolve, 1000));
  const createPage = (doc, pdfDoc, i) => {
    const canvas = document.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.top = '-10000px';
    document.body.appendChild(canvas);
    return renderPage(pdfDoc, i, canvas, 3)
      .then(delay)
      .then(() => {
        doc.addPage().image(canvas.toDataURL('image/jpg', 1), 0, 0, {
          scale: 0.33
        });
        document.body.removeChild(canvas);
      });
  };
  const createPDF = () => {
    const doc = new window.PDFDocument({
      autoFirstPage: false
    });
    const stream = doc.pipe(blobStream());
    stream.on('finish', () => {
      resolve(stream);
    });

    let promise = Promise.resolve();
    for (let i = page; i <= endPage; i++) {
      promise = promise
        .then(delay)
        .then(() => createPage(doc, pdfDoc, i));
    }
    promise
      .then(delay)
      .then(() => doc.end());
  };

  if (window.PDFDocument) {
    createPDF();
  } else {
    loadScript(pdfKitUrl, createPDF);
  }
});
