import React from 'react';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import { Button } from 'react-bootstrap';
import Field from '../../shared/Field';
import attorneyList from '../../../utils/attorney-list.json';
import Form from '../../shared/Form';
import { isValidDate } from '../../../utils/date';

export default class FindNewMatters extends Form {
  static propTypes = {
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      task: 'FindNewMatters',
      attorney: '',
      endDate: '',
      beginDate: ''
    };
  }

  assignRefForm(element) {
    this.formRef = element;
  }

  renderForm() {
    return (
      <form ref={this.assignRefForm} method='get' action='syncids://syncids.com' target='_blank'>
        {['task', 'attorney', 'beginDate', 'endDate'].map(key => (
          <input
            key={key}
            name={key}
            type='hidden'
            value={this.state[key]}
          />
        ))}
      </form>
    );
  }

  onChangeAttorney(e) {
    this.setState({ attorney: e.target.value });
  }

  renderAttorney() {
    const value = this.state.attorney;

    return (
      <Field
        label='Attorney Name'
        style={{ width: '25%', float: 'left' }}
        className={!value ? 'has-error has-feedback' : ''}
      >
        <select
          name='application.attorneyName'
          value={value}
          onChange={this.onChangeAttorney}
          className='form-control'
          style={{ fontSize: '18px', padding: '2px 10px' }}
        >
          <option value=''>
            - Select Attorney -
          </option>
          {attorneyList.map((name, index) => (
            <option key={index} value={name}>
              {name}
            </option>
          ))}
        </select>
      </Field>
    );
  }

  onClickSubmit() {
    this.formRef.submit();
  }

  isSubmitEnabled() {
    return this.state.attorney && isValidDate(this.state.beginDate) && isValidDate(this.state.endDate);
  }

  renderSubmit() {
    return (
      <Field label='' style={{ width: 'auto', float: 'left' }}>
        <Button
          variant='primary'
          onClick={this.onClickSubmit}
          disabled={!this.isSubmitEnabled()}
        >
          Start
        </Button>
      </Field>
    );
  }

  render() {
    return (
      <CollapsiblePanel id='find-new-matters' header='Find New Matters'>
        <div className='row'>
          <div className='col-md-12'>
            {this.renderAttorney()}
            {this.renderDateInput('Begin Date', 'beginDate', '150px', true)}
            {this.renderDateInput('End Date', 'endDate', '150px', true)}
            {this.renderSubmit()}
            {this.renderForm()}
          </div>
        </div>
      </CollapsiblePanel>
    );
  }
};
