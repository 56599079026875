import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import Field from '../shared/Field';
import { Button } from 'react-bootstrap';
import sortBy from 'lodash/sortBy';
import ConfirmModal from '../shared/ConfirmModal';
import HelpIcon from '../shared/HelpIcon';
import Loading from '../shared/Loading';

export default class SyncOverlap extends Component {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired,
    fetchSyncOverlap: PropTypes.func.isRequired,
    selectMatter: PropTypes.func.isRequired,
    addOneWay: PropTypes.func.isRequired,
    addTwoWay: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (props.client.get('clientNumber') !== state.clientNumber) {
      return {
        clientNumber: props.client.get('clientNumber'),
        report: null,
        loading: false
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      index: null,
      matterNumber: null
    };
  }

  componentDidMount() {
    if (this.props.matterList.size < 100) {
      this.loadOverlap();
    }
  }

  componentDidUpdate() {
    if (this.props.matterList.size < 100) {
      this.loadOverlap();
    }
  }

  onClickLoad() {
    this.loadOverlap();
  }

  loadOverlap() {
    if (this.state.loading || this.state.report || !this.props.client.get('clientNumber')) {
      return;
    }

    this.setState({
      index: null,
      loading: true,
      report: null
    });

    this.props.fetchSyncOverlap(this.props.client.get('clientNumber')).then((response) => {
      this.setState({
        loading: false,
        report: response
      });
    });
  }

  onClickMatter(e) {
    const matterNumber = e.target.getAttribute('data-matter');
    const matter = this.props.matterList.find(m => m.get('matterNumber') === matterNumber);
    if (matter) {
      this.props.selectMatter(matter.get('id'));
    }
    this.setState({
      index: null
    });
  }

  onClickOneWay() {
    this.setState({
      confirmOneWay: true
    });
  }

  onClickTwoWay() {
    this.setState({
      confirmTwoWay: true
    });
  }

  onClickLeft() {
    this.setState({
      index: Math.max(0, this.state.index - 1)
    });
  }

  onClickRight() {
    this.setState({
      index: Math.min(this.getOverlaps().length - 1, this.state.index + 1)
    });
  }

  onCancelMerge() {
    this.setState({
      confirmOneWay: false,
      confirmTwoWay: false
    });
  }

  onAfterMerge() {
    this.onCancelMerge();
    this.loadOverlap();
  }

  onConfirmMerge() {
    const overlap = this.getCurrentOverlap();
    const matterNumber = overlap.target.family.split(',').shift();
    if (this.state.confirmOneWay) {
      this.props.addOneWay(matterNumber, this.onAfterMerge);
    } else if (this.state.confirmTwoWay) {
      this.props.addTwoWay([matterNumber], this.onAfterMerge);
    }
  }

  hasOverlaps() {
    return Boolean(this.getOverlaps().length);
  }

  getOverlaps() {
    if (!this.state.report) {
      return [];
    }

    const clientNumber = this.props.client.get('clientNumber');
    const matterNumber = this.props.matter.get('matterNumber');
    const report = this.state.report.find(item => item.clientNumber === clientNumber);

    if (!report) {
      return [];
    }

    return sortBy(
      report.overlaps.filter(item => item.source.family === matterNumber),
      [(overlap) => -overlap.overlap.percentage]
    );
  }

  getCurrentOverlap() {
    const overlaps = this.getOverlaps();

    if (this.state.index !== null) {
      return overlaps[this.state.index];
    }
    if (this.state.matterNumber !== null) {
      const matter = overlaps.find(item => item.target.family.split(',').includes(this.state.matterNumber));
      if (matter) {
        return matter;
      }
    }

    return overlaps[0];
  }

  renderOverlap() {
    if (this.state.loading) {
      return (
        <Loading />
      );
    }

    if (!this.state.report) {
      return (
        <Button style={{ marginTop: '10px' }} size='sm' onClick={this.onClickLoad}>
          Load Overlap Suggestions
        </Button>
      );
    }

    if (!this.hasOverlaps()) {
      return (
        <div style={{ paddingTop: '10px' }}>
          <p className='text-muted'>No overlapping matters found.</p>
        </div>
      );
    }

    const overlap = this.getCurrentOverlap();
    if (!overlap) {
      return;
    }

    const matters = overlap.target.family.split(',');
    const matterNumber = matters.shift();
    const count = this.getOverlaps().length;

    const styleItem = {
      height: '35px'
    };

    const styleButton = {
      margin: '1px 6px',
      position: 'relative',
      top: '-2px'
    };

    const styleArrow = {
      cursor: 'pointer',
      width: '20px',
      height: '16px',
      textAlign: 'center',
      display: 'inline-block'
    };

    return (
      <ul style={{ marginTop: '10px', marginLeft: '-10px' }}>
        <li style={styleItem}>
          {`Group ${this.state.index + 1} of ${count}`}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {Boolean(count > 1) && (
            <span>
              <span
                title='Previous'
                style={styleArrow}
                onClick={this.onClickLeft}
                className='fa fa-chevron-left'
              />
              &nbsp;&nbsp;&nbsp;
              <span
                title='Next'
                style={styleArrow}
                onClick={this.onClickRight}
                className='fa fa-chevron-right '
              />
            </span>
          )}
        </li>
        <li style={styleItem}>
          {matters.length ? null : 'Matter'}
          <span
            key={matterNumber}
            data-matter={matterNumber}
            onClick={this.onClickMatter}
            className='btn btn-xs btn-secondary'
            style={styleButton}
          >
            {matterNumber}
          </span>
          {matters.length === 1 ? `+${matters.length} matter` : null}
          {matters.length > 1 ? `+${matters.length} matters` : null}
        </li>
        <li style={styleItem}>
          {overlap.overlap.percentage}% document overlap
        </li>
        <li style={styleItem}>
          {overlap.overlap.count} total documents
        </li>
      </ul>
    );
  }

  renderButtons() {
    if (!this.hasOverlaps()) {
      return;
    }

    return (
      <div style={{ paddingLeft: '13px' }}>
        <Button
          onClick={this.onClickTwoWay}
          size='sm'
        >
          Two-Way
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          onClick={this.onClickOneWay}
          size='sm'
        >
          One-Way
        </Button>
      </div>
    );
  }

  renderConfirmMerge() {
    if (this.state.confirmTwoWay) {
      return (
        <ConfirmModal
          message='Are you sure you want to merge these two groups and all of the associated documents together? This operation CANNOT BE UNDONE.'
          onCancel={this.onCancelMerge}
          onConfirm={this.onConfirmMerge}
        />
      );
    }
    if (this.state.confirmOneWay) {
      return (
        <ConfirmModal
          message='Are you sure you want to add all of the documents from the similar group to the current group? This operation CANNOT BE UNDONE.'
          onCancel={this.onCancelMerge}
          onConfirm={this.onConfirmMerge}
        />
      );
    }
  }

  renderHelp() {
    return (
      <div>
        Subject Matter Suggestions
        &nbsp;&nbsp;&nbsp;
        <HelpIcon
          id='sync-panel'
          help='Suggests matters that cite many of the same documents as the current matter.'
        />
      </div>
    );
  }

  render() {
    return (
      <Field label={this.renderHelp()} style={{ paddingRight: '0' }}>
        <div>
          {this.renderOverlap()}
          {this.renderButtons()}
          {this.renderConfirmMerge()}
        </div>
      </Field>
    );
  }
}
