import { handleActions } from 'redux-actions';

import {
  CLIENT_MATTER_SELECT,
  CLIENT_SELECT
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [CLIENT_MATTER_SELECT]: (state, { payload }) => (payload.clientId || initialState),

  [CLIENT_SELECT]: (state, { payload }) => (payload.selectedClientId || initialState)

}, initialState);
