import { handleActions } from 'redux-actions';
import {
  NEAR_DUPLICATE_BEGIN,
  NEAR_DUPLICATE_SUCCESS,
  NEAR_DUPLICATE_ERROR,
  NEAR_DUPLICATE_PROGRESS,
  NEAR_DUPLICATE_CLEAR
} from '../../constants/actions';

const initialState = { total: 0, done: 0 };

export default handleActions({

  [NEAR_DUPLICATE_CLEAR]: () => (initialState),
  [NEAR_DUPLICATE_BEGIN]: () => (initialState),
  [NEAR_DUPLICATE_SUCCESS]: () => (initialState),
  [NEAR_DUPLICATE_ERROR]: () => (initialState),
  [NEAR_DUPLICATE_PROGRESS]: (state, { payload }) => payload

}, initialState);
