import _uniq from 'lodash/uniq';

const regex = /(\d{11})|(\d{10})|(\d{8})|(\d{7})|(\d{6})|(D\d{3},?\d{3,6})|(RE\d{2},?\d{3,5})|(\d{4}\/0\d{6})|(\d{4}\/\d{6})|(\d{4}-\d{7})|(\d{4}\/?\d{2},?\d{3},?\d{3},?)|(\d{4}\/?\d{1},?\d{3},?\d{3},?)|(\d{2},?\d{3},?\d{3},?)|(\d{1},?\d{3},?\d{3},?)|(D\d{5,6})/g;

export default function getUSDocs(value, addZero = true) {
  const match = value.match(regex) || [];
  return _uniq(match.map(n => n.replace(/\W/g, '')))
    .map(number => {
      if (addZero && number.length === 10) {
        return number.substr(0, 4) + 0 + number.substr(4);
      }
      return number;
    })
    .filter(number => !!number);
}
