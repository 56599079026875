import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Button, Modal } from 'react-bootstrap';
import autoBind from 'class-autobind';

export default class BatchCategoryConfirm extends Component {
  static propTypes = {
    docType: PropTypes.string.isRequired,
    selected: PropTypes.instanceOf(Immutable.List),
    unselected: PropTypes.instanceOf(Immutable.List),
    onConfirmType: PropTypes.func.isRequired,
    onConfirmAll: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    countAll: PropTypes.number.isRequired,
    countType: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true
    };
  }

  onCancel() {
    this.setState({ show: false });
    this.props.onCancel();
  }

  onClickType() {
    this.setState({ show: false });
    this.props.onConfirmType();
  }

  onClickAll() {
    this.setState({ show: false });
    this.props.onConfirmAll();
  }

  getButtonLabel() {
    switch (this.props.docType) {
      case 'US':
        return 'Only US Documents';
      case 'Other':
        return 'Only NPL Documents';
      case 'Foreign':
        return 'Only Foreign Documents';
      default:
    }
  }

  renderAdd() {
    if (this.props.selected.size) {
      return (
        <li>
          <strong>Add:</strong>
            &nbsp;
          {this.props.selected.join(', ')}
        </li>
      );
    }
  }

  renderRemove() {
    if (this.props.unselected.size) {
      return (
        <li>
          <strong>Remove:</strong>
          &nbsp;
          {this.props.unselected.join(', ')}
        </li>
      );
    }
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.onCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ textAlign: 'left' }}>
            Confirmation Required
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'left' }}>
          Are you sure you want to modify the categorization of all of the selected documents?
            <br />
            <br />
            <ul>
              {this.renderAdd()}
              {this.renderRemove()}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={this.onCancel}
          >
          Cancel
          </Button>
          <Button
            variant='primary'
            onClick={this.onClickAll}
          >
          All Documents ({this.props.countAll})
          </Button>
          <Button
            autoFocus
            variant='primary'
            onClick={this.onClickType}
          >
            {this.getButtonLabel()} ({this.props.countType})
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
