import { handleActions } from 'redux-actions';

import {
  AUTH_LOGIN_SUCCESS
} from '../../constants/actions';

const initialState = false;

export default handleActions({

  [AUTH_LOGIN_SUCCESS]: () => (true)

}, initialState);
