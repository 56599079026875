import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Button } from 'react-bootstrap';

export default class NearDuplicateEdit extends Component {
  static propTypes = {
    document: PropTypes.instanceOf(Immutable.Map).isRequired,
    groupIndex: PropTypes.number.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.value) {
      return {
        value: props.document.get('nonPatentBib')
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  onClickSave() {
    this.props.onSave(this.props.groupIndex, this.props.document, this.state.value);
  }

  onClickCancel() {
    this.props.onCancel(this.props.groupIndex, this.props.document);
  }

  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        <textarea
          cols={55}
          rows={3}
          style={{ display: 'block' }}
          value={this.state.value}
          onChange={this.onChange}
        />
        <Button
          size='sm'
          variant='primary'
          style={{ marginTop: '7px', width: '60px' }}
          onClick={this.onClickSave}
        >
          Save
        </Button>
        <Button
          size='sm'
          variant='secondary'
          style={{ marginTop: '7px', width: '60px' }}
          onClick={this.onClickCancel}
        >
          Cancel
        </Button>
      </div>
    );
  }
}
