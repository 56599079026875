import { createAction } from 'redux-actions';
import { UPDATE_MATTER_NOTES } from '../../constants/actions';
import { matterNotesUrl } from '../../constants/urls';
import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';

const updateMatterNotes = createAction(UPDATE_MATTER_NOTES);

export default (matterId, documentNumber, notes) => (dispatch, getState) => {
  dispatch(addNotification({
    id: `matter-notes-${documentNumber}`,
    type: 'progress',
    message: `Updating document ${documentNumber}...`
  }));

  return dispatch(callApi({
    method: 'PUT',
    url: `${matterNotesUrl}`,
    data: {
      matterId,
      documentNumber,
      notes
    }
  }))
    .then((response) => {
      dispatch(addNotification({
        id: `matter-notes-${documentNumber}`,
        type: 'success',
        message: `Updated document ${documentNumber}.`
      }));

      return dispatch(updateMatterNotes({
        matterId,
        documentNumber,
        notes
      }));
    })
    .catch(() => {
      dispatch(addNotification({
        id: `matter-notes-${documentNumber}`,
        type: 'error',
        message: `Failed to update document ${documentNumber}...`
      }));
    });
};
