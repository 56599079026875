import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button, FormGroup } from 'react-bootstrap';
import { isValidEmail } from '../../utils/text-utils';
import LoginModal from './LoginModal';
import EmailField from './EmailField';

export default class ResetPasswordForm extends Component {
  static propTypes = {
    email: PropTypes.string,
    onChangeEmail: PropTypes.func.isRequired,
    inProgress: PropTypes.bool,
    message: PropTypes.string,
    messageType: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getResetPasswordParams() {
    const { email } = this.props;
    if (isValidEmail(email)) {
      return [
        email
      ];
    }
  }

  onSubmit() {
    const params = this.getResetPasswordParams();
    params && this.props.onSubmit(...params);
  }

  onKeyPress(e) {
    if (e.which === 13) {
      this.onSubmit();
    }
  }

  onChangeEmail(e) {
    this.props.onChangeEmail({ email: e.target.value });
  }

  renderEmail() {
    return (
      <EmailField
        value={this.props.email}
        onKeyPress={this.onKeyPress}
        onChange={this.onChangeEmail}
        autoFocus
      />
    );
  }

  renderInputs() {
    if (this.props.messageType !== 'success') {
      return (
        <FormGroup className='mb-3'>
          {this.renderEmail()}
        </FormGroup>
      );
    }
  }

  renderButtons() {
    let buttons;
    if (this.props.messageType === 'success') {
      buttons = (
        <div>
          <Button size='sm' variant='primary' onClick={this.props.onClose}>
            Close
          </Button>
        </div>
      );
    } else {
      buttons = (
        <div>
          <Button size='sm' variant='secondary' onClick={this.props.onClose}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button size='sm' variant='primary' onClick={this.onSubmit}>
            {this.props.inProgress ? <span className='fa fa-spinner fa-spin' /> : 'Reset Password'}
          </Button>
        </div>
      );
    }
    return buttons;
  }

  render() {
    return (
      <LoginModal
        id='reset-password-modal'
        title='Reset Password'
        inputs={this.renderInputs()}
        buttons={this.renderButtons()}
        message={this.props.message}
        messageType={this.props.messageType}
        onClose={this.props.onClose}
      />
    );
  }
}
