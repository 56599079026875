import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';

export default (matterId, documentNumbers) => (dispatch) => {
  dispatch(addNotification({
    id: 'remove-non-prior-art-notification',
    type: 'progress',
    message: 'Removing non-prior art...'
  }));

  return dispatch(callApi({
    url: '/api/documents/remove-non-prior-art',
    method: 'POST',
    data: {
      matterId,
      documentNumbers
    }
  })).then(() => {
    dispatch(addNotification({
      id: 'remove-non-prior-art-notification',
      type: 'success',
      message: 'Finished removing non-prior art.'
    }));
  }, () => {
    dispatch(addNotification({
      id: 'remove-non-prior-art-notification',
      type: 'error',
      message: 'Failed to remove non-prior art.'
    }));
  });
};
