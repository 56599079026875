import Immutable from 'immutable';
import callApi from '../../utils/callApi';
import { documentsUrl } from '../../constants/urls';
import addNotification from '../app/addNotification';

export default (docToResolve) => (dispatch, getState) => {
  const { app: { selectedMatterId }, entities } = getState();
  const matter = entities.getIn(['matters', selectedMatterId]);
  const documents = entities.getIn(['documents', matter.get('clientNumber')]);

  const resolveDoc = (doc) => {
    dispatch(addNotification({
      id: `resolve-${doc.get('documentNumber')}`,
      type: 'progress',
      message: `Resolving ${doc.get('documentNumber')}...`
    }));
    return dispatch(callApi({
      url: `${documentsUrl}/${doc.get('id')}/resolve`,
      method: 'get'
    }))
      .catch(() => {
        dispatch(addNotification({
          id: `resolve-${doc.get('documentNumber')}`,
          type: 'error',
          message: `Resolved ${doc.get('documentNumber')}.`
        }));
      })
      .then((r) => {
        dispatch(addNotification({
          id: `resolve-${doc.get('documentNumber')}`,
          type: 'success',
          message: `Resolved ${doc.get('documentNumber')}.`
        }));
      });
  };

  let docsToResolve = docToResolve
    ? Immutable.fromJS([docToResolve.toJS()])
    : Immutable.List()
      .concat(matter.get('citedArt', []))
      .concat(matter.get('uncitedArt', []))
      .filter(n => !!n)
      .map(n => documents.get(n))
      .filter(doc => {
        return doc.get('type') === 'Foreign' && doc.get('country') && (
          !doc.get('applicant') || !doc.get('pdfUrl')
        );
      });

  const resolveNext = () => {
    resolveDoc(docsToResolve.last()).then(() => {
      docsToResolve = docsToResolve.pop();
      if (docsToResolve.size) {
        resolveNext();
      }
    });
  };

  if (docsToResolve.size) {
    resolveNext();
  } else {
    dispatch(addNotification({
      id: `resolve-${selectedMatterId}`,
      type: 'warn',
      message: 'No documents to resolve.'
    }));
  }
};
