import React, { Component } from 'react';
import autoBind from 'class-autobind';
import PropTypes from 'prop-types';
import HelpIcon from '../shared/HelpIcon';

export default class PatentBots extends Component {
  static propTypes = {
    logAction: PropTypes.func.isRequired,
    section: PropTypes.string.isRequired,
    clientNumber: PropTypes.string.isRequired,
    matterNumber: PropTypes.string.isRequired,
    param: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  renderHelp() {
    return (
      <HelpIcon
        onClick={this.onClick}
        placement='top'
        styleIcon={{ marginLeft: '4px' }}
        stylePopover={{ maxWidth: '450px' }}
        id='help-copy-matter'
        help='Through our partnership with Patent Bots, click here to view a family tree, prosecution timeline, and image file wrapper from PAIR.'
      />
    );
  }

  onClickLink(e, noLog) {
    if (!noLog) {
      this.props.logAction({
        action: 'PatentBots',
        misc: this.props.section,
        clientNumber: this.props.clientNumber,
        matterNumber: this.props.matterNumber,
        documentNumber: this.props.param
      });
    }

    e.preventDefault();
    window.open(`https://www.patentbots.com/patentplex/${this.props.type}/${this.props.param}`);
  }

  render() {
    return (
      <div>
        <span onClick={this.onClickLink}>
          <span style={{ color: 'white' }}>Patent</span> <span style={{ color: '#009fe3' }}>Bots</span>
        </span>
        {this.renderHelp()}
      </div>
    );
  }
}
