import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class UploadPaymentsIntro extends Component {
  static propTypes = {
    title: PropTypes.bool
  };

  render() {
    return (
      <div>
        {this.props.title ? <h4>Upload payments</h4> : <br />}
        <p>This accounting tool is used to enter received checks.</p>
        <p>The input is a firm name, an invoice number, and the amount paid. Once confirmed, the amount paid is entered in to the DB.</p>
      </div>
    );
  }
};
