import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import DocumentRecord from '../../models/DocumentRecord';
import SimpleSuggest from '../shared/SimpleSuggest';

const iconQuoteLeft = (
  <span
    className='fa fa-quote-left' style={{
      fontSize: '8px',
      marginRight: '5px',
      position: 'relative',
      top: '-5px'
    }}
  />
);

const iconQuoteRight = (
  <span
    className='fa fa-quote-right' style={{
      fontSize: '8px',
      marginLeft: '5px',
      position: 'relative',
      top: '-5px'
    }}
  />
);

export default class DocumentDescription extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    doc: PropTypes.instanceOf(DocumentRecord).isRequired,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map),
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    clearNplDocuments: PropTypes.func.isRequired,
    updateDoc: PropTypes.instanceOf(DocumentRecord), // eslint-disable-line
    changeOnSelect: PropTypes.bool,
    dropdownTop: PropTypes.bool,
    searchCurrentValue: PropTypes.bool,
    emptyOnBlur: PropTypes.bool
  };

  static defaultProps = {
    doc: new DocumentRecord(),
    emptyOnBlur: true
  };

  static getDerivedStateFromProps(props, state) {
    if (
      !Immutable.is(props.doc, state.doc) ||
      (props.updateDoc && props.updateDoc.get('id') === state.doc.get('id'))
    ) {
      return {
        doc: props.doc,
        value: props.doc.get(props.field) || ''
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  isDisabled() {
    if (this.props.doc.get('type') !== 'US' && this.props.features.limitedDocs) {
      return true;
    }

    return this.props.disabled;
  }

  raiseChange() {
    const value = this.state.value.trim();
    const { doc, field } = this.props;
    const currentValue = (doc.get(field) || '').trim();
    if (currentValue !== value) {
      const matchDoc = this.getSuggestionsList().find(suggestion => (
        suggestion.get('documentNumber') === value || suggestion.get('nonPatentBib') === value
      ));
      this.props.onChange(doc, field, value, matchDoc);
      if (!doc.isSaved() && this.props.emptyOnBlur) {
        this.setState({ value: '' });
      }
    }
  }

  onChange(value) {
    this.onSearch(value);
    this.setState({ value });
  }

  onClear() {
    this.setState({ value: this.props.doc.get(this.props.field) });
  }

  onSubmit(value) {
    value = value.split(' - ').slice(1).join(' - ');
    this.setState({ value }, () => {
      if (this.props.changeOnSelect) {
        this.raiseChange();
      }
    });
  }

  onAutosuggestKeyDown(event) {
    // this needs to run at next tick for the correct active suggestion
    // since the onKeyDown event runs before its updated
    setTimeout(container => {
      const active = container.getElementsByClassName('active')[0];
      if (active) {
        const list = active.parentNode;
        const height = list.offsetHeight;
        if (list.scrollHeight > height) {
          list.scrollTop = active.offsetTop - (height / 2);
        }
      }
    }, 0, event.target.parentNode);
  }

  onFocus() {
    this.onSearch(this.state.value);
  }

  onBlur() {
    this.props.clearNplDocuments();
    this.raiseChange();
  }

  renderSuggestion(suggestion) {
    return (
      <a
        onClick={e => e.preventDefault()}
        href='#'
        tabIndex='-1'
        style={{ margin: '5px 0' }}
      >
        {iconQuoteLeft}
        {suggestion}
        {iconQuoteRight}
      </a>
    );
  }

  onSearch(value) {
    if (!value || value.length < 3 || (!this.props.searchCurrentValue && value === this.props.doc.get(this.props.field))) {
      return false;
    }
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = undefined;
    }
    this.searchTimeout = setTimeout(this.props.onSearch, 500, value, this.props.doc);
  }

  getSuggestionsList() {
    if (!this.props.nplSuggestions) {
      return Immutable.List();
    }
    return Immutable.List(this.props.nplSuggestions.valueSeq());
  }

  getSuggestions() {
    return this.getSuggestionsList()
      .map(doc => doc.get('documentNumber') + ' - ' + doc.get('nonPatentBib'));
  }

  render() {
    return (
      <SimpleSuggest
        optimizeDropdown
        disabled={this.isDisabled()}
        name={this.props.field}
        value={this.state.value}
        type='textarea'
        rows={3}
        autoSubmit
        dropdownTop={this.props.dropdownTop}
        className='form-control input-sm document-input'
        onKeyDown={this.onAutosuggestKeyDown}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        allSuggestions={this.getSuggestions()}
      />
    );
  }
}
