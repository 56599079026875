import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import ConfirmFileModal from '../dashboard/ConfirmFileModal';
import Immutable from 'immutable';
import { Button } from 'react-bootstrap';

export default class CitedHeader extends Component {
  static propTypes = {
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    features: PropTypes.object.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    fileMatter: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired,
    notesSearch: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      showConfirm: false
    };
  }

  onCloseModal() {
    this.setState({
      showConfirm: false
    });
  }

  renderConfirm() {
    return this.state.showConfirm && (
      <ConfirmFileModal
        hideClearFileReminder
        origin='CitedHeader'
        features={this.props.features}
        matter={this.props.matter}
        client={this.props.client}
        logAction={this.props.logAction}
        setFileReminder={this.props.setFileReminder}
        fileMatter={this.props.fileMatter}
        visibleDocuments={this.props.visibleDocuments}
        subsetIDSEnabled={Boolean(this.props.notesSearch)}
        onClose={this.onCloseModal}
      />
    );
  }

  isClickable() {
    return Boolean(
      this.props.features.admin &&
      this.props.matter.get('uncitedArt').size > 0 &&
      this.props.matter.get('matterClosed') !== 'ON'
    );
  }

  onClick() {
    this.setState({
      showConfirm: true
    });
  }

  render() {
    if (!this.isClickable()) {
      return 'Cited';
    }

    return (
      <Button
        style={{ width: '44px', paddingLeft: '0', paddingRight: '0' }}
        onClick={this.onClick}
        size='sm'
        variant='primary'
      >
        Cited
        {this.renderConfirm()}
      </Button>
    );
  }
}
