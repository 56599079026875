import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import MatterRelationInput from '../matter/MatterRelationInput';
import Field from '../shared/Field';
import SyncFamily from './SyncFamily';
import { splitCommaUnique } from '../../utils/text-utils';
import HelpIcon from '../shared/HelpIcon';

export default class SyncTwoWay extends Component {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired,
    addTwoWay: PropTypes.func.isRequired,
    createMatter: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    removeTwoWay: PropTypes.func.isRequired,
    selectMatter: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getCount() {
    return splitCommaUnique(this.props.matter.get('twoWayRelatedMatters') || '').length;
  }

  renderHelp() {
    return (
      <div>
        Two-Way Syncs ({this.getCount()})
        &nbsp;&nbsp;&nbsp;
        <HelpIcon
          id='sync-panel'
          help='Automatically cross-cites all of the references for a group of matters in both directions.'
        />
      </div>
    );
  }

  render() {
    return (
      <Field label={this.renderHelp()}>
        <div>
          <SyncFamily
            showEmpty
            showRemove
            field='twoWayRelatedMatters'
            client={this.props.client}
            matter={this.props.matter}
            matterList={this.props.matterList}
            selectMatter={this.props.selectMatter}
            onRemove={this.props.removeTwoWay}
          />
          <MatterRelationInput
            confirmOnBlur
            confirmAdd
            preventSpaces
            features={this.props.features}
            confirmMessage={'Are you sure you want to add matter "$matterNumber$", all of its related matters, and all of the associated documents to this group?" This operation CANNOT BE UNDONE.'}
            placeholder='Enter a New/Existing Matter ID'
            onAdd={this.props.addTwoWay}
            createMatter={this.props.createMatter}
            addNotification={this.props.addNotification}
            matterNumber={this.props.matter.get('matterNumber') || ''}
            matterList={this.props.matterList}
          />
        </div>
      </Field>
    );
  }
}
