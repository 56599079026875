import Immutable from 'immutable';
import DocumentRecord from '../models/DocumentRecord';

export function getVisibleDocuments(state, checkCited) {
  const {
    app: {
      selectedMatterId
    },
    documents: {
      notesTab,
      notesSearch,
      citedFilter
    },
    entities,
    matterNotes
  } = state;

  const matter = entities.getIn(['matters', selectedMatterId]) || Immutable.Map();
  const documents = entities.getIn(['documents', matter.get('clientNumber')]) || Immutable.Map();

  const getMatterNotes = (doc) => {
    if (!matterNotes[selectedMatterId]) {
      return '';
    }
    const item = matterNotes[selectedMatterId].find(note => note.documentNumber === doc.get('documentNumber'));
    return item && item.notes ? item.notes : '';
  };

  const getArts = (field, cited) => matter.get(field, [])
    .map(number => documents.get(number))
    .filter(doc => doc)
    .map(doc => new DocumentRecord(doc.set('cited', cited).set('matterNotes', getMatterNotes(doc))));

  const citedArt = checkCited && (citedFilter === 'all' || citedFilter === 'cited') ? getArts('citedArt', true) : Immutable.List();
  const uncitedArt = (citedFilter === 'all' || citedFilter === 'uncited' || !checkCited) ? getArts('uncitedArt', false) : Immutable.List();

  let docs = citedArt.concat(uncitedArt);

  if (notesSearch) {
    const negationRegex = /^[\s]*!/;
    const isNegation = negationRegex.test(notesSearch);
    const searchRegex = new RegExp(notesSearch.replace(negationRegex, ''), 'i');

    docs = docs.filter(doc => isNegation !== searchRegex.test(doc.getNotes(notesTab)));
  }

  return Immutable.List(docs);
};
