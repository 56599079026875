import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button } from 'react-bootstrap';
import Immutable from 'immutable';

export default class SweepOutput extends Component {
  static propTypes = {
    record: PropTypes.object,
    createSweepFile: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClick() {
    this.props.createSweepFile(Immutable.List([this.props.record]));
  }

  getFileName() {
    return this.props.record.get('firmName') + '-' + this.props.record.get('client') + '.txt';
  }

  renderIcon() {
    return (
      <i
        style={{ marginRight: '5px' }}
        className={this.props.record.get('loading') ? 'fa fa-spinner fa-spin' : 'fa fa-file'}
      />
    );
  }

  render() {
    return (
      <Button
        style={{ width: '200px' }}
        disabled={this.props.record.get('loading')}
        size='sm'
        onClick={this.onClick}
      >
        {this.renderIcon()}
        {this.getFileName()}
      </Button>
    );
  }
};
