import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from 'react-bootstrap';
import FieldGroup from '../shared/FieldGroup';
import LoginModal from './LoginModal';
import autoBind from 'class-autobind';

export default class ChangePasswordForm extends Component {
  static propTypes = {
    message: PropTypes.string,
    messageType: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    inProgress: PropTypes.bool
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  getChangePasswordParams() {
    const { password, newPassword, confirmPassword } = this.state;
    if (password && newPassword && confirmPassword) {
      return [
        password,
        newPassword,
        confirmPassword
      ];
    }
  }

  onSubmit() {
    const params = this.getChangePasswordParams();
    params && this.props.onSubmit(...params);
  }

  onKeyPress(e) {
    if (e.which === 13) {
      this.onSubmit();
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  renderPassword(key, label) {
    return (
      <FieldGroup
        type='password'
        label={label}
        name={key}
        spellCheck={false}
        value={this.state[key] || ''}
        onKeyPress={this.onKeyPress}
        onChange={this.onChange}
      />
    );
  }

  renderInputs() {
    return (
      <div>
        <FormGroup className='mb-3'>
          {this.renderPassword('password', 'Current Password')}
        </FormGroup>
        <FormGroup className='mb-3'>
          {this.renderPassword('newPassword', 'New Password')}
        </FormGroup>
        <FormGroup className='mb-3'>
          {this.renderPassword('confirmPassword', 'Confirm New Password')}
        </FormGroup>
      </div>
    );
  }

  renderButtons() {
    return (
      <div>
        <Button size='sm' variant='secondary' onClick={this.props.onClose}>
          Cancel
        </Button>
        &nbsp;&nbsp;
        <Button size='sm' variant='primary' onClick={this.onSubmit}>
          {this.props.inProgress ? <span className='fa fa-spinner fa-spin' /> : 'Change Password'}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <LoginModal
        id='change-password-modal'
        title='Change Password'
        inputs={this.renderInputs()}
        buttons={this.renderButtons()}
        message={this.props.message}
        messageType={this.props.messageType}
        onClose={this.props.onClose}
      />
    );
  }
}
