import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import PromptModal from '../shared/PromptModal';
import CopyFromMatter from './CopyFromMatter';
import HelpIcon from '../shared/HelpIcon';
import Form from '../shared/Form';
import Fieldset from '../shared/Fieldset';
import { stripMatterNumber } from '../../utils/matter';
import Help from '../shared/Help';
import MatterInput from './MatterInput';
import Field from '../shared/Field';

export default class MatterPromptNew extends Form {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    focusPrompt: PropTypes.bool,
    focusCancel: PropTypes.bool,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired,
    matterNumber: PropTypes.string
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      copyMatter: '',
      copyDeletedArt: false,
      migrateLegacy: false,
      applicationNumber: '',
      matterClosed: 'OFF',
      parentMatterNumber: ''
    };
  }

  onCopyChange(newValue) {
    this.setState({
      copyMatter: stripMatterNumber(newValue)
    });
  }

  onKeyDown(ev) {
    if (ev.which === 32) {
      ev.preventDefault();
    }
  }

  onMigrateLegacyChange(e) {
    this.setState({
      migrateLegacy: e.target.checked
    });
  }

  onDeletedArtChange(e) {
    this.setState({
      copyDeletedArt: e.target.checked
    });
  }

  getCopyMatterId() {
    const matterNumber = this.state.copyMatter;
    const matter = this.props.matterList.find(m => m.get('matterNumber') === matterNumber);
    return matter ? matter.get('id') : null;
  }

  getParentMatterNumber() {
    const matterNumber = this.state.parentMatterNumber;
    const matter = this.props.matterList.find(matter => matter.get('matterNumber') === matterNumber);
    return matter ? matter.get('matterNumber') : null;
  }

  onConfirm(matterNumber) {
    this.props.onConfirm({
      matterNumber,
      copyMatter: this.getCopyMatterId(),
      copyDeletedArt: this.state.copyDeletedArt,
      migrateLegacy: this.state.migrateLegacy,
      applicationNumber: this.state.applicationNumber,
      matterClosed: this.state.matterClosed,
      twoWayRelatedMatters: this.getParentMatterNumber()
    });
  }

  renderCopyFromMatter() {
    return (
      <div>
        <CopyFromMatter
          onChange={this.onCopyChange}
          matterList={this.props.matterList}
          matterNumber={this.state.copyMatter}
        />
        <div className='checkbox'>
          {this.renderCopyDeletedArt()}
        </div>
        <div className='clearfix' />
      </div>
    );
  }

  renderCopyDeletedArt() {
    return (
      <label>
        <input
          onChange={this.onDeletedArtChange}
          type='checkbox'
          name='copyDeletedArt'
          checked={this.state.copyDeletedArt}
        />
        &nbsp; Copy Deleted Art history
        <HelpIcon
          styleIcon={{ marginLeft: '4px' }}
          stylePopover={{ maxWidth: '450px' }}
          id='help-copy-deleted-art'
          help='Check this to copy Deleted Art history as well.'
        />
      </label>
    );
  }

  renderMigrateLegacy() {
    return (
      <div className='checkbox'>
        <label>
          <input
            onChange={this.onMigrateLegacyChange}
            type='checkbox'
            name='copyDeletedArt'
            checked={this.state.migrateLegacy}
          />
          &nbsp; Migrate Legacy Data
          <HelpIcon
            styleIcon={{ marginLeft: '4px' }}
            stylePopover={{ maxWidth: '450px' }}
            id='help-migrate-legacy'
            help={'Check this to have SyncIDS enter your historical data, including this matter\'s bibliographic data, prior art from this matter\'s file history, and family relationships for automatic cross-citing.'}
          />
        </label>
      </div>
    );
  }

  renderApplicationNumber() {
    return this.renderInput(
      'Application Number',
      'applicationNumber',
      null,
      this.state.migrateLegacy,
      false,
      '17/123,456 or CN202301234'
    );
  }

  renderMigrationMessage() {
    if (!this.state.migrateLegacy) {
      return;
    }

    return (
      <div className='row' style={{ padding: '15px' }}>
        <span className='fa fa-exclamation-triangle text-warning' />
        &nbsp;
        Please allow up to 10 business days to complete this migration. There might be a fee for this service. If you have any questions, please contact info@syncids.com.
      </div>
    );
  }

  renderClosed() {
    const label = (
      <span>
        Stop IDSs
        <HelpIcon
          placement='top'
          style={{ position: 'relative', left: '10px', top: '-2px' }}
          id='help-closed'
          help='No IDSs will be generated for this matter, but it will contribute its current and future art to synchronized matters. This box should be checked for issued, abandoned, and most foreign matters.'
        />
      </span>
    );

    return (
      <div>
        {this.renderCheckbox(label, 'matterClosed', ['OFF', 'ON'])}
        <Help text='(e.g., issued, foreign)' />
      </div>
    );
  }

  onChangeParentMatter(newValue) {
    this.setState({
      parentMatterNumber: newValue
    });
  }

  onBlurParentMatter() {
    const matterNumber = this.state.parentMatterNumber;
    const matter = this.props.matterList.find(matter => matter.get('matterNumber') === matterNumber);

    this.setState({
      parentMatterNumber: matter ? matter.get('matterNumber') : null
    });
  }

  renderParentMatter() {
    return (
      <Field label='Parent Matter ID'>
        <MatterInput
          name='parentMatterId'
          id='parentMatterId'
          value={this.state.parentMatterNumber}
          onChange={this.onChangeParentMatter}
          onSubmit={this.onChangeParentMatter}
          onBlur={this.onBlurParentMatter}
          matterList={this.props.matterList}
        />
      </Field>
    );
  }

  renderBody() {
    return (
      <Fieldset title='Optional' style={{ marginTop: '20px' }}>
        <div className='row' style={{ marginTop: '10px' }}>
          <div className='col-md-6'>
            {this.renderParentMatter()}
            {this.renderCopyFromMatter()}
          </div>
          <div className='col-md-6'>
            {this.renderApplicationNumber()}
            {this.renderClosed()}
            {this.renderMigrateLegacy()}
          </div>
        </div>
        {this.renderMigrationMessage()}
      </Fieldset>
    );
  }

  render() {
    return (
      <PromptModal
        size='lg'
        defaultValue={this.props.matterNumber}
        placeholder='Ex. 0001US'
        onConfirm={this.onConfirm}
        onCancel={this.props.onCancel}
        confirmDisabled={this.state.migrateLegacy ? !this.state.applicationNumber : false}
        body={this.renderBody()}
        confirmOnEnter
        stripValue={stripMatterNumber}
        message='New matter'
      />
    );
  }
}
