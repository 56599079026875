import callApi from '../../utils/callApi';
import { documentsUrl } from '../../constants/urls';
import { getDocNumbersFromMatter } from '../../../utils/get-doc-numbers-from-matter';
import deleteMissingDocumentsFromMatter from './deleteMissingDocumentsFromMatter';

export default (matter, docNumbers) => (dispatch, getState) => {
  const firmName = matter.get('firmName');
  const clientNumber = matter.get('clientNumber');
  const documentNumbers = docNumbers || getDocNumbersFromMatter(matter);

  if (!documentNumbers.length) {
    return Promise.resolve();
  }

  return dispatch(callApi({
    url: documentsUrl + '/get-multiple',
    method: 'post',
    params: { firmName, clientNumber },
    data: { documentNumbers }
  })).then((response) => (
    dispatch(deleteMissingDocumentsFromMatter(matter, documentNumbers, response))
  ));
};
