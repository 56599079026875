import { parse, parseISO, format, isValid, subMonths, compareAsc, isAfter as isAfterFns } from 'date-fns';

const dateFormat = 'MM/dd/yyyy';
const dateFormatTime = 'MM/dd/yyyy hh:mm';

function parseDate(value) {
  return parse(value, dateFormat, new Date());
}

export function isValidDate(value) {
  return isValid(parseDate(value)) || isValid(parseISO(value));
}

export function isAfter(baseDate, compareDate) {
  return isAfterFns(parseDate(baseDate), parseDate(compareDate));
}

export function toISODate(value) {
  const date = parseDate(value);
  if (isValid(date)) {
    return date.toISOString();
  }
}

export function formatDate(value) {
  const date = parseISO(value);
  if (isValid(date)) {
    return format(date, dateFormat);
  } else if (isValid(parse(value, 'M/d/yyyy', new Date()))) {
    return format(parse(value, 'M/d/yyyy', new Date()), dateFormat);
  }
  return value;
}

export function formatDateTime(value) {
  const date = parseISO(value);
  if (isValid(date)) {
    return format(date, dateFormatTime);
  }
  return value;
}

export function isRecentMonths(value, months) {
  const date = parseISO(value);
  if (isValid(date)) {
    return compareAsc(date, subMonths(new Date(), 3)) === 1;
  }
  return false;
}
