import { createAction } from 'redux-actions';
import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { MIGRATION_CONFIRM_TWOWAY } from '../../constants/actions';
import refreshTodos from '../todo/refreshTodos';

const confirmTwoWayMigration = createAction(MIGRATION_CONFIRM_TWOWAY);

export default (families) => (dispatch, getState) => {
  dispatch(addNotification({
    id: 'confirmTwoWayMigration',
    type: 'progress',
    message: 'Syncing families...'
  }));
  return dispatch(callApi({
    url: '/api/migrations/create-families',
    method: 'POST',
    data: families
  }))
    .then(response => {
      dispatch(addNotification({
        id: 'confirmTwoWayMigration',
        type: 'success',
        message: 'Sync Done!'
      }));
      dispatch(confirmTwoWayMigration(response));
      dispatch(refreshTodos());
    })
    .catch(() => {
      dispatch(addNotification({
        id: 'confirmTwoWayMigration',
        type: 'error',
        message: 'Sync Failed!'
      }));
    });
};
