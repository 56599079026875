import { handleActions } from 'redux-actions';
import {
  LOAD_MATTER_NOTES,
  UPDATE_MATTER_NOTES
} from '../../constants/actions';

const initialState = {};

export default handleActions({

  [LOAD_MATTER_NOTES]: (state, { payload }) => {
    return Object.assign({}, state, {
      [payload.matterId]: payload.notes
    });
  },

  [UPDATE_MATTER_NOTES]: (state, { payload }) => {
    const notes = state[payload.matterId] || [];
    const index = notes.findIndex(item => item.documentNumber === payload.documentNumber);
    if (index === -1) {
      notes.push({
        documentNumber: payload.documentNumber,
        notes: payload.notes
      });
    } else {
      notes[index].notes = payload.notes;
    }
    return Object.assign({}, state, {
      [payload.matterId]: notes
    });
  }

}, initialState);
