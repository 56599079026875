import React, { Component } from 'react';
import imageLogo from '../../files/logo-w.png';

const styleImage = {
  position: 'relative',
  display: 'inline-block',
  width: '155px',
  height: '50px'
};

export default class Logo extends Component {
  render() {
    return (
      <img
        style={styleImage}
        title='SyncIDS'
        alt='SyncIDS'
        src={imageLogo}
      />
    );
  }
};
