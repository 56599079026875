import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import DocumentRecord from '../../models/DocumentRecord';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import PatentBots from '../matter/PatentBots';
import MenuItemHelp from '../shared/MenuItemHelp';
import { isCategoryMatter } from '../../utils/categories';

export default class DocumentActions extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    doc: PropTypes.instanceOf(DocumentRecord),
    matter: PropTypes.object.isRequired,
    logAction: PropTypes.func.isRequired,
    showDeduplicate: PropTypes.bool,
    showResolve: PropTypes.bool,
    onAction: PropTypes.func.isRequired,
    showOtherRef: PropTypes.bool.isRequired,
    showPatentBots: PropTypes.bool
  };

  static defaultProps = {
    doc: new DocumentRecord(),
    showOtherRef: false
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  isDisabled() {
    return this.props.doc.get('type') !== 'US' && this.props.features.limitedDocs;
  }

  onToggle(open) {
    this.setState({ open });
  }

  onAction(action) {
    if (action) {
      this.props.onAction(this.props.doc, action);
    }
  }

  isDeletedDocument() {
    return this.props.matter.get('deletedArt').includes(this.props.doc.get('documentNumber'));
  }

  isCategoryMatter() {
    return isCategoryMatter(this.props.matter.get('matterNumber'));
  }

  renderOtherRef() {
    return (
      <Dropdown.Item eventKey='toggleOtherRef'>
        <span className={this.props.doc.get('isOtherNpl')
          ? 'fa fa-check-square-o text-success'
          : 'fa fa-square-o'}
        />
        &nbsp;
        Upload as "Other reference"
      </Dropdown.Item>
    );
  }

  onClickPatentBots(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.patentBots) {
      this.patentBots.onClickLink(e, e.target.nodeName !== 'DIV');
    }
  }

  patentBotsRef(ref) {
    this.patentBots = ref;
  }

  renderMenu() {
    return this.state.open && (
      <>
        {!this.isDeletedDocument() && !this.isCategoryMatter() && (
          <>
            <Dropdown.Item eventKey='deleteFromMatter'>
              Delete from just this Matter
            </Dropdown.Item>
            <MenuItemHelp
              id='deleteFromFamily'
              label='Delete from Family'
              help='Delete from all matters in two-way sync box.'
              eventKey='deleteFromFamily'
            />
            <Dropdown.Item eventKey='deleteFromClient'>
              Delete from <strong>ALL</strong> Matters
              &nbsp;<span className='fa fa-exclamation-triangle' />
            </Dropdown.Item>
            <Dropdown.Divider />
          </>
        )}
        {(this.props.showDeduplicate || this.props.showResolve) && (
          <>
            {this.props.showDeduplicate && (
              <MenuItemHelp
                id='deduplicate'
                help='Click to De-Duplicate NPL documents.'
                label='De-duplicate'
                eventKey='deduplicate'
                placement='top'
              />
            )}
            {this.props.showResolve && (
              <Dropdown.ItemHelp
                id='resolveForeign'
                help='Click to look up applicant and PDF for this document.'
                label='Look up Applicant'
                eventKey='resolveForeign'
                placement='top'
              />
            )}
            <Dropdown.Divider />
          </>
        )}
        {this.props.showOtherRef && (
          <>
            {this.renderOtherRef()}
            <Dropdown.Divider />
          </>
        )}
        <Dropdown.Item eventKey='documentHistory'>
          Document History
        </Dropdown.Item>
        {this.props.showPatentBots && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item onClick={this.onClickPatentBots}>
              <PatentBots
                ref={this.patentBotsRef}
                section='Document Section'
                logAction={this.props.logAction}
                clientNumber={this.props.doc.get('clientNumber')}
                matterNumber={this.props.matter.get('matterNumber')}
                type='patent'
                param={this.props.doc.get('documentNumber')}
              />
            </Dropdown.Item>
          </>
        )}
      </>
    );
  }

  render() {
    if (!this.props.doc.isSaved()) {
      return (
        <div className='btn btn-sm' style={{ visibility: 'hidden' }}>
          <span className='fa fa-gear' />
        </div>
      );
    }

    return (
      <DropdownButton
        id={`gear-${this.props.doc.get('documentNumber')}`}
        className='no-caret'
        disabled={this.isDisabled()}
        show={this.state.open}
        onToggle={this.onToggle}
        onSelect={this.onAction}
        size='sm'
        variant='secondary'
        title={<span className='fa fa-gear' />}
      >
        {this.renderMenu()}
      </DropdownButton>
    );
  }
}
