import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Modal, Button } from 'react-bootstrap';
import NearDuplicateGroup from './NearDuplicateGroup';
import NearDuplicateRegroup from './NearDuplicateRegroup';
import Loading from '../shared/Loading';
import { splitCommaUnique, joinCommaUnique } from '../../utils/text-utils';
import NearDuplicateForm from './NearDuplicateForm';

export default class NearDuplicateModal extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    progress: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    shingle: PropTypes.number.isRequired,
    threshold: PropTypes.number.isRequired,
    firmName: PropTypes.string.isRequired,
    clientNumber: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    replaceDocNumber: PropTypes.func.isRequired,
    updateDocument: PropTypes.func.isRequired,
    fetchNearDuplicate: PropTypes.func.isRequired,
    clearNearDuplicate: PropTypes.func.isRequired,
    groups: PropTypes.instanceOf(Immutable.List),
    isLoadingGroups: PropTypes.bool.isRequired,
    nplAppNumber: PropTypes.bool,
    nplDate: PropTypes.bool,
    nplPageRange: PropTypes.bool,
    nplSerialBegin: PropTypes.string,
    nplSerialEnd: PropTypes.string
  };

  static getDerivedStateFromProps(props, state) {
    if (props.groups && !state.groups) {
      return {
        groups: props.groups,
        running: false
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      running: false,
      show: true,
      type: props.type,
      threshold: props.threshold,
      shingle: props.shingle,
      nplSerialBegin: props.nplSerialBegin,
      nplSerialEnd: props.nplSerialEnd,
      isDeduplicating: false
    };
  }

  onChangeType(value) {
    this.setState({
      type: value
    });
  }

  onChangeThreshold(value) {
    this.setState({
      threshold: Number(value) / 10
    });
  }

  onChangeShingle(value) {
    this.setState({
      shingle: Number(value)
    });
  }

  onChangeNPLSerial(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  isComplete() {
    return this.state.groups && this.state.groups.every((group, groupIndex) => (
      this.getGroupWinner(groupIndex) && this.getGroupDocuments(group).size <= 1
    ));
  }

  getGroupDocuments(group) {
    return group.get('documents').filter(doc => (
      !doc.get('removed') &&
      !doc.get('deduped') &&
      !doc.get('loser')
    ));
  }

  hasDocuments(group) {
    return group.get('documents')
      .filter(doc => !doc.get('removed'))
      .size > 0;
  }

  getRemovedDocuments() {
    let documents = Immutable.List();
    this.state.groups.forEach((group, groupIndex) => {
      const groupDocs = group.get('documents')
        .filter(doc => doc.get('removed'))
        .filter(doc => !doc.get('loser'))
        .map(doc => doc.set('groupIndex', groupIndex));
      groupDocs.forEach(doc => {
        const included = !!documents.find(removedDoc => (
          removedDoc.get('documentNumber') === doc.get('documentNumber')
        ));
        if (!included) {
          documents = documents.push(doc);
        }
      });
    });
    return documents;
  }

  removeGroup(groupIndex) {
    this.setState({
      groups: this.state.groups.deleteIn([groupIndex])
    });
  }

  async removeLoserDocument(doc) {
    for (let i = 0; i < this.state.groups.size; i++) {
      await this.setDocumentProp(i, doc, 'loser', true);
    }
  }

  setDocumentProp(groupIndex, doc, prop, value) {
    return new Promise(resolve => {
      const docIndex = this.state.groups
        .getIn([groupIndex, 'documents'])
        .findIndex(d => d.get('id') === doc.get('id'));

      if (docIndex !== -1) {
        this.setState({
          groups: this.state.groups.setIn([groupIndex, 'documents', docIndex, prop], value)
        }, resolve);
      } else {
        resolve();
      }
    });
  }

  setGroupProp(groupIndex, prop, documentNumber) {
    return new Promise(resolve => {
      this.setState({
        groups: this.state.groups.setIn([groupIndex, prop], documentNumber)
      }, resolve);
    });
  }

  onClickClose() {
    this.setState({ show: false });
    this.props.onClose();
  }

  onClickIgnore(groupIndex, ignoreDoc) {
    const group = this.state.groups.get(groupIndex);
    const otherDocuments = group.get('documents')
      .filter(doc => !doc.get('removed'))
      .filter(doc => doc.get('documentNumber') !== ignoreDoc.get('documentNumber'))
      .map(doc => doc.get('documentNumber'))
      .toJS();

    this.props.updateDocument({
      id: ignoreDoc.get('id'),
      nearDupIgnore: joinCommaUnique(splitCommaUnique(ignoreDoc.get('ignore') || '').concat(otherDocuments))
    }, ignoreDoc.get('documentNumber'));

    this.setDocumentProp(groupIndex, ignoreDoc, 'removed', true);
  }

  getGroupWinner(groupIndex) {
    const group = this.state.groups.get(groupIndex);
    const winnerDocument = group.get('winner');
    if (winnerDocument) {
      return winnerDocument;
    }

    if (this.props.type === 'foreign-kind') {
      const documents = this.getGroupDocuments(group);
      const winner = documents.find(doc => (
        documents.every(d => (
          d.get('documentNumber') === doc.get('documentNumber') ||
          doc.get('documentNumber').length > d.get('documentNumber').length
        ))
      ));

      if (winner) {
        return winner.get('documentNumber');
      }
    } else if (this.props.type === 'npl-auto') {
      const documents = this.getGroupDocuments(group);
      const winner = documents.get(0);

      if (winner) {
        return winner.get('documentNumber');
      }
    }
  }

  onConfirm(groupIndex) {
    const group = this.state.groups.get(groupIndex);
    const winnerDocument = this.getGroupWinner(groupIndex);
    if (!winnerDocument) {
      return Promise.resolve();
    }

    let promise = this.setGroupProp(groupIndex, 'deduping', true);

    const losingDocuments = group.get('documents')
      .filter(doc => !doc.get('removed'))
      .filter(doc => !doc.get('loser'))
      .filter(doc => doc.get('documentNumber') !== winnerDocument);

    losingDocuments.forEach(losingDocument => {
      promise = promise.then(() => (
        this.props.replaceDocNumber(losingDocument, winnerDocument)
          .then(() => this.setDocumentProp(groupIndex, losingDocument, 'deduped', true))
          .then(() => this.removeLoserDocument(losingDocument))
      ));
    });

    promise = promise.then(() => this.setGroupProp(groupIndex, 'deduping', false));

    return promise;
  }

  onSelect(groupIndex, doc) {
    this.setGroupProp(groupIndex, 'winner', doc.get('documentNumber'));
  }

  onEdit(groupIndex, doc) {
    this.setDocumentProp(groupIndex, doc, 'edit', true);
  }

  onSaveEdit(groupIndex, doc, newValue) {
    this.props.updateDocument({
      id: doc.get('id'),
      nonPatentBib: newValue
    }, doc.get('documentNumber'))
      .then(() => this.setDocumentProp(groupIndex, doc, 'edit', false))
      .then(() => this.setDocumentProp(groupIndex, doc, 'nonPatentBib', newValue));
  }

  onCancelEdit(groupIndex, doc) {
    this.setDocumentProp(groupIndex, doc, 'edit', false);
  }

  onRemove(groupIndex, doc) {
    this.setDocumentProp(groupIndex, doc, 'removed', true);
  }

  onRestore(groupIndex, doc) {
    this.setDocumentProp(groupIndex, doc, 'removed', false);
  }

  onSelectRegroup(groupIndex, doc) {
    this.setDocumentProp(groupIndex, doc, 'regroup', !doc.get('regroup'));
  }

  onConfirmRegroup() {
    const docs = this.getRemovedDocuments()
      .filter(doc => doc.get('regroup'))
      .map(doc => doc.set('removed', false))
      .map(doc => doc.set('regroup', false))
      .toArray();
    const documentIds = docs.map(doc => doc.get('id'));
    const groups = this.state.groups.map(group => {
      const filteredDocuments = group.get('documents')
        .filter(doc => documentIds.indexOf(doc.get('id')) === -1);
      return group.set('documents', filteredDocuments);
    });
    const newGroup = Immutable.fromJS({
      documents: docs,
      winner: null
    });
    this.setState({
      groups: groups.push(newGroup)
    });
  }

  renderGroup(group, groupIndex) {
    if (!this.hasDocuments(group)) {
      return;
    }
    return (
      <Modal.Footer key={groupIndex} style={{ justifyContent: 'flex-start' }}>
        <NearDuplicateGroup
          winner={this.getGroupWinner(groupIndex)}
          groupIndex={groupIndex}
          group={group}
          onConfirm={this.onConfirm}
          onEdit={this.onEdit}
          onSaveEdit={this.onSaveEdit}
          onCancelEdit={this.onCancelEdit}
          onClickIgnore={this.onClickIgnore}
          onRemove={this.onRemove}
          onSelect={this.onSelect}
        />
      </Modal.Footer>
    );
  }

  renderGroups() {
    return this.state.groups.map(this.renderGroup);
  }

  renderRegroup() {
    const documents = this.getRemovedDocuments();
    return !!documents.size && (
      <Modal.Footer style={{ justifyContent: 'flex-start' }}>
        <NearDuplicateRegroup
          documents={documents}
          onRestore={this.onRestore}
          onSelect={this.onSelectRegroup}
          onConfirm={this.onConfirmRegroup}
        />
      </Modal.Footer>
    );
  }

  renderClose() {
    return (
      <Button
        tabIndex={-1}
        variant={this.isComplete() ? 'primary' : 'secondary'}
        onClick={this.onClickClose}
      >
        Close
      </Button>
    );
  }

  renderBody() {
    if (this.props.isLoadingGroups && this.props.progress.total > 0) {
      return (
        <div style={{ padding: '15px' }}>
          Comparing {this.props.progress.done} of {this.props.progress.total}...
          <br />
          <br />
          You may continue to work on SyncIDS by <a href='/#/app' target='_blank'>opening a new tab</a>.
        </div>
      );
    }

    if (this.props.isLoadingGroups) {
      return (
        <Loading padding='15px' />
      );
    }

    if (!this.state.groups) {
      return (
        <div style={{ padding: '15px' }}>
          Click "Run" to look for near duplicates.
        </div>
      );
    }

    if (!this.state.groups.size) {
      return (
        <div style={{ padding: '15px' }}>
          Nothing found for this client.
        </div>
      );
    }

    return (
      <div>
        {this.renderGroups()}
        {this.renderRegroup()}
      </div>
    );
  }

  onRun(params) {
    this.props.clearNearDuplicate();

    this.setState({
      groups: null,
      running: true
    }, () => {
      this.props.fetchNearDuplicate(this.props.firmName, this.props.clientNumber, params);
    });
  }

  renderTitle() {
    return (
      <div>
        Near Duplicate Tool
        <NearDuplicateForm
          onRun={this.onRun}
          isRunning={this.state.running}
          isDeduplicating={this.state.isDeduplicating}
          features={this.props.features}
          type={this.props.type}
          shingle={this.props.shingle}
          threshold={this.props.threshold}
          nplSerialBegin={this.props.nplSerialBegin}
          nplSerialEnd={this.props.nplSerialEnd}
          nplAppNumber={this.props.nplAppNumber}
          nplDate={this.props.nplDate}
          nplPageRange={this.props.nplPageRange}
        />
      </div>
    );
  }

  onClickDeduplicate() {
    if (!this.state.groups) {
      return;
    }

    this.setState({
      isDeduplicating: true
    });

    let promise = Promise.resolve();

    this.state.groups.forEach((group, groupIndex) => {
      promise = promise.then(() => this.onConfirm(groupIndex));
    });

    promise.then(() => {
      this.setState({
        isDeduplicating: false
      });
    });
  }

  renderDeduplicate() {
    return (
      <Button
        variant='primary'
        disabled={!this.state.groups || this.state.isDeduplicating}
        onClick={this.onClickDeduplicate}
      >
        {this.state.isDeduplicating ? 'Deduplicating...' : 'Deduplicate'}
      </Button>
    );
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.onClickClose}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.renderTitle()}
          </Modal.Title>
        </Modal.Header>
        {this.renderBody()}
        <Modal.Footer>
          {this.renderClose()}
          {this.renderDeduplicate()}
        </Modal.Footer>
      </Modal>
    );
  }
}
