import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import Field from '../../shared/Field';
import DateInput from '../../shared/DateInput';
import { Button, Table } from 'react-bootstrap';
import Loading from '../../shared/Loading';
import { toISODate, formatDateTime } from '../../../utils/date';
import ExpandLayoutIcon from '../../shared/ExpandLayoutIcon';
import ConfirmModal from '../../shared/ConfirmModal';
import CustomInput from '../../shared/CustomInput';

export default class CheatersReport extends Component {
  static propTypes = {
    wideLayout: PropTypes.bool.isRequired,
    toggleWideLayout: PropTypes.func.isRequired,
    fetchCheatersReport: PropTypes.func.isRequired,
    fetchCheatersFiledIDS: PropTypes.func.isRequired,
    generateCheaterInvoice: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      dateFrom: '',
      dateTo: '',
      step3: 10,
      step2: 60,

      data: null,
      loading: false,
      onlyFiledIDS: true,
      confirmInvoice: null
    };
  }

  onCancelInvoice() {
    this.setState({
      confirmInvoice: null
    });
  }

  onConfirmInvoice() {
    this.props.generateCheaterInvoice(this.state.confirmInvoice.item).then((response) => {
      const status = response.status === 'failed' ? 'Failed' : 'Done';
      this.setState({
        confirmInvoice: null,
        data: this.state.data.setIn([this.state.confirmInvoice.index, 'invoiceGenerated'], status)
      });
    });
  }

  onClickInvoice(item, index) {
    this.setState({
      confirmInvoice: {
        item,
        index
      }
    });
  }

  onChangeFiledIDS() {
    this.setState({
      onlyFiledIDS: !this.state.onlyFiledIDS
    });
  }

  onChange(e) {
    const name = e.target.getAttribute('name');

    this.setState({
      [name]: parseInt(e.target.value || 0, 10)
    });
  }

  onChangeTo(e, dateTo) {
    this.setState({ dateTo });
  }

  onChangeFrom(e, dateFrom) {
    this.setState({ dateFrom });
  }

  checkFiledIDS() {
    let promise = Promise.resolve();

    this.state.data.forEach((item, index) => {
      promise = promise
        .then(ids => {
          this.setState({
            data: this.state.data.setIn([index, 'IDS'], item.get('ApplicationNumber') ? 'Checking...' : 'No application')
          });
        })
        .then(() => {
          if (item.get('ApplicationNumber')) {
            return this.props.fetchCheatersFiledIDS({
              days: this.state.step3,
              date: item.get('Date'),
              applicationNumber: item.get('ApplicationNumber').replace(/\D/g, '')
            })
              .then(response => {
                this.setState({
                  data: this.state.data.setIn(
                    [index, 'IDS'],
                    response.ids
                      ? response.ids.legalDateStr
                      : response.error ? 'Error' : 'Not found'
                  )
                });
              });
          }
        })
        .catch(() => {
          this.setState({
            data: this.state.data.setIn([index, 'IDS'], 'Error')
          });
        });
    });
  }

  onSubmit() {
    this.setState({
      loading: true,
      data: null
    });

    const format = (date) => {
      const parts = date.split('/');
      return toISODate(`${parts[2]}-${parts[0]}-${parts[1]}`);
    };

    this.props.fetchCheatersReport({
      dateFrom: format(this.state.dateFrom),
      dateTo: format(this.state.dateTo),
      days: this.state.step2
    })
      .then(data => {
        this.setState({
          loading: false,
          data: Immutable.fromJS(data)
        });
        this.checkFiledIDS();
      });
  }

  renderForm() {
    return (
      <div>
        <Field label='From' style={{ width: '120px' }}>
          <DateInput
            name='from'
            value={this.state.dateFrom}
            onChange={this.onChangeFrom}
            spellCheck={false}
            className='form-control'
          />
        </Field>
        <Field label='To' style={{ width: '120px' }}>
          <DateInput
            name='from'
            value={this.state.dateTo}
            onChange={this.onChangeTo}
            spellCheck={false}
            className='form-control'
          />
        </Field>
        <Field label='Step 2' style={{ width: '120px' }}>
          <CustomInput
            name='step2'
            value={this.state.step2}
            onChange={this.onChange}
            spellCheck={false}
            className='form-control'
          />
        </Field>
        <Field label='Step 3' style={{ width: '120px' }}>
          <CustomInput
            name='step3'
            value={this.state.step3}
            onChange={this.onChange}
            spellCheck={false}
            className='form-control'
          />
        </Field>
        <Field label='' style={{ width: '120px' }}>
          <Button
            style={{ width: '100%' }}
            variant='primary'
            size='sm'
            onClick={this.onSubmit}
          >
            Submit
          </Button>
        </Field>
        <Field label='' style={{ width: '160px' }}>
          <div className='checkbox'>
            <label htmlFor='filed-ids'>
              <input
                onChange={this.onChangeFiledIDS}
                type='checkbox'
                name='filed-ids'
                checked={this.state.onlyFiledIDS}
              />

              Show only filed IDS
            </label>
          </div>
        </Field>
      </div>
    );
  }

  getRows() {
    if (this.state.onlyFiledIDS) {
      return this.state.data.filter(this.hasFiledIDS);
    }
    return this.state.data;
  }

  hasFiledIDS(item) {
    return (
      !item.get('IDS') ||
      !['Not found', 'No application', 'Error'].includes(item.get('IDS'))
    );
  }

  renderInvoiceButton(item, index) {
    if (!this.hasFiledIDS(item)) {
      return '-';
    }

    if (item.get('invoiceGenerated')) {
      return item.get('invoiceGenerated');
    }

    return (
      <Button
        size='sm'
        variant='primary'
        onClick={() => this.onClickInvoice(item, index)}
      >
        Invoice
      </Button>
    );
  }

  renderTable() {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Firm</th>
            <th>Client</th>
            <th>Matter</th>
            <th>Application</th>
            <th>User</th>
            <th>Filed IDS</th>
            <th style={{ width: '6rem' }}>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {this.getRows().map((item, index) => {
            return (
              <tr key={index}>
                <td>{formatDateTime(item.get('Date'))}</td>
                <td>{item.get('FirmName')}</td>
                <td>{item.get('ClientNumber')}</td>
                <td>{item.get('MatterNumber')}</td>
                <td>{item.get('ApplicationNumber')}</td>
                <td>{item.get('User')}</td>
                <td>{item.get('IDS')}</td>
                <td>{this.renderInvoiceButton(item, index)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderHeader() {
    return (
      <div>
        Cheaters Report

        <ExpandLayoutIcon
          toggleWideLayout={this.props.toggleWideLayout}
          wideLayout={this.props.wideLayout}
        />
      </div>
    );
  }

  renderConfirmInvoice() {
    return this.state.confirmInvoice && (
      <ConfirmModal
        focusCancel
        message={`Are you sure you want to generate an invoice for "${this.state.confirmInvoice.item.get('FirmName')}"?`}
        onCancel={this.onCancelInvoice}
        onConfirm={this.onConfirmInvoice}
      />
    );
  }

  render() {
    return (
      <CollapsiblePanel id='cheaters-report' header={this.renderHeader()}>
        <div className='row'>
          <div className='col-md-12'>
            {this.renderForm()}
            {this.renderConfirmInvoice()}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            {this.state.loading && <Loading />}
            {this.state.data && this.renderTable()}
          </div>
        </div>
      </CollapsiblePanel>
    );
  }
};
