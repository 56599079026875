import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import DocumentRecord from '../../models/DocumentRecord';
import { Modal, Table, Button, Tabs, Tab } from 'react-bootstrap';
import { formatDateTime } from '../../utils/date';
import Loading from '../shared/Loading';
import Immutable from 'immutable';
import { splitCommaUnique } from '../../utils/text-utils';
import sortBy from 'lodash/sortBy';

export default class DocumentHistoryModal extends Component {
  static propTypes = {
    fetchDocumentHistory: PropTypes.func.isRequired,
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    doc: PropTypes.instanceOf(DocumentRecord),
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true,
      history: null,
      selectedTab: 'matter'
    };
  }

  componentDidMount() {
    this.props.fetchDocumentHistory(this.props.doc.get('id')).then(history => {
      this.setState({
        history
      });
    });
  }

  getEvents() {
    const { client, matter } = this.props;

    const includeActions = [
      'Add Document',
      'Add Document from Sweep',
      'Add Document from Espace',
      'Cross Cited',
      'Delete Document From Client',
      'Delete Document From Family',
      'Delete Document From Matter',
      'Delete Document From Matter - Parent Pruned',
      'Delete Document From Matter - As Equivalent',
      'Manually Cited',
      'Manually Uncited'
    ];

    let events = (this.state.history || []).filter(item => (
      item.clientNumber === client.get('clientNumber') &&
      includeActions.includes(item.action)
    ));

    if (this.state.selectedTab === 'matter') {
      events = events.filter(item => (
        item.matterNumber === matter.get('matterNumber')
      ));
    } else if (this.state.selectedTab === 'family') {
      events = events.filter(item => (
        item.matterNumber === matter.get('matterNumber') ||
        splitCommaUnique(matter.get('oneWayRelatedMatters')).includes(item.matterNumber) ||
        splitCommaUnique(matter.get('twoWayRelatedMatters')).includes(item.matterNumber)
      ));
    }

    return sortBy(events, [
      'date',
      (item) => includeActions.indexOf(item.action)
    ]);
  }

  onClose() {
    this.setState({ show: false });
    this.props.onClose();
  }

  onSelectTab(selectedTab) {
    this.setState({ selectedTab });
  }

  renderLoading() {
    return (
      <Loading />
    );
  }

  renderTabs() {
    return (
      <Tabs
        onSelect={this.onSelectTab}
        activeKey={this.state.selectedTab}
        defaultActiveKey='matter'
        id='history-tabs'
      >
        <Tab eventKey='matter' title='Matter'>
          {this.renderTable('matter')}
        </Tab>
        <Tab eventKey='family' title='Family'>
          {this.renderTable('family')}
        </Tab>
        <Tab eventKey='client' title='Client'>
          {this.renderTable('client')}
        </Tab>
      </Tabs>
    );
  }

  renderBody() {
    return (
      <div>
        {this.isLoading() ? this.renderLoading() : this.renderTabs()}
      </div>
    );
  }

  isLoading() {
    return !this.state.history;
  }

  getHeader(col) {
    switch (col) {
      case 'matterNumber':
        return 'Matter';
      default:
        return col;
    }
  }

  getCrossCitedAction(item) {
    const matterNumber = (item.misc || '').replace(/From matter:/, '').trim();
    const clientNumber = this.props.client.get('clientNumber');
    const term = encodeURIComponent(`${clientNumber}|${matterNumber}`);
    const url = `/#/app/show=all&term=${term}&filterType=smart`;

    return (
      <div>
        Cross cited from matter <a href={url} target='_blank' rel='noreferrer'>{matterNumber}</a>
      </div>
    );
  }

  getSweepAction(item) {
    const [docDate, appNumber] = (item.misc || '')
      .split(';')
      .map(part => part.split(':').pop().trim());

    return `${item.action} - #${appNumber} - ${docDate}`;
  }

  getAction(item) {
    switch (item.action) {
      case 'Cross Cited':
        return this.getCrossCitedAction(item);
      case 'Add Document from Sweep':
        return this.getSweepAction(item);
      default:
        return item.action;
    }
  }

  getColumn(item, col) {
    if (!item[col]) {
      return '-';
    }

    switch (col) {
      case 'date':
        return formatDateTime(item.date);
      case 'action':
        return this.getAction(item);
      case 'user':
        return item.user === 'syncids@syncids.com' ? 'SyncIDS' : item.user;
      default:
        return item[col];
    }
  }

  getWidth(col) {
    switch (col) {
      case 'date':
        return '150px';
      default:
        return 'auto';
    }
  }

  renderTable() {
    const cols = [
      'date',
      'matterNumber',
      'action',
      'user'
    ];

    return (
      <div style={{ padding: '15px 0' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              {cols.map((col) => (
                <th key={col} style={{ textTransform: 'capitalize', width: this.getWidth(col) }}>
                  {this.getHeader(col)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.getEvents().map((item, i) => {
              return (
                <tr key={i}>
                  {cols.map((col) => (
                    <td key={col} style={{ width: this.getWidth(col) }}>
                      {this.getColumn(item, col)}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  renderClose() {
    return (
      <Button
        variant='primary'
        onClick={this.onClose}
      >
        Close
      </Button>
    );
  }

  getTitle() {
    const clientNumber = this.props.client.get('clientNumber');
    const documentNumber = this.props.doc.get('documentNumber');

    return (
      <div>
        <span style={{ marginRight: '20px' }}>
          Client: {clientNumber}
        </span>
        <span style={{ marginRight: '20px' }}>
          Document: {documentNumber}
        </span>
      </div>
    );
  }

  render() {
    return (
      <Modal
        keyboard={false}
        size='xl'
        show={this.state.show}
        onHide={this.onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.getTitle()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderClose()}
        </Modal.Footer>
      </Modal>
    );
  }
}
