import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import ConfirmModal from '../shared/ConfirmModal';

export default class RemoveCategoryModal extends Component {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    removeCategory: PropTypes.func.isRequired,
    category: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onCancel() {
    this.props.onClose();
  }

  onConfirm() {
    this.props.removeCategory(this.props.client.get('id'), this.props.category);
    this.props.onClose();
  }

  render() {
    return (
      <ConfirmModal
        focusCancel
        message={`Are you sure you want to remove the "${this.props.category}" category from the client?`}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
      />
    );
  }
}
