import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { mattersUrl } from '../../constants/urls';

export default (clientId, matterId, matterNumber) => (dispatch, getState) => {
  const { entities } = getState();
  const matter = entities.getIn(['matters', matterId]);
  const number = matter ? matter.get('matterNumber') : matterNumber;

  dispatch(addNotification({
    id: `matter-${matterId}`,
    type: 'progress',
    message: `Removing ${number} from sync group.`
  }));

  return dispatch(callApi({
    url: `${mattersUrl}/${matterId}`,
    method: 'put',
    data: {
      id: matterId,
      twoWayRelatedMatters: ''
    }
  }))
    .then(() => {
      dispatch(addNotification({
        id: `matter-${matterId}`,
        type: 'success',
        message: `Removed ${number} from sync group.`
      }));
    })
    .catch(() => {
      dispatch(addNotification({
        id: `matter-${matterId}`,
        type: 'error',
        message: `Failed to remove ${number} from sync group.`
      }));
    });
};
