import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';

const disabledStyle = {
  backgroundColor: '#ebebeb',
  cursor: 'not-allowed'
};

export default class CustomInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.string, // eslint-disable-line
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    spellCheck: PropTypes.bool,
    rows: PropTypes.number,
    expandRows: PropTypes.number,
    type: PropTypes.string,
    multiLineCompact: PropTypes.bool
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.propValue) {
      return {
        value: props.value || '',
        propValue: props.value || ''
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this, CustomInput.prototype);
    this.state = {};
  }

  onChange(e) {
    this.setValue(e.target.value);
  }

  setValue(value) {
    this.setState({ value });
  }

  getValue() {
    return this.state.value;
  }

  hasMultipleLines() {
    return (
      this.props.multiLineCompact &&
      this.getValue().trim().split(/\n/).map(line => /\w/.test(line)).length > 1
    );
  }

  onFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur(e) {
    const value = this.getValue();
    this.props.onChange(e, value);
    if (this.props.onBlur) {
      this.props.onBlur(e, value);
    }
  }

  getStyle() {
    const { style, disabled } = this.props;
    return disabled ? { ...style, ...disabledStyle } : style;
  }

  getClassname() {
    const className = this.props.className || '';
    return this.hasMultipleLines() ? `input-multi ${className}` : className;
  }

  render() {
    const inputProps = {
      name: this.props.name,
      required: this.props.required,
      placeholder: this.props.placeholder,
      maxLength: this.props.maxLength,
      spellCheck: this.props.spellCheck,
      rows: this.props.rows,
      expandRows: this.props.expandRows,
      onKeyUp: this.props.onKeyUp,
      onKeyDown: this.props.onKeyDown,
      ...this.state,
      disabled: false,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      onChange: this.onChange,
      style: this.getStyle(),
      className: this.getClassname()
    };
    delete inputProps.propValue;
    delete inputProps.expandRows;
    delete inputProps.multiLineCompact;
    return React.createElement(this.props.type || 'input', inputProps);
  }
}
