import { createAction } from 'redux-actions';
import { CLIENT_SELECT } from '../../constants/actions';
import fetchClientIfNeeded from './fetchClientIfNeeded';
import fetchMatterListIfNeeded from '../matter/fetchMatterListIfNeeded';

const selectClient = createAction(CLIENT_SELECT);

export default (selectedClientId) => (dispatch, getState) => {
  const currentClientId = getState().app.selectedClientId;
  if (currentClientId !== selectedClientId) {
    dispatch(fetchClientIfNeeded(selectedClientId));
    dispatch(fetchMatterListIfNeeded(selectedClientId));
    dispatch(selectClient({ selectedClientId }));
  }
};
