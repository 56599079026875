/**
 * Parses an application number.
 * See MatterIntakeScript.vbs for reference.
 *
 * @param {string} value
 */
export function parseApplicationNumber(value) {
  let applicationNumber = (value || '').trim().replace(/[-,/]/g, '');

  const regexPCT = /^(PCT|[A-Z]{2}PCT)/;

  const isPCT = regexPCT.test(applicationNumber);

  let countryCode = /^[A-Z]{2}/i.test(applicationNumber)
    ? applicationNumber.replace(regexPCT, '').substr(0, 2).toUpperCase()
    : null;

  // let pctNumber;

  applicationNumber = applicationNumber.replace(/^[A-Z]{2,7}/, '');

  if (!countryCode && (applicationNumber.length === 7 || applicationNumber.length === 8)) {
    countryCode = 'US';
  }

  if (countryCode === 'MX' && applicationNumber.length === 8) {
    countryCode = 'PA';
  }

  if (countryCode === 'KR' && applicationNumber.length === 13) {
    applicationNumber = applicationNumber.substr(-11);
  }

  if (countryCode === 'MX' && applicationNumber.substr(0, 1).toLowerCase() === 'a') {
    applicationNumber = applicationNumber.substr(-11);
  }

  if (countryCode === 'CA' && applicationNumber.length === 12) {
    applicationNumber = applicationNumber.substr(0, 11);
  }

  if (countryCode === 'WO' || isPCT) {
    if (applicationNumber.length === 12 && applicationNumber.substr(6, 1) === '0') {
      applicationNumber = `${applicationNumber.substr(0, 6)}${applicationNumber.substr(-5)}`;
    }

    if (applicationNumber.length === 10) {
      // pctNumber = `PCT/${countryCode}${applicationNumber.substr(0, 4)}/${applicationNumber.substr(-6)}`;
      if (applicationNumber.substr(4, 1) === '0') {
        applicationNumber = `${applicationNumber.substr(0, 4)}${countryCode}${applicationNumber.substr(-5)}`;
      } else {
        applicationNumber = `${applicationNumber.substr(0, 4)}${countryCode}${applicationNumber.substr(-6)}`;
      }
    } else if (applicationNumber.length === 9) {
      // pctNumber = `PCT/${countryCode}${applicationNumber.substr(0, 4)}/${applicationNumber.substr(-5)}`;
      applicationNumber = `${applicationNumber.substr(0, 4)}${countryCode}${applicationNumber.substr(-5)}`;
    } else if (applicationNumber.length === 7 || applicationNumber.length === 8) {
      // pctNumber = `PCT/${countryCode}${applicationNumber.substr(0, 2)}/${applicationNumber.substr(2)}`;

      const currentYear = new Date().getFullYear().toString().substr(2, 2);
      const year = Number(applicationNumber.substr(0, 2)) > currentYear ? 19 : 20;
      const start = applicationNumber.substr(2, 1) === '0' ? 3 : 2;

      applicationNumber = `${year}${applicationNumber.substr(0, 2)}${countryCode}${applicationNumber.substr(start)}`;
    }

    countryCode = 'WO';
    applicationNumber = `${countryCode}${applicationNumber}`;
  }

  return isPCT || countryCode === 'US' ? applicationNumber : `${countryCode}${applicationNumber}`;
}
