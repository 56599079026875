import { createAction } from 'redux-actions';
import fetchTodos from '../todo/fetchTodos';
import { splitCommaUnique } from '../../../utils/text-utils';
import _isEqual from 'lodash/isEqual';
import _sortedUniq from 'lodash/sortedUniq';
import _find from 'lodash/find';
import {
  CLEANUP_TWOWAY_BEGIN,
  CLEANUP_TWOWAY_SUCCESS,
  CLEANUP_TWOWAY_ERROR
} from '../../constants/actions';

const cleanUpTwoWayBegin = createAction(CLEANUP_TWOWAY_BEGIN);
const cleanUpTwoWaySuccess = createAction(CLEANUP_TWOWAY_SUCCESS);
const cleanUpTwoWayError = createAction(CLEANUP_TWOWAY_ERROR);

export default () => (dispatch, getState) => {
  const { app: { selectedClientId }, entities } = getState();
  const clientNumber = entities.getIn(['clients', selectedClientId, 'clientNumber']);

  dispatch(cleanUpTwoWayBegin({ clientNumber, isRunning: true }));

  return dispatch(fetchTodos({ term: clientNumber, show: 'all', filterType: 'smart' }, true))
    .then((response) => {
      const matters = {};
      const findMatter = (matterNumber) => {
        if (!matters[matterNumber]) {
          matters[matterNumber] = _find(response.entities.matters, (matter) => matter.matterNumber === matterNumber);
        }
        return matters[matterNumber];
      };
      const findRelated = (matter, related = {}) => {
        splitCommaUnique(matter.twoWayRelatedMatters).forEach(number => {
          if (!related[number]) {
            related[number] = true;
            const relatedMatter = findMatter(number);
            if (relatedMatter) {
              findRelated(relatedMatter, related);
            }
          }
        });
        return _sortedUniq(Object.keys(related));
      };

      const wrongMatters = [];
      Object.keys(response.entities.matters).forEach(matterId => {
        const matter = response.entities.matters[matterId];
        if (matter && matter.twoWayRelatedMatters) {
          const fieldValue = _sortedUniq(splitCommaUnique(matter.twoWayRelatedMatters));
          const relatedMatters = findRelated(matter);
          if (!_isEqual(fieldValue, relatedMatters)) {
            wrongMatters.push({
              matter,
              relatedMatters
            });
          }
        }
      });

      dispatch(cleanUpTwoWaySuccess({ clientNumber, wrongMatters, isRunning: false }));
    })
    .catch((error) => dispatch(cleanUpTwoWayError({ clientNumber, error, isRunning: false })));
};
