import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import classNames from 'classnames';
import DocumentRecord from '../../models/DocumentRecord';
import { Button } from 'react-bootstrap';

export default class DocumentCheckbox extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    doc: PropTypes.instanceOf(DocumentRecord).isRequired,
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    doc: new DocumentRecord()
  };

  static getDerivedStateFromProps(props, state) {
    const checked = props.doc.get(props.field);
    if (!state.isSaving && checked !== state.checked) {
      return {
        checked,
        isSaving: false
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  isDisabled() {
    if (this.props.doc.get('type') !== 'US' && this.props.features.limitedDocs) {
      return true;
    }

    return this.props.disabled;
  }

  onClick() {
    if (!this.isDisabled()) {
      this.onChange(!this.state.checked);
    }
  }

  onChange(checked) {
    const promise = this.props.onChange(this.props.doc, this.props.field, checked);
    this.setState({ isSaving: true, checked });
    promise.then(() => this.setState({ isSaving: false }));
  }

  render() {
    if (!this.props.doc.isSaved()) {
      return null;
    }

    return (
      <Button
        disabled={this.isDisabled()}
        onClick={this.onClick}
        size='sm'
        variant='secondary'
        name={this.props.field}
        style={{
          fontSize: '16px',
          cursor: this.isDisabled() ? 'not-allowed' : 'pointer',
          color: this.state.checked && !this.isDisabled() ? '#00bc8c' : 'white'
        }}
        className={classNames({
          fa: true,
          'fa-square-o': !this.state.checked,
          'fa-check-square-o': this.state.checked
        })}
      />
    );
  }
}
