import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Tooltip from '../shared/Tooltip';

export default class NotesHeader extends Component {
  static propTypes = {
    showPublicNotes: PropTypes.bool,
    showAutoNotes: PropTypes.bool,
    selectedTab: PropTypes.string.isRequired,
    onSelectTab: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      notesTab: 'notes'
    };
  }

  onSelectNotesTab(e) {
    this.props.onSelectTab(e.target.getAttribute('data-tab'));
  }

  renderNotesTitle(tab) {
    return (
      <Tooltip
        stylePopover={{ width: '250px' }}
        delay={600}
        id={`tooltip-notes-tab-${tab.field}`}
        tip={tab.help}
      >
        <a
          style={{
            cursor: 'pointer',
            color: tab.color,
            textDecoration: this.props.selectedTab === tab.field ? 'underline' : 'none'
          }}
          data-tab={tab.field}
          onClick={this.onSelectNotesTab}
        >
          {tab.label}
        </a>
      </Tooltip>
    );
  }

  renderNotesTab(tab, index) {
    return (
      <span key={tab.field}>
        {index > 0 ? <span style={{ margin: '0 4px' }}>|</span> : null}
        {this.renderNotesTitle(tab)}
      </span>
    );
  }

  render() {
    const tabs = [
      {
        field: 'notes',
        label: 'Client',
        color: 'white',
        help: 'Enter notes about this document for this client.',
        show: true
      },
      {
        field: 'matterNotes',
        label: 'Matter',
        color: '#ffff33',
        help: 'Enter notes about this document for this matter.',
        show: true
      },
      {
        field: 'autoNotes',
        label: 'Smart',
        color: '#00bc8c',
        help: 'SyncIDS populates these notes for you!',
        show: this.props.showAutoNotes
      },
      {
        field: 'publicNotes',
        label: 'Public',
        color: '#e74c3c',
        help: 'Enter notes about this document for the IDS form.',
        show: this.props.showPublicNotes
      }
    ].filter(tab => tab.show);

    return (
      <div>
        Doc Notes:
        <div className='pull-right'>
          {tabs.map(this.renderNotesTab)}
        </div>
      </div>
    );
  }
}
