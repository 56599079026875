import autoBind from 'class-autobind';
import React from 'react';
import PropTypes from 'prop-types';
import { getCustomers } from '../../utils/customers';
import imageLogo from '../../files/home/logo.png';
import imageTheOldWay from '../../files/home/the-old-way.jpg';
import imageTheBetterWay from '../../files/home/the-better-way.png';
import imageMonitor from '../../files/home/monitor.png';
import imageHand from '../../files/home/hand.png';
import imageForm from '../../files/home/form.png';
import imageMaintenance from '../../files/home/maintenance.png';
import debounce from 'lodash/debounce';
import FacebookButton from '../shared/FacebookButton';
import LinkedInButton from '../shared/LinkedInButton';

class LazyImage extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    style: PropTypes.object,
    height: PropTypes.number
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
    this.checkPosition();
  }

  componentDidUpdate() {
    this.checkPosition();
  }

  assignRefImage(element) {
    this.imageRef = element;
  }

  checkPosition() {
    if (
      !this.state.visible &&
      this.imageRef &&
      this.imageRef.getBoundingClientRect().top <= this.props.height
    ) {
      this.setState({ visible: true });
    }
  }

  getSrc() {
    if (this.state.visible) {
      return this.props.src;
    }
  }

  render() {
    return (
      <img
        ref={this.assignRefImage}
        src={this.getSrc()}
        style={this.props.style}
      />
    );
  }
}

export default class HomeView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      tab: 1,
      customers: 1,
      maxCustomers: 3,
      height: parseInt(document.getElementById('scroll')?.scrollHeight, 10)
    };
  }

  componentDidMount() {
    this.debounceResize = debounce(this.onResize, 100);
    window.addEventListener('load', this.debounceResize);
    window.addEventListener('scroll', this.debounceResize);
    window.addEventListener('resize', this.debounceResize);
  }

  componentWillUnmount() {
    this.unmounted = true;
    window.removeEventListener('load', this.debounceResize);
    window.removeEventListener('scroll', this.debounceResize);
    window.removeEventListener('resize', this.debounceResize);
  }

  onResize() {
    if (this.unmounted) {
      return;
    }
    this.setState({
      height: parseInt(document.getElementById('scroll')?.scrollHeight, 10)
    });
  }

  renderMonitor() {
    return (
      <div style={{ backgroundColor: 'white', color: '#222', marginTop: '-15px', paddingTop: '50px', paddingBottom: '70px' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6' style={{ paddingLeft: '3%', paddingRight: '0' }}>
              <h2>Simple and Powerful</h2>
              <h1>IDS Software and Services</h1>
              <h3>SyncIDS links your related matters, keeps your data current, and files your IDSs with the PTO.</h3>
            </div>
            <div className='col-md-6' style={{ paddingLeft: '5%', paddingTop: '50px' }}>
              <LazyImage
                height={this.state.height}
                src={imageLogo}
                style={{ width: '400px', maxWidth: '100%' }}
              />
            </div>
          </div>
          <div className='row' style={{ marginTop: '40px' }}>
            <div className='col-md-3' style={{ paddingLeft: '3%', paddingRight: '3%' }}>
              <a className='btn btn-primary btn-lg' href='/#/demo' style={{ width: '100%', padding: '18px 27px' }}>
                Schedule a Demo
              </a>
              <br />
              <br />
              <a className='btn btn-primary btn-lg' href='/#/test-drive' style={{ width: '100%', padding: '18px 27px' }}>
                Test Drive
              </a>
              <br />
              <br />
              <a className='btn btn-primary btn-lg' href='/#/contact' style={{ width: '100%', padding: '18px 27px' }}>
                Contact Us
              </a>
              <br />
              <br />
              <br />
              <h3 className='text-center'>
                Over 10K IDSs filed annually!
              </h3>
            </div>
            <div className='col-md-9'>
              <LazyImage
                height={this.state.height}
                src={imageMonitor}
                style={{ width: '600px', maxWidth: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderValuable() {
    return (
      <div style={{ backgroundColor: '#EEE', color: '#222' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h2 style={{ maxWidth: '525px', margin: '20px auto 25px auto' }}>
                Are you spending valuable time and still not getting your IDSs 100% right?
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderWays() {
    return (
      <div style={{ backgroundColor: 'white', color: '#222', paddingTop: '30px', paddingBottom: '90px' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 text-center'>
              <div style={{ height: '180px' }}>
                <h3>The Old Way</h3>
                <h2 className='h2-md-small'>
                  Questionable spreadsheets, multiple files, institutional knowledge, <br />and a little luck
                </h2>
              </div>
              <div>
                <LazyImage
                  height={this.state.height}
                  src={imageTheOldWay}
                  style={{ width: '100%', maxWidth: '350px', marginBottom: '30px' }}
                />
              </div>
            </div>
            <div className='col-md-6 text-center'>
              <div style={{ height: '180px' }}>
                <h3>The Better Way</h3>
                <h2 className='h2-md-small' style={{ padding: '0 30px' }}>
                  Centralized software that organizes all things IDS
                </h2>
              </div>
              <div>
                <LazyImage
                  height={this.state.height}
                  src={imageTheBetterWay}
                  style={{ width: '100%', maxWidth: '350px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTrusted() {
    return (
      <div style={{ backgroundColor: 'black' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h2 style={{ margin: '20px auto 25px auto' }}>
                Trusted by law firms and companies all over the world
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderThousands() {
    return (
      <div style={{ backgroundColor: 'white', color: '#375a7f', paddingTop: '30px', paddingBottom: '60px' }}>
        <div className='container'>

          <div className='row'>
            <div className='col-md-4 text-center'>
              <h2>12+</h2>
              <h3 style={{ marginTop: 0 }}>years in business</h3>
            </div>
            <div className='col-md-4 text-center' style={{ paddingLeft: '0', paddingRight: '0' }}>
              <h2>100+ thousand</h2>
              <h3 style={{ marginTop: 0 }}>U.S. and foreign matters managed</h3>
            </div>
            <div className='col-md-4 text-center'>
              <h2>1+ million</h2>
              <h3 style={{ marginTop: 0 }}>documents stored</h3>
            </div>
          </div>

          <br />

          <div className='row'>
            <div className='col-md-4 text-center'>
              <h2>3+ thousand</h2>
              <h3 style={{ marginTop: 0 }}>users worldwide</h3>
            </div>
            <div className='col-md-4 text-center'>
              <h2>10+ thousand</h2>
              <h3 style={{ marginTop: 0 }}>IDSs filed annually</h3>
            </div>
            <div className='col-md-4 text-center'>
              <h2>5+ thousand</h2>
              <h3 style={{ marginTop: 0 }}>companies worldwide</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderArrow(direction, onClick, enabled) {
    return (
      <div style={{
        display: 'inline-block',
        float: direction,
        width: '40px',
        height: '200px',
        paddingTop: '85px',
        textAlign: 'center',
        backgroundColor: enabled ? 'rgb(55, 90, 127)' : 'transparent',
        borderRadius: '5px'
      }}
      >
        {enabled && (
          <span
            onClick={onClick}
            className={`fa fa-chevron-${direction}`}
            style={{
              fontSize: '30px',
              color: '#fff',
              cursor: 'pointer'
            }}
          />
        )}
      </div>
    );
  }

  renderCustomerImage(customer) {
    return (
      <div
        key={customer} style={{
          width: '140px',
          height: '70px',
          display: 'inline-block',
          textAlign: 'center',
          margin: '5px'
        }}
      >
        <LazyImage
          height={this.state.height}
          src={customer}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </div>
    );
  }

  onClickLeft() {
    const width = parseInt(this.customersElm.offsetWidth, 10);
    this.customersElm.scrollLeft = Math.max(0, this.customersElm.scrollLeft - width);

    this.setState({
      customers: Math.max(1, this.state.customers - 1),
      maxCustomers: Math.ceil(this.customersElm.scrollWidth / width)
    });
  }

  onClickRight() {
    const width = parseInt(this.customersElm.offsetWidth, 10);
    const maxCustomers = Math.ceil(this.customersElm.scrollWidth / width);
    this.customersElm.scrollLeft = this.customersElm.scrollLeft + width;

    this.setState({
      customers: Math.min(maxCustomers, this.state.customers + 1),
      maxCustomers
    });
  }

  customersRef(element) {
    this.customersElm = element;
  }

  renderCustomers() {
    const customers = getCustomers();

    return (
      <div style={{ backgroundColor: 'white', paddingBottom: '50px' }}>
        <div className='container'>
          <div className='row'>
            {this.renderArrow('left', this.onClickLeft, this.state.customers > 1)}
            <div
              ref={this.customersRef} style={{
                overflow: 'hidden',
                width: 'calc(100% - 100px)',
                float: 'left',
                scrollBehavior: 'smooth'
              }}
            >
              <div style={{ width: `${(customers.length / 2) * 150}px` }}>
                {customers.map(this.renderCustomerImage)}
              </div>
            </div>
            {this.renderArrow('right', this.onClickRight, this.state.customers < this.state.maxCustomers)}
          </div>
        </div>
      </div>
    );
  }

  renderQuote(text, last) {
    return (
      <div style={{ backgroundColor: 'white', paddingBottom: last ? '60px' : '30px' }}>
        <div style={{ backgroundColor: '#375a7f' }}>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12' style={{ paddingBottom: '15px', paddingLeft: '10%', paddingTop: '20px' }}>
                <span
                  className='fa fa-quote-left pull-left'
                  style={{ fontSize: '40px', height: '100px', color: '#66ccff' }}
                />

                <h2 style={{ fontWeight: 'normal' }}>{text[0]}</h2>
                <h4 style={{ fontWeight: 'normal' }}>{text[1]}</h4>
                <h4 style={{ fontWeight: 'normal' }}>{text[2]}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderQuotes() {
    return (
      <div>
        {this.renderQuote([
          'I have to tell you about something amazing SyncIDS did today.',
          'Managing Partner',
          'Boutique Patent Firm'
        ])}

        {this.renderQuote([
          'I can\'t believe we used to do IDSs any other way.',
          'Chief Intellectual Property Officer',
          'Fortune 500 Company'
        ])}

        {this.renderQuote([
          'Wow. Thank you.',
          'Senior Patent Paralegal',
          'Am Law 100 Law Firm'
        ], true)}
      </div>
    );
  }

  renderHowItWorks() {
    return (
      <div style={{ backgroundColor: '#EEE', color: '#222' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h2 style={{ margin: '20px auto 25px auto' }}>
                How it works
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNumber(number, mobile) {
    return (
      <div style={{ position: 'absolute', left: '10px', top: '0' }}>
        <span style={{
          position: 'absolute',
          left: '14px',
          top: '10px',
          borderRadius: '25px',
          paddingTop: '1px',
          fontSize: '26px',
          width: '40px',
          height: '40px',
          border: '3px solid',
          borderColor: !mobile && number === this.state.tab ? '#375a7f' : 'white'
        }}
        >{number}
        </span>
      </div>
    );
  }

  onClickTab(e) {
    let target = e.target;
    while (!target.getAttribute('data-tab')) {
      target = target.parentNode;
    }

    this.setState({
      tab: Number(target.getAttribute('data-tab'))
    });
  }

  renderTabLink(title, number, mobile) {
    const selected = number === this.state.tab;
    const style = {
      height: '60px',
      padding: '0',
      cursor: 'pointer',
      position: 'relative',
      top: mobile ? null : '3px',
      borderRadius: '10px 10px 0px 0',
      color: !mobile && selected ? '#375a7f' : 'white',
      backgroundColor: !mobile && selected ? 'white' : '#375a7f'
    };

    return (
      <div
        data-tab={number}
        onClick={mobile ? null : this.onClickTab}
        className={mobile ? 'col-md-12' : 'col-md-4'}
        style={style}
      >
        <h3
          className='text-center'
          style={{
            marginTop: '0'
          }}
        >
          {this.renderNumber(number, mobile)}
          <span style={{ position: 'relative', top: '15px' }}>{title}</span>
        </h3>
      </div>
    );
  }

  renderMobileTabs() {
    return (
      <div className='hidden-md-up' style={{ backgroundColor: '#375a7f', padding: '3px 0' }}>
        <div className='container'>
          <div className='row'>
            {this.renderTabLink('Update Data', 1, true)}
            {this.renderTabUpdate(true)}
            {this.renderTabLink('Generate IDSs', 2, true)}
            {this.renderTabGenerate(true)}
            {this.renderTabLink('E-File', 3, true)}
            {this.renderTabFile(true)}
          </div>
        </div>
      </div>
    );
  }

  renderTabs() {
    return (
      <div style={{
        backgroundColor: '#375a7f',
        paddingTop: '15px',
        paddingBottom: '3px'
      }}
      >
        <div className='container'>
          <div className='row'>
            {this.renderTabLink('Update Data', 1)}
            {this.renderTabLink('Generate IDSs', 2)}
            {this.renderTabLink('E-File', 3)}
          </div>
        </div>
      </div>
    );
  }

  renderTab({ mobile, icon, iconColor, backgroundColor, title, description }) {
    return (
      <div style={{
        backgroundColor,
        color: '#222',
        padding: mobile ? '10px 0 10px 0' : '40px 0 30px 0',
        minHeight: mobile ? '120px' : '160px'
      }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <h3>
                <span
                  className={`fa fa-${icon}`}
                  style={{
                    color: iconColor,
                    marginRight: '20px',
                    fontSize: '40px'
                  }}
                />
                <span style={{ position: 'relative', top: '-5px' }}>
                  {title}
                </span>
              </h3>
            </div>
            <div className='col-md-8'>
              <h4>
                {description.map((text, index) => (
                  <div key={index} style={{ padding: '5px' }}>
                    {text}
                  </div>
                ))}
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTabUpdate(mobile) {
    return (
      <div>
        {this.renderTab({
          mobile,
          icon: 'database',
          iconColor: '#f38220',
          backgroundColor: 'white',
          title: 'Legacy migration',
          description: [
            <div key=''>
              <span style={{ color: '#375a7f' }}>SyncIDS</span> imports your file histories including previous IDSs, 892s, and most foreign actions.
            </div>
          ]
        })}
        {this.renderTab({
          mobile,
          icon: 'cloud-download',
          iconColor: '#f38220',
          backgroundColor: '#EEE',
          title: 'Import office actions',
          description: [
            <div key=''>
              <span style={{ color: '#375a7f' }}>SyncIDS</span> scans PAIR every week for new U.S. data.
              <br />
              <span style={{ color: '#f38220' }}>You</span> forward foreign correspondence to us for data entry.
            </div>
          ]
        })}
        {this.renderTab({
          mobile,
          icon: 'sitemap',
          iconColor: '#f38220',
          backgroundColor: 'white',
          title: 'Update syncs',
          description: [
            <div key=''>
              <span style={{ color: '#375a7f' }}>SyncIDS</span> keeps all of your family relationships up to date.
              <br />
              <span style={{ color: '#f38220' }}>You</span> add subject matter relationships (SyncIDS can help!).
            </div>
          ]
        })}
      </div>
    );
  }

  renderTabGenerate(mobile) {
    return (
      <div>
        {this.renderTab({
          mobile,
          icon: 'refresh',
          iconColor: '#375a7f',
          backgroundColor: 'white',
          title: 'Cross-cite',
          description: [
            <div key=''>
              <span style={{ color: '#375a7f' }}>SyncIDS</span> automatically flows references to matters where they are needed based on family and subject matter relationships.
            </div>
          ]
        })}
        {this.renderTab({
          mobile,
          icon: 'file-text-o',
          iconColor: '#375a7f',
          backgroundColor: '#EEE',
          title: 'Populate form',
          description: [
            <div key=''>
              <span style={{ color: '#375a7f' }}>SyncIDS</span> completes every portion of the EFS form. Or, learn about the advantages of our proprietary, PTO approved, IDS form.
            </div>
          ]
        })}
        {this.renderTab({
          mobile,
          icon: 'paperclip',
          iconColor: '#375a7f',
          backgroundColor: 'white',
          title: 'Attach PDFs',
          description: [
            <div key=''>
              <span style={{ color: '#375a7f' }}>SyncIDS</span> includes a copy of each foreign and NPL reference. All PDFs are EFS web ready, and English language abstracts are automatically attached when needed.
            </div>
          ]
        })}
      </div>
    );
  }

  renderTabFile(mobile) {
    return (
      <div>
        {this.renderTab({
          mobile,
          icon: 'thumbs-up',
          iconColor: '#00bc8c',
          backgroundColor: 'white',
          title: 'Approve',
          description: [
            <div key=''>
              <span style={{ color: '#f38220' }}>You</span> approve each IDS for filing.
            </div>
          ]
        })}
        {this.renderTab({
          mobile,
          icon: 'cloud-upload',
          iconColor: '#00bc8c',
          backgroundColor: '#EEE',
          title: 'Upload to PTO',
          description: [
            <div key=''>
              <span style={{ color: '#375a7f' }}>SyncIDS</span> uploads everything to the PTO. There is never a problem with embedded fonts, too many references, or the size of a reference.
            </div>
          ]
        })}
        {this.renderTab({
          mobile,
          icon: 'check-square',
          iconColor: '#00bc8c',
          backgroundColor: 'white',
          title: 'Record as cited',
          description: [
            <div key=''>
              <span style={{ color: '#375a7f' }}>SyncIDS</span> keeps a running tally of references at the PTO.
              <br />
              Done!
            </div>
          ]
        })}
      </div>
    );
  }

  renderDesktopTab() {
    return (
      <div className='hidden-md-down'>
        {this.renderTabs()}
        {this.renderTabContent()}
      </div>
    );
  }

  renderTabContent() {
    switch (this.state.tab) {
      case 2:
        return this.renderTabGenerate();
      case 3:
        return this.renderTabFile();
      default:
        return this.renderTabUpdate();
    }
  }

  renderProfitable() {
    return (
      <div style={{ backgroundColor: 'black' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h2 style={{ margin: '20px auto 25px auto' }}>
                Your profitable IDS team
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderHand() {
    const iconBan = (
      <span
        className='fa fa-ban'
        style={{ color: 'red', marginRight: '10px', fontSize: '30px' }}
      />
    );
    const iconArrow = (
      <span
        className='fa fa-arrow-down'
        style={{ color: 'red', marginRight: '10px', fontSize: '30px' }}
      />
    );

    return (
      <div style={{ backgroundColor: 'white', color: '#222', paddingTop: '50px', paddingBottom: '50px' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 text-center'>
              <LazyImage
                height={this.state.height}
                src={imageHand}
                style={{ marginTop: '20px', width: '450px', maxWidth: '100%' }}
              />
            </div>
            <div className='col-md-6'>
              <h2 className='text-center'>Our customer-first approach</h2>
              <br />
              <h4 style={{ lineHeight: '1.4' }}>The proprietary SyncIDS system takes all of the guess work out of your IDS decisions. We know creating IDSs in the traditional manner can be time-consuming and inaccurate. And learning new software can be intimidating.</h4>
              <br />
              <h4 style={{ lineHeight: '1.4' }}>That is why we are laser focused on creating easy-to-use software, along with providing expert paralegal services and unmatched support to our customers.</h4>
              <br />
              <h4 style={{ lineHeight: '1.4' }}>Our customer-focused approach has made SyncIDS the predominant choice for efficient and effective IDS management in corporate and law firm settings, especially for those complicated family and subject-matter relationships.</h4>
            </div>
          </div>
          <br />
          <br />
          <div className='row'>
            <div className='col-md-6'>
              <div style={{ backgroundColor: '#EEE', border: '1px solid #AAA', marginTop: '30px', marginBottom: '30px' }}>
                <h3 className='text-center' style={{ borderBottom: '1px solid #AAA', padding: '8px' }}>
                  Profit center pricing
                </h3>
                <h4 className='text-center' style={{ paddingTop: '20px', paddingBottom: '10px' }}>
                  <span className='fa fa-file-text-o' style={{ color: '#375a7f', marginRight: '10px', fontSize: '30px' }} />
                  One low flat fee per filed IDS
                </h4>
                <div className='pull-left' style={{ width: '50%', paddingLeft: '5%' }}>
                  <h4 style={{ marginBottom: '30px' }}>
                    {iconBan}
                    No upfront fees
                  </h4>
                  <h4 style={{ marginBottom: '30px' }}>
                    {iconBan}
                    No storage fees
                  </h4>
                  <h4 style={{ marginBottom: '30px' }}>
                    {iconArrow}
                    Reduced overhead
                  </h4>
                </div>
                <div className='pull-left' style={{ width: '50%', paddingLeft: '5%' }}>
                  <h4 style={{ marginBottom: '30px' }}>
                    {iconBan}
                    No monthly fees
                  </h4>
                  <h4 style={{ marginBottom: '30px' }}>
                    {iconBan}
                    No paralegal fees
                  </h4>
                  <h4 style={{ marginBottom: '30px' }}>
                    {iconArrow}
                    Reduced RCE fees
                  </h4>
                </div>
                <div className='clearfix' />
              </div>
            </div>
            <div className='col-md-6 text-center'>
              <LazyImage
                height={this.state.height}
                src={imageForm}
                style={{ maxHeight: '400px' }}
              />
            </div>
          </div>
        </div>

      </div>
    );
  }

  renderPriorArt() {
    return (
      <div style={{ backgroundColor: '#EEE', color: '#222' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h2 style={{ margin: '20px auto 25px auto' }}>
                All your prior art, just a few clicks away from the perfect IDS, every time
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFooterButton(text, href) {
    return (
      <a
        className='btn btn-primary btn-lg'
        href={href}
        style={{ width: '200px', margin: '15px', padding: '18px 27px', whiteSpace: 'nowrap' }}
      >
        {text}
      </a>
    );
  }

  renderFooter() {
    const styleIcon = {
      position: 'relative',
      display: 'block',
      color: '#375a7f',
      fontSize: '60px',
      width: '60px'
    };

    return (
      <div style={{ backgroundColor: 'white', color: '#222', paddingTop: '50px', paddingBottom: '100px' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 text-center'>

              <LazyImage
                height={this.state.height}
                src={imageLogo}
                style={{ maxWidth: '300px', marginBottom: '30px' }}
              />

              <div>
                {this.renderFooterButton('Schedule a Demo', '/#/demo')}
                {this.renderFooterButton('Test Drive', '/#/test-drive')}
              </div>
              <div>
                {this.renderFooterButton('FAQ', '/#/faq')}
                {this.renderFooterButton('Contact Us', '/#/contact')}
              </div>
            </div>
            <div className='col-md-6' style={{ paddingLeft: '60px' }}>
              <div style={{ paddingBottom: '20px', paddingTop: '62px' }}>
                <span className='fa fa-phone pull-left text-center' style={{ ...styleIcon, top: '-7px' }} />
                <h3 style={{ margin: '4px' }}>469-382-8144</h3>
                <div className='clearfix' />
              </div>
              <div style={{ paddingBottom: '20px' }}>
                <span className='fa fa-at pull-left text-center' style={{ ...styleIcon, top: '6px' }} />
                <h3 style={{ margin: '4px' }}><a href='mailto:demo@syncids.com' style={{ color: '#222' }}>demo@syncids.com</a></h3>
                <h3 style={{ margin: '4px' }}><a href='mailto:info@syncids.com' style={{ color: '#222' }}>info@syncids.com</a></h3>
                <div className='clearfix' />
              </div>
              <div>
                <span className='fa fa-envelope pull-left text-center' style={{ ...styleIcon, top: '15px', height: '100px' }} />
                <div>

                  <h3 style={{ margin: '4px' }}>SyncIDS</h3>
                  <h3 style={{ margin: '4px' }}>1341 W Mockingbird LN STE 600W</h3>
                  <h3 style={{ margin: '4px' }}>Dallas, TX 75247</h3>
                </div>
              </div>
              <div style={{ position: 'relative', left: '-20px', top: '30px' }}>
                <LinkedInButton />
                <FacebookButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMaintenance() {
    return (
      <div style={{ backgroundColor: 'white', paddingTop: '60px' }}>
        <div className='container' style={{ width: '900px', maxWidth: '90%', backgroundColor: '#375a7f', paddingTop: '30px', paddingBottom: '30px' }}>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h2>Legacy Data Migration</h2>
              <br />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <LazyImage
                height={this.state.height}
                src={imageMaintenance}
                style={{ maxWidth: '100%' }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <br />
              <h2>Accurate IDSs</h2>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id='home'>
        {this.renderMonitor()}
        {this.renderValuable()}
        {this.renderWays()}
        {this.renderTrusted()}
        {this.renderThousands()}
        {this.renderCustomers()}
        {this.renderQuotes()}
        {this.renderHowItWorks()}
        {this.renderMobileTabs()}
        {this.renderDesktopTab()}
        {this.renderProfitable()}
        {this.renderHand()}
        {this.renderPriorArt()}
        {this.renderMaintenance()}
        {this.renderFooter()}
      </div>
    );
  }
};
