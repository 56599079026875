import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import _get from 'lodash/get';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import NplMatchIntro from './NplMatchIntro';
import FirmClientMatter from './FirmClientMatter';
import PDFBrowser from './PDFBrowser';
import NPLBrowser from './NPLBrowser';
import DocumentRecord from '../../../models/DocumentRecord';

const KEY_LEFT = 37;
const KEY_RIGHT = 39;
const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_ENTER = 13;

export default class NplMatch extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    client: PropTypes.instanceOf(Immutable.Map),
    matter: PropTypes.instanceOf(Immutable.Map),
    documents: PropTypes.instanceOf(Immutable.Map),
    fetchMatter: PropTypes.func.isRequired,
    fetchDocuments: PropTypes.func.isRequired,
    fetchClientList: PropTypes.func.isRequired,
    fetchMatterList: PropTypes.func.isRequired,
    uploadNplMatchMigration: PropTypes.func.isRequired,
    renderPDFPage: PropTypes.func.isRequired,
    renderPDFBookmark: PropTypes.func.isRequired,
    uploadPDFMatch: PropTypes.func.isRequired,
    clearNplDocuments: PropTypes.func.isRequired,
    searchNplDocuments: PropTypes.func.isRequired,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map),
    updateDocument: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      bookmarks: null,
      client: this.props.client.get('clientNumber', ''),
      doc: new DocumentRecord(),
      documents: null,
      filterPDF: false,
      firm: this.props.client.get('firmName', ''),
      matter: this.props.matter.get('matterNumber', ''),
      pdf: null,
      queue: [],
      uploaded: [],
      selectedBookmark: -1,
      selectedDocument: -1,
      uploading: false
    };
  }

  componentDidMount() {
    if (this.props.matter.get('id')) {
      this.loadDocuments(this.props.matter);
    }
    this.setState({
      showIntro: !window.localStorage.getItem('hideNplMatchIntro')
    });
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  getDocuments(matter, responseDocuments) {
    if (responseDocuments && responseDocuments.entities && responseDocuments.entities.documents) {
      const documents = Immutable.fromJS(responseDocuments.entities.documents[this.state.client]);
      const getArts = (field, cited) => matter.get(field, [])
        .map(n => documents.get(n))
        .filter(d => d && d.get('type') === 'Other')
        .map(d => d.set('cited', cited));

      const citedArt = getArts('citedArt', true);
      const uncitedArt = getArts('uncitedArt', false);

      return citedArt.concat(uncitedArt);
    } else {
      return Immutable.List();
    }
  }

  loadDocuments(matter) {
    this.props.fetchMatter(matter.get('id')).then(responseMatter => {
      const fullMatter = Immutable.fromJS(responseMatter.entities.matters[matter.get('id')]);
      this.props.fetchDocuments(fullMatter).then(responseDocuments => {
        const documents = this.getDocuments(fullMatter, responseDocuments);
        this.setState({
          documents,
          selectedDocument: documents.size ? 0 : -1
        });
      });
    });
  }

  uploadPDFMatch(item) {
    return this.props.uploadPDFMatch(this.state.pdf, item.bookmark, item.doc)
      .then(() => {
        const index = this.state.documents.findIndex(doc => (
          doc.get('documentNumber') === item.doc.get('documentNumber')
        ));

        this.setState({
          documents: this.state.documents.setIn([index, 'stored'], 'Yes'),
          uploaded: this.state.uploaded.concat(item)
        });
      });
  }

  onUpload() {
    if (this.state.queue.length) {
      this.setState({ uploading: true }, () => {
        const promises = [];
        this.state.queue.forEach(item => {
          promises.push(this.uploadPDFMatch(item));
        });
        Promise.all(promises).then(() => {
          this.setState({
            uploading: false,
            queue: []
          });
        });
      });
    }
  }

  onKeyDown(event) {
    switch (event.keyCode) {
      case KEY_ENTER: {
        event.preventDefault();
        this.onMatch();
        break;
      }
      case KEY_LEFT: {
        event.preventDefault();
        this.onPreviousDocument();
        break;
      }
      case KEY_RIGHT: {
        event.preventDefault();
        this.onNextDocument();
        break;
      }
      case KEY_UP: {
        event.preventDefault();
        this.onPreviousBookmark();
        break;
      }
      case KEY_DOWN: {
        event.preventDefault();
        this.onNextBookmark();
        break;
      }
      default:
        break;
    }
  }

  onPreviousBookmark() {
    this.setState({
      selectedBookmark: Math.max(this.state.selectedBookmark - 1, 0)
    });
  }

  onNextBookmark() {
    this.setState({
      selectedBookmark: Math.min(
        this.state.selectedBookmark + 1,
        this.state.bookmarks ? this.state.bookmarks.length - 1 : 0
      )
    });
  }

  onPreviousDocument() {
    const index = Math.max(this.state.selectedDocument - 1, 0);
    this.onSelectDocument(index);
  }

  onNextDocument() {
    const docs = this.getFilteredDocuments();
    const index = Math.min(
      this.state.selectedDocument + 1,
      docs ? docs.size - 1 : 0
    );
    this.onSelectDocument(index);
  }

  getFilteredDocuments() {
    if (!this.state.filterPDF) {
      return this.state.documents;
    }

    if (this.state.documents) {
      return this.state.documents.filter(doc => doc.get('stored') !== 'Yes');
    }
  }

  getSelectedBookmark() {
    if (this.state.selectedBookmark === -1 || !this.state.bookmarks) {
      return;
    }

    return this.state.bookmarks[this.state.selectedBookmark];
  }

  getSelectedDocument() {
    const docs = this.getFilteredDocuments();

    if (this.state.selectedDocument === -1 || !docs.size) {
      return this.state.doc;
    }

    return new DocumentRecord(docs.get(this.state.selectedDocument));
  }

  isMatchEnabled() {
    return (
      !!this.getSelectedDocument().get('documentNumber') &&
      this.getSelectedBookmark()
    );
  }

  removeFromQueue({ doc, bookmark }) {
    this.setState({
      queue: this.state.queue.filter(item => {
        return !(
          item.bookmark.page === bookmark.page &&
          item.bookmark.endPage === bookmark.endPage &&
          item.doc.get('documentNumber') === doc.get('documentNumber')
        );
      })
    });
  }

  onMatch() {
    if (this.isMatchEnabled()) {
      const doc = this.getSelectedDocument();
      const bookmark = this.getSelectedBookmark();

      this.setState({
        queue: this.state.queue.concat({ doc, bookmark })
      }, () => {
        if (this.state.selectedDocument !== -1) {
          this.onNextDocument();
        }
        if (this.state.selectedBookmark !== -1) {
          this.onNextBookmark();
        }
      });
    } else {
      this.props.addNotification({
        id: 'npl-match',
        type: 'error',
        message: 'Select bookmark and document'
      });
    }
  }

  onFetchDocument() {
    let docNumber = this.state.doc.get('documentNumber');
    if (docNumber && /^[0-9]+$/.test(docNumber)) {
      docNumber = `NPL${docNumber}`;
    }

    const matter = Immutable.fromJS({
      firmName: this.state.firm,
      clientNumber: this.state.client,
      citedArt: [docNumber]
    });

    this.props.fetchDocuments(matter).then(response => {
      const doc = _get(response, ['entities', 'documents', this.state.client, docNumber]);
      if (doc) {
        this.setState({
          doc: new DocumentRecord(doc)
        });
      }
    });
  }

  onUpdateDocument(doc) {
    const selected = this.getSelectedDocument();
    if (selected) {
      const index = this.state.documents.findIndex(d => d.get('id') === selected.get('id'));
      if (index !== -1) {
        this.setState({
          documents: this.state.documents.setIn([index], doc)
        });
        return;
      }
    }

    this.setState({ doc });
  }

  onSelectDocument(selectedDocument) {
    this.setState({
      selectedDocument: isNaN(selectedDocument) ? -1 : selectedDocument
    });
  }

  onUpdate(state) {
    this.setState(state);
  }

  onUploadPDF(content) {
    this.props.uploadNplMatchMigration(content)
      .then(response => {
        this.setState({
          bookmarks: response.bookmarks,
          selectedBookmark: response.bookmarks.length ? 0 : -1,
          pdf: response.pdfDoc
        });
      });
  }

  onClearPDF() {
    this.setState({
      selectedBookmark: -1,
      bookmarks: null,
      pdf: null
    });
  }

  onSelectBookmark(selectedBookmark) {
    this.setState({ selectedBookmark });
  }

  onChangeFilterPDF(filterPDF) {
    this.setState({
      filterPDF,
      selectedDocument: this.state.documents ? 0 : -1
    });
  }

  onSearchNPL(value) {
    this.props.searchNplDocuments(this.state.firm, this.state.client, value, true);
  }

  getSuggestions() {
    const suggestions = {};

    if (this.props.nplSuggestions && this.state.client && this.props.nplSuggestions.get(this.state.client)) {
      this.props.nplSuggestions.get(this.state.client).valueSeq().forEach(item => {
        const docNumber = item.get('documentNumber');
        const doc = this.props.documents.getIn([this.state.client, docNumber]);
        if (doc && (!this.state.filterPDF || doc.get('stored') !== 'Yes')) {
          suggestions[docNumber] = doc;
        }
      });
    }

    if (this.props.documents) {
      this.props.documents.forEach(doc => {
        if ((!this.state.filterPDF || doc.get('stored') !== 'Yes')) {
          suggestions[doc.get('documentNumber')] = doc;
        }
      });
    }

    return Immutable.Map(suggestions);
  }

  toggleIntro() {
    this.setState({
      showIntro: !this.state.showIntro
    }, () => {
      if (this.state.showIntro) {
        window.localStorage.removeItem('hideNplMatchIntro');
      } else {
        window.localStorage.setItem('hideNplMatchIntro', true);
      }
    });
  }

  renderIntro() {
    if (this.state.showIntro) {
      return (
        <div className='col-md-12'>
          <NplMatchIntro />
          <br />
          <a style={{ cursor: 'pointer' }} onClick={this.toggleIntro}>Hide intro</a>
          <hr />
        </div>
      );
    }

    return (
      <div className='col-md-12'>
        <br />
        <a style={{ cursor: 'pointer' }} onClick={this.toggleIntro}>Show intro</a>
        <hr />
      </div>
    );
  }

  render() {
    return (
      <CollapsiblePanel header='NPL Match Migration'>
        <div className='row'>
          {this.renderIntro()}
        </div>

        <FirmClientMatter
          pdf={this.state.pdf}
          firm={this.state.firm}
          client={this.state.client}
          matter={this.state.matter}
          applicationNumber={this.props.matter.get('applicationNumber')}
          onClearPDF={this.onClearPDF}
          onUploadPDF={this.onUploadPDF}
          onUpdate={this.onUpdate}
          onSubmit={this.loadDocuments}
          fetchMatter={this.props.fetchMatter}
          fetchDocuments={this.props.fetchDocuments}
          fetchClientList={this.props.fetchClientList}
          fetchMatterList={this.props.fetchMatterList}
        />

        {this.state.pdf && this.state.bookmarks && (
          <PDFBrowser
            removeFromQueue={this.removeFromQueue}
            uploading={this.state.uploading}
            queue={this.state.queue}
            uploaded={this.state.uploaded}
            renderPDFPage={this.props.renderPDFPage}
            renderPDFBookmark={this.props.renderPDFBookmark}
            pdf={this.state.pdf}
            bookmark={this.getSelectedBookmark()}
            bookmarks={this.state.bookmarks}
            selectedBookmark={this.state.selectedBookmark}
            onUpload={this.onUpload}
            onPrevious={this.onPreviousBookmark}
            onNext={this.onNextBookmark}
            onSelectBookmark={this.onSelectBookmark}
          />
        )}

        {this.state.firm && this.state.client && (
          <NPLBrowser
            features={this.props.features}
            filterPDF={this.state.filterPDF}
            onFetchDocument={this.onFetchDocument}
            onUpdateDocument={this.onUpdateDocument}
            onSelectDocument={this.onSelectDocument}
            documents={this.getFilteredDocuments()}
            updateDocument={this.props.updateDocument}
            renderPDFBookmark={this.props.renderPDFBookmark}
            pdf={this.state.pdf}
            client={this.state.client}
            doc={this.getSelectedDocument()}
            bookmark={this.getSelectedBookmark()}
            bookmarks={this.state.bookmarks}
            selectedBookmark={this.state.selectedBookmark}
            selectedDocument={this.state.selectedDocument}
            onMatch={this.onMatch}
            onPrevious={this.onPreviousDocument}
            onNext={this.onNextDocument}
            onChangeFilterPDF={this.onChangeFilterPDF}
            nplSuggestions={this.getSuggestions()}
            uploadFile={this.props.uploadFile}
            clearNplDocuments={this.props.clearNplDocuments}
            onSearchNPL={this.onSearchNPL}
          />
        )}
      </CollapsiblePanel>
    );
  }
}
