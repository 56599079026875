import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import Form from '../../shared/Form';

export default class InvoiceReminder extends Form {

  static propTypes = {
    token: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      updateDb: false,
      sendEmail: false,
      sendTo: '',
      limit: 10,
      reminderFirmName: ''
    };
  }

  renderLink() {
    let url = window.location.protocol +
      '//' +
      window.location.host +
      '/api/invoices/past-due-reminders?';

    if (this.state.sendEmail) {
      url += '&sendEmail=true';
    }

    if (this.state.updateDb) {
      url += '&updateDb=true';
    }

    if (this.state.sendTo) {
      url += '&sendTo=' + this.state.sendTo;
    }

    if (this.state.reminderFirmName) {
      url += '&reminderFirmName=' + this.state.reminderFirmName;
    }

    if (this.state.limit) {
      url += '&limit=' + this.state.limit;
    }

    url += '&token=' + this.props.token;

    return (
      <div style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingTop: '35px'
      }}>
        <a href={url} target='_blank'>
          {url}
        </a>
      </div>
    );
  }

  renderForm() {
    return (
      <div style={{ padding: '10px 20px' }}>
        <div className='row'>
          {this.renderCheckbox('Update DB', 'updateDb', [false, true])}
          {this.renderCheckbox('Send Email', 'sendEmail', [false, true])}
          {this.renderInput('Send To', 'sendTo', '300px')}
          {this.renderInput('Firm Name', 'reminderFirmName', '300px')}
          {this.renderInput('Limit', 'limit', '100px')}
        </div>
        <div className='row'>
          {this.renderLink()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <CollapsiblePanel id='invoice-reminder' header='Invoice'>
        {this.renderForm()}
      </CollapsiblePanel>
    );
  }
};
