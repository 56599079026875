import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { DropdownButton, NavDropdown } from 'react-bootstrap';
import MenuItemHelp from './MenuItemHelp';

export default class DropdownHelp extends Component {
  static propTypes = {
    footer: PropTypes.node,
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    selected: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    variant: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    delayValues: PropTypes.array.isRequired,
    emptyLabel: PropTypes.string,
    style: PropTypes.object,
    onOpen: PropTypes.func
  };

  static defaultProps = {
    delayValues: [],
    variant: 'primary',
    size: 'sm'
  };

  static getDerivedStateFromProps(props, state) {
    if (props.selected !== state.selected) {
      return {
        value: props.selected,
        selected: props.selected
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      open: false
    };
  }

  renderOption(option, index) {
    if (option.divider) {
      return (
        <NavDropdown.Divider key={index} />
      );
    }

    return (
      <MenuItemHelp
        key={option.value}
        id={`dropdown-help-${this.props.id}-${option.value}`}
        help={option.help}
        label={option.label}
        disabled={option.disabled}
        labelClass={option.labelClass}
        helpIcon={option.helpIcon}
        eventKey={option.value.toString()}
      />
    );
  }

  getSelectedLabel() {
    const options = this.props.options.filter((option) => !option.divider);

    const selected = options.find((option) => option.value.toString() === this.state.value?.toString());

    if (selected) {
      return selected.label;
    }

    if (this.props.emptyLabel) {
      return this.props.emptyLabel;
    }

    if (options[0]) {
      return options[0].label;
    }
  }

  renderTitle() {
    return (
      <span style={{ paddingRight: '3px' }}>
        {this.getSelectedLabel()}
      </span>
    );
  }

  onClick(e) {
    e.stopPropagation();
  }

  onToggle(open) {
    if (open && this.props.onOpen) {
      this.props.onOpen();
    }
    if (this.props.delayValues.includes(this.justSelected) && !open) {
      this.justSelected = null;
      setTimeout(() => {
        this.setState({ open });
      }, 500);
      return;
    }
    this.setState({ open });
  }

  onSelect(value) {
    this.justSelected = value;
    this.props.onChange(value);
  }

  getOptions() {
    return this.props.footer
      ? this.props.options.concat({ divider: true })
      : this.props.options;
  }

  renderDropdown() {
    return (
      <DropdownButton
        show={this.state.open}
        style={this.props.style}
        variant={this.props.variant}
        size={this.props.size}
        id={this.props.id}
        title={this.renderTitle()}
        onSelect={this.onSelect}
        onToggle={this.onToggle}
      >
        {this.state.open && this.getOptions().filter(opt => opt.show !== false).map(this.renderOption)}
        {this.state.open && this.props.footer}
      </DropdownButton>
    );
  }

  render() {
    return (
      <div onClick={this.onClick} style={{ display: 'inline-block' }}>
        {this.renderDropdown()}
      </div>
    );
  }
}
