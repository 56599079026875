import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import CategoriesInput from '../categories/CategoriesInput';
import BatchCategoryConfirm from './BatchCategoryConfirm';
import DocumentRecord from '../../models/DocumentRecord';
import _uniq from 'lodash/uniq';

export default class BatchCategoryIcon extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    docType: PropTypes.string.isRequired,
    docFilter: PropTypes.string.isRequired,
    notesFilter: PropTypes.string.isRequired,
    notesTab: PropTypes.string.isRequired,
    categories: PropTypes.any,
    viewCategory: PropTypes.func.isRequired,
    updateDocumentCategories: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  isDisabled() {
    return this.props.docType !== 'US' && this.props.features.limitedDocs;
  }

  onConfirm(selected, unselected) {
    this.setState({
      confirmCategories: { selected, unselected }
    });
  }

  onCancel() {
    this.setState({
      confirmCategories: null
    });
  }

  getDocsByType(type) {
    const { matter, documents, docFilter } = this.props;

    const getArts = (field, cited) => matter.get(field, [])
      .map(n => documents.get(n))
      .filter(d => !!d)
      .filter(d => type ? d.get('type') === type : true)
      .map(d => d.set('cited', cited));

    const citedArt = (docFilter === 'all' || docFilter === 'cited') ? getArts('citedArt', true) : Immutable.List();
    const uncitedArt = (docFilter === 'all' || docFilter === 'uncited') ? getArts('uncitedArt', false) : Immutable.List();

    let docs = citedArt.concat(uncitedArt).map(doc => new DocumentRecord(doc));

    if (this.props.notesFilter) {
      docs = docs.filter(doc => (
        doc.getNotes(this.props.notesTab)
          .toLowerCase()
          .includes(this.props.notesFilter.toLowerCase())
      ));
    }

    return docs;
  }

  onConfirmType() {
    this.updateDocuments(this.getDocsByType(this.props.docType), this.state.confirmCategories);
    this.setState({ confirmCategories: null });
  }

  onConfirmAll() {
    this.updateDocuments(this.getDocsByType(), this.state.confirmCategories);
    this.setState({ confirmCategories: null });
  }

  updateDocuments(docs, newCategories) {
    let promise = Promise.resolve();
    docs.forEach(doc => {
      promise = promise.then(() => {
        const currentCategories = doc.get('categories', Immutable.List());
        const merged = [].concat(currentCategories.toJS(), newCategories.selected.toJS())
          .filter(category => !newCategories.unselected.includes(category));
        const categories = Immutable.fromJS(_uniq(merged).filter(c => !!c));
        return Immutable.is(categories, currentCategories)
          ? Promise.resolve()
          : this.props.updateDocumentCategories(doc, categories);
      });
    });
    return promise;
  }

  renderConfirm() {
    const docsType = this.getDocsByType(this.props.docType);
    const selected = this.state.confirmCategories.selected.filter(cat => (
      !docsType.every(doc => doc.get('categories').includes(cat))
    ));

    return (
      <BatchCategoryConfirm
        countAll={this.getDocsByType().size}
        countType={docsType.size}
        docType={this.props.docType}
        onCancel={this.onCancel}
        selected={selected}
        unselected={this.state.confirmCategories.unselected}
        onConfirmType={this.onConfirmType}
        onConfirmAll={this.onConfirmAll}
      />
    );
  }

  getGreyedCategories() {
    const docs = this.getDocsByType(this.props.docType);

    return this.props.categories.filter(category => {
      return (
        docs.some(doc => doc.get('categories').includes(category)) &&
        !docs.every(doc => doc.get('categories').includes(category))
      );
    });
  }

  getSelectedCategories() {
    const docs = this.getDocsByType(this.props.docType);

    return this.props.categories.filter(category => {
      return docs.count() > 0 && docs.every(doc => doc.get('categories').includes(category));
    });
  }

  render() {
    return (
      <div>
        {this.state.confirmCategories && this.renderConfirm()}
        <CategoriesInput
          compact
          disabled={this.isDisabled()}
          onConfirm={this.onConfirm}
          id='batch-category-icon'
          categories={this.props.categories}
          greyedCategories={this.getGreyedCategories()}
          selectedCategories={this.getSelectedCategories()}
          viewCategory={this.props.viewCategory}
        />
      </div>
    );
  }
}
