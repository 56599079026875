import callApi from '../../utils/callApi';
import { createAction } from 'redux-actions';
import addNotification from '../app/addNotification';
import { mattersUrl } from '../../constants/urls';
import { MATTER_RENAME_SUCCESS } from '../../constants/actions';

const matterRenameSuccess = createAction(MATTER_RENAME_SUCCESS);

const trimFields = [
  'firstInventor',
  'applicationNumber',
  'confirmationNumber',
  'artUnit',
  'examinerName',
  'alternateId'
];

export default (updatedFields, matterNumber) => (dispatch, getState) => {
  if (!updatedFields.id) {
    return;
  }

  if (matterNumber) {
    dispatch(addNotification({
      id: `matter-${updatedFields.id}`,
      type: 'progress',
      message: `Updating matter ${matterNumber}...`
    }));
  }

  // trim whitespaces on all changed fields
  trimFields.forEach(key => {
    if (typeof updatedFields[key] === 'string') {
      updatedFields[key] = updatedFields[key].trim();
    }
  });

  if (updatedFields.matterClosed === 'ON') {
    updatedFields.urgent = false;
  }

  return dispatch(callApi({
    url: mattersUrl + '/' + updatedFields.id,
    method: 'put',
    data: updatedFields
  }))
    .then((r) => {
      if (updatedFields.matterNumber) {
        dispatch(matterRenameSuccess({
          matterId: updatedFields.id,
          matterNumber: updatedFields.matterNumber
        }));
      }
      if (matterNumber) {
        dispatch(addNotification({
          id: `matter-${updatedFields.id}`,
          type: 'success',
          message: `Updated matter ${matterNumber}.`
        }));
      }
      return r;
    })
    .catch(() => {
      if (matterNumber) {
        dispatch(addNotification({
          id: `matter-${updatedFields.id}`,
          type: 'error',
          message: `Failed to update ${matterNumber}.`
        }));
      }
    });
};
