import React, { Component } from 'react';
import { Route, Routes, HashRouter } from 'react-router-dom';
import AppBarContainer from './AppBarContainer';
import AboutView from '../views/AboutView';
import AdminView from '../views/AdminView';
import AppView from '../views/AppView';
import CiteChronView from '../views/CiteChronView';
import DemoView from '../views/DemoView';
import FAQView from '../views/FaqView';
import HomeView from '../views/HomeView';
import requireAuth from '../views/decorators/requireAuth';
import ServicesView from '../views/ServicesView';
import TestDriveView from '../views/TestDriveView';
import PrivacyPolicyView from '../views/PrivacyPolicyView';
import '../../styles/core.scss';
import '../../favicon.ico';
import PaymentsView from '../views/PaymentsView';
import TermsAndConditionsView from '../views/TermsAndConditionsView';
import AppFooter from './AppFooter';
import SecurityView from '../views/SecurityView';

const AppViewWithAuth = requireAuth(AppView);
const AdminViewWithAuth = requireAuth(AdminView);

export default class Root extends Component {
  render() {
    return (
      <div id='scroll'>
        <AppBarContainer />
        <HashRouter>
          <Routes>
            <Route element={<AppViewWithAuth />} path='/app/*' />
            <Route element={<AdminViewWithAuth />} path='/admin/*' />

            <Route element={<AboutView />} path='/contact' exact />
            <Route element={<CiteChronView />} path='/cite-chron' exact />
            <Route element={<DemoView />} path='/demo' exact />
            <Route element={<FAQView />} path='/faq' />
            <Route element={<HomeView />} path='/' exact />
            <Route element={<HomeView />} path='/overview' exact />
            <Route element={<PrivacyPolicyView />} path='/privacy-policy' exact />
            <Route element={<ServicesView />} path='/services' exact />
            <Route element={<TestDriveView />} path='/test-drive' exact />
            <Route element={<PaymentsView />} path='/payments' exact />
            <Route element={<TermsAndConditionsView />} path='/terms-and-conditions' exact />
            <Route element={<SecurityView />} path='/security' exact />
          </Routes>
        </HashRouter>
        <AppFooter />
      </div>
    );
  }
}
