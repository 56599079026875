import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Pagination, DropdownButton } from 'react-bootstrap';
import { MenuItem } from '../shared/MenuItem';

export default class TodosPages extends Component {
  static propTypes = {
    currentPageSize: PropTypes.number.isRequired,
    pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
    pageCount: PropTypes.number.isRequired,
    activePage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangePageSize: PropTypes.func.isRequired
  };

  static defaultProps = {
    pageSizes: [10, 25, 50]
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onChangePageSize(e) {
    const pageSize = Number(e);
    this.props.onChangePageSize(pageSize);
  }

  renderPageSize() {
    return (
      <DropdownButton
        id='page-size-selector'
        size='sm'
        variant='secondary'
        title={`Page Size: ${this.props.currentPageSize || 'All'}`}
        onSelect={this.onChangePageSize}
      >
        {this.renderItems()}
      </DropdownButton>
    );
  }

  renderItems() {
    return this.props.pageSizes.map(size => (
      <MenuItem eventKey={size} key={size}>{size || 'All'}</MenuItem>
    ));
  }

  onSelect(e) {
    const number = Number(e.target.getAttribute('data-page')) ||
      Number(e.target.parentNode.getAttribute('data-page'));
    this.props.onChangePage(number);
  }

  render() {
    const items = this.props.currentPageSize ? this.props.pageCount : 1;
    const active = this.props.currentPageSize ? this.props.activePage : 1;
    const first = Math.max(active - 4, 1);
    const last = Math.min(active + 4, items);
    const prev = Math.max(active - 1, 1);
    const next = Math.min(active + 1, items);

    const buttons = [];
    for (let number = first; number <= last; number++) {
      buttons.push(
        <Pagination.Item
          key={number}
          active={number === active}
          data-page={number}
          onClick={this.onSelect}
        >
          {number}
        </Pagination.Item>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <Pagination
          size='sm'
          onSelect={this.onSelect}
        >
          <Pagination.First data-page='1' onClick={this.onSelect} />
          <Pagination.Prev data-page={prev} onClick={this.onSelect} />
          {buttons}
          <Pagination.Next data-page={next} onClick={this.onSelect} />
          <Pagination.Last data-page={items} onClick={this.onSelect} />
        </Pagination>
        {this.renderPageSize()}
      </div>
    );
  }
}
