import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Table } from 'react-bootstrap';
import autoBind from 'class-autobind';
import Field from '../shared/Field';
import DateInput from '../shared/DateInput';
import DocumentRecord from '../../models/DocumentRecord';
import { isAfter, formatDate } from '../../utils/date';
import Loading from '../shared/Loading';

export default class RemoveNonPriorArtModal extends Component {
  static propTypes = {
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    onClose: PropTypes.func.isRequired,
    removeNonPriorArt: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true,
      priorityDate: props.matter.get('priorityDate')
    };
  }

  onClose() {
    this.setState({ show: false });
    this.props.onClose();
  }

  onConfirm() {
    const documentNumbers = this.getDocuments('US')
      .concat(this.getDocuments('Foreign'))
      .map((doc) => doc.getRealDocumentNumber());

    this.setState({ saving: true });

    this.props.removeNonPriorArt(
      this.props.matter.get('id'),
      documentNumbers
    ).then(() => {
      this.setState({ saving: false });
      this.onClose();
    });
  }

  isLoading() {
    return this.props.matter.get('uncitedArt').some(doc => !this.props.documents.get(doc));
  }

  getDocuments(type) {
    const priorityDate = this.props.matter.get('priorityDate');

    const documents = this.props.matter.get('uncitedArt')
      .toJS()
      .map((doc) => new DocumentRecord(this.props.documents.get(doc)))
      .filter((doc) => (
        doc &&
        doc.get('type') === type &&
        doc.get('priorityDate') &&
        priorityDate &&
        isAfter(doc.get('priorityDate'), priorityDate)
      ));

    return documents;
  }

  onBlurPriorityDate(ev) {
    this.setState({
      priorityDate: formatDate(ev.target.value)
    }, () => {
      this.props.updateMatter({
        id: this.props.matter.get('id'),
        priorityDate: this.state.priorityDate
      }, this.props.matter.get('matterNumber'));
    });
  }

  onChangePriorityDate(ev, priorityDate) {
    this.setState({ priorityDate: formatDate(priorityDate) });
  }

  onKeyDownPriorityDate(ev) {
    if (ev.which === 13) {
      this.onBlurPriorityDate(ev);
    }
  }

  renderPriorityDate() {
    return (
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <p style={{ flex: '1' }}>
          SyncIDS will remove all uncited references from this matter where its priority date is AFTER this matter's priority date. See Deleted List button for a list of references Removed as Non-Prior Art.
        </p>
        <Field
          label='Matter Priority Date'
          style={{ width: '200px', flex: '0 0 200px' }}
        >
          <DateInput
            name='priorityDate'
            className='form-control'
            value={this.state.priorityDate || ''}
            required
            onBlur={this.onBlurPriorityDate}
            onChange={this.onChangePriorityDate}
            onKeyDown={this.onKeyDownPriorityDate}
          />
        </Field>
      </div>
    );
  }

  renderTable(docs) {
    return (
      <Table>
        <thead>
          <tr>
            <th>Document Number</th>
            <th>Priority Date</th>
          </tr>
        </thead>
        <tbody>
          {docs.map((doc) => (
            <tr key={doc.get('documentNumber')}>
              <td>{doc.get('documentNumber')}</td>
              <td>{doc.get('priorityDate')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderDocuments(type) {
    const docs = this.getDocuments(type);

    return (
      <div>
        <h5>{type} Documents ({docs.length})</h5>
        {docs.length ? this.renderTable(docs) : null}
      </div>
    );
  }

  renderBody() {
    return (
      <div>
        {this.renderPriorityDate()}
        <hr />
        {this.isLoading()
          ? (
            <Loading text='Loading documents...' />
          )
          : (
            <>
              {this.renderDocuments('US')}
              {this.renderDocuments('Foreign')}
            </>
          )}
      </div>
    );
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        size='lg'
        onHide={this.onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Remove Non-Prior Art
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={this.onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={this.state.saving}
            variant='primary'
            onClick={this.onConfirm}
          >
            {this.state.saving ? 'Saving...' : 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
