import React, { Component } from 'react';
import HelpIcon from '../shared/HelpIcon';

export default class HelpCopyMatter extends Component {
  renderHelp() {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <strong>Data copied over:</strong>
        </div>
        <div className='col-md-5'>
          <ul>
            <li>Inventor name</li>
            <li>Filer information</li>
            <li>Priority date</li>
            <li>Examiner name</li>
          </ul>
        </div>
        <div className='col-md-7'>
          <ul>
            <li>Two-way related matters</li>
            <li>One-way related matters</li>
            <li>Sync with categories</li>
            <li>Notes</li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <HelpIcon
        styleIcon={{ marginLeft: '4px' }}
        stylePopover={{ maxWidth: '450px' }}
        id='help-copy-matter'
        help={this.renderHelp()}
      />
    );
  }
}
