import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import Form from '../../shared/Form';

export default class MonthlyInvoice extends Form {
  static propTypes = {
    token: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    };
  }

  renderLink() {
    const padMonth = this.state.month < 10 ? '0' + this.state.month : this.state.month;
    const url = window.location.protocol +
      '//' +
      window.location.host +
      '/api/invoices/monthly/' +
      this.state.year +
      '/' +
      padMonth +
      '?token=' +
      this.props.token;

    return (
      <div style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingTop: '35px'
      }}
      >
        <a href={url} target='_blank' rel='noreferrer'>
          {url}
        </a>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        {this.renderInput('Month', 'month', '100px')}
        {this.renderInput('Year', 'year', '100px')}
        {this.renderLink()}
      </div>
    );
  }

  render() {
    return (
      <CollapsiblePanel id='monthly-invoice' header='Monthly Invoice'>
        {this.renderForm()}
      </CollapsiblePanel>
    );
  }
};
