import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import CleanUpTwoWayModal from './CleanUpTwoWayModal';
import SyncOverlapModal from './SyncOverlapModal';

export default class Migration extends Component {
  static propTypes = {
    closeCleanUpTwoWayRelations: PropTypes.func.isRequired,
    closeSyncOverlap: PropTypes.func.isRequired,
    navigateApp: PropTypes.func.isRequired,
    mergeSyncOverlap: PropTypes.func.isRequired,
    cleanUpTwoWay: PropTypes.object,
    syncOverlap: PropTypes.object
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderCleanUpTwoWayModal() {
    return (
      <CleanUpTwoWayModal
        closeCleanUpTwoWayRelations={this.props.closeCleanUpTwoWayRelations}
        {...this.props.cleanUpTwoWay}
      />
    );
  }

  renderSyncOverlap() {
    return (
      <SyncOverlapModal
        {...this.props.syncOverlap}
        navigateApp={this.props.navigateApp}
        mergeSyncOverlap={this.props.mergeSyncOverlap}
        closeSyncOverlap={this.props.closeSyncOverlap}
      />
    );
  }

  render() {
    if (this.props.syncOverlap && this.props.syncOverlap.showModal) {
      return this.renderSyncOverlap();
    }
    if (this.props.cleanUpTwoWay) {
      return this.renderCleanUpTwoWayModal();
    }
    return (
      <span />
    );
  }
}
