export function downloadUrl(url) {
  const iframe = document.createElement('iframe');

  iframe.src = url;

  Object.assign(iframe.style, {
    position: 'absolute',
    top: '-1000px',
    left: '-1000px'
  });

  document.body.appendChild(iframe);
}
