import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import { Button } from 'react-bootstrap';
import ConfirmModal from '../shared/ConfirmModal';
import TodoRecord from '../../models/TodoRecord';
import { isCategoryMatter } from '../../utils/categories';

export default class ConfirmFileModal extends Component {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map),
    features: PropTypes.object.isRequired,
    fileMatter: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map),
    onClose: PropTypes.func.isRequired,
    origin: PropTypes.string.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired,
    todo: PropTypes.instanceOf(TodoRecord),
    subsetIDSEnabled: PropTypes.bool,
    hideClearFileReminder: PropTypes.bool
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onCancel() {
    this.props.onClose();
  }

  isSubset() {
    return (
      this.props.subsetIDSEnabled
    );
  }

  logAction(action) {
    this.props.logAction({
      action: `${action} file reminder from ${this.props.origin}`,
      matterNumber: (this.props.todo || this.props.matter).get('matterNumber'),
      clientNumber: (this.props.todo || this.props.client).get('clientNumber')
    });
  }

  onConfirm() {
    this.props.fileMatter({
      matterId: this.props.matter ? this.props.matter.get('id') : this.props.todo.get('matterId'),
      matterNumber: (this.props.todo || this.props.matter).get('matterNumber'),
      subsetIDS: this.isSubset(),
      documentNumbers: this.isSubset() ? this.props.visibleDocuments.map(doc => doc.getRealDocumentNumber()).toJS() : []
    });
    this.logAction('Confirm');
    this.props.setFileReminder(this.props.todo || this.props.matter, false);
    this.props.onClose();
  }

  onClickClear() {
    this.logAction('Clear');
    this.props.setFileReminder(this.props.todo || this.props.matter, false);
    this.props.onClose();
  }

  shouldShowClear() {
    return (
      !this.props.hideClearFileReminder &&
      !this.props.features.hideClearFileReminder &&
      (this.props.todo || this.props.matter).get('fileReminder') &&
      !isCategoryMatter((this.props.todo || this.props.matter).get('matterNumber'))
    );
  }

  onClickCheater() {
    this.props.fileMatter({
      matterId: this.props.matter ? this.props.matter.get('id') : this.props.todo.get('matterId'),
      matterNumber: (this.props.todo || this.props.matter).get('matterNumber'),
      noCite: true,
      immediateInvoice: true
    });
    this.logAction('Cheater Invoice');
    this.props.onClose();
  }

  renderFooter() {
    return (
      <>
        {this.shouldShowClear() && (
          <Button variant='secondary' onClick={this.onClickClear}>
            Clear Reminder
          </Button>
        )}
        {this.props.features.cheaterInvoice && (
          <Button variant='danger' onClick={this.onClickCheater}>
            Cheater Invoice
          </Button>
        )}
      </>
    );
  }

  renderMessage() {
    return (
      <div>
        {this.isSubset()
          ? 'Mark only the displayed uncited references as cited?'
          : 'Mark all valid uncited references as cited?'}
        <br />
        Please note, an invoice will be generated for this matter.
      </div>
    );
  }

  render() {
    return (
      <ConfirmModal
        focusConfirm
        footer={this.renderFooter()}
        message={this.renderMessage()}
        confirmLabel={this.isSubset() ? `Confirm Reduced (${this.props.visibleDocuments.size})` : 'Confirm All'}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
      />
    );
  }
};
