import fetchMatter from './fetchMatter';

export default (matterId) => (dispatch, getState) => {
  const matter = getState().entities.hasIn(['matters', matterId]);
  if (!matterId || matter) {
    return Promise.resolve();
  }

  return dispatch(fetchMatter(matterId, {
    entities: {
      matters: {
        [matterId]: null
      }
    }
  }));
};
