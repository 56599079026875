import updatePairStatus from './updatePairStatus';
import addNotification from '../app/addNotification';
import sortTodos from './sortTodos';

export default (items) => (dispatch, getState) => {
  const {
    todos: {
      pairStatus
    }
  } = getState();

  const applicationNumbers = items
    .map(todo => todo.get('applicationNumber'))
    .toJS()
    .filter(applicationNumber => !!applicationNumber)
    .map(applicationNumber => applicationNumber.replace(/[\D]/g, ''))
    .filter(applicationNumber => !pairStatus[applicationNumber]);

  let promise = Promise.resolve();
  let counter = applicationNumbers.length;

  dispatch(addNotification({
    id: 'sort-pair-status',
    type: 'progress',
    message: 'Sorting PAIR...'
  }));

  const done = () => {
    if (--counter <= 0) {
      dispatch(sortTodos('pairStatus'));
      dispatch(addNotification({
        id: 'sort-pair-status',
        type: 'success',
        message: 'Sorting PAIR done!'
      }));
    }
  };

  if (counter > 0) {
    applicationNumbers.forEach(applicationNumber => {
      promise = promise.then(() => {
        return dispatch(updatePairStatus(applicationNumber)).then(done).catch(done);
      });
    });
  } else {
    done();
  }
};
