import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';
import { createAction } from 'redux-actions';
import setFileReminder from '../todo/setFileReminder';

import { UPDATE_CITED } from '../../constants/actions';

const updateCited = createAction(UPDATE_CITED);

export default (matter, documentNumber, isCited) => (dispatch, getState) => {
  const { documents: { cited } } = getState();
  const matterId = matter.get('id');
  let showFileModal = false;

  // 7 clicks in 2 minutes
  if (isCited) {
    const lastMinute = Date.now() - (2 * 60 * 1000);
    const citedLastMinute = cited.filter(item => (
      item.matterId === matterId &&
      item.timestamp > lastMinute
    ));

    if (citedLastMinute.length >= 6 && matter.get('fileReminder')) {
      showFileModal = true;
      dispatch(setFileReminder(matter, true));
    }
  }

  dispatch(addNotification({
    id: `document-${documentNumber}`,
    type: 'progress',
    message: `Updating document ${documentNumber}...`
  }));
  return dispatch(callApi({
    url: documentsUrl + '/update-cited',
    params: { matterId, documentNumber, isCited }
  }))
    .then(() => {
      if (isCited) {
        dispatch(updateCited({
          matterId,
          documentNumber,
          timestamp: Date.now()
        }));
      }
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'success',
        message: `Updated ${documentNumber}.`
      }));

      return showFileModal;
    })
    .catch(() => {
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'error',
        message: `Failed to update ${documentNumber}.`
      }));
    });
};
