import { handleActions } from 'redux-actions';

import {
  UPDATE_PAIR_STATUS
} from '../../constants/actions';

const initialState = {};

export default handleActions({

  [UPDATE_PAIR_STATUS]: (state, { payload }) => {
    return Object.assign({}, state, {
      [payload.applicationNumber]: payload.pairStatus
    });
  }

}, initialState);
