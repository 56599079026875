import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';

export default class HelpIcon extends Component {
  static propTypes = {
    icon: PropTypes.string,
    gmIcon: PropTypes.string,
    id: PropTypes.string,
    help: PropTypes.node.isRequired,
    placement: PropTypes.string.isRequired,
    styleIcon: PropTypes.object,
    stylePopover: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    className: PropTypes.string,
    popoverTitle: PropTypes.string,
    popoverClass: PropTypes.string
  };

  static defaultProps = {
    icon: 'question-circle',
    gmIcon: null,
    placement: 'right',
    className: ''
  };

  render() {
    const iconClass = this.props.gmIcon ? 'material-symbols-outlined' : 'fa fa-' + this.props.icon;

    return (
      <Tooltip
        id={this.props.id}
        tip={this.props.help}
        style={this.props.style}
        popoverTitle={this.props.popoverTitle}
        popoverClass={this.props.popoverClass}
        stylePopover={this.props.stylePopover}
        placement={this.props.placement}
      >
        <span
          style={this.props.styleIcon}
          className={iconClass + ' ' + this.props.className}
          onClick={this.props.onClick}
          onMouseOver={this.props.onMouseOver}
        >
          {this.props.gmIcon}
        </span>
      </Tooltip>
    );
  }
};
