import { connect } from 'react-redux';
import actions from '../../redux/actions';
import Admin from './Admin';

const mapStateToProps = (state, props) => {
  return {
    wideLayout: state.app.wideLayout,
    features: state.app.features,
    section: state.admin.section
  };
};

export default connect(mapStateToProps, actions)(Admin);
