import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { DropdownButton } from 'react-bootstrap';
import { MenuItem } from '../shared/MenuItem';
import ConfirmModal from '../shared/ConfirmModal';
import Immutable from 'immutable';

export default class AdminDropdown extends Component {
  static propTypes = {
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    hasPendingOfficeAction: PropTypes.bool.isRequired,
    onClickBatchSweep: PropTypes.func.isRequired,
    onClickCleanUpTwoWay: PropTypes.func.isRequired,
    uploadPDFScraper: PropTypes.func.isRequired,
    onShowPDFScraper: PropTypes.func.isRequired,
    clearPendingOfficeActions: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      reloadInput: false,
      confirmUpload: null,
      confirmClearPendingOfficeActions: false
    };
  }

  onClickClearPendingOfficeActions() {
    this.setState({
      confirmClearPendingOfficeActions: true
    });
  }

  reloadInput() {
    this.setState({ reloadInput: true }, () => {
      this.setState({ reloadInput: false });
    });
  }

  assignRefUpload(element) {
    this.uploadRef = element;
  }

  onClickScrapePDF() {
    this.uploadRef.click();
  }

  onConfirmUpload({ name, file } = this.state.confirmUpload) {
    this.props.uploadPDFScraper(name, file)
      .then((docs) => {
        this.props.onShowPDFScraper(docs);
      })
      .finally(() => {
        this.onCancelUpload();
      });
  }

  onCancelUpload() {
    this.setState({
      confirmUpload: null
    }, () => {
      this.reloadInput();
    });
  }

  onInputChange(e) {
    const file = e.target.files[0];
    const name = e.target.getAttribute('data-name');
    if (name && file) {
      const appNumber = this.props.matter.get('applicationNumber').replace(/[^0-9]/g, '').trim();
      if (!appNumber || !file.name.includes(appNumber)) {
        this.setState({
          confirmUpload: { name, file }
        });
      } else {
        this.onConfirmUpload({ name, file });
      }
    }
  }

  renderFileInput() {
    return this.state.reloadInput || (
      <input
        ref={this.assignRefUpload}
        type='file'
        accept='.pdf'
        data-name='pdf'
        onChange={this.onInputChange}
        style={{
          display: 'none'
        }}
      />
    );
  }

  onCancelClearPendingOfficeActions() {
    this.setState({
      confirmClearPendingOfficeActions: false
    });
  }

  onConfirmClearPendingOfficeActions() {
    this.props.clearPendingOfficeActions(this.props.matter.get('id')).then(() => {
      this.setState({
        confirmClearPendingOfficeActions: false
      });
    });
  }

  renderConfirmClearPendingOfficeActions() {
    return this.state.confirmClearPendingOfficeActions && (
      <ConfirmModal
        message={`Delete pending Office Actions for matter ${this.props.matter.get('matterNumber')}?`}
        onCancel={this.onCancelClearPendingOfficeActions}
        onConfirm={this.onConfirmClearPendingOfficeActions}
        focusConfirm
      />
    );
  }

  renderConfirmUpload() {
    return this.state.confirmUpload && (
      <ConfirmModal
        message={(
          <div>
            <p>Are you sure this is a correct match with this matter?</p>
            <br />
            <p>
              PDF: {this.state.confirmUpload.file.name}
            </p>
            <p>
              Application: {this.props.matter.get('applicationNumber')}
            </p>
          </div>
        )}
        onCancel={this.onCancelUpload}
        onConfirm={this.onConfirmUpload}
        focusConfirm
      />
    );
  }

  render() {
    return (
      <div style={{
        display: 'inline-block'
      }}
      >
        <DropdownButton
          className='no-caret'
          size='sm'
          variant={this.props.hasPendingOfficeAction ? 'danger' : 'primary'}
          title={<span className='fa fa-cog' />}
          id='docs-admin-tools'
        >
          {this.props.hasPendingOfficeAction && (
            <MenuItem onClick={this.props.onClickBatchSweep}>
              Sweep Ready
            </MenuItem>
          )}
          <MenuItem onClick={this.onClickClearPendingOfficeActions}>
            Clear Pending Office Actions
          </MenuItem>
          <MenuItem onClick={this.onClickScrapePDF}>
            Scrape PDF
          </MenuItem>
          <MenuItem onClick={this.props.onClickCleanUpTwoWay}>
            Clean up Two-way
          </MenuItem>
        </DropdownButton>
        {this.renderFileInput()}
        {this.renderConfirmClearPendingOfficeActions()}
        {this.renderConfirmUpload()}
      </div>
    );
  }
};
