import { handleActions } from 'redux-actions';
import {
  NEAR_DUPLICATE_BEGIN,
  NEAR_DUPLICATE_SUCCESS,
  NEAR_DUPLICATE_ERROR,
  NEAR_DUPLICATE_CLEAR
} from '../../constants/actions';

const initialState = false;

export default handleActions({

  [NEAR_DUPLICATE_CLEAR]: () => (false),
  [NEAR_DUPLICATE_BEGIN]: () => (true),
  [NEAR_DUPLICATE_SUCCESS]: () => (false),
  [NEAR_DUPLICATE_ERROR]: () => (false)

}, initialState);
