import React, { Component } from 'react';
import RequestDemo from '../shared/RequestDemo';
import TestDriveContainer from '../test-drive/TestDriveContainer';
import testDriveImage from '../../files/test-drive.png';

const styleImage = {
  backgroundColor: 'white',
  textAlign: 'center'
};

export default class TestDriveView extends Component {
  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  render() {
    return (
      <div>
        <div className='container bigger-fonts'>
          <div>
            <h1>Test Drive</h1>
          </div>
          <hr />
          <p>{'Let us show you the power of SyncIDS by handling one of your "messy" families at no risk to you. Just enter your email address and the application number of any publicly-available U.S. member of the family. That\'s it! We will do the rest and deliver PTO submittable results for the entire U.S. family in an easy to use web-based application within a few days. After we walk you through your Test Drive results, we can enter the historical data for any number of additional matters (even most foreign). You only pay if you file an IDS.'}</p>
          <br />
          <TestDriveContainer />
          <br />
        </div>
        <div style={styleImage}>
          <br />
          <img src={testDriveImage} style={{ maxWidth: '80%' }} />
          <br />
          <br />
        </div>
        <div className='container'>
          <br />
          <p>Going forward, keeping your database and IDS filings up to date is a breeze. Simply add new document numbers or descriptions (e.g., a few references from an 892) to the database, and SyncIDS will do the rest! (i.e., look up dates and inventors, deduplicate, cross-cite, generate forms, pull PDFs, etc.) Alternatively, SyncIDS can scan PAIR every week and enter new data for you! Again, if you like what you see, we will enter the historical data for any number of additional matters (even most foreign). You only pay if you file an IDS.</p>
          <hr />
          <div className='text-center'>
            <RequestDemo />
          </div>
          <br />
        </div>
      </div>
    );
  }
}
