import { createAction } from 'redux-actions';
import { constructTodoSearchParams } from '../../../utils/todo-query';
import fetchTodos from './fetchTodos';
import { TODOS_FETCH_PARAMS } from '../../constants/actions';

const fetchParams = createAction(TODOS_FETCH_PARAMS);

export default (newParams = {}) => (dispatch, getState) => {
  const {
    auth: { firmName },
    entities,
    app: { selectedClientId },
    todos: { currentSearchParams, term, show, filterType }
  } = getState();

  const fetchTodosParams = { term, show, filterType, ...newParams };

  const params = constructTodoSearchParams({
    firmName,
    entities,
    selectedClientId,
    ...fetchTodosParams
  });

  if (!params.firmName || (
    currentSearchParams &&
    currentSearchParams.firmName === params.firmName &&
    currentSearchParams.q === params.q &&
    currentSearchParams.filterMode === params.filterMode
  )) {
    return;
  }

  dispatch(fetchParams(params));
  dispatch(fetchTodos(fetchTodosParams, false));
};
