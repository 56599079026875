import { createAction } from 'redux-actions';
import { isValidEmail } from '../../../utils/text-utils';
import { testDriveUrl, API_ROOT } from '../../constants/urls';
import { TEST_DRIVE_SUBMIT } from '../../constants/actions';
import axios from '../../../utils/axios';

const submitTestDrive = createAction(TEST_DRIVE_SUBMIT);

export default (email, applicationNumber) => (dispatch, getState) => {
  dispatch(submitTestDrive({}));

  if (!isValidEmail(email)) {
    dispatch(submitTestDrive({
      errorMessage: 'Please enter a valid email address.'
    }));
    return;
  }
  if (!/[\w\d]+/.test(applicationNumber)) {
    dispatch(submitTestDrive({
      errorMessage: 'Please enter a valid application number.'
    }));
    return;
  }

  const request = {
    url: API_ROOT + testDriveUrl,
    method: 'GET',
    params: {
      email,
      applicationNumber
    }
  };
  return axios(request)
    .then(() => {
      dispatch(submitTestDrive({
        successMessage: 'Request sent successfully. We will answer shortly.'
      }));
    })
    .catch(() => {
      dispatch(submitTestDrive({
        errorMessage: 'An error happened while submitting your request. Please try again.'
      }));
    });
};
