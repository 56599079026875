import { combineReducers } from 'redux';

import groups from './groups';
import isLoadingGroups from './isLoadingGroups';
import nplAppNumber from './nplAppNumber';
import nplDate from './nplDate';
import nplPageRange from './nplPageRange';
import nplSerialBegin from './nplSerialBegin';
import nplSerialEnd from './nplSerialEnd';
import progress from './progress';
import shingle from './shingle';
import threshold from './threshold';
import type from './type';
import workerId from './workerId';

export default combineReducers({
  groups,
  isLoadingGroups,
  nplAppNumber,
  nplDate,
  nplPageRange,
  nplSerialBegin,
  nplSerialEnd,
  progress,
  shingle,
  threshold,
  type,
  workerId
});
