import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Table } from 'react-bootstrap';
import { splitCommaUnique } from '../../utils/text-utils';
import ConfirmModal from '../shared/ConfirmModal';

export default class SyncFamily extends Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired,
    onRemove: PropTypes.func.isRequired,
    showRemove: PropTypes.bool,
    selectMatter: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (props.matter.get('id') !== state.matterId) {
      return {
        matterId: props.matter.get('id'),
        confirmRemove: false,
        changed: true
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  componentDidUpdate() {
    if (this.state.changed) {
      setTimeout(this.scrollToMatter, 500);
    }
  }

  assignRef(el) {
    this.el = el;
  }

  scrollToMatter() {
    this.setState({ changed: false });
    if (this.el) {
      const icon = this.el.querySelector('.fa-circle');
      if (icon) {
        const top = parseInt(icon.offsetTop, 10) - parseInt(this.el.offsetTop, 10);
        const height = parseInt(this.el.offsetHeight, 10);
        const scrollHeight = parseInt(this.el.scrollHeight, 10);

        if (scrollHeight > height) {
          this.el.scrollTop = Math.max(0, top - (height / 2));
        }
      }
    }
  }

  onClickRemove(e) {
    this.setState({
      confirmRemove: e.target.getAttribute('data-matter')
    });
  }

  onCloseRemove() {
    this.setState({
      confirmRemove: false
    });
  }

  onConfirmRemove() {
    this.props.onRemove(this.state.confirmRemove);
    this.onCloseRemove();
  }

  renderConfirmRemove() {
    return this.state.confirmRemove && (
      <ConfirmModal
        focusCancel
        message={`Are you sure you want to remove matter ${this.state.confirmRemove} from the group?`}
        onCancel={this.onCloseRemove}
        onConfirm={this.onConfirmRemove}
      />
    );
  }

  renderRemove(matterNumber) {
    return (
      <td style={{ width: '30px', textAlign: 'center' }}>
        <span
          style={{ cursor: 'pointer' }}
          onClick={this.onClickRemove}
          data-matter={matterNumber}
          className='fa fa-times'
        />
      </td>
    );
  }

  onClickRow(e) {
    const matterNumber = e.target.getAttribute('data-number');
    const matter = this.props.matterList.find(m => m.get('matterNumber') === matterNumber);
    if (matter) {
      this.props.selectMatter(matter.get('id'));
    }
  }

  renderNumber(matterNumber) {
    return (
      <td
        style={{
          maxWidth: '200px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingLeft: '5px'
        }}
      >
        <div onClick={this.onClickRow} data-number={matterNumber} style={{ cursor: 'pointer' }}>
          {matterNumber}
          {matterNumber === this.props.matter.get('matterNumber') && (
            <span
              className='fa fa-circle' style={{
                position: 'relative',
                left: '10px',
                top: '-2px',
                fontSize: '10px'
              }}
            />
          )}
        </div>
      </td>
    );
  }

  renderTable() {
    const matter = this.props.matter;
    const group = splitCommaUnique(matter.get(this.props.field) || '');

    if (!group.length) {
      return;
    }

    return (
      <Table striped bordered hover style={{ marginBottom: 0 }}>
        <tbody>
          {group.map(matterNumber => (
            <tr key={matterNumber}>
              {this.renderNumber(matterNumber)}
              {this.props.showRemove && this.renderRemove(matterNumber)}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <div ref={this.assignRef} style={{ maxHeight: '320px', overflow: 'auto', marginBottom: '15px' }}>
        {this.renderTable()}
        {this.renderConfirmRemove()}
      </div>
    );
  }
}
