import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button, ButtonGroup } from 'react-bootstrap';

const items = [
  { value: 'all', text: 'All' },
  { value: 'cited', text: 'Cited' },
  { value: 'uncited', text: 'Uncited' }
];

const iconChecked = (
  <span className='fa fa-check text-warning' style={{ marginRight: '3px' }} />
);

const styleButtonWithoutBorder = {
  outline: 'none',
  height: '30px'
};

const styleButton = {
  ...styleButtonWithoutBorder,
  borderLeft: '1px solid #28415b'
};

class CitedFilterButton extends Component {
  static propTypes = {
    item: PropTypes.object,
    active: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClick(e) {
    e.stopPropagation();
    this.props.onClick(this.props.item.value);
  }

  render() {
    return (
      <Button
        key={this.props.item.value}
        variant='primary'
        size='sm'
        style={this.props.style}
        active={this.props.active}
        onClick={this.onClick}
      >
        <span>
          {this.props.active && iconChecked}
          {this.props.item.text}
        </span>
      </Button>
    );
  }
};

export default class CitedFilter extends Component {
  static propTypes = {
    filter: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderItem(item, index) {
    return (
      <CitedFilterButton
        key={item.value}
        item={item}
        style={index ? styleButton : styleButtonWithoutBorder}
        active={this.props.filter === item.value}
        onClick={this.props.onChange}
      />
    );
  }

  render() {
    return (
      <span style={this.props.style}>
        <ButtonGroup>
          {items.map(this.renderItem)}
        </ButtonGroup>
      </span>
    );
  }
}
