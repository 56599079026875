import updateDocument from './updateDocument';
import addDocumentToMatter from './addDocumentToMatter';
import Immutable from 'immutable';
import refreshCategoryMatter from '../category/refreshCategoryMatter';
import { getMatterNumberFromCategory } from '../../../utils/categories';

export default (doc, newCategories) => (dispatch, getState) => {
  const state = getState();
  const {
    app: { selectedClientId }
  } = state;

  const matterList = state.entities.getIn(['matterList', selectedClientId]) || Immutable.List();
  const getMatterId = (category) => {
    const matterNumber = getMatterNumberFromCategory(category);
    const matter = matterList.find(m => m.get('matterNumber') === matterNumber);
    if (matter) {
      return matter.get('id');
    }
  };

  const categories = doc.get('categories');
  const added = newCategories.filter(category => !categories.includes(category)).toJS();
  const removed = categories.filter(category => !newCategories.includes(category)).toJS();

  let promise = Promise.resolve();

  added.forEach(category => {
    const matterId = getMatterId(category);
    if (matterId) {
      promise = promise.then(() => (
        dispatch(addDocumentToMatter(
          matterId,
          doc.getRealDocumentNumber(),
          doc.get('type'),
          null,
          false
        ))
      ));
    }
  });

  return promise.then(() => (
    dispatch(updateDocument({
      id: doc.get('id'),
      categories: newCategories
    }, doc.get('documentNumber')))
  )).then(() => {
    promise = Promise.resolve();
    removed.forEach(category => {
      promise = promise.then(() => dispatch(refreshCategoryMatter(category)));
    });
    return promise;
  });
};
