import { handleActions } from 'redux-actions';

import {
  TOGGLE_SYNC_PANEL
} from '../../constants/actions';

const initialState = true;

export default handleActions({
  [TOGGLE_SYNC_PANEL]: (state, { payload }) => (payload)
}, initialState);
