import { handleActions } from 'redux-actions';

import {
  LOAD_SYNC_OVERLAP_BEGIN,
  LOAD_SYNC_OVERLAP_SUCCESS,
  LOAD_SYNC_OVERLAP_ERROR,
  CLOSE_SYNC_OVERLAP
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [CLOSE_SYNC_OVERLAP]: () => (initialState),
  [LOAD_SYNC_OVERLAP_BEGIN]: (state, { payload }) => (payload),
  [LOAD_SYNC_OVERLAP_SUCCESS]: (state, { payload }) => (payload),
  [LOAD_SYNC_OVERLAP_ERROR]: (state, { payload }) => (payload)

}, initialState);
