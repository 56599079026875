import Immutable from 'immutable';
import { isCategoryMatter, getCategoryFromMatterNumber } from '../../../utils/categories';
import updateDocument from '../document/updateDocument';
import _uniq from 'lodash/uniq';

export default (matterId, documentNumber) => (dispatch, getState) => {
  const state = getState();
  const matter = state.entities.getIn(['matters', matterId]);
  const client = state.entities.getIn(['clients', matter.get('clientId')]);
  const doc = state.entities.getIn(['documents', client.get('clientNumber'), documentNumber]);
  const matterNumber = matter.get('matterNumber');

  if (isCategoryMatter(matterNumber)) {
    const category = getCategoryFromMatterNumber(matterNumber);
    if (category) {
      dispatch(updateDocument({
        id: doc.get('id'),
        categories: Immutable.fromJS(_uniq(doc.get('categories').toJS().filter(cat => cat !== category)))
      }, documentNumber));
    }
  }
};
