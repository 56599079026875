import addDocumentToMatter from './addDocumentToMatter';
import DocumentRecord from '../../../models/DocumentRecord';
import updateDocumentCategories from './updateDocumentCategories';
import updateDocument from './updateDocument';
import Immutable from 'immutable';
import _get from 'lodash/get';

function mergeNotes(savedNote, newDocNote, newRepeatNotes = '') {
  const newNote = newRepeatNotes
    ? mergeNotes(newDocNote, newRepeatNotes)
    : newDocNote;

  if (!newNote) {
    return savedNote;
  }
  if (!savedNote && newNote) {
    return newNote;
  }
  if (savedNote && newNote) {
    return `${savedNote}; ${newNote}`;
  }
}

function getDocNumber(type, doc) {
  if (type === 'Foreign') {
    return `0${doc.number}`;
  } else {
    return doc.number;
  }
}

function getDocFromResponse(type, clientNumber, response, doc) {
  if (response.status === 'duplicate') {
    return;
  }

  const docs = _get(response, ['entities', 'documents', clientNumber]);

  let docNumber;
  if (doc.number) {
    docNumber = type === 'Foreign' ? `0${doc.number}` : doc.number;
  } else if (doc.description) {
    docNumber = Object.keys(docs).filter(key => (
      docs[key].type === 'Other' &&
      docs[key].nonPatentBib === doc.description
    )).shift();
  }
  if (docNumber && docs[docNumber]) {
    return new DocumentRecord(Immutable.fromJS(docs[docNumber]));
  }
}

function getDocFromState(type, clientNumber, getState, doc) {
  const clientDocs = getState().entities.getIn(['documents', clientNumber]);
  if (doc.number) {
    const docNumber = type === 'Foreign' ? `0${doc.number}` : doc.number;
    const d = clientDocs.get(docNumber);
    if (d) {
      return new DocumentRecord(d);
    }
  } else if (doc.description) {
    const nplDoc = clientDocs.find(d => (
      d.get('type') === 'Other' &&
      d.get('nonPatentBib') === doc.description
    ));
    if (nplDoc) {
      return new DocumentRecord(nplDoc);
    }
  }
}

function updateCategories(dispatch, docs, categories, type, clientNumber, response, getState) {
  let promise = Promise.resolve();
  if (categories.count()) {
    docs
      .map(doc => (
        response.status === 'duplicate'
          ? getDocFromState(type, clientNumber, getState, doc)
          : getDocFromResponse(type, clientNumber, response, doc)
      ))
      .filter(doc => !!doc)
      .forEach(doc => {
        promise = promise.then(() => dispatch(updateDocumentCategories(doc, categories)));
      });
  }
  return promise.then(() => response);
}

function updateDocuments(dispatch, docs, type, clientNumber, response, getState, notes) {
  let promise = Promise.resolve();

  docs
    .filter(doc => Boolean(doc.date || doc.applicant || doc.country || doc.notes || notes))
    .forEach(doc => {
      const savedDoc = response.status === 'duplicate'
        ? getDocFromState(type, clientNumber, getState, doc)
        : getDocFromResponse(type, clientNumber, response, doc);
      if (savedDoc) {
        promise = promise.then(() => dispatch(updateDocument({
          id: savedDoc.get('id'),
          publicationDate: savedDoc.get('publicationDate') || doc.date,
          applicant: savedDoc.get('applicant') || doc.applicant,
          country: savedDoc.get('country') || doc.country,
          notes: mergeNotes(savedDoc.get('notes'), doc.notes, notes)
        }, doc.number)));
      }
    });

  return promise.then(() => response);
}

export default (options) => (dispatch, getState) => {
  const {
    matter,
    type,
    docs,
    cited,
    categories,
    officeAction,
    notes
  } = options;

  const clientNumber = matter.get('clientNumber');
  const matterId = matter.get('matterId');

  let promise = Promise.resolve();
  docs.forEach(doc => {
    const value = type === 'Other' ? doc.description : getDocNumber(type, doc);
    const field = type === 'Other' ? 'nonPatentBib' : 'documentNumber';
    promise = promise.then(() => (
      dispatch(addDocumentToMatter(matterId, value, type, field, cited, officeAction))
        .then((response) => updateCategories(dispatch, [doc], categories, type, clientNumber, response, getState))
        .then((response) => updateDocuments(dispatch, [doc], type, clientNumber, response, getState, notes))
    ));
  });

  return promise;
};
