import { push } from 'react-router-redux';

export default (newQuery = {}, newTab = false) => (dispatch, getState) => {
  const { todos: { term, show, filterType } } = getState();
  const currentQuery = { show, term, filterType };
  const query = Object.assign({}, currentQuery, newQuery);

  if (query.ref) {
    window.sessionStorage.setItem('syncids-ref', query.ref);
    delete query.ref;
  }

  const querystring = Object.keys(query)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key] || '')}`)
    .join('&');

  const url = querystring ? `/app/${querystring}` : '/app';

  if (newTab) {
    window.open(`${window.location.protocol}//${window.location.host}/#${url}`);
  } else {
    document.location.hash = url;
    dispatch(push(url));
  }
};
