import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

export default class LoginModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    inputs: PropTypes.object,
    buttons: PropTypes.object,
    message: PropTypes.string,
    messageType: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onEntered: PropTypes.func
  };

  getMessage() {
    if (this.props.message) {
      const className = cx({
        'text-danger': this.props.messageType === 'error',
        [this.props.messageType]: true
      });
      return (
        <div className={className}>
          {this.props.message}
        </div>
      );
    }
  }

  render() {
    return (
      <Modal
        id={this.props.id}
        backdrop='static'
        keyboard={false}
        size='sm'
        onEntered={this.props.onEntered}
        onHide={this.props.onClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.inputs}
          {this.getMessage()}
        </Modal.Body>
        <Modal.Footer>
          {this.props.buttons}
        </Modal.Footer>
      </Modal>
    );
  }
}
