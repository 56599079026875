import openAuthModal from './openAuthModal';
import { createAction } from 'redux-actions';
import { AUTH_LOGIN_SUCCESS } from '../../constants/actions';
import Cookies from 'js-cookie';
import axios from 'axios';
import { usersUrl } from '../../constants/urls';

const authLoginSuccess = createAction(AUTH_LOGIN_SUCCESS);

export default (url = '/validate') => (dispatch, getState) => {
  let { auth: { isLoggedIn, isOpen, token } } = getState();

  if (!token) {
    token = Cookies.get('syncids-session');
  }

  if (token) {
    return axios({
      url: usersUrl + url,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        ref: window.sessionStorage.getItem('syncids-ref') || ''
      }
    }).then(response => {
      const newToken = response.data.token || response.data.newToken;
      Cookies.set('syncids-session', newToken);
      dispatch(authLoginSuccess({
        token: newToken,
        email: response.data.email,
        firmName: response.data.firmName,
        partnership: response.data.partnership,
        rememberMe: true
      }));
      return true;
    }).catch(() => {
      Cookies.remove('syncids-session');
      dispatch(openAuthModal({ mode: 'login' }));
      return false;
    });
  } else if (!isLoggedIn && !isOpen) {
    if (document.location.href.includes('#/app')) {
      dispatch(openAuthModal({ mode: 'login' }));
    }
  }

  return Promise.resolve(false);
};
