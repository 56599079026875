import { handleActions } from 'redux-actions';

import {
  AUTH_CANCEL_CLICK,
  AUTH_RESET_MESSAGE,
  AUTH_RESET_SUCCESS,
  AUTH_REGISTER_MESSAGE,
  AUTH_REGISTER_SUCCESS,
  AUTH_PASSWORD_MESSAGE,
  AUTH_PASSWORD_SUCCESS,
  AUTH_LOGIN_MESSAGE,
  AUTH_LOGIN_SUCCESS,
  AUTH_OPEN_MODAL
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [AUTH_OPEN_MODAL]: () => (initialState),

  [AUTH_CANCEL_CLICK]: () => (initialState),

  [AUTH_RESET_MESSAGE]: (state, { payload }) => (payload.message),

  [AUTH_RESET_SUCCESS]: (state, { payload }) => (payload.message),

  [AUTH_REGISTER_MESSAGE]: (state, { payload }) => (payload.message),

  [AUTH_REGISTER_SUCCESS]: (state, { payload }) => (payload.message),

  [AUTH_PASSWORD_MESSAGE]: (state, { payload }) => (payload.message),

  [AUTH_PASSWORD_SUCCESS]: () => (initialState),

  [AUTH_LOGIN_MESSAGE]: (state, { payload }) => (payload.message),

  [AUTH_LOGIN_SUCCESS]: () => (initialState)

}, initialState);
