import callApi from '../../utils/callApi';
import { documentsUrl } from '../../constants/urls';
import addNotification from '../app/addNotification';

export default (matter) => (dispatch, getState) => {
  const firmName = matter.get('firmName');
  const clientNumber = matter.get('clientNumber');
  const documentNumbers = matter.get('citedArt', []).concat(matter.get('uncitedArt', []));

  if (!firmName || !clientNumber || !documentNumbers.size) {
    return;
  }

  const request = {
    url: documentsUrl + '/get-multiple',
    method: 'post',
    params: { firmName, clientNumber },
    data: { documentNumbers }
  };
  dispatch(addNotification({
    id: `refresh-docs-${firmName}-${clientNumber}`,
    type: 'progress',
    message: 'Checking for updates...'
  }));
  return dispatch(callApi(request))
    .then(() => {
      dispatch(addNotification({
        id: `refresh-docs-${firmName}-${clientNumber}`,
        type: 'success',
        message: 'Finished checking for updates.'
      }));
    })
    .catch(() => {
      dispatch(addNotification({
        id: `refresh-docs-${firmName}-${clientNumber}`,
        type: 'error',
        message: 'Failed to check for updates.'
      }));
    });
};
