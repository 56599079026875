import { createAction } from 'redux-actions';
import axios from 'axios';
import { resetPasswordUrl } from '../../constants/urls';
import {
  AUTH_RESET_SUCCESS,
  AUTH_RESET_MESSAGE,
  AUTH_RESET_PROGRESS
} from '../../constants/actions';

const authResetSuccess = createAction(AUTH_RESET_SUCCESS);
const authResetMessage = createAction(AUTH_RESET_MESSAGE);
const authResetProgress = createAction(AUTH_RESET_PROGRESS);

export default (email) => (dispatch) => {
  const params = { email };
  dispatch(authResetProgress());
  axios
    .get(resetPasswordUrl, { params })
    .then(res => {
      dispatch(authResetSuccess({
        type: 'success',
        message: res.data.status
      }));
    })
    .catch(err => {
      dispatch(authResetMessage({
        type: 'error',
        message: err.response.data.status || err.response.data.error
      }));
    });
};
