import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button, FormGroup } from 'react-bootstrap';
import LoginModal from './LoginModal';
import EmailField from './EmailField';
import { isValidEmail } from '../../utils/text-utils';
import FieldGroup from '../shared/FieldGroup';

export default class LoginForm extends Component {
  static propTypes = {
    email: PropTypes.string,
    inProgress: PropTypes.bool,
    message: PropTypes.string,
    messageType: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangeEmail: PropTypes.func.isRequired,
    rememberMe: PropTypes.bool.isRequired,
    onSingleSignOn: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      password: '',
      rememberMe: Boolean(props.rememberMe)
    };
  }

  getLoginParams() {
    const { email } = this.props;
    const { password, rememberMe } = this.state;
    if (isValidEmail(email) && password) {
      return [
        email,
        password,
        !!rememberMe
      ];
    }
  }

  onSubmit() {
    const params = this.getLoginParams();
    params && this.props.onSubmit(...params);
  }

  onKeyPress(e) {
    if (e.which === 13) {
      this.onSubmit();
    }
  }

  onChangeEmail(e) {
    this.props.onChangeEmail({ email: e.target.value });
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  onChangeRememberMe(e) {
    this.setState({ rememberMe: e.target.checked });
  }

  renderEmail() {
    return (
      <EmailField
        value={this.props.email}
        autoFocus={!this.state.rememberMe}
        onKeyPress={this.onKeyPress}
        onChange={this.onChangeEmail}
      />
    );
  }

  renderPassword() {
    return (
      <FieldGroup
        type='password'
        label='Password'
        spellCheck={false}
        autoFocus={this.state.rememberMe}
        value={this.state.password}
        onKeyPress={this.onKeyPress}
        onChange={this.onChangePassword}
      />
    );
  }

  renderRememberMe() {
    return (
      <div className='form-check'>
        <input
          className='form-check-input'
          type='checkbox'
          value=''
          id='flexCheckChecked'
          checked={this.state.rememberMe}
          onChange={this.onChangeRememberMe}
          onKeyPress={this.onKeyPress}
        />
        <label className='form-check-label' htmlFor='flexCheckChecked'>
          Remember Me
        </label>
      </div>
    );
  }

  renderInputs() {
    return (
      <div>
        <FormGroup className='mb-3'>
          {this.renderEmail()}
        </FormGroup>
        <FormGroup className='mb-3'>
          {this.renderPassword()}
        </FormGroup>
        <FormGroup className='mb-3'>
          {this.renderRememberMe()}
        </FormGroup>
      </div>
    );
  }

  onRegister(e) {
    e.preventDefault();
    this.props.onRegister();
  }

  onResetPassword(e) {
    e.preventDefault();
    this.props.onResetPassword();
  }

  onSingleSignOn(e) {
    e.preventDefault();
    this.props.onSingleSignOn();
  }

  renderLinks() {
    return (
      <ul style={{
        float: 'left',
        textAlign: 'left',
        padding: '0 0 0 10px',
        margin: 0,
        position: 'relative',
        left: '-20px'
      }}
      >
        <li><a href='#' onClick={this.onRegister}>Create Account</a></li>
        <li><a href='#' onClick={this.onResetPassword}>Reset Password</a></li>
        <li><a href='#' onClick={this.onSingleSignOn}>SSO</a></li>
      </ul>
    );
  }

  renderCancel() {
    return (
      <Button
        style={{ width: '55px' }}
        size='sm'
        variant='secondary'
        onClick={this.props.onClose}
      >
        Cancel
      </Button>
    );
  }

  renderSubmit() {
    return (
      <Button
        style={{ width: '55px' }}
        size='sm'
        variant='primary'
        onClick={this.onSubmit}
      >
        {this.props.inProgress ? <span className='fa fa-spinner fa-spin' /> : 'Login'}
      </Button>
    );
  }

  renderButtons() {
    return (
      <>
        {this.renderLinks()}
        {this.renderCancel()}
        {this.renderSubmit()}
      </>
    );
  }

  render() {
    return (
      <LoginModal
        id='login-modal'
        title='Login'
        inputs={this.renderInputs()}
        buttons={this.renderButtons()}
        message={this.props.message}
        messageType={this.props.messageType}
        onClose={this.props.onClose}
      />
    );
  }
}
