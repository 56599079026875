import callApi from '../../utils/callApi';
import { attorneyUrl } from '../../constants/urls';

export default (email) => (dispatch, getState) => {
  email = email ? email.toLowerCase() : '';

  if (!email || getState().entities.getIn(['attorneys', email])) {
    return null;
  }

  return dispatch(callApi({
    url: attorneyUrl + '/' + email
  }, {
    entities: {
      attorneys: {
        [email]: { email }
      }
    }
  }));
};
