import { createAction } from 'redux-actions';
import callApi from '../../utils/callApi';
import { isValidEmail } from '../../../utils/text-utils';
import { citeChronUrl } from '../../constants/urls';
import { CITE_CHRON_SUBMIT } from '../../constants/actions';

const submitCiteChron = createAction(CITE_CHRON_SUBMIT);

export default (email, patentNumber) => (dispatch, getState) => {
  dispatch(submitCiteChron({
    email,
    patentNumber
  }));

  if (!isValidEmail(email)) {
    dispatch(submitCiteChron({
      email,
      patentNumber,
      errorMessage: 'Please enter a valid email address.',
      successMessage: null
    }));
    return;
  }
  if (!/[\w\d]+/.test(patentNumber)) {
    dispatch(submitCiteChron({
      email,
      patentNumber,
      errorMessage: 'Please enter a valid application number.',
      successMessage: null
    }));
    return;
  }

  const request = {
    url: citeChronUrl,
    params: {
      email,
      patentNumber
    }
  };
  return dispatch(callApi(request))
    .then(() => {
      dispatch(submitCiteChron({
        email,
        errorMessage: null,
        successMessage: 'Request sent successfully. We will answer shortly.'
      }));
    })
    .catch(() => {
      dispatch(submitCiteChron({
        successMessage: null,
        errorMessage: 'An error happened while submitting your request. Please try again.'
      }));
    });
};
