import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Modal, Button, Table } from 'react-bootstrap';

export default class BatchUploadModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    confirmBatchUpload: PropTypes.func.isRequired,
    zip: PropTypes.object,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    features: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true,
      files: this.getFiles(),
      valid: {}
    };
  }

  getDocument(docNumber) {
    return this.props.documents.get(docNumber);
  }

  getFiles() {
    return Immutable.List(
      Object.keys(this.props.zip.files)
        .map(file => ({
          name: file,
          file: this.props.zip.files[file],
          documentNumber: file.replace(/\.pdf/i, '')
        }))
        .filter(file => /\.pdf$/i.test(file.name))
        .filter(file => !/^_|\._/.test(file.name))
        .map(file => Immutable.Map(file))
    );
  }

  onClickCancel() {
    this.setState({
      show: false
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  getFileValidation(file) {
    if (this.state.validated && file) {
      return this.state.validated.find(f => f.file.file === file.file);
    }
  }

  getFilesToUpload() {
    return this.state.files
      .map(file => {
        return {
          doc: this.getDocument(file.get('documentNumber')),
          file: file.get('file')
        };
      })
      .filter(file => !!file.doc)
      .toJS();
  }

  getMode() {
    if (!this.props.features.validatePDF) {
      return 'upload';
    }

    const files = this.getFilesToUpload();
    const valid = files.filter(file => this.getFileValidation(file));

    return files.length && files.length === valid.length ? 'upload' : 'validate';
  }

  onClickConfirm() {
    const files = this.getFilesToUpload();
    const mode = this.getMode();

    this.props.confirmBatchUpload(files, mode)
      .then((responses) => {
        if (!this.state.validated) {
          this.setState({
            validated: responses
          });
        }
      });
  }

  renderConfirm() {
    return (
      <Button
        disabled={!this.getFilesToUpload().length}
        variant='primary'
        onClick={this.onClickConfirm}
      >
        {this.getMode() === 'upload' ? 'Upload' : 'Validate'}
      </Button>
    );
  }

  renderCancel() {
    return (
      <Button
        variant='secondary'
        onClick={this.onClickCancel}
      >
        Cancel
      </Button>
    );
  }

  onChange(e) {
    const index = Number(e.target.getAttribute('data-index'));
    const value = e.target.value;

    this.setState({
      files: this.state.files.setIn([index, 'documentNumber'], value)
    });
  }

  renderValidIcon(file) {
    if (!this.state.validated) {
      return;
    }

    const validation = this.getFileValidation(file.toJS());

    if (!validation) {
      return;
    }

    if (validation.response.embeddedFonts && validation.response.embeddedFonts.length) {
      return (
        <span>
          <span className='fa fa-exclamation-triangle text-danger' />
          &nbsp;
          Embedded Fonts
        </span>
      );
    }

    if (validation.response.isValid) {
      return (
        <span>
          <span className='fa fa-check-square-o text-success' />
          &nbsp;
          Valid
        </span>
      );
    }
  }

  renderMatchIcon(file) {
    if (this.getDocument(file.get('documentNumber'))) {
      return (
        <span>
          <span className='fa fa-check-square-o text-success' />
          &nbsp;
          Match
        </span>
      );
    } else {
      return (
        <span>
          <span className='fa fa-times text-danger' />
          &nbsp;
          Not Found
        </span>
      );
    }
  }

  renderFile(file, index) {
    return (
      <tr key={index}>
        <td style={{ width: '180px' }}>
          <input
            style={{ height: '25px' }}
            className='form-control input-sm'
            spellCheck={false}
            data-index={index}
            value={file.get('documentNumber', '')}
            onChange={this.onChange}
            lang='en'
          />
        </td>
        <td style={{ paddingLeft: '15px' }}>
          {this.renderMatchIcon(file)}
        </td>
        <td>
          {file.get('name')}
        </td>
        <td>
          {this.renderValidIcon(file)}
        </td>
      </tr>
    );
  }

  renderBody() {
    return (
      <div>
        <Table id='table-batch-upload' borderless>
          <thead>
            <tr>
              <th style={{ width: '180px' }}>Document Number</th>
              <th style={{ width: '140px' }}>&nbsp;</th>
              <th>File</th>
              <th style={{ width: '200px' }}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.state.files.map(this.renderFile)}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <Modal
        size='xl'
        show={this.state.show}
        onHide={this.onClickCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          Batch Upload
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderCancel()}
          {this.renderConfirm()}
        </Modal.Footer>
      </Modal>
    );
  }
};
