import { createAction } from 'redux-actions';
import { AUTH_LOGOUT } from '../../constants/actions';
import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

const authLogout = createAction(AUTH_LOGOUT);

export default () => (dispatch) => {
  Cookies.remove('syncids-session');

  dispatch(authLogout());
  dispatch(push('/'));
};
