import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';
import fetchDocument from './fetchDocument';

export default (matterId, documentNumber) => (dispatch, getState) => {
  const matter = getState().entities.getIn(['matters', matterId]);

  dispatch(addNotification({
    id: `document-${documentNumber}`,
    type: 'progress',
    message: `Deleting document ${documentNumber}...`
  }));
  return dispatch(callApi({
    url: documentsUrl + '/delete-from-family',
    params: { matterId, documentNumber }
  }))
    .then(() => {
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'success',
        message: `Deleted document ${documentNumber}.`
      }));
      dispatch(fetchDocument({
        firmName: matter.get('firmName'),
        clientNumber: matter.get('clientNumber'),
        documentNumber
      }));
    })
    .catch(() => {
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'error',
        message: `Failed to delete ${documentNumber}.`
      }));
    });
};
