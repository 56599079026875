import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { clientsUrl } from '../../constants/urls';

export default (updatedFields, clientNumber) => (dispatch, getState) => {
  if (!updatedFields.id) {
    return;
  }

  if (updatedFields.sweepCitationsFlag === false) {
    updatedFields.sweepUsOfficeActionsFlag = false;
  }

  if (updatedFields.reminderRecipientEmails) {
    updatedFields.reminderRecipientEmails = updatedFields.reminderRecipientEmails
      .split(/ *, */)
      .map(val => val.trim().toLowerCase())
      .join(',');
  }

  dispatch(addNotification({
    id: `client-${clientNumber}`,
    type: 'progress',
    message: `Updating client ${clientNumber}...`
  }));
  return dispatch(callApi({
    url: clientsUrl + '/' + updatedFields.id,
    method: 'put',
    data: updatedFields
  }))
    .then(() => {
      dispatch(addNotification({
        id: `client-${clientNumber}`,
        type: 'success',
        message: `Updated client ${clientNumber}.`
      }));
    })
    .catch(() => {
      dispatch(addNotification({
        id: `client-${clientNumber}`,
        type: 'error',
        message: `Failed to update ${clientNumber}.`
      }));
    });
};
