import callApi from '../../utils/callApi';
import { clientsUrl } from '../../constants/urls';

export default (clientId) => (dispatch, getState) => {
  if (!clientId || getState().entities.hasIn(['clients', clientId])) {
    return Promise.resolve();
  }

  return dispatch(callApi({
    url: clientsUrl + '/' + clientId
  }));
};
