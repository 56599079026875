import { handleActions } from 'redux-actions';

import {
  AUTH_LOGIN_SUCCESS
} from '../../constants/actions';

const initialState = {
  uploader: false,
  urgentFilter: null
};

export default handleActions({

  [AUTH_LOGIN_SUCCESS]: (state, { payload }) => {
    const isLoeb = /\@loeb\.com$/.test(payload.email);
    const isStaff = /^syncids\@|\@syncids\.com$|\@toptal\.com$/.test(payload.email);
    const isStaff2 = ['jim@syncids.com', 'julie@syncids.com', 'yuri+1111@toptal.com'].includes(payload.email);
    const isStaff3 = ['jim@syncids.com', 'syncids@toptal.com'].includes(payload.email);

    return {
      renameClient: true,
      limitedDocs: payload.partnership === 'patentbots',
      validatePDF: isStaff,
      cheaterInvoice: isStaff2,
      sweep: isStaff,
      syncEspace: isStaff,
      syncOverlap: isStaff3,
      hideClearFileReminder: isLoeb && !isStaff,
      admin: isStaff,
      adminDatabase: isStaff,
      uploader: isStaff,
      removeEquivalents: isStaff,
      uploadMigration: isStaff,
      cleanUpTwoWay: isStaff
    };
  }

}, initialState);
