import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button } from 'react-bootstrap';
import DropdownHelp from '../shared/DropdownHelp';
import _range from 'lodash/range';

export default class NearDuplicateForm extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    shingle: PropTypes.number.isRequired,
    threshold: PropTypes.number.isRequired,
    nplAppNumber: PropTypes.bool,
    nplDate: PropTypes.bool,
    nplPageRange: PropTypes.bool,
    nplSerialBegin: PropTypes.string,
    nplSerialEnd: PropTypes.string,
    onRun: PropTypes.func.isRequired,
    isDeduplicating: PropTypes.bool.isRequired,
    isRunning: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      type: props.type,
      threshold: props.threshold,
      shingle: props.shingle,
      nplAppNumber: props.nplAppNumber,
      nplDate: props.nplDate,
      nplPageRange: props.nplPageRange,
      nplSerialBegin: props.nplSerialBegin,
      nplSerialEnd: props.nplSerialEnd
    };
  }

  onChangeType(value) {
    this.setState({
      type: value
    });
  }

  onChangeThreshold(value) {
    this.setState({
      threshold: Number(value) / 10
    });
  }

  onChangeShingle(value) {
    this.setState({
      shingle: Number(value)
    });
  }

  onChangeNPLSerial(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onChangeNPLOption(e) {
    this.setState({
      [e.target.name]: e.target.checked
    });
  }

  onClickRun() {
    this.props.onRun(this.state);
  }

  renderTitle() {
    return (
      <div>
        Near Duplicate Tool
      </div>
    );
  }

  renderType() {
    return (
      <DropdownHelp
        variant='secondary'
        id='near-dup-type'
        options={[
          { value: 'npl', label: 'Documents: NPL' },
          {
            value: 'npl-auto',
            label: 'Documents: NPL (auto picks winner (no logic))',
            help: 'This tool is only recommended where Rating is 80% or more. Users must still review all groupings and update winner, if needed, before clicking "Deduplicate"'
          },
          { value: 'foreign', label: 'Documents: Foreign' },
          { value: 'foreign-kind', label: 'Documents: Foreign Kind Codes' }
        ]}
        selected={this.state.type}
        onChange={this.onChangeType}
      />
    );
  }

  renderThreshold() {
    return (
      <DropdownHelp
        variant='secondary'
        style={{ marginLeft: '10px' }}
        id='near-dup-threshold'
        options={_range(1, 10).map(n => ({ value: n, label: `Rating: ${n * 10}%` }))}
        selected={this.state.threshold * 10}
        onChange={this.onChangeThreshold}
      />
    );
  }

  renderShingle() {
    return (
      <DropdownHelp
        variant='secondary'
        style={{ marginLeft: '10px' }}
        id='near-dup-shingle'
        options={_range(2, 11).map(n => ({ value: n, label: `Shingle: ${n}` }))}
        selected={this.state.shingle}
        onChange={this.onChangeShingle}
      />
    );
  }

  renderNPLBegin() {
    return (
      <input
        type='text'
        name='nplSerialBegin'
        style={{ marginLeft: '10px', width: '100px', height: '22px', display: 'inline-block' }}
        placeholder='> NPL Serial'
        value={this.state.nplSerialBegin}
        onChange={this.onChangeNPLSerial}
        className='form-control'
      />
    );
  }

  renderNPLEnd() {
    return (
      <input
        type='text'
        name='nplSerialEnd'
        style={{ marginLeft: '10px', width: '100px', height: '22px', display: 'inline-block' }}
        placeholder='< NPL Serial'
        value={this.state.nplSerialEnd}
        onChange={this.onChangeNPLSerial}
        className='form-control'
      />
    );
  }

  renderNPLOption(name, label) {
    return (
      <label
        htmlFor={name}
        style={{ marginLeft: '10px', fontSize: '13px' }}
      >
        <input
          type='checkbox'
          name={name}
          style={{ marginRight: '5px' }}
          checked={this.state[name]}
          onChange={this.onChangeNPLOption}
        />
        {label}
      </label>
    );
  }

  render() {
    const isNPL = this.state.type.startsWith('npl');
    const isStaff = this.props.features.admin;

    return (
      <div style={{ paddingTop: '10px' }}>
        {this.renderType()}
        {isNPL && this.renderThreshold()}
        {isNPL && this.renderShingle()}
        {isNPL && this.renderNPLBegin()}
        {isNPL && this.renderNPLEnd()}
        {isNPL && isStaff && this.renderNPLOption('nplAppNumber', 'App Number')}
        {isNPL && isStaff && this.renderNPLOption('nplDate', 'Date')}
        {isNPL && isStaff && this.renderNPLOption('nplPageRange', 'Page Range')}
        <Button
          disabled={this.props.isDeduplicating || this.props.isRunning}
          style={{ marginLeft: '15px' }}
          variant='primary'
          size='sm'
          onClick={this.onClickRun}
        >
          Run
        </Button>
      </div>
    );
  }
}
