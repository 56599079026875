import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class SurveyModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  };

  onRef() {
    setTimeout(() => {
      const script = document.createElement('script');
      script.src = '//embed.typeform.com/next/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, 100);
  }

  render() {
    return (
      <Modal
        id='survey-modal'
        onHide={this.props.onClose}
        size='lg'
        show
      >
        <div
          ref={this.onRef}
          data-tf-widget='dptDL3qg'
          data-tf-opacity='100'
          data-tf-iframe-props='title=SyncIDS Survey'
          data-tf-transitive-search-params
          data-tf-medium='snippet'
          style={{ width: '100%', height: '500px' }}
        />
        <Modal.Footer>
          <Button onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
