import autoBind from 'class-autobind';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import FieldGroup from './FieldGroup';

export default class PromptModal extends Component {
  static propTypes = {
    renderInput: PropTypes.func,
    body: PropTypes.node,
    footer: PropTypes.node,
    placeholder: PropTypes.string,
    confirmOnEnter: PropTypes.bool,
    defaultValue: PropTypes.string,
    message: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    preventSpaces: PropTypes.bool,
    autoSelect: PropTypes.bool,
    stripValue: PropTypes.func,
    confirmDisabled: PropTypes.bool,
    size: PropTypes.string,
    focusCancel: PropTypes.bool
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true,
      value: this.props.defaultValue || ''
    };
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onDocumentClick);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onDocumentClick);
  }

  onDocumentClick(e) {
    if (e.target.getAttribute('role') === 'dialog') {
      this.cancel();
    }
  }

  cancel() {
    this.setState({
      show: false,
      value: ''
    });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  confirm() {
    if (this.props.onConfirm) {
      this.props.onConfirm(this.state.value);
    }
    this.setState({
      show: false,
      value: ''
    });
  }

  onChange(value) {
    if (this.props.preventSpaces) {
      value = value.replace(/\s/g, '');
    }
    if (this.props.stripValue) {
      value = this.props.stripValue(value);
    }
    this.setState({ value });
  }

  onKeyDown(e) {
    if (e.which === 32 && this.props.preventSpaces) {
      e.preventDefault();
    }
    if (e.which === 13 && this.props.confirmOnEnter) {
      e.preventDefault();
      e.stopPropagation();
      this.confirm();
    }
  }

  onInputChange(e) {
    this.onChange(e.target.value);
  }

  onInputFocus(e) {
    if (this.props.autoSelect) {
      e.target.select();
    }
  }

  renderInput() {
    const value = this.state.value;
    let input;
    if (this.props.renderInput) {
      input = this.props.renderInput({
        value,
        onChange: this.onChange
      });
    } else {
      input = (
        <FieldGroup
          type='text'
          value={value}
          autoFocus
          placeholder={this.props.placeholder}
          onKeyDown={this.onKeyDown}
          onChange={this.onInputChange}
          onFocus={this.onInputFocus}
        />
      );
    }
    return input;
  }

  isDisabled() {
    if (this.props.confirmDisabled) {
      return true;
    }

    return !this.state.value;
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.state.show}
        onEntered={this.onEntered}
        size={this.props.size}
        onHide={this.cancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderInput()}
          {this.props.body}
        </Modal.Body>
        <Modal.Footer>
          {this.props.footer}
          <Button
            variant='secondary'
            onClick={this.cancel}
            autoFocus={this.props.focusCancel}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={this.confirm}
            disabled={this.isDisabled()}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
