import { createAction } from 'redux-actions';
import { constructTodoSearchParams } from '../../../utils/todo-query';
import callApi from '../../utils/callApi';
import { todosUrl } from '../../constants/urls';
import { TODOS_FETCH_START, TODOS_FETCH_STOP } from '../../constants/actions';
import selectClientAndMatterFromTodoList from '../app/selectClientAndMatterFromTodoList';

const fetchStart = createAction(TODOS_FETCH_START);
const fetchStop = createAction(TODOS_FETCH_STOP);

export default ({ term, show, filterType }, preventDispatch) => (dispatch, getState) => {
  const {
    auth: { firmName },
    entities,
    app: { selectedClientId }
  } = getState();

  const request = {
    url: todosUrl,
    params: constructTodoSearchParams({
      term,
      filterType,
      entities,
      selectedClientId,
      firmName,
      show
    })
  };

  if (!preventDispatch) {
    dispatch(fetchStart());
  }

  return dispatch(callApi(request, null, preventDispatch))
    .then((response) => {
      if (!preventDispatch) {
        dispatch(fetchStop());
        dispatch(selectClientAndMatterFromTodoList());
      }
      return response;
    });
};
