import JSZip from 'jszip';

const formatDefault = (row, matter) => {
  return [
    row.clientNumber,
    matter.matterNumber,
    `US${matter.applicationNumber}`
  ].join(';');
};

const formatApplicationNumber = (row, matter) => {
  return matter.applicationNumber;
};

const createSweepFile = (sweep, format) => {
  const lines = [];

  sweep.forEach(row => {
    row.openMatters.forEach(matter => {
      switch (format) {
        case 'applicationNumber':
          lines.push(formatApplicationNumber(row, matter));
          break;
        default:
          lines.push(formatDefault(row, matter));
          break;
      }
    });
  });

  return new Blob([lines.join('\r\n')], { type: 'text/plain' });
};

const createZIP = (files) => {
  const zip = new JSZip();
  files.forEach(file => zip.file(file.name, file.data));
  return zip;
};

const downloadFile = (name, file) => {
  const a = document.createElement('a');
  const url = URL.createObjectURL(file);

  a.href = url;
  a.download = name;
  document.body.appendChild(a);

  a.click();

  setTimeout(function() {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

const getFileName = (record, ext) => {
  return record.get('firmName') + '-' + record.get('client') + '.' + ext;
};

const createTxt = (record, format) => {
  return {
    name: getFileName(record, 'txt'),
    data: createSweepFile(record.get('sweep'), format)
  };
};

export default (rows, format) => {
  if (rows.size === 1) {
    const file = createTxt(rows.get(0), format);
    downloadFile(file.name, file.data);
    return { type: 'NOOP' };
  }

  const files = rows.toArray().map(createTxt);
  const zip = createZIP(files);

  zip.generateAsync({ type: 'blob' }).then((blob) => {
    downloadFile('sweep.zip', blob);
  });

  return { type: 'NOOP' };
};
