import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import RequestDemo from '../shared/RequestDemo';

const mailSubject = encodeURIComponent('SyncIDS Demo Request (CodeWeb)');
const mailBody = encodeURIComponent('I would like to schedule a web-based demonstration of your software. Below is my business contact information.');
const mailToHref = `mailto:demo@syncids.com?subject=${mailSubject}&body=${mailBody}`;

export default class DemoView extends React.Component {
  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  render() {
    return (
      <div className='container bigger-fonts'>
        <h2>Quickly learn how to increase your IDS profitability and accuracy</h2>
        <p>SyncIDS demonstration includes:</p>
        <ListGroup>
          <ListGroupItem>Sample of real data from PAIR</ListGroupItem>
          <ListGroupItem>Handling of continuations and office actions</ListGroupItem>
          <ListGroupItem>Cross citing capabilities</ListGroupItem>
          <ListGroupItem>Automated completion of IDS form(s)</ListGroupItem>
          <ListGroupItem>Automated retrieval of foreign and non-patent literature PDFs</ListGroupItem>
        </ListGroup>
        <br />
        <p>Email us at <a href={mailToHref}>demo@syncids.com</a> to request your web-based demonstration.</p>
        <hr />
        <div className='text-center'>
          <RequestDemo href='https://www.syncids.com/meetjulie' target='_blank' />
        </div>
      </div>
    );
  }
}
