import { connect } from 'react-redux';
import actions from '../../redux/actions';
import AppBar from './AppBar';

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    auth: state.auth,
    features: state.app.features,
    maintenance: state.app.maintenance
  };
};

export default connect(mapStateToProps, actions)(AppBar);
