import React, { Component } from 'react';

export default class FacebookButton extends Component {
  render() {
    return (
      <a
        href='https://facebook.com/syncids'
        target='_blank'
        className='pull-left btn btn-secondary'
        style={{
          width: '50px',
          marginLeft: '20px',
          backgroundColor: '#3B5998',
          borderColor: '#3B5998'
        }} rel='noreferrer'
      >
        <span className='fa fa-facebook' style={{ fontSize: '20px' }} />
      </a>
    );
  }
}
