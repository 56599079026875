import { createAction } from 'redux-actions';
import uploadFile from './uploadFile';
import {
  BATCH_UPLOAD_DOCUMENTS_CONFIRM
} from '../../constants/actions';

const confirmBatchUpload = createAction(BATCH_UPLOAD_DOCUMENTS_CONFIRM);

export default (files, mode) => (dispatch, getState) => {
  const responses = [];

  let promise = Promise.resolve();
  files.forEach(file => {
    promise = promise
      .then(() => file.file.async('blob'))
      .then(blob => dispatch(uploadFile(file.doc, blob, mode, false)))
      .then(response => {
        responses.push({
          file,
          response
        });
      });
  });
  return promise
    .then(() => {
      if (mode === 'upload') {
        return dispatch(confirmBatchUpload({}));
      }
    })
    .then(() => responses);
};
