export function getMatterNumberFromCategory(category) {
  return `Category:${category}`;
}

export function isCategoryMatter(matterNumber) {
  return matterNumber && matterNumber.indexOf('Category:') === 0;
}

export function getCategoryFromMatterNumber(matterNumber) {
  if (isCategoryMatter(matterNumber)) {
    return matterNumber.replace(/^Category:/, '');
  }
}
