import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';

export default class SearchNotesInput extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onChange(e) {
    this.props.onChange(e.target.value);
  }

  onClear(e) {
    e.stopPropagation();
    this.props.onChange('');
  }

  renderIcon() {
    return (
      <span
        className='fa fa-search text-muted'
        style={{
          position: 'absolute',
          left: '7px',
          top: '7px'
        }}
      />
    );
  }

  renderClear() {
    return this.props.value && (
      <span
        onClick={this.onClear}
        className='fa fa-times text-muted'
        style={{
          position: 'absolute',
          right: '7px',
          top: '7px',
          cursor: 'pointer'
        }}
      />
    );
  }

  render() {
    return (
      <div style={{
        position: 'relative',
        flex: '1',
        display: 'inline-block'
      }}
      >
        {this.renderIcon()}
        {this.renderClear()}
        <input
          style={{
            height: '30px',
            paddingLeft: '26px'
          }}
          className='form-control input-sm'
          spellCheck={false}
          value={this.props.value || ''}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
