import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from '../../constants/actions';

const initialState = 'smart';

export default handleActions({

  [LOCATION_CHANGE]: (state, { payload }) => {
    return payload.query.filterType || state;
  }

}, initialState);
