import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import DocumentRecord from '../../models/DocumentRecord';
import CategoriesInput from '../categories/CategoriesInput';
import { isCategoryMatter, getCategoryFromMatterNumber } from '../../utils/categories';

export default class DocumentCheckbox extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    categories: PropTypes.instanceOf(Immutable.List).isRequired,
    doc: PropTypes.instanceOf(DocumentRecord).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    viewCategory: PropTypes.func.isRequired
  };

  static defaultProps = {
    doc: new DocumentRecord()
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  isDisabled() {
    return this.props.doc.get('type') !== 'US' && this.props.features.limitedDocs;
  }

  onConfirm(selectedArg, unselected) {
    let selected = selectedArg;
    const matterNumber = this.props.matter.get('matterNumber');
    if (isCategoryMatter(matterNumber)) {
      const category = getCategoryFromMatterNumber(matterNumber);
      if (category && unselected.includes(category)) {
        selected = selected.concat(category);
        this.props.onAction(this.props.doc, 'deleteFromMatter');
      }
    }
    this.props.onChange(this.props.doc, this.props.field, selected);
  }

  render() {
    return this.props.doc.isSaved() && (
      <CategoriesInput
        compact
        onConfirm={this.onConfirm}
        disabled={this.isDisabled()}
        id={`categories-drop-down-${this.props.doc.get('documentNumber')}`}
        categories={this.props.categories}
        selectedCategories={this.props.doc.get(this.props.field)}
        viewCategory={this.props.viewCategory}
      />
    );
  }
}
