import { createAction } from 'redux-actions';
import callApi from '../../utils/callApi';
import { isValidEmail } from '../../../utils/text-utils';
import { priorArtUrl } from '../../constants/urls';
import { PRIOR_ART_SUBMIT } from '../../constants/actions';

const submitPriorArt = createAction(PRIOR_ART_SUBMIT);

export default (email, docket, description) => (dispatch, getState) => {
  dispatch(submitPriorArt({}));

  if (!isValidEmail(email)) {
    dispatch(submitPriorArt({
      errorMessage: 'Please enter a valid email address.'
    }));
    return;
  }
  if (!/\w|\d/.test(docket)) {
    dispatch(submitPriorArt({
      errorMessage: 'Please enter a valid docket number.'
    }));
    return;
  }
  if (!/\w|\d/.test(description)) {
    dispatch(submitPriorArt({
      errorMessage: 'Please enter your search query description.'
    }));
    return;
  }

  const request = {
    url: priorArtUrl,
    method: 'post',
    data: {
      email,
      docket,
      description
    }
  };
  return dispatch(callApi(request))
    .then(() => {
      dispatch(submitPriorArt({
        successMessage: 'Request sent successfully. We will answer shortly.'
      }));
    })
    .catch(() => {
      dispatch(submitPriorArt({
        errorMessage: 'An error happened while submitting your request. Please try again.'
      }));
    });
};
