import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import ConfirmModal from '../shared/ConfirmModal';
import DocumentRecord from '../../models/DocumentRecord';

export default class DeduplicateNPLModal extends Component {
  static propTypes = {
    doc: PropTypes.instanceOf(DocumentRecord),
    matter: PropTypes.instanceOf(Immutable.Map),
    searchNplDocuments: PropTypes.func.isRequired,
    clearNplDocuments: PropTypes.func.isRequired,
    description: PropTypes.string.isRequired,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map).isRequired,
    replaceDocNumber: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const firmName = this.props.matter.get('firmName');
    const clientNumber = this.props.matter.get('clientNumber');

    this.props.searchNplDocuments(firmName, clientNumber, this.props.description);
  }

  findDocumentByDescription() {
    const doc = this.props.nplSuggestions.find((doc) => (
      doc.get('nonPatentBib').trim() === (this.props.description || '').trim()
    ));
    if (doc) {
      return doc;
    }
  }

  onCancel() {
    this.props.clearNplDocuments();
    this.props.onClose();
  }

  onConfirm() {
    const winner = this.findDocumentByDescription();
    if (winner) {
      this.props.replaceDocNumber(this.props.doc, winner.get('documentNumber')).then(() => {
        this.props.clearNplDocuments();
        this.props.onClose();
      });
    }
  }

  renderMessage() {
    const loser = this.props.doc;
    const winner = this.findDocumentByDescription();
    if (winner) {
      return (
        <div>
          <p>Are you sure you want to replace this document?</p>
          <ul>
            <li>
              <strong>Loser:</strong> [{loser.get('documentNumber')}] {loser.get('nonPatentBib')}
            </li>
            <li>
              <strong>Winner:</strong> [{winner.get('documentNumber')}] {winner.get('nonPatentBib')}
            </li>
          </ul>
        </div>
      );
    }
    return 'Loading...';
  }

  render() {
    return (
      <ConfirmModal
        title='Confirmation Required'
        message={this.renderMessage()}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
      />
    );
  }
}
