import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import Form from '../shared/Form';
import Field from '../shared/Field';
import { isValidEmail } from '../../utils/text-utils';

const styleButton = {
  marginTop: '25px'
};

export default class CiteChron extends Form {
  static propTypes = {
    record: PropTypes.instanceOf(Immutable.Map).isRequired,
    submitCiteChron: PropTypes.func.isRequired,
    updateCiteChron: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string
  };

  static defaultProps = {
    record: Immutable.Map()
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onChange(event) {
    this.props.updateCiteChron(event.target.name, event.target.value);
  }

  onKeyDown(ev) {
    if (ev.which === 13) {
      this.onSubmit();
    }
  }

  onSubmit() {
    const { email, patentNumber } = this.props;
    this.props.submitCiteChron(email, patentNumber);
  }

  renderError() {
    return this.props.errorMessage && (
      <p>
        <span className='fa fa-times text-danger' />
        &nbsp;
        {this.props.errorMessage}
      </p>
    );
  }

  renderSuccess() {
    return this.props.successMessage && (
      <p>
        <span className='fa fa-check text-success' />
        &nbsp;
        {this.props.successMessage}
      </p>
    );
  }

  renderEmail() {
    const isInvalid = this.props.email && !isValidEmail(this.props.email);

    return (
      <Field
        label='Business Email'
        style={{ width: '250px', float: 'left' }}
        className={isInvalid ? 'has-error has-feedback' : ''}
      >
        <input
          type='text'
          name='email'
          placeholder='lawyer@lawfirm.com'
          value={this.props.email || ''}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          required
          className='form-control'
        />
        {isInvalid && this.renderRequiredIcon()}
      </Field>
    );
  }

  renderPatent() {
    return (
      <Field
        label='Patent Number'
        style={{ width: '250px', float: 'left' }}
      >
        <input
          type='text'
          name='patentNumber'
          placeholder='9,603,065'
          value={this.props.patentNumber || ''}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          required
          className='form-control'
        />
      </Field>
    );
  }

  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12'>
            <p>Provide us with a U.S. patent number, and we will identify any withheld art.</p>
            {this.renderEmail()}
            {this.renderPatent()}
            <button className='btn btn-sm btn-primary' style={styleButton} onClick={this.onSubmit}>
              Submit
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            {this.renderError()}
            {this.renderSuccess()}
          </div>
        </div>
      </div>
    );
  }
};
