import { createAction } from 'redux-actions';
import { MIGRATION_UPLOAD_TWOWAY } from '../../constants/actions';
import fetchClientList from '../client/fetchClientList';
import fetchMatterList from '../matter/fetchMatterList';

const uploadTwoWayMigration = createAction(MIGRATION_UPLOAD_TWOWAY);

const parseFamilies = (text) => {
  const families = {};
  text
    .split('\n')
    .filter(line => /\;/.test(line))
    .forEach(line => {
      const parts = line.split(';').map(part => part.trim());
      const key = parts[2];
      if (/\w/.test(key || '')) {
        if (families[key]) {
          families[key].matterNumbers.push(parts[1]);
        } else {
          families[key] = {
            family: key,
            clientNumber: parts[0],
            matterNumbers: [parts[1]]
          };
        }
      }
    });
  return {
    families: Object.keys(families)
      .map(key => {
        families[key].matterNumbers.sort();
        return families[key];
      })
  };
};

export default (content) => (dispatch, getState) => {
  const twoWayMigration = parseFamilies(content);

  return dispatch(fetchClientList())
    .then(() => {
      const clients = getState().entities.get('clientList');

      let promise = Promise.resolve();

      twoWayMigration.families.forEach(family => {
        const client = clients.find(item => item.get('clientNumber') === family.clientNumber);
        if (client) {
          promise = promise.then(() => dispatch(fetchMatterList(client.get('id'))));
        }
      });

      return promise.then(() => dispatch(uploadTwoWayMigration(twoWayMigration)));
    });
};
