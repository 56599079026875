import _toLower from 'lodash/toLower';
import { shouldComponentUpdate as shouldPureComponentUpdate } from 'react-immutable-render-mixin';
import autoBind from 'class-autobind';
import { Button } from 'react-bootstrap';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isCategoryMatter, getCategoryFromMatterNumber } from '../../utils/categories';
import { stripMatterNumber } from '../../utils/matter';
import ConfirmModal from '../shared/ConfirmModal';
import FamilyModal from './FamilyModal';
import MatterInput from './MatterInput';
import MatterPromptNew from './MatterPromptNew';
import PromptModal from '../shared/PromptModal';
import RemoveCategoryModal from '../categories/RemoveCategoryModal';
import ConfirmFileModal from '../dashboard/ConfirmFileModal';

const styleOuter = {
  position: 'absolute',
  top: '10px',
  left: '28px',
  right: '10px',
  display: 'inline-block'
};

const styleForm = {
  position: 'absolute',
  top: '0',
  left: '80px',
  right: '0'
};

const styleInput = {
  position: 'relative',
  top: '-4px',
  height: '30px'
};

export default class MatterID extends Component {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map),
    createCategoryMatter: PropTypes.func.isRequired,
    createMatter: PropTypes.func.isRequired,
    deleteMatter: PropTypes.func.isRequired,
    downloadExcelReport: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired,
    fileMatter: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map),
    matterList: PropTypes.instanceOf(Immutable.List),
    removeCategory: PropTypes.func.isRequired,
    selectMatter: PropTypes.func.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired,
    viewCategoryMatter: PropTypes.func.isRequired,
    viewFamily: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.matter, state.matter)) {
      return {
        matter: props.matter,
        matterNumber: props.matter.get('matterNumber') || ''
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  shouldComponentUpdate = shouldPureComponentUpdate;

  isCategoryMatter() {
    return isCategoryMatter(this.props.matter.get('matterNumber'));
  }

  raiseChange() {
    const matterNumber = this.state.matterNumber.replace(/\s/g, '');
    if (matterNumber !== this.props.matter.get('matterNumber')) {
      const selectedMatter = this.props.matterList.find(m => (
        _toLower(m.get('matterNumber')) === _toLower(matterNumber))
      );
      if (!selectedMatter) {
        this.setState({
          confirmNewMatter: matterNumber
        });
      } else if (isCategoryMatter(matterNumber)) {
        this.props.viewCategoryMatter(getCategoryFromMatterNumber(matterNumber));
      } else {
        this.props.selectMatter(selectedMatter.get('id'));
      }
    }
  }

  onClear() {
    this.setState({
      matterNumber: this.props.matter.get('matterNumber')
    });
  }

  onChange(newValue) {
    this.setState({
      matterNumber: stripMatterNumber(newValue)
    });
  }

  onSubmit(newValue) {
    this.setState({
      matterNumber: newValue
    }, this.raiseChange);
  }

  onBlur() {
    if (this.props.matter.get('id') && this.state.matterNumber === '') {
      this.onClear();
    } else {
      this.raiseChange();
    }
  }

  newMatter() {
    this.setState({
      promptNewMatter: true
    });
  }

  renameMatter() {
    this.setState({
      promptRenameMatter: true
    });
  }

  deleteMatter() {
    this.props.logAction({
      action: 'DeleteMatterButton',
      clientNumber: this.props.matter.get('clientNumber'),
      matterNumber: this.props.matter.get('matterNumber')
    });

    if (this.props.matter.get('fileReminder')) {
      this.setState({
        confirmFileMatter: true
      });
    } else {
      this.setState({
        confirmDeleteMatter: true
      });
    }
  }

  closeDelete() {
    this.setState({
      confirmDeleteMatter: false
    });
  }

  confirmDelete() {
    this.props.deleteMatter(this.props.matter.get('id'), this.props.matter.get('matterNumber'));
    this.closeDelete();
  }

  closeRename() {
    this.setState({
      promptRenameMatter: false
    });
  }

  confirmRename(newMatterNumber) {
    if (newMatterNumber) {
      this.props.updateMatter({
        id: this.props.matter.get('id'),
        matterNumber: newMatterNumber
      }, newMatterNumber);
    }
    this.closeRename();
  }

  renderConfirmDelete() {
    if (!this.state.confirmDeleteMatter) {
      return;
    }

    if (this.isCategoryMatter()) {
      return (
        <RemoveCategoryModal
          client={this.props.client}
          category={getCategoryFromMatterNumber(this.props.matter.get('matterNumber'))}
          onClose={this.closeDelete}
          removeCategory={this.props.removeCategory}
        />
      );
    }

    return (
      <ConfirmModal
        key='delete'
        message={`Are you sure you want to delete matter - ${this.props.matter.get('matterNumber')}?`}
        onCancel={this.closeDelete}
        onConfirm={this.confirmDelete}
        focusCancel
      />
    );
  }

  renderPromptRename() {
    return this.state.promptRenameMatter && (
      <PromptModal
        key='rename'
        preventSpaces
        confirmOnEnter
        autoSelect
        message='Enter new matter number'
        defaultValue={this.props.matter.get('matterNumber')}
        onCancel={this.closeRename}
        onConfirm={this.confirmRename}
      />
    );
  }

  closePromptNew() {
    this.setState({
      promptNewMatter: false,
      confirmNewMatter: null
    });
  }

  confirmPromptNew(matter) {
    if (isCategoryMatter(matter.matterNumber)) {
      this.props.createCategoryMatter(this.props.client.get('id'), matter.matterNumber)
        .then((category) => this.props.viewCategoryMatter(category));
    } else {
      this.props.createMatter({
        ...matter,
        shouldSelect: true
      });
    }

    this.closePromptNew();
  }

  renderPromptNew() {
    return this.state.promptNewMatter && (
      <MatterPromptNew
        key='confirm-new'
        features={this.props.features}
        matterList={this.props.matterList}
        onCancel={this.closePromptNew}
        onConfirm={this.confirmPromptNew}
      />
    );
  }

  renderConfirmNew() {
    return this.state.confirmNewMatter && (
      <MatterPromptNew
        key='prompt-new'
        features={this.props.features}
        matterNumber={this.state.confirmNewMatter}
        matterList={this.props.matterList}
        onCancel={this.closePromptNew}
        onConfirm={this.confirmPromptNew}
      />
    );
  }

  renderNew() {
    return this.props.client.get('id') && (
      <Button
        size='sm'
        variant='success'
        style={{
          position: 'absolute',
          top: '-4px',
          right: '0',
          lineHeight: '1.3em',
          marginLeft: '10px',
          float: 'right',
          height: '30px',
          width: '40px',
          padding: 0
        }}
        onClick={this.newMatter}
      >
        New
      </Button>
    );
  }

  renderRename() {
    return this.props.matter.get('id') && (
      <Button
        disabled={this.isCategoryMatter()}
        size='sm'
        variant='primary'
        style={{
          position: 'absolute',
          top: '-4px',
          right: '112px',
          lineHeight: '1.3em',
          marginLeft: '10px',
          float: 'right',
          height: '30px',
          width: '60px',
          padding: 0
        }}
        onClick={this.renameMatter}
      >
        Rename
      </Button>
    );
  }

  renderDelete() {
    return this.props.matter.get('id') && (
      <Button
        size='sm'
        variant='danger'
        style={{
          position: 'absolute',
          top: '-4px',
          right: '46px',
          lineHeight: '1.3em',
          float: 'right',
          height: '30px',
          width: '60px',
          padding: 0
        }}
        onClick={this.deleteMatter}
      >
        Delete
      </Button>
    );
  }

  renderInput() {
    return (
      <MatterInput
        id='matter-id-input'
        value={this.state.matterNumber}
        initialValue={this.props.matter.get('matterNumber')}
        disabled={!this.props.client.get('id')}
        style={styleInput}
        matterList={this.props.matterList}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onClear={this.onClear}
        onBlur={this.onBlur}
      />
    );
  }

  onFormClick(e) {
    e.stopPropagation();
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.raiseChange();
  }

  renderForm() {
    const right = '243px';
    const style = {
      ...styleForm,
      right: this.props.matter.get('id') ? right : this.props.client.get('id') ? '46px' : '0'
    };
    return (
      <form
        style={style}
        onClick={this.onFormClick}
        onSubmit={this.onFormSubmit}
      >
        {this.renderInput()}
      </form>
    );
  }

  onClickFamily() {
    this.setState({
      confirmFamily: true
    });
  }

  onCancelFamily() {
    this.setState({
      confirmFamily: false
    });
  }

  onDownloadExcelReport() {
    this.setState({
      confirmFamily: false
    });
    this.props.downloadExcelReport({
      client: this.props.client,
      matter: this.props.matter
    });
  }

  onViewFamily() {
    this.setState({
      confirmFamily: false
    });
    this.props.viewFamily({
      client: this.props.client,
      matter: this.props.matter
    });
  }

  renderFamilyButton() {
    return this.props.matter.get('id') && (
      <Button
        variant='warning'
        size='sm'
        style={{
          position: 'absolute',
          top: '-4px',
          right: '177px',
          lineHeight: '1.3em',
          marginLeft: '10px',
          float: 'right',
          height: '30px',
          width: '60px',
          padding: 0
        }}
        onClick={this.onClickFamily}
      >
        Family
      </Button>
    );
  }

  renderFamilyModal() {
    return this.state.confirmFamily && (
      <FamilyModal
        onDownloadExcelReport={this.onDownloadExcelReport}
        onViewFamily={this.onViewFamily}
        onCancel={this.onCancelFamily}
      />
    );
  }

  closeFileModal() {
    this.setState({
      confirmFileMatter: false
    });
  }

  renderConfirmFile() {
    return this.state.confirmFileMatter && (
      <ConfirmFileModal
        origin='DeleteMatter'
        features={this.props.features}
        matter={this.props.matter}
        client={this.props.client}
        logAction={this.props.logAction}
        fileMatter={this.props.fileMatter}
        setFileReminder={this.props.setFileReminder}
        onClose={this.closeFileModal}
      />
    );
  }

  render() {
    return (
      <div style={styleOuter}>
        <span>Matter ID</span>
        {this.renderForm()}
        {this.renderFamilyButton()}
        {this.renderRename()}
        {this.renderDelete()}
        {this.renderNew()}
        {this.renderConfirmDelete()}
        {this.renderPromptRename()}
        {this.renderPromptNew()}
        {this.renderConfirmNew()}
        {this.renderFamilyModal()}
        {this.renderConfirmFile()}
      </div>
    );
  }
};
