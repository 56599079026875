import _get from 'lodash/get';
import fetchClientList from '../client/fetchClientList';
import fetchMatterList from '../matter/fetchMatterList';
import fetchMatter from '../matter/fetchMatter';

export default (firmArg, clientArg) => (dispatch, getState) => {
  const firmName = firmArg.replace('.', '');

  const sweepClient = (clientId) => {
    return dispatch(fetchMatterList(clientId))
      .then(response => {
        const matters = _get(response, ['entities', 'matterList', clientId], []);
        let matterPromise = Promise.resolve();
        const matterList = [];

        matters.forEach(matter => {
          matterPromise = matterPromise.then(() => (
            dispatch(fetchMatter(matter.id)).then(matterResponse => {
              const matterData = _get(matterResponse, ['entities', 'matters', matter.id], {});
              matterList.push(matterData);
            })
          ));
        });

        return matterPromise.then(() => (
          matterList.filter(matter => (
            matter.matterClosed !== 'ON' &&
            matter.applicationNumber &&
            matter.id
          ))
        ));
      });
  };

  return dispatch(fetchClientList(firmName))
    .then(response => {
      const clients = _get(response, ['entities', 'clientList'], []);
      let sweepPromise = Promise.resolve();
      const sweep = [];

      clients.forEach(client => {
        if (clientArg.toLowerCase() === 'all' || client.clientNumber === clientArg) {
          sweepPromise = sweepPromise.then(() => (
            sweepClient(client.id).then((openMatters) => {
              if (openMatters.length) {
                sweep.push({
                  firmName,
                  clientNumber: client.clientNumber,
                  openMatters
                });
              }
            })
          ));
        }
      });

      return sweepPromise.then(() => sweep);
    });
};
