import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from '../../constants/actions';

const initialState = null;

export default handleActions({

  [LOCATION_CHANGE]: (state, { payload }) => {
    if (payload.pathname && payload.pathname.indexOf('/admin') === 0) {
      return initialState;
    }
    if (payload.query) {
      return payload.query.section || initialState;
    }
    return initialState;
  }

}, initialState);
