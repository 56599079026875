import callApi from '../../utils/callApi';
import { sweepUrl } from '../../constants/urls';
import addNotification from '../app/addNotification';
import fetchFamily from './fetchFamily';
import { isValidDate } from '../../../utils/date';
import updateMatter from './updateMatter';
import refreshTodos from '../todo/refreshTodos';
import fetchPendingOfficeActions from './fetchPendingOfficeActions';

export default (matter) => (dispatch, getState) => {
  const checkSweepDate = () => {
    const date = matter.get('lastSweepTime');
    if (date && date !== '0000-00-00 00:00:00' && isValidDate(date)) {
      return Promise.resolve();
    }
    return dispatch(updateMatter({
      id: matter.get('id'),
      lastSweepTime: null
    }, matter.get('matterNumber')));
  };

  dispatch(addNotification({
    id: `sweep-${matter.get('id')}`,
    type: 'progress',
    message: `Sweeping ${matter.get('matterNumber')}...`
  }));

  return checkSweepDate().then(() => (
    dispatch(callApi({
      url: `${sweepUrl}/${matter.get('id')}`,
      noQuery: true
    }))
  ))
    .then(response => {
      if (response.status === 'success') {
        dispatch(addNotification({
          id: `sweep-${matter.get('id')}`,
          type: 'success',
          message: `Finished sweeping ${matter.get('matterNumber')}.`
        }));
      } else {
        dispatch(addNotification({
          id: `sweep-${matter.get('id')}`,
          type: 'error',
          message: `Failed to sweep ${matter.get('matterNumber')}.`
        }));
      }
    })
    .catch(() => {
      dispatch(addNotification({
        id: `sweep-${matter.get('id')}`,
        type: 'error',
        message: `Failed to sweep ${matter.get('matterNumber')}.`
      }));
    })
    .then(() => dispatch(fetchFamily(matter, true)))
    .then(() => dispatch(fetchPendingOfficeActions(matter.get('id'))))
    .then(() => dispatch(refreshTodos()));
};
