import autoBind from 'class-autobind';
import { Button, Modal, Tabs, Tab } from 'react-bootstrap';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CheckButton from '../shared/CheckButton';
import HelpIcon from '../shared/HelpIcon';

export default class TransmittalLetterModal extends Component {
  static propTypes = {
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      tab: 'general',
      show: true,
      within30Days: this.props.matter.get('within30Days'),
      earlierApplicationIdentified: this.props.matter.get('earlierApplicationIdentified'),
      transmittalLetterAdditionalComments: this.props.matter.get('transmittalLetterAdditionalComments') || '',
      transmittalLetterAdditionalCommentsValid: true,
      transmittalLetterGeneralText: this.props.matter.get('transmittalLetterGeneralText') || '',
      transmittalLetterGeneralTextValid: true
    };
  }

  onClickCancel() {
    this.setState({ show: false });
    this.props.onCancel();
  }

  onClickConfirm() {
    this.setState({ show: false });
    this.props.onConfirm({
      within30Days: this.state.within30Days,
      earlierApplicationIdentified: this.state.earlierApplicationIdentified,
      transmittalLetterAdditionalComments: this.state.transmittalLetterAdditionalComments,
      transmittalLetterGeneralText: this.state.transmittalLetterGeneralText
    });
  }

  onSelectTab(tab) {
    this.setState({ tab });
  }

  onChange(event) {
    const { target } = event;
    const { value, name } = target;

    this.setState({ [name]: value });
  }

  renderBody() {
    return (
      <div>
        <Tabs
          transition={false}
          onSelect={this.onSelectTab}
          activeKey={this.state.tab}
          defaultActiveKey='general'
          id='transmittal-letter-tabs'
        >
          <Tab eventKey='general' title='General Boilerplate'>
            {this.renderGeneralText()}
          </Tab>
          <Tab eventKey='comments' title='Additional Comments'>
            {this.renderComments()}
          </Tab>
        </Tabs>
      </div>
    );
  }

  renderGeneralText() {
    const value = this.state.transmittalLetterGeneralText || '';

    return (
      <div>
        <textarea
          className='form-control'
          name='transmittalLetterGeneralText'
          onChange={this.onChange}
          rows={14}
          spellCheck={false}
          style={{ border: this.state.transmittalLetterGeneralTextValid ? '2px solid transparent' : '2px solid red' }}
          value={value}
        />
      </div>
    );
  }

  renderComments() {
    const value = this.state.transmittalLetterAdditionalComments || '';

    return (
      <div style={{ position: 'relative' }}>
        <textarea
          className='form-control'
          name='transmittalLetterAdditionalComments'
          onChange={this.onChange}
          rows={14}
          spellCheck={false}
          style={{ border: this.state.transmittalLetterAdditionalCommentsValid ? '2px solid transparent' : '2px solid red' }}
          value={value}
        />
      </div>
    );
  }

  isValid() {
    return (
      this.state.transmittalLetterAdditionalCommentsValid &&
      this.state.transmittalLetterGeneralTextValid
    );
  }

  renderConfirm() {
    return (
      <Button
        variant='primary'
        disabled={!this.isValid()}
        onClick={this.onClickConfirm}
      >
        Confirm
      </Button>
    );
  }

  renderCancel() {
    return (
      <Button
        style={{ marginRight: '10px' }}
        variant='secondary'
        onClick={this.onClickCancel}
      >
        Cancel
      </Button>
    );
  }

  renderButtons() {
    return (
      <div className='pull-right'>
        {this.renderCancel()}
        {this.renderConfirm()}
      </div>
    );
  }

  onChange30Days(checked) {
    this.setState({
      within30Days: checked
    });
  }

  renderCited() {
    return (
      <div>
        <CheckButton
          checked={this.state.within30Days}
          onChange={this.onChange30Days}
        />
        <span style={{ position: 'relative', top: '3px', left: '10px' }}>&lt; 30 Days</span>
        <HelpIcon
          style={{ position: 'relative', left: '15px', top: '3px' }}
          help='704(d) All items cited in a foreign counterpart < 30 days ago'
        />
      </div>
    );
  }

  onChangeEarlierApp(checked) {
    this.setState({
      earlierApplicationIdentified: checked
    });
  }

  renderEarlierApplication() {
    return (
      <div style={{ marginBottom: '10px' }}>
        <CheckButton
          checked={this.state.earlierApplicationIdentified}
          onChange={this.onChangeEarlierApp}
        />
        <span style={{ position: 'relative', top: '3px', left: '10px' }}>Earlier Application Identified</span>
        <HelpIcon
          style={{ position: 'relative', left: '15px', top: '3px' }}
          help='I have identified an earlier application pursuant to 37 CFR 1.98(d)(1) above.'
        />
      </div>
    );
  }

  renderOptions() {
    return (
      <div style={{ marginRight: 'auto' }}>
        {this.props.features.earlierApplication && this.renderEarlierApplication()}
        {this.renderCited()}
      </div>
    );
  }

  render() {
    return (
      <Modal
        keyboard={false}
        size='lg'
        show={this.state.show}
        onHide={this.onClickCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Transmittal Letter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer style={{ alignItems: 'end' }}>
          {this.renderOptions()}
          {this.renderButtons()}
        </Modal.Footer>
      </Modal>
    );
  }
}
