import updateClient from '../client/updateClient';
import fetchClientIfNeeded from '../client/fetchClientIfNeeded';
import deleteCategoryMatterIfNeeded from './deleteCategoryMatterIfNeeded';
import { splitCommaUnique, joinCommaUnique } from '../../../utils/text-utils';

export default (clientId, category) => (dispatch, getState) => {
  return dispatch(fetchClientIfNeeded(clientId)).then(() => {
    const client = getState().entities.getIn(['clients', clientId]);
    const clientCategories = splitCommaUnique(client.get('clientCategories'));
    const updatedClient = {
      id: clientId,
      clientCategories: joinCommaUnique(clientCategories.filter(c => c !== category))
    };

    return dispatch(updateClient(updatedClient, client.get('clientNumber')))
      .then(() => dispatch(deleteCategoryMatterIfNeeded(clientId, category)));
  });
};
