import React from 'react';
import PageFooter from '../shared/PageFooter';

export default class AboutView extends React.Component {
  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  renderPhone() {
    return (
      <div>
        <h3>Phone</h3>
        <ul>
          <li>Phone: <a href='tel:4693828144'>469-382-8144</a></li>
          {/* <li>Fax: <a href='tel:3128817501'>312-881-7501</a></li> */}
        </ul>
      </div>
    );
  }

  renderEmail() {
    return (
      <div>
        <h3>Email</h3>
        <ul>
          <li>Demonstrations <a href='mailto:demo@syncids.com'>demo@syncids.com</a></li>
          <li>Customer Service & Billing <a href='mailto:info@syncids.com'>info@syncids.com</a></li>
        </ul>
      </div>
    );
  }

  renderAddress() {
    return (
      <div>
        <h3>Address</h3>
        <ul>
          <li>SyncIDS</li>
          <li>1341 W Mockingbird LN STE 600W</li>
          <li>Dallas, TX 75247</li>
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div className='container bigger-fonts'>
        <h1>Contact Us</h1>
        <hr />
        {this.renderPhone()}
        {this.renderEmail()}
        {this.renderAddress()}
        <hr />
        <PageFooter />
      </div>
    );
  }
}
