import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import _get from 'lodash/get';

export default (doc) => (dispatch) => {
  if (doc.hasPDF() || doc.get('type') === 'US' || !doc.get('country')) {
    return Promise.resolve();
  }

  dispatch(addNotification({
    id: `fetch-pdf-${doc.documentNumber}`,
    type: 'progress',
    message: `Fetching ${doc.country}${doc.documentNumber}...`
  }));

  return dispatch(callApi({
    url: `/api/fetch-pdf/doc/${doc.id}`
  }))
    .then((response) => {
      const success = Boolean(
        !response.error &&
      _get(response, ['processedDocs', doc.id, 'status'], '').includes('Completed')
      );

      if (success) {
        dispatch(addNotification({
          id: `fetch-pdf-${doc.documentNumber}`,
          type: 'success',
          message: `Done ${doc.country}${doc.documentNumber}`
        }));
      }
    })
    .catch(() => {
      dispatch(addNotification({
        id: `fetch-pdf-${doc.documentNumber}`,
        type: 'error',
        message: `Failed to fetch ${doc.country}${doc.documentNumber}`
      }));
    });
};
