import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Fieldset from '../shared/Fieldset';
import Field from '../shared/Field';
import Help from '../shared/Help';
import Form from '../shared/Form';
import AttorneyContainer from '../attorney/AttorneyContainer';
import { selectiveToJS } from '../../utils/immutable-helpers';
import { Tabs, Tab } from 'react-bootstrap';
import HelpIcon from '../shared/HelpIcon';
import Immutable from 'immutable';
import ReminderEmail from './ReminderEmail';

const fields = [
  'billingPeriod',
  'depositAccountNumber',
  'entitySize',
  'firmName',
  'generalReminderFrequency',
  'immediateInvoiceRecipients',
  'legacyCount',
  'notes',
  'partnerEmail',
  'patsAndPubsFlag',
  'reminderRecipientEmails',
  'sweepCitationsFlag',
  'sweepISRFlag',
  'sweepUsOfficeActionsFlag',
  'urgentReminderFrequency'
];
const extractState = ({ record }) => selectiveToJS(record, fields);

export default class Client extends Form {
  static propTypes = {
    features: PropTypes.object.isRequired,
    record: PropTypes.object,
    onChange: PropTypes.func,
    forceChange: PropTypes.bool,
    selectedTab: PropTypes.string.isRequired,
    onSelectTab: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.record, state.record)) {
      return {
        record: props.record,
        ...extractState(props)
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onChangeEmail(ev, value) {
    this.onChange({
      target: {
        name: 'partnerEmail',
        value
      }
    });
  }

  renderBillingPeriod() {
    return (
      <Field
        label='Invoice Frequency'
        style={{ width: '50%' }}
        tip='Choose how frequently you want to receive SyncIDS invoices.'
      >
        <select
          name='billingPeriod'
          value={this.state.billingPeriod}
          onChange={this.onChange}
          className='form-control'
        >
          <option value='Monthly'>Monthly</option>
          <option value='Immediately'>Immediately</option>
        </select>
      </Field>
    );
  }

  renderEntitySize() {
    return (
      <Field label='Entity Size' style={{ width: '50%' }}>
        <select
          name='entitySize'
          value={this.state.entitySize}
          onChange={this.onChange}
          className='form-control'
        >
          <option value='Large'>Undiscounted</option>
          <option value='Small'>Small</option>
          <option value='Micro'>Micro</option>
        </select>
      </Field>
    );
  }

  renderLabelWithHelp({ label, help }) {
    return (
      <div>
        {label}
        &nbsp;&nbsp;&nbsp;
        <HelpIcon help={help} />
      </div>
    );
  }

  renderReminderFrequency({ label, help, field }) {
    return (
      <Field label={this.renderLabelWithHelp({ label, help })} style={{ width: '50%' }}>
        <select
          name={field}
          disabled={!this.state.reminderRecipientEmails}
          value={this.state[field]}
          onChange={this.onChange}
          className='form-control'
        >
          <option value='None'>None</option>
          <option value='Daily'>Daily</option>
          <option value='Weekly'>Weekly</option>
          <option value='SemiMonthly'>Semi-monthly</option>
          <option value='Monthly'>Monthly</option>
        </select>
      </Field>
    );
  }

  renderFiler() {
    return (
      <AttorneyContainer
        title='Attorney'
        context='client'
        email={this.state.partnerEmail}
        firmName={this.state.firmName}
        parentRecord={this.props.record}
        onChange={this.onChangeEmail}
      />
    );
  }

  rederImmediateInvoiceRecipients() {
    return (
      <ReminderEmail
        label='Immediate Invoice Recipients'
        field='immediateInvoiceRecipients'
        record={this.props.record}
        onChange={this.props.onChange}
      />
    );
  }

  renderRequired() {
    return (
      <Fieldset>
        {this.props.features.limitedDocs ? null : this.renderBillingPeriod()}
        {this.renderEntitySize()}
        {this.props.features.admin && this.rederImmediateInvoiceRecipients()}
      </Fieldset>
    );
  }

  renderLegacyCount() {
    return (
      <Field label='Legacy Count'>
        {this.state.legacyCount}
      </Field>
    );
  }

  renderSweep() {
    const disabled = !this.props.features.sweep;

    return (
      <Field label='Sweep Services' style={{ paddingRight: '0' }}>
        {this.renderCheckbox('892 Citations', 'sweepCitationsFlag', [false, true], '46%', disabled)}
        {this.renderCheckbox('US Office Actions', 'sweepUsOfficeActionsFlag', [false, true], '54%', disabled || !this.state.sweepCitationsFlag)}
        {this.props.features.sweep && this.renderCheckbox('ISR Citations and OAs', 'sweepISRFlag', [false, true], '100%')}
        {this.renderCheckbox('Self-Cite (own US Pats & Pubs)', 'patsAndPubsFlag', [false, true], '100%', disabled)}
        {disabled && (
          <p className='text-muted'>To make any changes, please email your request to data@syncids.com.</p>
        )}
      </Field>
    );
  }

  renderOptional() {
    const help = (
      <Help
        style={{ marginTop: '10px' }}
        text='(leave blank to use alternate method)'
      />
    );

    return (
      <Fieldset>
        <div style={{ width: '50%', display: 'inline-block', float: 'left' }}>
          {this.renderInput('Deposit Account Number', 'depositAccountNumber', '100%', false, false, null, help)}
          {this.renderLegacyCount()}
        </div>
        <div style={{ width: '50%', display: 'inline-block' }}>
          {this.renderSweep()}
        </div>
      </Fieldset>
    );
  }

  renderReminderEmail() {
    return (
      <ReminderEmail
        record={this.props.record}
        onChange={this.props.onChange}
      />
    );
  }

  renderReminder() {
    return (
      <Fieldset>
        {this.renderReminderEmail()}
        {this.renderReminderFrequency({
          label: 'Pending Frequency',
          field: 'generalReminderFrequency',
          help: 'Reminders for all pending IDSs (includes urgent IDSs)'
        })}
        {this.renderReminderFrequency({
          label: 'Urgent Frequency',
          field: 'urgentReminderFrequency',
          help: 'Reminders for urgent IDSs only'
        })}
      </Fieldset>
    );
  }

  renderNotes() {
    return (
      <div style={{ paddingTop: '9px' }}>
        <textarea
          rows={5}
          name='notes'
          value={this.state.notes || ''}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </div>
    );
  }

  getNotesTitle() {
    return (
      <div>
        <span className={/\w/.test(this.props.record.get('notes')) ? 'fa fa-file' : 'fa fa-file-o'} />
        &nbsp;
        Notes
      </div>
    );
  }

  render() {
    return (
      <div style={{ height: '200px' }}>
        <Tabs
          activeKey={this.props.selectedTab}
          onSelect={this.props.onSelectTab} id='client-tabs'
        >
          <Tab eventKey='required' title='Required'>
            {this.renderRequired()}
          </Tab>
          <Tab eventKey='optional' title='Optional'>
            {this.renderOptional()}
          </Tab>
          <Tab eventKey='filer' title='Signator'>
            {this.renderFiler()}
          </Tab>
          <Tab eventKey='reminder' title='Reminders'>
            {this.renderReminder()}
          </Tab>
          <Tab eventKey='notes' title={this.getNotesTitle()}>
            {this.renderNotes()}
          </Tab>
        </Tabs>
      </div>
    );
  }
}
