import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Button } from 'react-bootstrap';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import TableList, { Column } from '../../shared/TableList';
import InvoiceInput from './InvoiceInput';
import InvoiceRemove from './InvoiceRemove';
import InvoiceStatus from './InvoiceStatus';
import UploadPaymentsIntro from './UploadPaymentsIntro';

const defaultRows = Immutable.fromJS([{
  firmName: '',
  invoiceNumber: '',
  paidAmount: ''
}]);

export default class UploadPayments extends Component {
  static propTypes = {
    uploadPayments: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      show: true,
      rows: defaultRows
    };
  }

  onClickReset() {
    this.setState({
      rows: defaultRows
    });
  }

  onClickConfirm() {
    this.props.uploadPayments(this.state.rows.toJS())
      .then(response => {
        this.setState({
          rows: Immutable.fromJS(response)
        });
      });
  }

  getRows() {
    return this.state.rows;
  }

  onBlur() {
    const last = this.state.rows.last();
    const firmName = last ? last.get('firmName') : '';
    const isFilled = last.get('paidAmount') || last.get('invoiceNumber');

    if (isFilled) {
      this.setState({
        rows: this.state.rows.push(Immutable.fromJS({ firmName }))
      });
    }
  }

  onChange(rowIndex, field, value) {
    this.setState({
      rows: this.state.rows.setIn([rowIndex, field], value)
    });
  }

  onRemove(rowIndex) {
    this.setState({
      rows: this.state.rows.deleteIn([rowIndex])
    });
  }

  renderBody() {
    return (
      <TableList
        id='invoices'
        forceUpdate={false}
        rows={this.getRows()}
        rowKey='id'
        rowAs='record'
      >
        <Column key='firmName' title='Firm Name' style={{ width: '230px' }}>
          <InvoiceInput
            onBlur={this.onBlur}
            onChange={this.onChange}
            field='firmName'
            example='e.g. firmcom'
          />
        </Column>
        <Column key='invoiceNumber' title='Invoice Number' style={{ width: '130px' }}>
          <InvoiceInput
            isNumber
            onBlur={this.onBlur}
            onChange={this.onChange}
            field='invoiceNumber'
            example='e.g. 123'
          />
        </Column>
        <Column key='paidAmount' title='Amount Paid' style={{ width: '130px' }}>
          <InvoiceInput
            isNumber
            onBlur={this.onBlur}
            onChange={this.onChange}
            field='paidAmount'
            example='e.g. $80'
          />
        </Column>
        <Column key='status' title='Status'>
          <InvoiceStatus />
        </Column>
        <Column key='remove' title='' style={{ width: '50px' }}>
          <InvoiceRemove
            onRemove={this.onRemove}
          />
        </Column>
      </TableList>
    );
  }

  renderConfirm() {
    return (
      <Button
        variant='primary'
        onClick={this.onClickConfirm}
      >
        Save
      </Button>
    );
  }

  renderReset() {
    return (
      <Button
        style={{ marginRight: '15px' }}
        onClick={this.onClickReset}
      >
        Reset
      </Button>
    );
  }

  renderTotal() {
    const total = this.getRows()
      .filter(record => typeof record.get('paidAmount') === 'number')
      .reduce((total, record) => total + record.get('paidAmount'), 0);
    return (
      <span style={{ marginRight: '155px' }}>
        Total: ${total}
      </span>
    );
  }

  render() {
    return (
      <CollapsiblePanel id='upload-payments' header='Upload Payments'>
        <div className='row'>
          <div className='col-md-12'>
            <UploadPaymentsIntro />
            <hr />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            {this.renderBody()}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div style={{ textAlign: 'right', padding: '0 8px 15px 8px' }}>
              {this.renderTotal()}
              {this.renderReset()}
              {this.renderConfirm()}
            </div>
          </div>
        </div>
      </CollapsiblePanel>
    );
  }
};
