import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';

export default class InvoiceStatus extends Component {
  static propTypes = {
    record: PropTypes.object
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderIcon() {
    switch (this.props.record.get('status')) {
      case 'Wrong Amount':
        return (
          <span className='fa fa-times text-danger' />
        );
      case 'OK':
        return (
          <span className='fa fa-check text-success' />
        );
      default:
        return (
          <span className='fa fa-circle' style={{ fontSize: '10px', position: 'relative', top: '-1px' }} />
        );
    }
  }

  renderLabel() {
    switch (this.props.record.get('status') || '') {
      case 'Wrong Amount':
        return `Amount doesn't match ($${this.props.record.get('invoiceAmount')})`;
      case 'OK':
        return 'Amount match';
      case '':
        return 'Pending';
      default:
        return this.props.record.get('status');
    }
  }

  render() {
    return (
      <div style={{ paddingTop: '5px' }}>
        {this.renderIcon()}
        <span style={{ marginLeft: '5px' }}>
          {this.renderLabel()}
        </span>
      </div>
    );
  }
};
