import autoBind from 'class-autobind';
import React from 'react';
import PropTypes from 'prop-types';
import { isValidEmail } from '../../utils/text-utils';
import FieldGroup from '../shared/FieldGroup';

export default class EmailField extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { value } = this.props;
    return (
      <FieldGroup
        validationState={!value || isValidEmail(value) ? null : 'error'}
        label='Email'
        type='email'
        value={value || ''}
        autoFocus={this.props.autoFocus}
        onKeyPress={this.props.onKeyPress}
        spellCheck={false}
        onChange={this.props.onChange}
      />
    );
  }
}
