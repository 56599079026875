import { createAction } from 'redux-actions';
import { MATTER_CLEAR_CONSISTENCY } from '../../constants/actions';

const clearConsistency = createAction(MATTER_CLEAR_CONSISTENCY);

export default (matterId) => {
  return clearConsistency({
    matterId
  });
};
