import Immutable from 'immutable';
import { createAction } from 'redux-actions';
import callApi from '../../utils/callApi';
import { NPL_SUGGESTIONS, SEARCH_NPL_DOCUMENTS } from '../../constants/actions';
import { documentsUrl } from '../../constants/urls';
import _get from 'lodash/get';
import fetchDocumentsIfNeeded from './fetchDocumentsIfNeeded';

const nplSuggestions = createAction(NPL_SUGGESTIONS);
const searchNplDocuments = createAction(SEARCH_NPL_DOCUMENTS);

export default (firmName, clientNumber, nonPatentBib, loadFullDocs = false) => (dispatch, getState) => {
  if (!firmName || !clientNumber || !nonPatentBib) {
    return;
  }

  const docNumber = nonPatentBib.trim();
  if (/^NPL[0-9]{2,}$/.test(docNumber)) {
    dispatch(callApi({
      url: documentsUrl + '/get-multiple',
      method: 'post',
      params: { firmName, clientNumber },
      data: { documentNumbers: [docNumber] }
    }))
      .then((response) => {
        dispatch(nplSuggestions({
          clientNumber,
          doc: _get(response, ['entities', 'documents', clientNumber, docNumber])
        }));
      });
    return;
  }

  const { documents: { searchNpl } } = getState();
  const isSearchCached = searchNpl &&
    searchNpl[clientNumber] && (
    searchNpl[clientNumber][nonPatentBib] ||
      searchNpl[clientNumber][nonPatentBib.substr(0, 3)]
  );

  if (isSearchCached) {
    return;
  }

  dispatch(searchNplDocuments({
    clientNumber,
    nonPatentBib
  }));

  const request = {
    url: documentsUrl + '/search-npl',
    method: 'get',
    params: { firmName, clientNumber, nonPatentBib }
  };
  return dispatch(callApi(request))
    .then((response) => {
      if (loadFullDocs) {
        const citedArt = Object.values(_get(response, ['entities', 'nplSuggestions', clientNumber]))
          .map(item => item.documentNumber);
        const fakeMatter = Immutable.fromJS({
          firmName,
          clientNumber,
          citedArt
        });
        return dispatch(fetchDocumentsIfNeeded(fakeMatter));
      }
    });
};
