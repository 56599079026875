import React, { Component } from 'react';
import TestDrive from './TestDrive';
import RequestDemo from './RequestDemo';
import FacebookButton from './FacebookButton';
import LinkedInButton from './LinkedInButton';

export default class PageFooter extends Component {
  static propTypes = {
  };

  static defaultProps = {
  };

  renderFacebook() {
    return (
      <FacebookButton />
    );
  }

  renderLinkedIn() {
    return (
      <LinkedInButton />
    );
  }

  render() {
    return (
      <div className='text-center'>
        {this.renderLinkedIn()}
        {this.renderFacebook()}
        <TestDrive />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <RequestDemo />
        <br />
        <br />
      </div>
    );
  }
}
