import callApi from '../../utils/callApi';
import { attorneyUrl } from '../../constants/urls';
import { createAction } from 'redux-actions';

import {
  LOAD_ATTORNEY_LIST_BEGIN,
  LOAD_ATTORNEY_LIST_SUCCESS,
  LOAD_ATTORNEY_LIST_ERROR
} from '../../constants/actions';

const loadAttorneyListSuccess = createAction(LOAD_ATTORNEY_LIST_SUCCESS);
const loadAttorneyListError = createAction(LOAD_ATTORNEY_LIST_ERROR);
const loadAttorneyListBegin = createAction(LOAD_ATTORNEY_LIST_BEGIN);

export default () => (dispatch, getState) => {
  if (getState().attorneyList) {
    return;
  }

  dispatch(loadAttorneyListBegin());

  return dispatch(callApi({
    url: attorneyUrl
  })).then(response => {
    dispatch(loadAttorneyListSuccess(response.attorneys));
  }).catch(() => {
    dispatch(loadAttorneyListError());
  });
};
