import { createAction } from 'redux-actions';
import axios from 'axios';
import { registerUrl } from '../../constants/urls';
import {
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_MESSAGE,
  AUTH_REGISTER_PROGRESS
} from '../../constants/actions';

const authRegisterSuccess = createAction(AUTH_REGISTER_SUCCESS);
const authRegisterMessage = createAction(AUTH_REGISTER_MESSAGE);
const authRegisterProgress = createAction(AUTH_REGISTER_PROGRESS);

export default (email) => (dispatch) => {
  const params = {
    email,
    ref: window.sessionStorage.getItem('syncids-ref') || ''
  };

  dispatch(authRegisterProgress());
  axios
    .get(registerUrl, { params })
    .then(res => {
      dispatch(authRegisterSuccess({
        type: 'success',
        message: res.data.status
      }));
    })
    .catch(err => {
      dispatch(authRegisterMessage({
        type: 'error',
        message: err.response.data.status || err.response.data.error
      }));
    });
};
