import { getCategoryFromMatterNumber } from '../../../utils/categories';
import createCategory from './createCategory';
import createCategoryMatterIfNeeded from './createCategoryMatterIfNeeded';

export default (clientId, matterNumber) => (dispatch, getState) => {
  const category = getCategoryFromMatterNumber(matterNumber);
  if (!category) {
    return;
  }

  return dispatch(createCategory(clientId, category)).then(() => {
    return dispatch(createCategoryMatterIfNeeded(category));
  }).then(() => category);
};
