import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';

export default (doc) => (dispatch) => {
  const id = doc.get('id');
  const documentNumber = doc.get('documentNumber');

  dispatch(addNotification({
    id: `document-${documentNumber}`,
    type: 'progress',
    message: `Appending translation to ${documentNumber}...`
  }));
  return dispatch(callApi({
    url: `/api/fetch-pdf/doc/${id}/append-translation`
  }))
    .then(() => {
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'success',
        message: `Finished appending translation to ${documentNumber}.`
      }));
    })
    .catch(() => {
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'error',
        message: `Failed to append translation to ${documentNumber}.`
      }));
    });
};
