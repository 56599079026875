import _get from 'lodash/get';
import _find from 'lodash/find';
import _uniq from 'lodash/uniq';
import createClient from '../client/createClient';
import createMatter from '../matter/createMatter';
import fetchClientList from '../client/fetchClientList';
import fetchMatterList from '../matter/fetchMatterList';
import updateMatter from '../matter/updateMatter';
import addNotification from '../app/addNotification';

export default (mattersToUpdate, createMissing) => async(dispatch) => {
  const clientListResponse = await dispatch(fetchClientList());
  const clientList = _get(clientListResponse, ['entities', 'clientList'], []);
  const clientNotFound = [];

  _uniq(mattersToUpdate.map(row => row.clientNumber)).forEach(clientNumber => {
    const client = clientList.find(c => c.clientNumber.toLowerCase() === clientNumber.toLowerCase());
    if (client) {
      mattersToUpdate
        .filter(m => m.clientNumber === clientNumber)
        .forEach(m => {
          m.clientNumber = client.clientNumber;
        });
    } else {
      clientNotFound.push(clientNumber);
    }
  });

  if (createMissing) {
    for (const clientNumber of clientNotFound) {
      const clientCreated = await dispatch(createClient({ clientNumber }));
      if (clientCreated) {
        clientList.push({
          id: clientCreated.id,
          clientNumber
        });
      }
    }
  } else {
    clientNotFound.forEach(clientNumber => {
      dispatch((addNotification({
        id: 'client-not-found' + clientNumber,
        type: 'error',
        message: `Client not found: ${clientNumber}`
      })));
    });
  }

  const mattersByClient = {};

  await Promise.all(
    _uniq(mattersToUpdate.map(row => row.clientNumber)).map(clientNumber => {
      const client = _find(clientList, { clientNumber });
      return dispatch(fetchMatterList(client.id))
        .then(response => {
          mattersByClient[client.clientNumber] = _get(response, ['entities', 'matterList', client.id]);
        });
    })
  );

  mattersToUpdate.forEach(row => {
    if (!row.matterNumber && row.clientNumber && row.applicationNumber && mattersByClient[row.clientNumber]) {
      const matterByApplication = mattersByClient[row.clientNumber].find(m => (
        m.applicationNumber.replace(/\/,/g, '') === row.applicationNumber.replace(/\/,/g, '')
      ));
      if (matterByApplication) {
        row.matterNumber = matterByApplication.matterNumber;
      }
    }
  });

  const mattersNotFound = [];

  mattersToUpdate.forEach(row => {
    const matter = _find(mattersByClient[row.clientNumber], { matterNumber: row.matterNumber });
    if (!matter) {
      mattersNotFound.push(row);
    }
  });

  if (createMissing) {
    for (const row of mattersNotFound) {
      const matterCreated = await dispatch(createMatter(row));
      if (matterCreated) {
        mattersByClient[row.clientNumber].push({
          id: matterCreated.id,
          matterNumber: row.matterNumber,
          applicationNumber: row.applicationNumber
        });
      }
    }
  } else {
    mattersNotFound.forEach(row => {
      dispatch((addNotification({
        id: 'matter-not-found' + row.matterNumber,
        type: 'error',
        message: `Matter not found: ${row.matterNumber}`
      })));
    });
  }

  for (const row of mattersToUpdate) {
    const matter = _find(mattersByClient[row.clientNumber], { matterNumber: row.matterNumber });
    if (matter) {
      row.id = matter.id;
      await dispatch(updateMatter({ id: matter.id, ...row }, row.matterNumber));
    }
  }

  return mattersToUpdate;
};
