import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import classNames from 'classnames';

const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_RETURN = 13;
const KEY_ENTER = 14;
const KEY_ESCAPE = 27;
const KEY_SPACE = 32;
const KEY_COMMA = 188;

export default class SimpleSuggest extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    rows: PropTypes.number,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.string,
    initialValue: PropTypes.string,
    spellCheck: PropTypes.bool,
    placeholder: PropTypes.string,
    styleDropdown: PropTypes.object,
    styleOuter: PropTypes.object,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onKeyDown: PropTypes.func,

    autoSubmit: PropTypes.bool,
    preventSpaces: PropTypes.bool,
    preventCommas: PropTypes.bool,
    dropdownTop: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    submitOnBlur: PropTypes.bool,
    optimizeDropdown: PropTypes.bool,

    allSuggestions: PropTypes.instanceOf(Immutable.List)
  };

  static defaultProps = {
    allSuggestions: Immutable.List(),
    spellCheck: false
  };

  static getDerivedStateFromProps(props, state) {
    if (props.initialValue === props.value) {
      return {
        changed: false
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this, SimpleSuggest.prototype);

    this.state = {
      changed: false,
      index: -1,
      showOptions: false,
      focusInput: false,
      mouseOverOptions: false,
      renderDropdown: !props.optimizeDropdown
    };
  }

  componentWillUnmount() {
    return this.clearBlurTimeout();
  }

  hideDropdown() {
    if (this.dropdownElm) {
      this.dropdownElm.style.display = 'none';
    }

    this.setState({
      showOptions: false,
      index: -1,
      mouseOverOptions: false
    });
  }

  clearBlurTimeout() {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
      this.blurTimeout = null;
    }
  }

  onMouseEnter() {
    if (!this.state.renderDropdown) {
      this.setState({
        renderDropdown: true
      });
    }
  }

  onKeyDown(event) {
    event.stopPropagation();

    const options = this.getVisibleOptions();
    const hasOptions = options && this.state.showOptions && this.state.changed;
    const index = this.state.index;

    switch (event.keyCode) {
      case KEY_ESCAPE:
        event.preventDefault();
        break;
      case KEY_UP:
        if (hasOptions) {
          event.preventDefault();
          const upIndex = Math.max(index - 1, 0);
          this.setState({ index: upIndex }, this.updateScroll);
        }
        break;
      case KEY_DOWN:
        if (hasOptions) {
          event.preventDefault();
          const downIndex = Math.min(index + 1, options.length - 1);
          this.setState({ index: downIndex }, this.updateScroll);
        }
        break;
      case KEY_ENTER:
      case KEY_RETURN:
        if (hasOptions) {
          event.preventDefault();
          const value = index === -1 ? this.getValue() : options[index];
          if (this.props.submitOnEnter) {
            this.onSubmit(value);
          } else {
            this.onSelect(value);
          }
        } else if (this.props.submitOnEnter) {
          this.onSubmit(this.getValue());
        }
        break;
      case KEY_SPACE:
        if (this.props.preventSpaces) {
          event.preventDefault();
        }
        break;
      case KEY_COMMA:
        if (this.props.preventCommas) {
          event.preventDefault();
        }
        break;
      default:
        if (this.props.onKeyDown) {
          this.props.onKeyDown(event);
        }
        break;
    }
  }

  dropdownRef(ref) {
    this.dropdownElm = ref;
  }

  updateScroll() {
    const option = this.dropdownElm.querySelector(`li:nth-child(${this.state.index + 1})`);
    if (option) {
      const height = parseInt(this.dropdownElm.offsetHeight, 10);
      const top = parseInt(option.offsetTop, 10);
      this.dropdownElm.scrollTop = top - (height / 2);
    }
  }

  onSelect(value) {
    if (this.props.autoSubmit) {
      this.onSubmit(value);
    } else {
      this.onChange({ target: { value } });
    }
  }

  onFocus(e) {
    this.clearBlurTimeout();
    this.setState({
      focusInput: true,
      showOptions: true
    });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  clearBlur() {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
      this.blurTimeout = null;
    }
  }

  onBlur(e) {
    e.persist();

    this.setState({
      focusInput: false
    });

    this.clearBlur();

    this.blurTimeout = setTimeout(() => {
      if (this.state.mouseOverOptions && this.getVisibleOptions()) {
        return;
      }
      if (this.props.submitOnBlur) {
        this.onSubmit(this.getValue());
      }
      if (this.props.onBlur) {
        this.props.onBlur(e);
      }
      this.hideDropdown();
    }, 500);
  }

  onSubmit(value) {
    if (this.props.onSubmit) {
      this.props.onSubmit(value);
    }
    this.setState({
      changed: false
    });
  }

  onChange(e) {
    const value = e.target.value;
    if (value !== this.props.value) {
      this.setState({ changed: true });
      this.props.onChange(value);
    }
  }

  inputRef(input) {
    if (input) {
      this.inputElm = input;
      this.inputElm.rows = this.props.rows;
    }
  }

  getOptions() {
    return this.props.allSuggestions.toJS();
  }

  getVisibleOptions() {
    const options = this.getOptions();
    const value = this.getMatchValue();
    if (!options.length || !value) {
      return;
    }
    const matchOptions = options.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    if (matchOptions.length < 1) {
      return;
    }
    return matchOptions;
  }

  getMatchValue() {
    return this.getValue();
  }

  getValue() {
    return this.props.value || '';
  }

  onClickOption(e) {
    const option = e.target.getAttribute('data-option');
    this.onSelect(option);
    this.hideDropdown();
  }

  clearMouseLeave() {
    if (this.mouseLeaveTimeout) {
      clearTimeout(this.mouseLeaveTimeout);
      this.mouseLeaveTimeout = null;
    }
  }

  onMouseEnterOptions() {
    this.setState({ mouseOverOptions: true });
  }

  onMouseLeaveOptions() {
    this.setState({ mouseOverOptions: false });
  }

  renderOptions() {
    const visibleOptions = this.getVisibleOptions();
    return visibleOptions && (
      <ul
        ref={this.dropdownRef}
        onClick={this.onClickOption}
        onMouseEnter={this.onMouseEnterOptions}
        onMouseLeave={this.onMouseLeaveOptions}
        style={this.props.styleDropdown}
      >
        {visibleOptions.map((option, index) => {
          return (
            <li
              key={index}
              data-option={option}
              className={this.state.index === index ? 'active' : null}
            >
              {option}
            </li>
          );
        })}
      </ul>
    );
  }

  renderInput() {
    const Input = this.props.type || 'input';

    return (
      <Input
        id={this.props.id}
        name={this.props.name}
        autoComplete='something-chrome-dont-understand'
        spellCheck={this.props.spellCheck}
        ref={this.inputRef}
        style={this.props.style}
        className={this.props.className}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        value={this.getValue()}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        onMouseEnter={this.onMouseEnter}
      />
    );
  }

  renderDropdown() {
    return (
      <div
        style={this.props.styleOuter}
        className={classNames({
          'auto-suggest': true,
          'dropdown-top': this.props.dropdownTop
        })}
      >
        {this.renderInput()}
        {this.state.showOptions && this.state.changed && this.renderOptions()}
      </div>
    );
  }

  render() {
    return this.state.renderDropdown ? this.renderDropdown() : this.renderInput();
  }
}
