import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import selectMatter from './selectMatter';
import fetchMatterList from './fetchMatterList';
import { mattersUrl } from '../../constants/urls';
import refreshTodos from '../todo/refreshTodos';

export default (matterId, matterNumber, dontSelect) => (dispatch, getState) => {
  if (!matterId) {
    return null;
  }

  if (!dontSelect) {
    dispatch(selectMatter());
  }

  dispatch(addNotification({
    id: `matter-${matterId}`,
    type: 'progress',
    message: `Deleting matter ${matterNumber}...`
  }));

  return dispatch(callApi({
    url: mattersUrl + '/' + matterId,
    method: 'delete'
  }))
    .then(r => {
      if (r.status === 'completed_office_actions_error') {
        dispatch(addNotification({
          id: `matter-${matterId}`,
          type: 'error',
          message: `Matter ${matterNumber} has completed OAs.`
        }));
      } else {
        return dispatch(refreshTodos())
          .then(() => dispatch(fetchMatterList(getState().app.selectedClientId)))
          .then(() => (
            dispatch(addNotification({
              id: `matter-${matterId}`,
              type: 'success',
              message: `Deleted matter ${matterNumber}.`
            }))
          ));
      }
    })
    .catch(() => {
      dispatch(addNotification({
        id: `matter-${matterId}`,
        type: 'error',
        message: `Failed to delete ${matterNumber}.`
      }));
    });
};
