import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import ConfirmModal from '../shared/ConfirmModal';
import { Table } from 'react-bootstrap';

export default class RemoveEquivalentsModal extends Component {
  static propTypes = {
    getEquivalents: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    removeEquivalents: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      data: [],
      removed: [],
      loading: true,
      removing: false
    };
  }

  componentDidMount() {
    this.props.getEquivalents(this.props.matter).then(data => {
      this.setState({
        data,
        loading: false
      });
    });
  }

  onCancel() {
    this.props.onClose();
  }

  async onConfirm() {
    this.setState({ removing: true });
    const matterId = this.props.matter.get('matterId');

    const documentNumbers = [];

    this.state.data.forEach(item => {
      const docs = this.getDocsToRemove(item);
      documentNumbers.push(...docs.map(doc => doc.documentNumber));
    });

    await this.props.removeEquivalents(matterId, documentNumbers);

    this.props.onClose();
  }

  onClickRemove(e) {
    const doc = e.target.getAttribute('data-doc');

    this.setState({
      removed: this.state.removed.concat(doc)
    });
  }

  getDocsToRemove(item) {
    return item.uncitedEquivalentDocs.filter(doc => !this.state.removed.includes(doc.documentNumber));
  }

  renderTable(item, index) {
    const winner = item.winnerDoc;
    const title = item.isUsPatent ? 'US Patent' : 'Cited Document';
    const docs = this.getDocsToRemove(item);

    return (
      <Table striped bordered hover key={index}>
        <thead>
          <tr>
            <th style={{ width: '120px' }}>{title}</th>
            <th>Uncited Equivalents</th>
            <th style={{ width: '30px' }}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {docs.map((doc, index) => (
            <tr key={index}>
              <td style={{ width: '120px' }}>{index === 0 ? winner.fullNumber : '-'}</td>
              <td>{doc.fullNumber}</td>
              <td style={{ width: '30px', textAlign: 'center' }}>
                <span
                  className='fa fa-times'
                  style={{ cursor: 'pointer' }}
                  data-doc={doc.documentNumber}
                  onClick={this.onClickRemove}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderContent() {
    if (this.state.loading) {
      return 'Loading...';
    }

    if (!this.state.data.length) {
      return 'There are no cited equivalents in this matter.';
    }

    return (
      <>
        {this.state.data.map(this.renderTable)}
      </>
    );
  }

  renderMessage() {
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }

  render() {
    return (
      <ConfirmModal
        title='Remove Equivalents'
        help='"Remove Equivalents" is an on-demand tool designed to filter out references that are considered equivalent to US patent numbers. This helps ensure that only unique and non-equivalent references are included in your IDS. It also helps streamline the IDS review process by reducing the number of references that need to be considered by patent practitioners and USPTO Examiners.'
        message={this.renderMessage()}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
        confirmDisabled={this.state.removing || this.state.loading || !this.state.data.length}
        focusCancel
      />
    );
  }
};
