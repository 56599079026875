import { connect } from 'react-redux';
import Immutable from 'immutable';
import actions from '../../../redux/actions';
import NplMatch from './NplMatch';

const mapStateToProps = (state, props) => {
  const {
    app: {
      selectedMatterId,
      selectedClientId,
      features
    },
    entities
  } = state;

  const documents = entities.get('documents') || Immutable.Map();
  const clientList = entities.get('clientList') || Immutable.List();
  const nplSuggestions = entities.get('nplSuggestions') || Immutable.Map();
  const client = entities.getIn(['clients', selectedClientId]) || Immutable.Map();
  const matter = entities.getIn(['matters', selectedMatterId]) || Immutable.Map();

  return {
    client,
    matter,
    documents,
    clientList,
    nplSuggestions,
    features
  };
};

export default connect(mapStateToProps, actions)(NplMatch);
