import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class BrowseModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  };

  render() {
    return (
      <Modal
        id='browser-modal'
        onHide={this.props.onClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title>Recommended Browsers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>We recommend using <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer'>Chrome</a> for best result.</p>
          <p><strong>Please note:</strong> Microsoft IE is not currently supported.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={this.props.onClose}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
