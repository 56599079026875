import { createAction } from 'redux-actions';
import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';
import { DOCUMENT_ADD, DOCUMENT_ADD_SUCCESS } from '../../constants/actions';
import addCategoryToDocumentIfNeeded from '../category/addCategoryToDocumentIfNeeded';
import _get from 'lodash/get';
import fetchPDF from './fetchPDF';
import DocumentRecord from '../../../models/DocumentRecord';
import fetchDocuments from './fetchDocuments';

const documentAdd = createAction(DOCUMENT_ADD);
const documentAddSuccess = createAction(DOCUMENT_ADD_SUCCESS);

const focusInput = (selector) => {
  const input = document.querySelector(selector);
  if (input) {
    input.focus();
  }
};

const focusNextInput = (type, field, failed) => {
  const active = document.activeElement;
  if (failed || active.value || !active.required) {
    focusInput(`#table-docs-${type.toLowerCase()} tr:last-child .document-input[name="${field}"]`);
  }
};

const getDoc = (response, matterId, documentNumber) => {
  const clientNumber = _get(response, ['entities', 'matters', matterId, 'clientNumber']);
  return _get(response, ['entities', 'documents', clientNumber, documentNumber.replace(/^0[A-Z]{2}/, '0')]);
};

export default (matterId, documentNumber, type, field, isCited, officeAction) => (dispatch, getState) => {
  if (!documentNumber) {
    return Promise.resolve();
  }

  const displayNumber = type === 'Foreign' ? documentNumber.substr(1) : documentNumber;
  dispatch(addNotification({
    id: `document-${documentNumber}`,
    type: 'progress',
    message: `Adding ${displayNumber}...`
  }));
  dispatch(documentAdd({
    type,
    field,
    matterId,
    documentNumber
  }));
  return dispatch(callApi({
    url: documentsUrl + '/add-to-matter',
    params: {
      matterId,
      documentNumber,
      type,
      isCited,
      officeAction: officeAction ? officeAction.id : undefined
    }
  }))
    .then(response => {
      const isDuplicate = response.status === 'duplicate';
      dispatch(documentAddSuccess({
        type,
        matterId,
        documentNumber,
        isDuplicate
      }));
      if (isDuplicate) {
        dispatch(addNotification({
          id: `document-${documentNumber}`,
          type: 'warn',
          message: `Duplicate ${displayNumber} ignored.`
        }));
      } else {
        let promise = Promise.resolve();
        Object.keys(response.entities.documents).forEach(clientNumber => {
          Object.keys(response.entities.documents[clientNumber]).forEach(documentNumber => {
            promise = promise.then(() => dispatch(addCategoryToDocumentIfNeeded(matterId, documentNumber)));
          });
        });

        dispatch(addNotification({
          id: `document-${documentNumber}`,
          type: 'success',
          message: `Added ${displayNumber}.`
        }));
      }

      const doc = getDoc(response, matterId, documentNumber);

      if (doc && doc.type === 'Foreign') {
        const record = new DocumentRecord(doc);
        dispatch(fetchPDF(record)).then(() => {
          const matter = getState().entities.getIn(['matters', matterId]);
          return dispatch(fetchDocuments(matter, [record.getRealDocumentNumber()]));
        });
      }

      if (!isDuplicate && doc && doc.type === 'Foreign' && (!doc.publicationDate || !doc.country)) {
        const field = doc.country ? 'publicationDate' : 'country';
        focusInput(`#document-input-${field}-${doc.id} input`);
      } else {
        focusNextInput(type, field, isDuplicate);
      }

      return response;
    })
    .catch(() => {
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'error',
        message: `Failed to add ${displayNumber}...`
      }));
    });
};
