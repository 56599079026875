import React from 'react';
import PageFooter from '../shared/PageFooter';
import linkMigration from '../../files/Legacy_Data_Migration_Service_4.0.pdf';
import linkSweeps from '../../files/U.S._Office_Action_Sweeps_Service_4.0.pdf';
import linkParalegal from '../../files/Custom_IDS_Paralegal_Service_4.0.pdf';
import linkAllIn from '../../files/SyncIDS.All-In.Brochure.4.0.pdf';
import { generateViewPDFUrl } from '../../utils/google-doc-viewer';

const styleIcon = {
  float: 'left',
  clear: 'left',
  fontSize: '48px',
  padding: '15px',
  width: '100px',
  height: '100px',
  textAlign: 'center',
  display: 'block'
};

const stylePDF = {
  marginLeft: '5px'
};

export default class ServicesView extends React.Component {
  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  render() {
    return (
      <div className='container bigger-fonts'>
        <h1>Services</h1>
        <hr />
        <div className='row'>
          <div className='col-md-1 col-xs-2'>
            <span style={styleIcon} className='fa fa-refresh' />
          </div>
          <div className='col-md-11 col-xs-10'>
            <h4><a target='_blank' href={generateViewPDFUrl(linkAllIn)} rel='noreferrer'>All-In Package <span style={stylePDF} className='fa fa-file-pdf-o' /></a></h4>
            <p>The SyncIDS All-In Package does it all! You never need to enter any data or file any IDSs. Ever. We start by entering all of your U.S. and foreign historical data into our proprietary database. We also automatically determine family and most subject matter relationships. Then we keep your data up to date. For example, if one case receives a U.S. or foreign Office action, all of the related cases, in jurisdictions with a duty of disclosure, will automatically be filed after your simple checkbox approval. <a target='_blank' href={generateViewPDFUrl(linkAllIn)} rel='noreferrer'>Click here for more details.</a></p>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-1 col-xs-2'>
            <span style={styleIcon} className='fa fa-database' />
          </div>
          <div className='col-md-11 col-xs-10'>
            <h4><a target='_blank' href={generateViewPDFUrl(linkMigration)} rel='noreferrer'>Legacy Data Migration <span style={stylePDF} className='fa fa-file-pdf-o' /></a></h4>
            <p>The SyncIDS proprietary legacy data migration process automatically creates matters in SyncIDS for the U.S. and most foreign application numbers you provide to us. We migrate the inventor name, filing date, art unit, etc. along with any previously-cited U.S. patents/publications, foreign patents (includes most PDFs), and NPL data. Matters can also be auto-synced according to family and/or subject matter. <a target='_blank' href={generateViewPDFUrl(linkMigration)} rel='noreferrer'>Click here for more details.</a></p>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-1 col-xs-2'>
            <span style={styleIcon} className='fa fa-files-o' />
          </div>
          <div className='col-md-11 col-xs-10'>
            <div style={{ float: 'left' }}>
              <h4><a target='_blank' href={generateViewPDFUrl(linkSweeps)} rel='noreferrer'>U.S. Office Action Sweeps <span style={stylePDF} className='fa fa-file-pdf-o' /></a></h4>
              <p>SyncIDS offers a weekly Office Action Sweep Service that sweeps your U.S., pending matters for the following documents. <a target='_blank' href={generateViewPDFUrl(linkSweeps)} rel='noreferrer'>Click here for more details.</a></p>
              <ul>
                <li>Office Actions</li>
                <li>Notices of Allowance</li>
                <li>892s</li>
                <li>Third Party Submissions</li>
                <li>Ex Parte Quayle</li>
              </ul>
              <p>Please see the All-in Package brochure for details related to Foreign Office Action Data Entry services.</p>
            </div>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-1 col-xs-2'>
            <span style={styleIcon} className='fa fa-balance-scale' />
          </div>
          <div className='col-md-11 col-xs-10'>
            <h4><a target='_blank' href={generateViewPDFUrl(linkParalegal)} rel='noreferrer'>Custom IDS Paralegal Services <span style={stylePDF} className='fa fa-file-pdf-o' /></a></h4>
            <p>SyncIDS is happy to be partnering with our customers to understand and fulfill all of their IDS needs. Our quality-assured IDS Paralegal Services allow us to automate and customize processes to assist our customers while improving our tool to stay innovative. <a target='_blank' href={generateViewPDFUrl(linkParalegal)} rel='noreferrer'>Click here for more details.</a></p>
          </div>
        </div>
        <hr />
        <PageFooter />
      </div>
    );
  }
}
