import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Fade, Button } from 'react-bootstrap';

const icons = {
  warn: 'fa-exclamation-triangle',
  error: 'fa-times',
  success: 'fa-check',
  progress: 'fa-spinner fa-spin'
};

const classes = {
  warn: 'warning',
  error: 'danger',
  success: 'success',
  progress: 'primary'
};

const styleIcon = {
  fontSize: '24px',
  marginRight: '10px',
  position: 'relative',
  top: '1px'
};

const styleText = {
  fontSize: '16px',
  position: 'relative',
  top: '-2px'
};

const styleMessage = {
  display: 'block',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left'
};

export default class Toaster extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    notification: PropTypes.object.isRequired,
    removeNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      in: false
    };
  }

  onExited() {
    this.props.removeNotification({
      id: this.props.notification.get('id')
    });
  }

  renderIcon() {
    const type = this.props.notification.get('type');
    return (
      <span className={`fa ${icons[type]}`} style={styleIcon} />
    );
  }

  renderText() {
    return (
      <span style={styleText}>
        {this.props.notification.get('message')}
      </span>
    );
  }

  renderMessage() {
    const type = this.props.notification.get('type');
    return (
      <Button variant={classes[type]} style={styleMessage} onClick={this.onExited}>
        {this.renderIcon()}
        {this.renderText()}
      </Button>
    );
  }

  render() {
    const type = this.props.notification.get('type');
    const style = {
      position: 'absolute',
      right: '10px',
      height: this.props.height,
      bottom: this.props.index * this.props.height,
      width: '100%'
    };
    return (
      <div className={'toaster toaster-' + type} style={style}>
        <Fade in={!this.props.notification.get('removed')} onExited={this.onExited}>
          {this.renderMessage()}
        </Fade>
      </div>
    );
  }
}
