import Immutable from 'immutable';
import createCategoryMatterIfNeeded from './createCategoryMatterIfNeeded';
import fetchDocumentsIfNeeded from '../document/fetchDocumentsIfNeeded';
import deleteDocumentFromMatter from '../document/deleteDocumentFromMatter';

export default (category) => (dispatch, getState) => {
  return dispatch(createCategoryMatterIfNeeded(category))
    .then((matter) => {
      return dispatch(fetchDocumentsIfNeeded(matter)).then(() => {
        const clientNumber = matter.get('clientNumber');
        const cited = matter.get('citedArt', Immutable.List());
        const uncited = matter.get('uncitedArt', Immutable.List());
        const docNumbers = cited.concat(uncited).toJS();
        const docs = getState().entities.getIn(['documents', clientNumber]);
        const docsWithoutCategory = docNumbers.map(docNumber => docs.get(docNumber))
          .filter(doc => !doc.get('categories').includes(category));

        let promise = Promise.resolve();
        docsWithoutCategory.forEach(doc => {
          promise = promise.then(() => (
            dispatch(deleteDocumentFromMatter(matter.get('id'), doc.get('documentNumber')))
          ));
        });
        return promise.then(() => matter);
      });
    });
};
