import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import { Button } from 'react-bootstrap';

export default class AutoGold extends Component {
  static propTypes = {
    uploadAutoGoldData: PropTypes.func.isRequired

  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
    };
  }

  componentDidUpdate() {
    /* eslint react/no-did-update-set-state: 0 */
    if (this.state.reloadInput) {
      this.setState({ reloadInput: false });
    }
  }

  onUploadAutoGoldChange(e) {
    const file = e.target.files[0];
    this.props.uploadAutoGoldData(file);
    // reset so users can upload the same file again
    e.target.value = null;
    this.setState({ reloadInput: true });
  }

  assignRefUpload(element) {
    this.uploadRef = element;
  }

  onClickUploadAutoGoldInput() {
    this.uploadRef.click();
  }

  renderUploadInput() {
    if (this.state.reloadInput) {
      return;
    }

    return (
      <input
        ref={this.assignRefUpload}
        name='uploadAutoGold'
        type='file'
        accept='.txt'
        onChange={this.onUploadAutoGoldChange}
        style={{ display: 'none' }}
      />
    );
  }

  render() {
    return (
      <CollapsiblePanel id='auto-gold' header='Auto-Gold'>
        <div className='row'>
          <div className='col-md-12'>
            <Button
              variant='primary'
              onClick={this.onClickUploadAutoGoldInput}
            >
               Upload
            </Button>
            {this.renderUploadInput()}
          </div>
        </div>
      </CollapsiblePanel>
    );
  }
};
