import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import autoBind from 'class-autobind';

export default class FamilyModal extends Component {
  static propTypes = {
    onDownloadExcelReport: PropTypes.func.isRequired,
    onViewFamily: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true
    };
  }

  onCancel() {
    this.setState({ show: false });
    this.props.onCancel();
  }

  onClickView() {
    this.setState({ show: false });
    this.props.onViewFamily();
  }

  onClickDownload() {
    this.setState({ show: false });
    this.props.onDownloadExcelReport();
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.onCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ textAlign: 'left' }}>
            Confirmation Required
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'left' }}>
          What do you want to do next?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={this.onCancel}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={this.onClickView}
          >
            View Family
          </Button>
          <Button
            autoFocus
            variant='primary'
            onClick={this.onClickDownload}
          >
            Download Excel Report
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
