import { uid } from 'uid';
import { createAction } from 'redux-actions';
import NearDuplicateWorker from '../../../workers/near-duplicate';
import {
  NEAR_DUPLICATE_BEGIN,
  NEAR_DUPLICATE_ERROR,
  NEAR_DUPLICATE_SUCCESS,
  NEAR_DUPLICATE_PROGRESS
} from '../../constants/actions';

const nearDuplicateBegin = createAction(NEAR_DUPLICATE_BEGIN);
const nearDuplicateSuccess = createAction(NEAR_DUPLICATE_SUCCESS);
const nearDuplicateError = createAction(NEAR_DUPLICATE_ERROR);
const nearDuplicateProgress = createAction(NEAR_DUPLICATE_PROGRESS);

export default (firmName, clientNumber, params) => (dispatch, getState) => {
  const { auth: { token } } = getState();
  const worker = new NearDuplicateWorker();
  const workerId = uid();

  worker.addEventListener('message', (e) => {
    if (e.data.log) {
      console.log(e.data.log);
      return;
    }

    const { nearDuplicate } = getState();
    if (nearDuplicate.workerId === workerId) {
      if (e.data.groups) {
        dispatch(nearDuplicateSuccess(e.data.groups));
        worker.terminate();
      } else if (e.data.progress) {
        dispatch(nearDuplicateProgress(e.data.progress));
      } else if (e.data.error) {
        dispatch(nearDuplicateError(e.data.error));
        worker.terminate();
      }
    } else {
      worker.terminate();
    }
  });

  worker.postMessage({
    workerId,
    token,
    firmName,
    clientNumber,
    params
  });

  dispatch(nearDuplicateBegin({ params, workerId }));
};
