import { handleActions } from 'redux-actions';

import {
  TODOS_SELECT_PAGE,
  TODOS_SET_PAGE_SIZE,
  TODOS_FETCH_PARAMS
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [TODOS_SELECT_PAGE]: (state, { payload }) => (payload),

  [TODOS_FETCH_PARAMS]: () => (initialState),

  [TODOS_SET_PAGE_SIZE]: () => (initialState)

}, initialState);
