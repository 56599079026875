import React from 'react';
import AdminContainer from '../admin/AdminContainer';

export default class AdminView extends React.Component {
  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  render() {
    return (
      <AdminContainer />
    );
  }
}
