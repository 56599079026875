import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button, Nav, NavItem } from 'react-bootstrap';
import Field from '../../shared/Field';

export default class PDFBrowser extends Component {
  static propTypes = {
    uploading: PropTypes.bool,
    bookmark: PropTypes.object,
    bookmarks: PropTypes.array,
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onSelectBookmark: PropTypes.func.isRequired,
    pdf: PropTypes.object,
    renderPDFBookmark: PropTypes.func.isRequired,
    renderPDFPage: PropTypes.func.isRequired,
    selectedBookmark: PropTypes.number.isRequired,
    queue: PropTypes.array.isRequired,
    uploaded: PropTypes.array.isRequired,
    onUpload: PropTypes.func.isRequired,
    removeFromQueue: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.selectedBookmark >= 0 &&
      props.selectedBookmark !== state.selectedBookmark &&
      props.bookmarks &&
      props.bookmarks.length > 0
    ) {
      return {
        reloadCanvas: state.selectedBookmark >= 0,
        selectedBookmark: props.selectedBookmark
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      reloadCanvas: false
    };
  }

  componentDidUpdate() {
    if (this.state.reloadCanvas) {
      /* eslint react/no-did-update-set-state: 0 */
      this.setState({ reloadCanvas: false });
    }
    if (this.state.selectedBookmark >= 0) {
      this.scrollToSelected();
    }
  }

  assignRefBookmark(element) {
    this.bookmarkRef = element;
  }

  scrollToSelected() {
    const container = this.bookmarkRef;
    const height = container.getBoundingClientRect().height;
    const scrollHeight = Number(container.scrollHeight);
    const scrollTop = Number(container.scrollTop);
    const element = container.querySelector(`li:nth-child(${this.state.selectedBookmark + 1})`);
    if (element) {
      const position = element.offsetTop - container.offsetTop;
      const isVisible = position > scrollTop && position < scrollTop + height;
      if (!isVisible) {
        container.scrollTop = Math.min(position - (height / 2), scrollHeight - height);
      }
    }
  }

  renderPDFPage(canvasElm) {
    if (this.props.bookmark && canvasElm) {
      this.props.renderPDFPage(
        this.props.pdf,
        this.props.bookmark.page,
        canvasElm
      );
    }
  }

  onClickNext() {
    this.props.onNext();
  }

  onClickPrev() {
    this.props.onPrevious();
  }

  onClickDownload() {
    const { bookmarks, pdf, selectedBookmark } = this.props;
    const bookmark = bookmarks[selectedBookmark];
    this.props.renderPDFBookmark(pdf, bookmark, bookmark.title.replace(/[^\w]/g, '_'));
  }

  isDownloadEnabled() {
    return (
      this.props.pdf &&
      this.props.bookmarks &&
      this.props.selectedBookmark !== -1 &&
      this.props.bookmarks[this.props.selectedBookmark]
    );
  }

  renderQueued(queued) {
    return (
      <span style={{ marginRight: '5px' }}>
        <span
          className='fa fa-times'
          style={{ marginRight: '5px' }}
          onClick={() => this.props.removeFromQueue(queued)}
        />
        [{queued.doc.get('documentNumber')}]
      </span>
    );
  }

  renderUploaded(doc) {
    return (
      <span style={{ marginRight: '5px' }}>
        <span className='fa fa-check text-success' style={{ marginRight: '5px' }} />
        [{doc.get('documentNumber')}]
      </span>
    );
  }

  renderBookmark(bookmark, isActive) {
    const queued = this.props.queue
      .filter(item => item.bookmark.page === bookmark.page && item.bookmark.endPage === bookmark.endPage)
      .shift();
    const uploaded = this.props.uploaded
      .filter(item => item.bookmark.page === bookmark.page && item.bookmark.endPage === bookmark.endPage)
      .shift();
    return (
      <div
        title={bookmark.title}
        style={{ overflow: 'hidden', whiteSpace: 'nowrap', width: '100%', textOverflow: 'ellipsis' }}
      >
        {queued ? this.renderQueued(queued) : null}
        {uploaded ? this.renderUploaded(uploaded.doc) : null}
        {bookmark.title}
      </div>
    );
  }

  renderButtons() {
    return (
      <div style={{ paddingBottom: '10px' }}>
        <Button
          disabled={this.props.selectedBookmark === 0}
          style={{ width: '30%', marginRight: '5%' }}
          size='sm'
          variant='secondary'
          onClick={this.onClickPrev}
        >
          &lt; Previous
        </Button>
        <Button
          disabled={!this.isDownloadEnabled()}
          style={{ width: '30%', marginRight: '5%' }}
          size='sm'
          variant='secondary'
          onClick={this.onClickDownload}
        >
          View PDF
        </Button>
        <Button
          disabled={this.props.selectedBookmark === this.props.bookmarks.length - 1}
          style={{ width: '30%' }}
          size='sm'
          variant='secondary'
          onClick={this.onClickNext}
        >
          Next &gt;
        </Button>
      </div>
    );
  }

  renderUpload() {
    return (
      <Button
        disabled={this.props.uploading || this.props.queue.length === 0}
        style={{ width: '100%' }}
        size='sm'
        variant='secondary'
        onClick={this.props.onUpload}
      >
        {this.props.uploading ? 'Saving...' : `Save Matches (${this.props.queue.length})`}
      </Button>
    );
  }

  renderBookmarks() {
    return this.props.bookmarks && (
      <div style={{ width: '35%', float: 'left' }}>
        <Field label='Bookmarks'>
          <div>
            {this.renderButtons()}
            <div
              ref={this.assignRefBookmark}
              style={{ overflow: 'auto', height: '380px', marginBottom: '15px' }}
            >
              <Nav
                variant='pills'
                className='flex-column'
                activeKey={this.props.selectedBookmark}
                onSelect={this.props.onSelectBookmark}
              >
                {this.props.bookmarks.map((bookmark, index) => (
                  <NavItem key={index} eventKey={index}>
                    {this.renderBookmark(bookmark, this.props.selectedBookmark === index)}
                  </NavItem>
                ))}
              </Nav>
            </div>
            {this.renderUpload()}
          </div>
        </Field>
      </div>
    );
  }

  canvasRef(elm) {
    this.renderPDFPage(elm);
  }

  onMouseDown(e) {
    this.setState({
      scrolling: {
        top: e.pageY,
        left: e.pageX,
        scrollTop: e.target.parentNode.scrollTop,
        scrollLeft: e.target.parentNode.scrollLeft
      }
    });
  }

  onMouseUp() {
    this.setState({
      scrolling: null
    });
  }

  onMouseMove(e) {
    if (this.state.scrolling) {
      const movedTop = this.state.scrolling.top - e.pageY;
      const movedLeft = this.state.scrolling.left - e.pageX;
      e.target.parentNode.scrollTop = this.state.scrolling.scrollTop + movedTop;
      e.target.parentNode.scrollLeft = this.state.scrolling.scrollLeft + movedLeft;
    }
  }

  renderCanvas() {
    const stylePDF = {
      cursor: '-webkit-grab',
      overflow: 'auto',
      width: '62%',
      height: '500px',
      marginLeft: '20px',
      float: 'left'
    };

    return (
      <div style={stylePDF}>
        <Field label='Front Page'>
          <div
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            onMouseUp={this.onMouseUp}
            onMouseLeave={this.onMouseUp}
          >
            {this.state.reloadCanvas || <canvas ref={this.canvasRef} />}
          </div>
        </Field>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderBookmarks()}
        {this.renderCanvas()}
      </div>
    );
  }
}
