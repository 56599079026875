import { createAction } from 'redux-actions';
import { CLIENT_MATTER_SELECT } from '../../constants/actions';
import fetchClientIfNeeded from '../client/fetchClientIfNeeded';
import fetchMatterIfNeeded from '../matter/fetchMatterIfNeeded';
import fetchMatterListIfNeeded from '../matter/fetchMatterListIfNeeded';
import toggleClientMatter from './toggleClientMatter';

const selectClientAndMatter = createAction(CLIENT_MATTER_SELECT);

export default (payload) => (dispatch, getState) => {
  const {
    selectedClientId: currentClientId,
    selectedMatterId: currentMatterId
  } = getState().app;

  return Promise.resolve()
    .then(() => {
      if (currentClientId !== payload.clientId) {
        return dispatch(fetchClientIfNeeded(payload.clientId));
      }
    })
    .then(() => {
      if (currentClientId !== payload.clientId) {
        return dispatch(fetchMatterListIfNeeded(payload.clientId));
      }
    })
    .then(() => {
      if (currentMatterId !== payload.matterId) {
        dispatch(fetchMatterIfNeeded(payload.matterId));
      }
    })
    .then(() => {
      dispatch(selectClientAndMatter(payload));
      dispatch(toggleClientMatter(true));
    });
};
