import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import SimpleSuggest from '../shared/SimpleSuggest';
import Immutable from 'immutable';
import { splitCommaUnique } from '../../utils/text-utils';

export default class TodosFilterInput extends Component {
  static propTypes = {
    defaultTerm: PropTypes.string,
    setTerm: PropTypes.func.isRequired,
    term: PropTypes.string.isRequired,
    filterType: PropTypes.string.isRequired,
    client: PropTypes.instanceOf(Immutable.Map),
    searchNplDocuments: PropTypes.func.isRequired,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (props.term !== state.term) {
      return {
        term: props.term,
        value: props.term
      };
    }

    if (props.term !== state.defaultTerm) {
      return {
        term: props.term,
        defaultTerm: props.term
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      value: this.props.term || this.props.defaultTerm
    };
  }

  getValue() {
    const value = this.state.value;

    return ['document', 'query'].includes(this.props.filterType)
      ? value
      : value.trim();
  }

  searchNpl(value) {
    const client = this.props.client;
    if (client) {
      const firmName = client.get('firmName');
      const clientNumber = client.get('clientNumber');
      this.props.searchNplDocuments(firmName, clientNumber, value);
    }
  }

  onChange(value) {
    if (this.props.filterType === 'document') {
      this.searchNpl(value);
    }

    this.setState({ value });
  }

  onSubmit(value) {
    this.setState({
      value: value || this.props.defaultTerm
    }, () => {
      let term = this.getValue();

      if (this.props.filterType === 'document' && /^NPL[0-9]+ -/.test(term)) {
        term = term.split(' - ').shift();
      }

      this.props.setTerm(term);
    });
  }

  getSuggestions() {
    switch (this.props.filterType) {
      case 'category':
        if (this.props.client) {
          return Immutable.List(splitCommaUnique(this.props.client.get('clientCategories')));
        }
        return Immutable.List();
      case 'document':
        return this.props.nplSuggestions
          ? Immutable.List(this.props.nplSuggestions.valueSeq())
            .map(doc => doc.get('documentNumber') + ' - ' + doc.get('nonPatentBib'))
          : Immutable.List();
      default:
        return Immutable.List();
    }
  }

  render() {
    return (
      <SimpleSuggest
        styleOuter={{
          height: '30px',
          marginLeft: '6px',
          flex: '1 1 auto'
        }}
        style={{
          height: '30px'
        }}
        id='todos-filter-input'
        value={this.getValue()}
        className='form-control input-sm'
        allSuggestions={this.getSuggestions()}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        styleDropdown={{ marginTop: '35px' }}
        spellCheck={false}
        submitOnEnter
        submitOnBlur
      />
    );
  }
};
