import { handleActions } from 'redux-actions';
import Cookies from 'js-cookie';

import {
  AUTH_LOGOUT,
  AUTH_LOGIN_SUCCESS
} from '../../constants/actions';

const initialState = Cookies.get('syncids-remember') || '';

export default handleActions({

  [AUTH_LOGIN_SUCCESS]: (state, { payload }) => (
    payload.rememberMe ? payload.email : ''
  ),

  [AUTH_LOGOUT]: () => Cookies.get('syncids-remember') || ''

}, initialState);
