import { handleActions } from 'redux-actions';

import {
  MIGRATION_UPLOAD_TWOWAY,
  MIGRATION_CLEAR_TWOWAY,
  MIGRATION_CONFIRM_TWOWAY
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [MIGRATION_UPLOAD_TWOWAY]: (state, { payload }) => (payload),
  [MIGRATION_CONFIRM_TWOWAY]: (state, { payload }) => (payload),
  [MIGRATION_CLEAR_TWOWAY]: () => (initialState)

}, initialState);
