import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import { Button } from 'react-bootstrap';
import _sortBy from 'lodash/sortBy';
import _every from 'lodash/every';

const styleTextarea = {
  resize: 'none',
  width: '100%'
};

export default class TwoWayMigration extends Component {
  static propTypes = {
    clientList: PropTypes.instanceOf(Immutable.List).isRequired,
    matterLists: PropTypes.instanceOf(Immutable.Map).isRequired,
    families: PropTypes.array,
    clearTwoWayMigration: PropTypes.func.isRequired,
    confirmTwoWayMigration: PropTypes.func.isRequired,
    uploadTwoWayMigration: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      value: ''
    };
  }

  componentDidUpdate() {
    /* eslint react/no-did-update-set-state: 0 */
    if (this.state.reloadInput) {
      this.setState({ reloadInput: false });
    }
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  onUploadFile(uploadEvent) {
    const file = uploadEvent.target.files[0];
    const reader = new FileReader();
    const onLoad = (callback) => (loadEvent) => {
      callback(loadEvent.target.result);
      this.setState({ reloadInput: true });
    };

    reader.onload = onLoad(this.props.uploadTwoWayMigration);
    reader.readAsText(file);
  }

  assignRefUpload(element) {
    this.uploadRef = element;
  }

  onClickUpload() {
    this.uploadRef.click();
  }

  onClickProcess() {
    this.props.uploadTwoWayMigration(this.state.value);
  }

  onClickClear() {
    this.setState({ value: '', reloadInput: true });
    this.props.clearTwoWayMigration();
  }

  onClickConfirm() {
    const families = this.getFamilies().map(family => Object.assign({}, family, {
      matterNumbers: family.matterNumbers.filter(matterNumber => (
        this.isValid(family.clientNumber, matterNumber)
      ))
    }));

    this.props.confirmTwoWayMigration(families)
      .then(() => this.onClickClear());
  }

  isDone() {
    return _every(this.props.families, item => item.status === 'Synced');
  }

  isValid(clientNumber, matterNumber) {
    const client = this.props.clientList.find(client => client.get('clientNumber') === clientNumber);
    if (client) {
      const matterList = this.props.matterLists.get(client.get('id'));
      if (matterList) {
        return Boolean(matterList.find(matter => matter.get('matterNumber') === matterNumber));
      }
    }
    return false;
  }

  getFamilies() {
    return _sortBy(this.props.families, (item) => item.status === 'Failed' ? 0 : 1);
  }

  renderForm() {
    return (
      <div style={{ padding: '20px 10px' }}>
        <textarea
          className='form-control input-sm'
          rows={10}
          cols={50}
          value={this.state.value}
          onChange={this.onChange}
          spellCheck={false}
          style={styleTextarea}
        />
      </div>
    );
  }

  renderFamilies() {
    return (
      <div style={{ paddingTop: '15px' }}>
        {this.getFamilies().map((item, i, list) => {
          const color = {
            Synced: 'success',
            Failed: 'danger'
          }[item.status];
          return (
            <div key={i}>
              <div style={{ padding: '0 20px' }}>
                <h5>
                  Family: {item.family}
                  {item.status && (
                    <span
                      className={`btn btn-xs btn-secondary btn-${color}`}
                      style={{ margin: '0 0 0 10px' }}
                    >
                      {item.status}
                    </span>
                  )}
                </h5>
                <h5>Client Number: {item.clientNumber}</h5>
                {item.matterNumbers.map(matterNumber => (
                  <span
                    key={matterNumber}
                    className='btn btn-xs btn-secondary'
                    style={{ margin: '5px 10px 5px 0' }}
                  >
                    {matterNumber}
                    &nbsp;
                    <span className={this.isValid(item.clientNumber, matterNumber)
                      ? 'fa fa-check text-success'
                      : 'fa fa-times text-danger'}
                    />
                  </span>
                ))}
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    );
  }

  renderBody() {
    if (this.props.families) {
      return this.renderFamilies();
    }

    return this.renderForm();
  }

  renderUploadInput() {
    if (this.state.reloadInput) {
      return;
    }
    return (
      <input
        ref={this.assignRefUpload}
        name='file'
        type='file'
        accept='.txt'
        onChange={this.onUploadFile}
        style={{ display: 'none' }}
      />
    );
  }

  renderProcess() {
    return (
      <Button variant='secondary' onClick={this.onClickProcess}>
        Process
      </Button>
    );
  }

  renderUpload() {
    return (
      <div>
        {this.renderUploadInput()}
        <Button variant='secondary' onClick={this.onClickUpload}>
          Upload
        </Button>
      </div>
    );
  }

  renderConfirm() {
    return (
      <Button variant='primary' onClick={this.onClickConfirm} disabled={!this.props.families}>
        Confirm
      </Button>
    );
  }

  renderClear() {
    return (
      <Button variant='secondary' onClick={this.onClickClear} style={{ marginRight: '15px' }}>
        Clear
      </Button>
    );
  }

  renderInput() {
    if (this.state.value) {
      return this.renderProcess();
    }
    return this.renderUpload();
  }

  render() {
    return (
      <CollapsiblePanel id='two-way-migration' header='Two-Way Sync'>
        {this.renderBody()}
        <div className='row'>
          <div className='col-md-4'>
            <div style={{ textAlign: 'left', padding: '0 8px 15px 8px' }}>
              {this.props.families ? null : this.renderInput()}
            </div>
          </div>
          <div className='col-md-8'>
            <div style={{ textAlign: 'right', padding: '0 8px 15px 8px' }}>
              {this.renderClear()}
              {this.renderConfirm()}
            </div>
          </div>
        </div>
      </CollapsiblePanel>
    );
  }
}
