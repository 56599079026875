import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { OverlayTrigger, Popover, Dropdown } from 'react-bootstrap';

const styleHelp = {
  position: 'absolute',
  marginTop: '3px',
  right: '0',
  marginRight: '20px'
};

export default class MenuItemHelp extends Component {
  static propTypes = {
    style: PropTypes.object,
    eventKey: PropTypes.string,
    id: PropTypes.string.isRequired,
    help: PropTypes.node,
    label: PropTypes.node.isRequired,
    placement: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    styleLink: PropTypes.object,
    disabled: PropTypes.bool,
    labelClass: PropTypes.string,
    helpIcon: PropTypes.string
  };

  static defaultProps = {
    placement: 'right',
    helpIcon: 'fa-question-circle'
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderTooltip() {
    return (
      <Popover id={this.props.id}>
        <div style={{ padding: '5px 10px' }}>
          {this.props.help}
        </div>
      </Popover>
    );
  }

  onClick(e) {
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick(this.props.eventKey, e);
    }
  }

  renderHelp() {
    if (!this.props.help) {
      return;
    }
    return (
      <OverlayTrigger placement={this.props.placement} overlay={this.renderTooltip()}>
        <span style={styleHelp} className={`fa ${this.props.helpIcon}`} />
      </OverlayTrigger>
    );
  }

  getStyle() {
    return Object.assign(
      {
        cursor: this.props.disabled ? 'not-allowed' : 'pointer'
      },
      this.props.styleLink,
      this.props.style
    );
  }

  render() {
    return (
      <Dropdown.Item
        onClick={this.onClick}
        style={this.getStyle()}
        eventKey={this.props.eventKey}
      >
        <span className={this.props.labelClass}>{this.props.label}</span>
        {this.renderHelp()}
      </Dropdown.Item>
    );
  }
};
