import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Button } from 'react-bootstrap';
import DocumentPDF from '../../documents/DocumentPDF';
import DocumentDescription from '../../documents/DocumentDescription';
import DocumentRecord from '../../../models/DocumentRecord';
import Field from '../../shared/Field';
import _get from 'lodash/get';

export default class NPLBrowser extends Component {
  static propTypes = {
    bookmark: PropTypes.object,
    clearNplDocuments: PropTypes.func.isRequired,
    doc: PropTypes.instanceOf(DocumentRecord).isRequired,
    documents: PropTypes.instanceOf(Immutable.List),
    uploadFile: PropTypes.func.isRequired,
    filterPDF: PropTypes.bool.isRequired,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map),
    onChangeFilterPDF: PropTypes.func.isRequired,
    onMatch: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onSearchNPL: PropTypes.func.isRequired,
    onSelectDocument: PropTypes.func.isRequired,
    onUpdateDocument: PropTypes.func.isRequired,
    onFetchDocument: PropTypes.func.isRequired,
    selectedDocument: PropTypes.number.isRequired,
    updateDocument: PropTypes.func.isRequired,
    client: PropTypes.string.isRequired,
    features: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
    };
  }

  onClickPrev() {
    this.props.onPrevious();
  }

  onClickNext() {
    this.props.onNext();
  }

  onClickClear() {
    if (this.isLoadEnabled()) {
      this.props.onSelectDocument(0);
    } else {
      this.props.onSelectDocument(-1);
    }
  }

  isMatchEnabled() {
    return (
      this.props.doc &&
      this.props.bookmark &&
      !!this.props.doc.get('documentNumber')
    );
  }

  onSearchDescription(value) {
    if (this.props.selectedDocument !== -1) {
      return;
    }
    this.props.onSearchNPL(value);
  }

  onChangeNumber(event) {
    const newDoc = this.props.doc.set('documentNumber', event.target.value);
    this.props.onUpdateDocument(newDoc);
  }

  onBlurNumber() {
    this.props.onFetchDocument();
  }

  onChangeDescription(doc, field, newValue, matchDoc) {
    let newDoc;
    if (matchDoc) {
      newDoc = doc.set(field, newValue);
      ['id', 'nonPatentBib', 'documentNumber'].forEach(key => {
        newDoc = newDoc.set(key, matchDoc.get(key));
      });
    } else {
      newDoc = new DocumentRecord().set(field, newValue);
    }
    this.props.onUpdateDocument(newDoc);
  }

  onChangePDF(doc, field, value) {
    const newDoc = this.props.doc.set(field, value);

    this.props.updateDocument({
      id: doc.get('id'),
      [field]: value
    }, doc.get('documentNumber')).then(() => {
      this.props.onUpdateDocument(newDoc);
    });
  }

  onUploadPDF(doc, file, mode) {
    this.props.uploadFile(doc, file, mode).then((response) => {
      const data = _get(response, [
        'entities',
        'documents',
        this.props.client,
        doc.get('documentNumber')
      ]);
      this.props.onUpdateDocument(new DocumentRecord(data));
    });
  }

  onChangeFilterPDF(e) {
    this.props.onChangeFilterPDF(e.target.checked);
  }

  getSuggestions() {
    return this.props.nplSuggestions;
  }

  renderCheckbox() {
    return (
      <label>
        <input
          type='checkbox'
          name='filter'
          value={this.props.filterPDF}
          onChange={this.onChangeFilterPDF}
          spellCheck={false}
        />
        &nbsp;
        Only search descriptions with no PDF
      </label>
    );
  }

  renderNumber() {
    return (
      <Field label='Doc #' style={{ width: '20%' }}>
        <input
          type='text'
          name='documentNumber'
          disabled={this.props.selectedDocument !== -1}
          value={this.props.doc.get('documentNumber') || ''}
          onChange={this.onChangeNumber}
          onBlur={this.onBlurNumber}
          spellCheck={false}
          className='form-control'
        />
      </Field>
    );
  }

  renderPDF() {
    return (
      <Field label='' style={{ width: '10%' }}>
        <DocumentPDF
          features={this.props.features}
          doc={this.props.doc}
          onChange={this.onChangePDF}
          onUpload={this.onUploadPDF}
        />
      </Field>
    );
  }

  renderDescription() {
    return (
      <Field label='Description'>
        <DocumentDescription
          features={this.props.features}
          disabled={this.props.selectedDocument !== -1}
          doc={this.props.doc}
          nplSuggestions={this.getSuggestions()}
          field='nonPatentBib'
          onChange={this.onChangeDescription}
          onSearch={this.onSearchDescription}
          clearNplDocuments={this.props.clearNplDocuments}
          changeOnSelect
          searchCurrentValue
        />
      </Field>
    );
  }

  renderMatch() {
    return (
      <Button
        style={{ width: '100%' }}
        disabled={!this.isMatchEnabled()}
        size='sm'
        variant='primary'
        onClick={this.props.onMatch}
      >
        Match
      </Button>
    );
  }

  isLoadEnabled() {
    return (
      this.props.selectedDocument === -1 &&
      this.props.documents &&
      this.props.documents.size
    );
  }

  renderButtons() {
    return (
      <Field label='' style={{ width: '70%', paddingRight: '20px' }}>
        <Button
          id='button-previous-document'
          disabled={this.props.selectedDocument <= 0}
          style={{ width: '30%', marginRight: '5%' }}
          size='sm'
          variant='secondary'
          onClick={this.onClickPrev}
        >
          &lt; Previous
        </Button>
        <Button
          style={{ width: '30%', marginRight: '5%' }}
          size='sm'
          variant='secondary'
          onClick={this.onClickClear}
        >
          {this.isLoadEnabled() ? 'Load' : 'Clear'}
        </Button>
        <Button
          id='button-next-document'
          disabled={this.props.documents && this.props.selectedDocument === this.props.documents.size - 1}
          style={{ width: '30%' }}
          size='sm'
          variant='secondary'
          onClick={this.onClickNext}
        >
          Next &gt;
        </Button>
      </Field>
    );
  }

  renderIndex() {
    if (this.props.selectedDocument === -1 || !this.props.documents || !this.props.documents.size) {
      return;
    }

    return (
      <div style={{ textAlign: 'right', paddingRight: '80px' }}>
        {`${this.props.selectedDocument + 1} / ${this.props.documents.size}`}
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <br />
            <br />
            {this.renderMatch()}
            <br />
            <br />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6' style={{ paddingRight: '0' }}>
            {this.renderButtons()}
            {this.renderNumber()}
            {this.renderPDF()}
            {this.renderIndex()}
          </div>
          <div className='col-md-6'>
            {this.renderDescription()}
            {this.renderCheckbox()}
          </div>
        </div>
      </div>
    );
  }
}
