import addBatchDocuments from './addBatchDocuments';
import addDocumentToMatter from './addDocumentToMatter';
import appendTranslationsToDocument from './appendTranslationsToDocument';
import batchUploadDocument from './batchUploadDocument';
import clearNearDuplicate from './clearNearDuplicate';
import clearNplDocuments from './clearNplDocuments';
import closeBatchUpload from './closeBatchUpload';
import confirmBatchUpload from './confirmBatchUpload';
import deleteDocumentFromClient from './deleteDocumentFromClient';
import deleteDocumentFromFamily from './deleteDocumentFromFamily';
import deleteDocumentFromMatter from './deleteDocumentFromMatter';
import fetchDocumentHistory from './fetchDocumentHistory';
import fetchDocuments from './fetchDocuments';
import fetchDocumentsIfNeeded from './fetchDocumentsIfNeeded';
import fetchFamilyDocuments from './fetchFamilyDocuments';
import fetchNearDuplicate from './fetchNearDuplicate';
import lookUpDocuments from './lookUpDocuments';
import refreshDocuments from './refreshDocuments';
import removeEquivalents from './removeEquivalents';
import removeNonPriorArt from './removeNonPriorArt';
import replaceDocNumber from './replaceDocNumber';
import resolveForeign from './resolveForeign';
import searchNotes from './searchNotes';
import searchNplDocuments from './searchNplDocuments';
import selectCitedFilter from './selectCitedFilter';
import selectNotesTab from './selectNotesTab';
import sortDocuments from './sortDocuments';
import updateDocument from './updateDocument';
import updateDocumentCategories from './updateDocumentCategories';
import updateDocumentCitedState from './updateDocumentCitedState';
import uploadFile from './uploadFile';

export default {
  addBatchDocuments,
  addDocumentToMatter,
  appendTranslationsToDocument,
  batchUploadDocument,
  clearNearDuplicate,
  clearNplDocuments,
  closeBatchUpload,
  confirmBatchUpload,
  deleteDocumentFromClient,
  deleteDocumentFromFamily,
  deleteDocumentFromMatter,
  fetchDocumentHistory,
  fetchDocuments,
  fetchDocumentsIfNeeded,
  fetchFamilyDocuments,
  fetchNearDuplicate,
  lookUpDocuments,
  refreshDocuments,
  removeEquivalents,
  removeNonPriorArt,
  replaceDocNumber,
  resolveForeign,
  searchNotes,
  searchNplDocuments,
  selectCitedFilter,
  selectNotesTab,
  sortDocuments,
  updateDocument,
  updateDocumentCategories,
  updateDocumentCitedState,
  uploadFile
};
