import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const style = {
  position: 'relative',
  top: '-5px',
  marginBottom: '0px'
};

export default class Help extends Component {
  static propTypes = {
    text: PropTypes.node,
    style: PropTypes.object,
    className: PropTypes.string
  };

  render() {
    return (
      <div
        style={{ ...style, ...this.props.style }}
        className={cx({ 'text-muted': true, [this.props.className]: true })}
      >
        {this.props.text}
      </div>
    );
  }
};
