import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';

const getNumber = (doc) => {
  if (doc.correctedDocumentNumber && doc.correctedDocumentNumber !== 'Unneeded' && doc.correctedDocumentNumber !== 'N/A') {
    return doc.correctedDocumentNumber;
  }
  if (doc.OCRDocNum && doc.OCRDocNum !== 'Unneeded' && doc.OCRDocNum !== 'N/A') {
    return doc.OCRDocNum;
  }
  return doc.textractDocumentNumber;
};

export default (type, file) => (dispatch, getState) => {
  const data = new FormData();
  data.append('file', file);

  dispatch(addNotification({
    id: 'upload-pdf-scraper',
    type: 'progress',
    message: 'Uploading...'
  }));

  return dispatch(callApi({
    url: {
      pdf: 'https://www.syncids.com/textract-nodejs/pdf-scraper/',
      ids: 'https://www.syncids.com/textract-nodejs/ids/'
    }[type],
    method: 'POST',
    data
  }))
    .then((response) => {
      dispatch(addNotification({
        id: 'upload-pdf-scraper',
        type: 'success',
        message: 'Upload complete!'
      }));

      const docs = {
        US: [],
        Foreign: [],
        Other: []
      };

      response.documents.forEach(file => {
        docs.US.push(...file.US.map(doc => {
          return {
            date: doc.publicationDate,
            number: getNumber(doc).substr(2),
            inventor: doc.publicationInventor,
            checked: doc.datesMatch && doc.inventorsMatch
          };
        }));

        docs.Foreign.push(...file.F.map(doc => {
          return {
            date: doc.publicationDate,
            number: getNumber(doc).substr(2),
            country: getNumber(doc).substr(0, 2),
            applicant: doc.publicationApplicant,
            checked: doc.datesMatch
          };
        }));
        docs.Other.push(...file.NPL.map(description => ({ description, checked: true })));
      });

      return docs;
    })
    .catch(() => {
      dispatch(addNotification({
        id: 'upload-pdf-scraper',
        type: 'error',
        message: 'Upload Failed'
      }));
    });
};
