import { createAction } from 'redux-actions';
import JSZip from 'jszip';
import {
  BATCH_UPLOAD_DOCUMENTS_LOAD
} from '../../constants/actions';

const batchUploadDocumentLoad = createAction(BATCH_UPLOAD_DOCUMENTS_LOAD);

export default (docType, file) => (dispatch) => {
  JSZip.loadAsync(file).then(zip => {
    dispatch(batchUploadDocumentLoad({ file, zip }));
  });
};
