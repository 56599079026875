import callApi from '../../utils/callApi';
import { statsUrl } from '../../constants/urls';

export default (data) => (dispatch, getState) => {
  const {
    action,
    misc,
    clientNumber,
    matterNumber,
    documentNumber
  } = data;

  return dispatch(callApi({
    url: statsUrl,
    method: 'post',
    data: {
      action,
      misc,
      clientNumber,
      matterNumber,
      documentNumber,
      firmName: getState().auth.firmName
    }
  }));
};
