import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import {
  NEAR_DUPLICATE_BEGIN,
  NEAR_DUPLICATE_SUCCESS,
  NEAR_DUPLICATE_ERROR,
  NEAR_DUPLICATE_CLEAR
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [NEAR_DUPLICATE_CLEAR]: () => (initialState),
  [NEAR_DUPLICATE_BEGIN]: () => (initialState),
  [NEAR_DUPLICATE_SUCCESS]: (state, { payload }) => (Immutable.fromJS(payload)),
  [NEAR_DUPLICATE_ERROR]: () => (Immutable.fromJS([]))

}, initialState);
