import { handleActions } from 'redux-actions';
import {
  NEAR_DUPLICATE_BEGIN,
  NEAR_DUPLICATE_CLEAR
} from '../../constants/actions';

const initialState = '';

export default handleActions({

  [NEAR_DUPLICATE_CLEAR]: () => initialState,
  [NEAR_DUPLICATE_BEGIN]: (state, { payload: { params: { nplSerialEnd } } }) => (nplSerialEnd)

}, initialState);
