import uniq from 'lodash/uniq';
import selectClient from '../client/selectClient';
import selectClientAndMatter from './selectClientAndMatter';

export default () => (dispatch, getState) => {
  const { entities, todos: { term } } = getState();

  const todoList = entities.get('todoList').toJS();

  let clientId;
  let matterId;

  const parts = term.split('|');
  if (parts.length === 2) {
    const [clientNumber, matterNumber] = parts;

    const matter = todoList
      .map(todo => entities.getIn(['matters', todo]))
      .find(matter => (
        matter.get('matterNumber') === matterNumber &&
        matter.get('clientNumber') === clientNumber
      ));

    if (matter) {
      clientId = matter.get('clientId');
      matterId = matter.get('matterId');
    }
  }

  const clientIds = todoList
    .map(todo => entities.getIn(['matters', todo]))
    .map(matter => matter.get?.('clientId'));

  if (!clientId && !matterId && uniq(clientIds).length === 1) {
    clientId = clientIds[0];

    if (todoList.length === 1) {
      matterId = todoList[0];
    }
  }

  if (clientId && matterId) {
    dispatch(selectClientAndMatter({ clientId, matterId }));
  } else {
    dispatch(selectClient(clientId));
  }
};
