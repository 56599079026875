import updateClient from '../client/updateClient';
import fetchClientIfNeeded from '../client/fetchClientIfNeeded';
import createCategoryMatterIfNeeded from './createCategoryMatterIfNeeded';
import { splitCommaUnique, joinCommaUnique } from '../../../utils/text-utils';

export default (clientId, category) => (dispatch, getState) => {
  return dispatch(fetchClientIfNeeded(clientId)).then(() => {
    const client = getState().entities.getIn(['clients', clientId]);
    const clientCategories = splitCommaUnique(client.get('clientCategories'));
    const updatedClient = {
      id: clientId,
      clientCategories: joinCommaUnique(clientCategories.concat(category))
    };

    return dispatch(updateClient(updatedClient, client.get('clientNumber')))
      .then(() => dispatch(createCategoryMatterIfNeeded(category)));
  });
};
