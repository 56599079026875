import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import ToastersContainer from '../toasters/ToastersContainer';
import { Nav } from 'react-bootstrap';
import UploadPaymentsContainer from './upload-payments/UploadPaymentsContainer';
import CollapsiblePanel from '../shared/CollapsiblePanel';
import SweepFilesContainer from './sweep-files/SweepFilesContainer';
import BatchMatterUpdateContainer from './batch-matter-update/BatchMatterUpdateContainer';
import TwoWayMigrationContainer from './two-way-migration/TwoWayMigrationContainer';
import NplMatchContainer from './npl-match/NplMatchContainer';
import BatchInputContainer from './batch-input/BatchInputContainer';
import Intro from './Intro';
import CheatersReportContainer from './cheaters-report/CheatersReportContainer';
import FirmClientSettingsContainer from './firm-client-settings/FirmClientSettingsContainer';
import AutoGoldContainer from './auto-gold/AutoGoldContainer';
import MonthlyInvoiceContainer from './monthly-invoice/MonthlyInvoiceContainer';
import InvoiceReminderContainer from './invoice-reminder/InvoiceReminderContainer';
import DatabaseContainer from './database/DatabaseContainer';
import FindNewMattersContainer from './find-new-matters/FindNewMattersContainer';

export default class Admin extends Component {
  static propTypes = {
    section: PropTypes.string,
    wideLayout: PropTypes.bool.isRequired,
    toggleWideLayout: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getSection() {
    if (this.props.section) {
      return this.props.section;
    }
    if (window.location.href.includes('/admin/section=')) {
      return window.location.href.split('/admin/section=').pop();
    }
  }

  renderSection() {
    switch (this.getSection()) {
      case 'upload-payments':
        return (
          <UploadPaymentsContainer />
        );
      case 'sweep-files':
        return (
          <SweepFilesContainer />
        );
      case 'batch-matter-update':
        return (
          <BatchMatterUpdateContainer />
        );
      case 'batch-input':
        return (
          <BatchInputContainer />
        );
      case 'two-way-sync':
        return (
          <TwoWayMigrationContainer />
        );
      case 'npl-match':
        return (
          <NplMatchContainer />
        );
      case 'cheaters-report':
        return (
          <CheatersReportContainer
            wideLayout={this.props.wideLayout}
            toggleWideLayout={this.props.toggleWideLayout}
          />
        );
      case 'firm-client-settings':
        return (
          <FirmClientSettingsContainer />
        );
      case 'auto-gold':
        return (
          <AutoGoldContainer />
        );
      case 'monthly-invoice':
        return (
          <MonthlyInvoiceContainer />
        );
      case 'invoice-reminder':
        return (
          <InvoiceReminderContainer />
        );
      case 'database':
        return (
          <DatabaseContainer />
        );
      case 'find-new-matters':
        return (
          <FindNewMattersContainer />
        );
      default:
        return (
          <Intro />
        );
    }
  }

  render() {
    return (
      <div
        className={this.props.wideLayout ? '' : 'container'}
        style={this.props.wideLayout ? { padding: '0 15px' } : null}
      >
        <div className='row'>
          <div className='col-lg-3'>
            <CollapsiblePanel id='admin-menu' header='Admin'>
              <br />
              <h6>Migration</h6>
              <Nav className='flex-column'>
                <Nav.Link href='#/admin/section=sweep-files'>Sweep Files</Nav.Link>
                <Nav.Link href='#/admin/section=batch-matter-update'>Batch Matter Update</Nav.Link>
                <Nav.Link href='#/admin/section=batch-input'>Batch Input</Nav.Link>
                <Nav.Link href='#/admin/section=two-way-sync'>Two Way Sync</Nav.Link>
                <Nav.Link href='#/admin/section=npl-match'>NPL/PDF Match</Nav.Link>
                <Nav.Link href='#/admin/section=auto-gold'>Auto-Gold</Nav.Link>
                <Nav.Link href='#/admin/section=find-new-matters'>Find New Matters</Nav.Link>
              </Nav>
              <hr />
              <h6>Accounting</h6>
              <Nav className='flex-column'>
                <Nav.Link href='#/admin/section=upload-payments'>Upload Payments</Nav.Link>
                <Nav.Link href='#/admin/section=cheaters-report'>Cheaters Report</Nav.Link>
                <Nav.Link href='#/admin/section=monthly-invoice'>Monthly Invoices</Nav.Link>
                <Nav.Link href='#/admin/section=invoice-reminder'>Invoice Reminder</Nav.Link>
              </Nav>
              <hr />
              <h6>Settings</h6>
              <Nav className='flex-column'>
                <Nav.Link href='#/admin/section=firm-client-settings'>Firm Settings</Nav.Link>
              </Nav>
              {this.props.features.adminDatabase && (
                <>
                  <hr />
                  <h6>Database</h6>
                  <Nav className='flex-column'>
                    <Nav.Link href='#/admin/section=database'>Transfer Matter</Nav.Link>
                  </Nav>
                  <br />
                </>
              )}
            </CollapsiblePanel>
          </div>
          <div className='col-lg-9'>
            {this.renderSection()}
          </div>
        </div>
        <ToastersContainer />
      </div>
    );
  }
}
