import { createAction } from 'redux-actions';
import fetchSyncOverlap from './fetchSyncOverlap';

import {
  LOAD_SYNC_OVERLAP_BEGIN,
  LOAD_SYNC_OVERLAP_SUCCESS,
  LOAD_SYNC_OVERLAP_ERROR
} from '../../constants/actions';

const loadSyncOverlapBegin = createAction(LOAD_SYNC_OVERLAP_BEGIN);
const loadSyncOverlapSuccess = createAction(LOAD_SYNC_OVERLAP_SUCCESS);
const loadSyncOverlapError = createAction(LOAD_SYNC_OVERLAP_ERROR);

export default ({ showModal, clientNumber }) => (dispatch, getState) => {
  const isLoading = () => {
    const state = getState();
    return state.migration.syncOverlap && state.migration.syncOverlap.isLoading;
  };
  if (isLoading()) {
    return Promise.resolve();
  }

  dispatch(loadSyncOverlapBegin({ isLoading: true, showModal }));

  return dispatch(fetchSyncOverlap(clientNumber))
    .then((response) => {
      if (isLoading()) {
        return dispatch(loadSyncOverlapSuccess({ isLoading: false, report: response, showModal }));
      }
    })
    .catch((error) => {
      if (isLoading()) {
        return dispatch(loadSyncOverlapError({ isLoading: false, error, showModal }));
      }
    });
};
