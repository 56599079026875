import { combineReducers } from 'redux';

import email from './email';
import token from './token';
import inProgress from './inProgress';
import isLoggedIn from './isLoggedIn';
import isOpen from './isOpen';
import message from './message';
import messageType from './messageType';
import mode from './mode';
import firmName from './firmName';
import rememberEmail from './rememberEmail';
import partnership from './partnership';

export default combineReducers({
  firmName,
  email,
  token,
  inProgress,
  isLoggedIn,
  isOpen,
  message,
  messageType,
  mode,
  rememberEmail,
  partnership
});
