import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import HelpIcon from '../shared/HelpIcon';
import NearDuplicateEdit from './NearDuplicateEdit';

const iconSpinner = (
  <span className='fa fa-spinner fa-spin' style={{ marginRight: '5px', float: 'left' }} />
);

const iconChecked = (
  <span className='fa fa-check text-success' style={{ marginRight: '5px', float: 'left' }} />
);

export default class NearDuplicateDocument extends Component {
  static propTypes = {
    document: PropTypes.instanceOf(Immutable.Map).isRequired,
    groupIndex: PropTypes.number.isRequired,
    isMuted: PropTypes.bool.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isRunning: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    onClickIgnore: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickRemove: PropTypes.func,
    onClickUndo: PropTypes.func,
    onSaveEdit: PropTypes.func,
    onCancelEdit: PropTypes.func,
    input: PropTypes.string.isRequired,
    showEdit: PropTypes.bool,
    showRemove: PropTypes.bool,
    showIgnore: PropTypes.bool,
    showUndo: PropTypes.bool
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClickIgnore() {
    this.props.onClickIgnore(this.props.groupIndex, this.props.document);
  }

  onClickDocument() {
    this.props.onSelect(this.props.groupIndex, this.props.document);
  }

  onClickEdit() {
    this.props.onClickEdit(this.props.groupIndex, this.props.document);
  }

  onClickRemove() {
    this.props.onClickRemove(this.props.groupIndex, this.props.document);
  }

  onClickUndo() {
    this.props.onClickUndo(this.props.groupIndex, this.props.document);
  }

  isRunning() {
    return this.props.isMuted && this.props.isRunning;
  }

  isComplete() {
    return this.props.isChecked && this.props.isComplete;
  }

  isEdit() {
    return !!this.props.document.get('edit');
  }

  renderInput() {
    return (
      <input
        type={this.props.input}
        name={`near-dup-group-${this.props.groupIndex}`}
        value={this.props.document.get('documentNumber')}
        checked={this.props.isChecked}
        onChange={this.onClickDocument}
        style={{ marginRight: '7px', float: 'left', marginTop: '5px' }}
      />
    );
  }

  getNumber() {
    const doc = this.props.document;
    if (doc.get('type') === 'Foreign') {
      return (doc.get('country') || '') + doc.get('documentNumber').replace(/^0/, '');
    }
    return this.props.document.get('documentNumber');
  }

  renderNumber() {
    const className = 'btn btn-xs ' + (this.props.isChecked ? 'btn-primary' : 'btn-secondary');
    return (
      <div
        className={className}
        style={{
          marginRight: '8px',
          padding: '0px 3px',
          float: 'left'
        }}
      >
        {this.getNumber()}
      </div>
    );
  }

  renderIconUndo() {
    return this.props.showUndo && (
      <HelpIcon
        icon='undo'
        help='Restore to original group'
        placement='top'
        styleIcon={{
          position: 'relative',
          marginLeft: '5px',
          top: '-1px',
          cursor: 'pointer'
        }}
        onClick={this.onClickUndo}
      />
    );
  }

  renderIconRemove() {
    return this.props.showRemove && (
      <HelpIcon
        icon='times'
        help='Remove from this group'
        placement='top'
        styleIcon={{
          position: 'relative',
          marginLeft: '5px',
          top: '-1px',
          cursor: 'pointer'
        }}
        onClick={this.onClickRemove}
      />
    );
  }

  renderIconEdit() {
    return this.props.showEdit && (
      <HelpIcon
        icon='pencil'
        help='Edit description'
        placement='top'
        styleIcon={{
          position: 'relative',
          marginLeft: '5px',
          top: '-1px',
          cursor: 'pointer'
        }}
        onClick={this.onClickEdit}
      />
    );
  }

  renderEdit() {
    return (
      <NearDuplicateEdit
        document={this.props.document}
        groupIndex={this.props.groupIndex}
        onSave={this.props.onSaveEdit}
        onCancel={this.props.onCancelEdit}
      />
    );
  }

  renderDescription() {
    const doc = this.props.document;
    switch (doc.get('type')) {
      case 'Other':
        return doc.get('nonPatentBib');
      case 'Foreign':
        if (doc.get('publicationDate') && doc.get('applicant')) {
          return doc.get('publicationDate') + ' - ' + this.props.document.get('applicant');
        } else {
          return doc.get('publicationDate') || doc.get('applicant');
        }
    }
  }

  renderIconIgnore() {
    return this.props.showIgnore && (
      <HelpIcon
        icon='ban'
        help='Do NOT match this document with this group again.'
        placement='top'
        styleIcon={{
          position: 'relative',
          marginLeft: '5px',
          top: '-1px',
          cursor: 'pointer'
        }}
        onClick={this.onClickIgnore}
      />
    );
  }

  renderLabel() {
    return (
      <div className={this.props.isMuted ? 'text-muted' : ''}>
        {this.renderNumber()}
        {this.isEdit() ? this.renderEdit() : this.renderDescription()}
        {!this.isEdit() && this.renderIconIgnore()}
        {!this.isEdit() && this.renderIconEdit()}
        {!this.isEdit() && this.renderIconRemove()}
        {!this.isEdit() && this.renderIconUndo()}
      </div>
    );
  }

  renderBullet() {
    if (this.isComplete()) {
      return iconChecked;
    }
    if (this.isRunning()) {
      return iconSpinner;
    }
    return this.renderInput();
  }

  render() {
    return (
      <div style={{ marginBottom: this.isComplete() ? '0' : '15px' }}>
        {this.renderBullet()}
        {this.renderLabel()}
        {this.isEdit() && <hr />}
      </div>
    );
  }
}
