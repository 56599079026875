import React, { Component } from 'react';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../shared/CollapsiblePanel';
import UploadPaymentsIntro from './upload-payments/UploadPaymentsIntro';
import SweepFilesIntro from './sweep-files/SweepFilesIntro';
import BatchMatterUpdateIntro from './batch-matter-update/BatchMatterUpdateIntro';
import NplMatchIntro from './npl-match/NplMatchIntro';

export default class Admin extends Component {
  static propTypes = {
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <CollapsiblePanel id='admin-intro' header='Admin Intro'>
        <SweepFilesIntro title />
        <hr />
        <BatchMatterUpdateIntro title />
        <hr />
        <NplMatchIntro title />
        <hr />
        <UploadPaymentsIntro title />
      </CollapsiblePanel>
    );
  }
}
