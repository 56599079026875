import CustomInput from './CustomInput';
import autoBind from 'class-autobind';

export default class ExpandableTextarea extends CustomInput {
  static defaultProps = {
    type: 'textarea',
    lang: 'en',
    rows: 1,
    expandRows: 3,
    spellCheck: true,
    style: {
      resize: 'none'
    }
  };

  static getDerivedStateFromProps = CustomInput.getDerivedStateFromProps;

  constructor(props) {
    super(props);
    autoBind(this);
    this.state.rows = props.rows;
  }

  onKeyDown(e) {
    if (e.which === 27) {
      e.target.blur();
    }
  }

  onFocus(e) {
    super.onFocus(e);
    if (this.props.rows !== this.props.expandRows) {
      this.setState({ rows: this.props.expandRows });
    }
  }

  onBlur(e) {
    super.onBlur(e);
    if (this.props.rows !== this.props.expandRows) {
      this.setState({ rows: 1 });
    }
  }
};
