import callApi from '../../utils/callApi';
import { createAction } from 'redux-actions';
import { LOAD_MATTER_NOTES } from '../../constants/actions';
import { matterNotesUrl } from '../../constants/urls';

const loadMatterNotes = createAction(LOAD_MATTER_NOTES);

export default (matterId) => (dispatch, getState) => {
  return dispatch(callApi({
    url: `${matterNotesUrl}/${matterId}`
  })).then((response) => {
    return dispatch(loadMatterNotes({
      matterId,
      notes: response
    }));
  });
};
