import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Dropdown, Button, DropdownButton } from 'react-bootstrap';
import CategoryItem from './CategoryItem';

const iconTagged = (
  <span className='fa text-success fa-tag' />
);

const iconTag = (
  <span className='fa fa-tag' />
);

const styleEmpty = {
  padding: '5px 15px',
  whiteSpace: 'nowrap'
};

const styleLabel = {
  float: 'left',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: '5px',
  textAlign: 'left'
};

export default class CategoriesInput extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    categories: PropTypes.instanceOf(Immutable.List).isRequired,
    onChange: PropTypes.func,
    compact: PropTypes.bool,
    greyedCategories: PropTypes.instanceOf(Immutable.List),
    selectedCategories: PropTypes.instanceOf(Immutable.List),
    viewCategory: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    disabled: PropTypes.bool
  };

  static getDerivedStateFromProps(props, state) {
    const selected = props.selectedCategories || Immutable.List();

    if (Immutable.is(selected, state.selectedProp) || state.open || state.changed) {
      return null;
    }

    return {
      open: false,
      changed: false,
      selected,
      unselected: Immutable.List(),
      selectedProp: selected
    };
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onSelectCategory(event, category) {
    let { selected, unselected } = this.state;

    if (selected.includes(category)) {
      if (selected.indexOf(category) !== -1) {
        selected = selected.delete(selected.indexOf(category));
      }
      unselected = unselected.push(category);
    } else {
      selected = selected.push(category);
      if (unselected.indexOf(category) !== -1) {
        unselected = unselected.delete(unselected.indexOf(category));
      }
    }

    this.justClicked = true;

    this.setState({
      selected,
      unselected,
      changed: !selected.equals(this.props.selectedCategories) || (
        this.props.greyedCategories &&
        this.props.greyedCategories.some(category => unselected.includes(category))
      )
    });

    if (this.props.onChange) {
      this.props.onChange(event, selected, unselected);
    }
  }

  onClick() {
    if (!this.props.disabled) {
      this.onToggle(true);
    }
  }

  onToggle(open) {
    if (this.justClicked) {
      this.justClicked = false;
      return;
    }

    if (open) {
      this.setState({ open: true });
    } else {
      this.onClickCancel();
    }
  }

  onClickCancel() {
    this.justClicked = false;

    this.setState({
      open: false,
      changed: false,
      selectedProp: null
    });

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onClickConfirm() {
    this.justClicked = false;

    this.setState({
      open: false,
      changed: false
    });

    if (this.props.onConfirm) {
      this.props.onConfirm(this.state.selected, this.state.unselected);
    }
  }

  onClickView(event, category) {
    event.preventDefault();
    event.stopPropagation();
    this.props.viewCategory(category);
  }

  getSelectedCategories() {
    const categories = this.state.selected;
    if (!categories) {
      return Immutable.List();
    }
    return categories.filter(category => (
      this.props.categories.includes(category)
    ));
  }

  getGreyedCategories() {
    const categories = this.props.greyedCategories;
    if (categories) {
      return categories.filter(category => (
        this.props.categories &&
        this.props.categories.includes(category) &&
        (!this.state.unselected || !this.state.unselected.includes(category))
      ));
    }
    return Immutable.List();
  }

  renderLabel() {
    const selectedCategories = this.getSelectedCategories();
    return (
      <div style={styleLabel}>
        {selectedCategories.size ? selectedCategories.join(', ') : '-'}
      </div>
    );
  }

  renderCategories() {
    let categories;
    if (this.props.categories.count()) {
      categories = this.props.categories.map(this.renderCategory, this);
    } else {
      categories = (
        <li style={styleEmpty}>
          No categories to list.
        </li>
      );
    }
    return categories;
  }

  renderCategory(category, index) {
    return (
      <CategoryItem
        key={index}
        greyed={this.getGreyedCategories().includes(category)}
        selected={this.state.selected && this.state.selected.includes(category)}
        onClickView={this.onClickView}
        onSelect={this.onSelectCategory}
        category={category}
      />
    );
  }

  renderCompact() {
    return this.getSelectedCategories().size ? iconTagged : iconTag;
  }

  renderButtons() {
    return (
      <Dropdown.Item>
        <Button
          variant='secondary'
          onClick={this.onClickCancel}
          size='sm'
        >
          Cancel
        </Button>
        &nbsp;&nbsp;
        <Button
          disabled={!this.state.changed}
          variant='primary'
          onClick={this.onClickConfirm}
          size='sm'
        >
          Confirm
        </Button>
      </Dropdown.Item>
    );
  }

  renderMenu() {
    return this.state.open && (
      <>
        {this.renderCategories()}
        <Dropdown.Divider />
        {this.renderButtons()}
      </>
    );
  }

  render() {
    return (
      <DropdownButton
        style={{ width: this.props.compact ? undefined : '100%' }}
        size='sm'
        variant='secondary'
        className='no-caret'
        show={this.state.open}
        onToggle={this.onToggle}
        autoClose='outside'
        title={this.props.compact ? this.renderCompact() : this.renderLabel()}
        id={this.props.id}
      >
        {this.renderMenu()}
      </DropdownButton>
    );
  }
}
