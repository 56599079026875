import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export default class PatentBotsModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  };

  render() {
    return (
      <Modal
        id='patent-bots-modal'
        onHide={this.props.onClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome to SyncIDS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>SyncIDS is a software and services company that organizes <strong>All Things IDS</strong>.</p>
          <p>As a customer of PatentBots, you have free access to certain sections of SyncIDS including its automated IDS package generator. To enjoy ALL sections, login to SyncIDS directly at <a href='https://www.syncids.com'>https://www.syncids.com</a> (a pay-per-IDS fee will apply).</p>
          <p>To learn more about SyncIDS and its many services including its All-In Package, book a quick demo here: <a href='https://www.syncids.com/#/demo'>https://www.syncids.com/#/demo</a></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={this.props.onClose}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
