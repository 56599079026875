import { handleActions } from 'redux-actions';
import { TODOS_FETCH_PARAMS } from '../../constants/actions';

const initialState = null;

export default handleActions({

  [TODOS_FETCH_PARAMS]: (state, { payload }) => (payload)

}, initialState);
