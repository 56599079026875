import { connect } from 'react-redux';
import actions from '../../redux/actions';
import Matter from './Matter';
import Immutable from 'immutable';

function mapStateToProps(state) {
  const {
    app: {
      features,
      selectedMatterId,
      selectedClientId,
      expandedClientMatter: expanded,
      selectedMatterTab
    },
    entities
  } = state;
  const client = entities.getIn(['clients', selectedClientId]) || Immutable.Map();
  const matter = entities.getIn(['matters', selectedMatterId]) || Immutable.Map();
  const matterList = entities.getIn(['matterList', selectedClientId]) || Immutable.List();
  const categories = Immutable.fromJS((client.get('clientCategories') || '').split(/ *, */));
  return {
    selectedMatterTab,
    features,
    client,
    matter,
    matterList,
    categories,
    expanded: !!expanded
  };
};

export default connect(mapStateToProps, actions)(Matter);
