import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import { Button, Table } from 'react-bootstrap';
import BatchMatterUpdateIntro from './BatchMatterUpdateIntro';

const styleTextarea = {
  resize: 'none',
  width: '100%'
};

export default class BatchMatterUpdate extends Component {
  static propTypes = {
    saveBatchMatterUpdate: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      value: '',
      data: null,
      loading: false,
      createMissing: false
    };
  }

  isConfirmEnabled() {
    const includes = (field) => this.state.data.cols.map(c => c.toLowerCase()).includes(field);
    return (
      this.state.data &&
      this.state.data.rows.length > 0 &&
      includes('clientnumber') && (includes('matternumber') || includes('applicationnumber'))
    );
  }

  onChangeCreateMissing() {
    this.setState({
      createMissing: !this.state.createMissing
    });
  }

  onClickConfirm() {
    this.setState({
      loading: true
    });

    this.props.saveBatchMatterUpdate(this.state.data.rows, this.state.createMissing)
      .finally(() => this.onClickClear());
  }

  onClickClear() {
    this.setState({
      value: '',
      data: null,
      loading: false,
      createMissing: false
    });
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  onClickProcess() {
    const cols = [];
    const rows = [];

    this.state.value.trim().split(/\n/).forEach((line, index) => {
      const vals = line.split(/[,\t\;]/);

      if (index === 0 && /^MATTER$/.test(line)) {
        cols.push(
          'clientNumber',
          'matterNumber',
          'applicationNumber',
          'firstInventor',
          'filingDate',
          'artUnit',
          'matterClosed',
          'confirmationNumber',
          'examinerName'
        );
      } else if (index === 0) {
        cols.push(...vals.map(val => val.trim()).map(val => {
          const col = val.substr(0, 1).toLowerCase() + val.substr(1);
          switch (col) {
            case 'alternateid':
            case 'alternateID':
              return 'alternateId';
            default:
              return col;
          }
        }));
      } else {
        if (/^\*------|^MATTER$/.test(line) || line === '') {
          return;
        }

        const row = {};
        cols.forEach((col, i) => {
          switch (col) {
            case 'matterClosed':
              row[col] = /closed/i.test(vals[i]) ? 'ON' : vals[i];
              break;
            case 'filingDate':
              row[col] = vals[i].replace(/-/g, '/');
              break;
            default:
              row[col] = vals[i];
              break;
          }
        });
        rows.push(row);
      }
    });

    this.setState({
      data: { cols, rows }
    });
  }

  renderForm() {
    return (
      <div style={{ padding: '20px 10px' }}>
        <textarea
          className='form-control input-sm'
          rows={10}
          cols={50}
          value={this.state.value}
          onChange={this.onChange}
          spellCheck={false}
          placeholder={`ClientNumber;MatterNumber;AlternateId
Acme;001;AAA
Acme;002;BBB
Acme;003;CCC`}
          style={styleTextarea}
        />
      </div>
    );
  }

  renderTable() {
    return (
      <div style={{ padding: '20px 10px' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              {this.state.data.cols.map((col) => (
                <th key={col}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.state.data.rows.map((doc, i) => {
              return (
                <tr key={i}>
                  {this.state.data.cols.map((col) => <td key={col}>{doc[col] || '-'}</td>)}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  renderBody() {
    if (this.state.data && this.state.data.cols.length) {
      return (
        <div className='row'>
          <div className='col-md-5'>
            {this.renderForm()}
          </div>
          <div className='col-md-7'>
            {this.renderTable()}
          </div>
        </div>
      );
    }

    return this.renderInput();
  }

  renderInput() {
    return (
      <div className='row'>
        <div className='col-md-12'>
          {this.renderForm()}
        </div>
      </div>
    );
  }

  renderProcess() {
    return (
      <Button style={{ float: 'left' }} onClick={this.onClickProcess}>
        Process
      </Button>
    );
  }

  renderConfirm() {
    return (
      <Button
        variant='primary'
        onClick={this.onClickConfirm}
        disabled={!this.isConfirmEnabled()}
      >
        Update
      </Button>
    );
  }

  renderCreateMissing() {
    return (
      <div
        style={{ float: 'left', marginLeft: '20px', marginTop: '7px' }}
      >
        <input
          type='checkbox'
          checked={this.state.createMissing}
          onChange={this.onChangeCreateMissing}
          style={{ marginRight: '5px' }}
        />
        Create missing clients and matters
      </div>
    );
  }

  renderSubmit() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button variant='secondary' onClick={this.onClickClear} style={{ marginRight: '15px' }}>
          Reset
        </Button>
        {this.renderConfirm()}
      </div>
    );
  }

  renderLoading() {
    return (
      <Button
        disabled
        variant='primary'
      >
        Processing...
      </Button>
    );
  }

  render() {
    return (
      <CollapsiblePanel id='batch-matter-update' header='Batch Matter Update'>
        <div className='row'>
          <div className='col-md-12'>
            <BatchMatterUpdateIntro />
            <hr />
          </div>
        </div>
        {this.renderBody()}
        <div className='row'>
          <div className='col-md-12'>
            <div style={{ textAlign: 'right', padding: '0 8px 15px 8px' }}>
              {this.renderProcess()}
              {this.renderCreateMissing()}
              {this.state.loading ? this.renderLoading() : this.renderSubmit()}
            </div>
          </div>
        </div>
      </CollapsiblePanel>
    );
  }
}
