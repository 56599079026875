import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Table } from 'react-bootstrap';
import every from 'lodash/every';

const compareStyles = (a, b) => {
  const sa = a.props.style;
  const sb = b.props.style;
  return (sa === sb) || every(sa, (val, key) => (sb[key] === val));
};

const compareCols = (a, b) => {
  const ca = Array.from(a.children);
  const cb = Array.from(b.children);
  return ca.length === cb.length &&
    every(ca, (col, idx) => {
      if (col === null && cb[idx] === null) {
        return true;
      }
      return col && cb[idx] &&
        (col.props.className === cb[idx].props.className) &&
        (col.props.title === cb[idx].props.title) &&
        (col.props.tip === cb[idx].props.tip) &&
        (compareStyles(col, cb[idx]));
    });
};

const getCols = (props) => {
  return props.children.filter(col => !!col).map(col => col.props);
};

export class Column extends Component {
  static propTypes = {
    style: PropTypes.object, // eslint-disable-line
    className: PropTypes.string, // eslint-disable-line
    title: PropTypes.node, // eslint-disable-line
    tip: PropTypes.string, // eslint-disable-line
    children: PropTypes.any // eslint-disable-line
  };

  static defaultProps = {
    style: {}
  };

  render() {
    return null;
  }
};

export class Row extends Component {
  static propTypes = {
    cols: PropTypes.arrayOf(PropTypes.shape(Column.propTypes)).isRequired,
    update: PropTypes.bool,
    forceUpdate: PropTypes.bool,
    row: PropTypes.object,
    rowAs: PropTypes.string,
    rowIndex: PropTypes.number,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    countUpdate: PropTypes.func
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  shouldComponentUpdate(nextProps) {
    let update = nextProps.update;
    if (!nextProps.update) {
      update = !Immutable.is(nextProps.row, this.props.row);
    }
    if (update) {
      nextProps.countUpdate();
    }
    return update;
  };

  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.row);
    }
  }

  renderColumn(col, colIndex) {
    return (
      <td
        key={colIndex}
        style={col.style}
        className={col.className}
        onClick={this.onClick}
      >
        {React.cloneElement(col.children, {
          [this.props.rowAs]: this.props.row,
          rowIndex: this.props.rowIndex,
          forceUpdate: this.props.forceUpdate
        })}
      </td>
    );
  }

  render() {
    return (
      <tr className={this.props.selected ? 'active' : ''}>
        {this.props.cols.map(this.renderColumn)}
      </tr>
    );
  }
};

export default class TableList extends Component {
  static propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    hover: PropTypes.bool,
    forceUpdate: PropTypes.bool,
    rows: PropTypes.instanceOf(Immutable.List).isRequired,
    rowAs: PropTypes.string,
    rowKey: PropTypes.string,
    selectedRow: PropTypes.string,
    onClickRow: PropTypes.func,
    children: PropTypes.any, // eslint-disable-line
    updateRow: PropTypes.object
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.cols = getCols(props);
    this.shouldRowUpdate = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.cols = getCols(nextProps);
    this.shouldRowUpdate = nextProps.forceUpdate ||
      nextProps.rowAs !== this.props.rowAs ||
      nextProps.rowKey !== this.props.rowKey ||
      nextProps.selectedRow !== this.props.selectedRow ||
      !compareCols(nextProps, this.props);
  }

  renderHeaderColumn(col, colIndex) {
    return (
      <th key={colIndex} style={col.style} className={col.className}>
        {col.title}
      </th>
    );
  }

  renderHeader() {
    return this.cols.map(this.renderHeaderColumn);
  }

  countUpdatedRows() {
    this.updatedRows++;
  }

  renderRow(row, rowIndex) {
    const { rowKey, updateRow, rowAs, selectedRow, onClickRow } = this.props;
    const newKey = '_new_' + rowIndex;
    const key = row.get(rowKey) || newKey;
    const isUpdateRow = (updateRow && updateRow.get(rowKey) && updateRow.get(rowKey) === key);
    const update = this.shouldRowUpdate ||
      (key === newKey && this.updatedRows > 0) ||
      isUpdateRow ||
      (updateRow && !updateRow.get(rowKey));
    return (
      <Row
        key={rowIndex}
        cols={this.cols}
        update={update}
        forceUpdate={isUpdateRow}
        countUpdate={this.countUpdatedRows}
        row={row}
        rowAs={rowAs}
        rowIndex={rowIndex}
        selected={Boolean(key && selectedRow === key)}
        onClick={onClickRow}
      />
    );
  }

  renderRows() {
    this.updatedRows = 0;
    return this.props.rows.map(this.renderRow);
  }

  render() {
    const { id, style, hover } = this.props;
    return (
      <Table
        id={id}
        style={style}
        hover={hover}
        borderless
      >
        <thead>
          <tr>
            {this.renderHeader()}
          </tr>
        </thead>
        <tbody>
          {this.renderRows()}
        </tbody>
      </Table>
    );
  }
};
