import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import TodoRecord from '../../models/TodoRecord';
import CheckButton from '../shared/CheckButton';
import Immutable from 'immutable';

export default class TodoUrgent extends Component {
  static propTypes = {
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    updateMatter: PropTypes.func.isRequired
  };

  static defaultProps = {
    todo: new TodoRecord()
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.todo, state.todo)) {
      return {
        todo: props.todo,
        confirm: false,
        checked: props.todo.urgent
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onChange(checked) {
    this.setState({ checked });
    this.props.updateMatter({
      id: this.props.todo.get('id'),
      urgent: checked
    }, this.props.todo.get('matterNumber'));
  }

  render() {
    return (
      <CheckButton
        red
        checked={this.state.checked}
        onChange={this.onChange}
      />
    );
  }
};
