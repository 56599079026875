import { handleActions } from 'redux-actions';
import _last from 'lodash/last';

import {
  AUTH_LOGIN_SUCCESS,
  LOCATION_CHANGE
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [LOCATION_CHANGE]: (state, { payload }) => {
    return payload.query.term || state;
  },

  [AUTH_LOGIN_SUCCESS]: (state, { payload }) => (state || _last(payload.email.split('@')))

}, initialState);
