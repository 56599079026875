import _uniq from 'lodash/uniq';

// http://emailregex.com/
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email) => {
  if (!email) return false;

  if (emailRegex.test(email)) return true;

  const split = email.split('@');

  if (split.length !== 3) return false;

  return emailRegex.test(`${split[0]}@${split[1]}`) &&
    emailRegex.test(`${split[1]}@${split[2]}`);
};

export const splitCommaUnique = (value) => {
  return _uniq(value.trim().split(/ *, */).filter(value => /\w/.test(value)));
};

export const joinCommaUnique = (...args) => {
  return _uniq(Array.prototype.concat.apply([], args)).join(',');
};
