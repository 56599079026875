import { handleActions } from 'redux-actions';

import {
  PRIOR_ART_SUBMIT
} from '../../constants/actions';

const initialState = {
  errorMessage: null,
  successMessage: null
};

export default handleActions({

  [PRIOR_ART_SUBMIT]: (state, { payload }) => {
    return payload;
  }

}, initialState);
