import callApi from '../../utils/callApi';

export default (matterId, documentNumbers) => (dispatch) => {
  return dispatch(callApi({
    url: '/api/documents/remove-equivalents',
    method: 'POST',
    data: {
      matterId,
      documentNumbers
    }
  }));
};
