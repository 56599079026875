import { handleActions } from 'redux-actions';

import {
  AUTH_OPEN_MODAL,
  AUTH_LOGIN_SUCCESS
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [AUTH_OPEN_MODAL]: (state, { payload }) => (payload.mode),

  [AUTH_LOGIN_SUCCESS]: () => (initialState)

}, initialState);
