import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import { Modal, Button, Table } from 'react-bootstrap';
import HelpIcon from '../shared/HelpIcon';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import { parse, format, isValid } from 'date-fns';
import CategoriesInput from '../categories/CategoriesInput';
import getUSDocs from 'utils/us-docs';
import { isCategoryMatter, getCategoryFromMatterNumber } from '../../utils/categories';
import CheckButton from '../shared/CheckButton';
import Field from '../shared/Field';

const styleTextarea = {
  resize: 'none',
  width: '100%'
};

const descriptions = {
  US: 'Type or paste in this box any amount of U.S. patent or publication numbers, in any format. Click PROCESS to clean and review data before entry.',
  Foreign: 'Type or paste in this box any amount of Foreign patent or publication numbers, in any format. Click PROCESS to clean and review data before entry.',
  Other: 'Type or paste any number of NPL descriptions here (must be on separate lines)'
};

export default class DocumentBatchModal extends Component {
  static propTypes = {
    addBatchDocuments: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    categories: PropTypes.instanceOf(Immutable.List).isRequired,
    viewCategory: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      cited: false,
      show: true,
      value: '',
      selectedCategories: Immutable.List(),
      edit: [],
      notes: ''
    };
  }

  getDocuments() {
    if (this.state.documents) {
      return this.state.documents;
    }
  }

  getCategories() {
    const matterNumber = this.props.matter.get('matterNumber');
    if (isCategoryMatter(matterNumber)) {
      const category = getCategoryFromMatterNumber(matterNumber);
      if (!this.state.selectedCategories.includes(category)) {
        return this.state.selectedCategories.concat(category);
      }
    }
    return this.state.selectedCategories;
  }

  onClickCancel() {
    this.setState({
      show: false
    });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  isValid() {
    return this.getDocuments() && this.getDocuments().length > 0;
  }

  onClickConfirm() {
    if (this.isValid()) {
      this.setState({ saving: true });

      this.props.addBatchDocuments({
        categories: this.getCategories(),
        cited: this.state.cited,
        docs: this.getDocuments(),
        matter: this.props.matter,
        type: this.props.type,
        notes: this.state.notes
      })
        .then(() => this.onSaved());
    }
  }

  onClose() {
    this.setState({ saving: false });
    this.props.onClose(this.state.sweepComplete);
  }

  onSaved() {
    this.onClose();
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
      count: null
    });
  }

  renderForm() {
    return (
      <textarea
        autoFocus
        className='form-control input-sm'
        rows={10}
        cols={50}
        value={this.state.value}
        onChange={this.onChange}
        spellCheck={false}
        placeholder={descriptions[this.props.type]}
        style={styleTextarea}
      />
    );
  }

  processTextNPL() {
    const documents = _uniq(this.state.value.split(/\n|¶|§/g).map(text => text.trim()))
      .filter(description => /\w/.test(description))
      .map(description => ({ description }));
    this.setState({
      documents,
      count: documents.length
    });
  }

  processTextUS() {
    const stripSpace = (str) => str.replace(/ /g, '');
    const text = this.state.value
      .replace(/([\d]{4} [\d]{6,7})/g, stripSpace)
      .replace(/(-\d ?\d ?\d ?\d ?\d ?\d ?\d ?\d ?\d ?\d ?\d ?-)/g, stripSpace);

    const documents = getUSDocs(text, false);
    this.setState({
      documents: documents.map(number => ({ number })),
      count: documents.length
    });
  }

  processTextForeign() {
    const re = {
      separator: '(;| {1,2}|\t)',
      kind: '[A-Z]\\d?',
      country: '[A-Z]{2}',
      number: '[A-Z]?\\d{4,13}',
      date: '[0-9]{1,2}[-\\\/]{1}[0-9]{1,2}[-\\\/]{1}[0-9]{4}',
      applicant: '[\\w\\s\,]+'
    };

    const regex = {
      separator: new RegExp(re.separator),
      number: new RegExp(re.number),
      country: new RegExp(re.country),
      kind: new RegExp(re.kind),
      date: new RegExp(re.date),
      applicant: new RegExp(re.applicant)
    };

    const getValue = (str, r) => (
      str.split(regex.separator)
        .filter(val => r.exec(val))
        .shift()
    );

    const compareApplicant = (applicant, doc) => {
      if (!doc.country && applicant && applicant[0].trim()) {
        return true;
      }
      if (applicant && applicant[0].trim() !== doc.country.trim() && applicant[0].trim().length > 3) {
        return true;
      }
      return false;
    };

    const parseDocument = (val) => {
      const doc = {
        date: getValue(val, regex.date),
        kind: getValue(val, regex.kind),
        country: getValue(val, regex.country),
        number: getValue(val, regex.number)
      };
      if (doc.kind === doc.number || doc.kind === doc.country || doc.kind === doc.date) {
        doc.kind = '';
      }
      if (doc.kind) {
        doc.number = doc.number + doc.kind;
      }
      const applicant = val
        .replace(`${doc.country}${doc.number}`, '')
        .replace(doc.number, '')
        .replace(doc.date, '')
        .replace(doc.country + ';', '')
        .match(regex.applicant);
      if (compareApplicant(applicant, doc)) {
        doc.applicant = applicant[0].trim();
      }
      if (doc.date) {
        const date = parse(doc.date.replace(/-/g, '/'));
        if (isValid(date)) {
          doc.date = format(date, 'MM/DD/YYYY');
        } else {
          doc.date = '';
        }
      }
      const countryMatch = doc.number.match(regex.country);
      if (countryMatch) {
        doc.country = countryMatch[0];
        doc.number = doc.number.replace(doc.country, '');
      }
      return doc;
    };
    const fixNumber = str => {
      return str.replace(/[ -\/]/g, '');
    };
    const fixDocumentNumbers = (text) => {
      // prepare strings like "CN-104735464-A" to be matched
      return text
        .replace(/[A-Z]{2} [\d]{1,4}\/[\d]{1,6} [A-Z]{1}[01]?/g, fixNumber)
        .replace(/[a-z0-9]{6}\/\d{6}/ig, fixNumber)
        .replace(/[a-z0-9]{6}\/\d{5}/ig, fixNumber)
        .replace(/\d{4}-\d{7}/g, fixNumber)
        .replace(/\d{4}\/\d{7}/g, fixNumber)
        .replace(/[A-Z]{2} \d{7,10}/g, fixNumber)
        .replace(/[A-Z]{2} \d{4}[ -\/]{1}\d{3,6}/g, fixNumber)
        .replace(/[A-Z]{2} \d{2,3} \d{2,3} \d{2,3}/g, fixNumber)
        .replace(/[A-Z]{2}[ -]{1}\d{4,13}-[A-Z]\d?/g, fixNumber)
        .replace(/[A-Z]{2}[ -]{1}\d{1,4}[ -\/]{1}\d{4,10}-[A-Z]\d?/g, fixNumber)
        .replace(/[A-Z]{2}[ -]{1}\d{1,4}[ -]{1}\d{1,4}[ -]{1}\d{1,4}-[A-Z]\d?/g, fixNumber)
        .replace(/(\d{7,10}) ([A-Z]{2}) ([A-Z]\d?)/g, (line, number, country, kind) => `${number}${kind} ${country}`);
    };
    const combinations = [
      [re.country, re.separator, re.number, re.separator, re.kind],
      [re.country, re.number, re.kind, re.separator, re.date, re.applicant],
      [re.country, re.number, re.kind, re.separator, re.date],
      [re.number, re.kind, re.separator, re.country, re.separator, re.date, re.separator, re.applicant],
      [re.number, re.kind, re.separator, re.country, re.separator, re.date],
      [re.number, re.kind, re.separator, re.country],
      [re.number, re.separator, re.date, re.separator, re.country, re.separator, re.applicant],
      [re.number, re.separator, re.date, re.separator, re.country],
      [re.country, re.number, re.separator, re.kind],
      [re.country, re.number, re.kind],
      [re.country, re.number],
      [re.number, re.separator, re.country],
      [re.number, re.kind],
      [re.number]
    ];
    const combined = new RegExp(combinations.map(seq => seq.join('')).join('|'), 'g');
    const text = fixDocumentNumbers(this.state.value);

    let match = [];
    text.split(/\n/).forEach(line => {
      const matchLine = line.match(combined);
      if (matchLine) {
        match = match.concat(matchLine);
      }
    });

    const validDocument = (doc) => {
      if (/^[0-9]{4}$/.test(doc.number) && !doc.country) {
        return false;
      }
      return true;
    };

    const documents = match ? _uniqBy(match.map(parseDocument).filter(validDocument), doc => `${doc.country}${doc.number}`) : null;
    this.setState({
      documents,
      count: documents ? documents.length : null
    });
  }

  onClickProcess() {
    switch (this.props.type) {
      case 'US':
        return this.processTextUS();
      case 'Foreign':
        return this.processTextForeign();
      case 'Other':
        return this.processTextNPL();
    }
  }

  renderProcess() {
    return (
      <Button
        variant='secondary'
        style={{ float: 'left' }}
        onClick={this.onClickProcess}
      >
        Process
      </Button>
    );
  }

  renderCounter() {
    return (
      <div style={{ float: 'left', padding: '11px 16px' }}>
        {typeof this.state.count !== 'number'
          ? 'Must process before confirm.'
          : this.state.count === 1
            ? '1 document number found.'
            : this.state.count + ' document numbers found.'}
      </div>
    );
  }

  renderConfirm() {
    return (
      <Button
        disabled={!this.isValid() || this.state.saving}
        variant='primary'
        onClick={this.onClickConfirm}
      >
        {this.state.saving ? 'Saving...' : 'Confirm'}
      </Button>
    );
  }

  renderCancel() {
    return (
      <Button
        variant='secondary'
        onClick={this.onClickCancel}
      >
        Cancel
      </Button>
    );
  }

  renderHelpIcon() {
    return (
      <HelpIcon
        help='Type or paste in the box below any amount of U.S. patent or publication numbers, in any format. Click PROCESS to clean and review data before entry.'
        id='batch-input-modal'
        placement='bottom'
        styleIcon={{ marginRight: '10px', float: 'right' }}
      />
    );
  }

  onChangeCited(cited) {
    this.setState({
      cited
    });
  }

  renderCited() {
    return (
      <div style={{ position: 'relative', left: '-2px' }}>
        <CheckButton
          checked={this.state.cited}
          onChange={this.onChangeCited}
        />
        <span style={{ position: 'relative', top: '3px', left: '10px' }}>Mark documents as cited</span>
      </div>
    );
  }

  onSelectCategory(selectedCategories) {
    this.setState({ selectedCategories });
  }

  renderCategory() {
    const categories = this.getCategories();

    return (
      <div>
        <div style={{ margin: '10px 10px 0 -2px', display: 'inline-block' }}>
          <CategoriesInput
            compact
            onConfirm={this.onSelectCategory}
            id={'categories-drop-down-{this.props.doc.get(\'documentNumber\')}'}
            categories={this.props.categories}
            selectedCategories={categories}
            viewCategory={this.props.viewCategory}
          />
        </div>
        {categories.count() ? categories.join(', ') : 'Select categories'}
      </div>
    );
  }

  onClickRemove(e) {
    const index = Number(e.target.getAttribute('data-index'));
    const docs = this.getDocuments();

    this.setState({
      documents: docs.length === 1 ? null : docs.filter(doc => doc !== docs[index])
    });
  }

  renderRemove(doc, index) {
    return (
      <span
        title='Remove'
        style={{ cursor: 'pointer' }}
        data-index={index}
        className='fa fa-times'
        onClick={this.onClickRemove}
      />
    );
  }

  renderDocuments() {
    const cols = [];
    switch (this.props.type) {
      case 'US':
        cols.push('number');
        break;
      case 'Foreign':
        cols.push('number', 'country', 'date', 'applicant');
        break;
      case 'Other':
        cols.push('description');
        break;
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '30px' }}>&nbsp;</th>
            {cols.map((col) => (
              <th key={col} style={{ textTransform: 'capitalize' }}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.getDocuments().map((doc, i) => {
            return (
              <tr key={i}>
                <td style={{ width: '30px', textAlign: 'center' }}>
                  {this.renderRemove(doc, i)}
                </td>
                {cols.map((col) => (
                  <td key={col}>
                    {doc[col] || '-'}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderDocumentsRow() {
    if (this.getDocuments()) {
      return (
        <div className='row'>
          <div className='col-md-5'>
            {this.renderForm()}
          </div>
          <div className='col-md-7'>
            {this.renderDocuments()}
          </div>
        </div>
      );
    }

    return (
      <div className='row'>
        <div className='col-md-12'>
          {this.renderForm()}
        </div>
      </div>
    );
  }

  renderOptionsRow() {
    return (
      <div className='row' style={{ marginTop: '15px' }}>
        <div className='col-md-6'>
          {this.renderCited()}
          {this.renderCategory()}
        </div>
        <div className='col-md-6'>
          {this.renderNotes()}
        </div>
      </div>
    );
  }

  onChangeNotes(event) {
    this.setState({
      notes: event.target.value
    });
  }

  renderNotes() {
    return (
      <Field label='Client Notes'>
        <textarea
          className='form-control input-sm'
          rows={2}
          value={this.state.notes}
          onChange={this.onChangeNotes}
        />
      </Field>
    );
  }

  renderBody() {
    return (
      <div>
        {this.renderDocumentsRow()}
        {this.renderOptionsRow()}
      </div>
    );
  }

  getTitle() {
    switch (this.props.type) {
      case 'Other':
        return 'NPL Batch Input';
      default:
        return `${this.props.type} Batch Input`;
    }
  }

  renderButtons() {
    return (
      <div style={{ marginLeft: 'auto' }}>
        {this.renderCancel()}
        &nbsp;&nbsp;
        {this.renderConfirm()}
      </div>
    );
  }

  render() {
    return (
      <Modal
        keyboard={false}
        size={this.getDocuments() ? 'xl' : 'lg'}
        show={this.state.show}
        onHide={this.onClickCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.getTitle()}
            {this.renderHelpIcon()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderProcess()}
          {this.renderCounter()}
          {this.renderButtons()}
        </Modal.Footer>
      </Modal>
    );
  }
}
