import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styleFieldset = {
  marginTop: '6px'
};

const styleLegend = {
  marginBottom: '0px',
  float: 'none'
};

export default class Fieldset extends Component {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    style: PropTypes.object,
    onClick: PropTypes.func
  };

  renderLegend() {
    return Boolean(this.props.title) && (
      <legend style={styleLegend}>{this.props.title}</legend>
    );
  }

  render() {
    return (
      <fieldset onClick={this.props.onClick} style={{ ...styleFieldset, ...this.props.style }}>
        {this.renderLegend()}
        {this.props.children}
      </fieldset>
    );
  }
};
