import { connect } from 'react-redux';
import actions from '../../redux/actions';
import Sync from './Sync';
import Immutable from 'immutable';

function mapStateToProps(state) {
  const {
    app: {
      selectedMatterId,
      selectedClientId,
      expandedSyncPanel,
      features
    },
    migration: {
      syncOverlap
    },
    entities
  } = state;
  const client = entities.getIn(['clients', selectedClientId]) || Immutable.Map();
  const matter = entities.getIn(['matters', selectedMatterId]) || Immutable.Map();
  const matterList = entities.getIn(['matterList', selectedClientId]) || Immutable.List();

  return {
    expanded: expandedSyncPanel,
    features,
    client,
    matter,
    matterList,
    syncOverlap
  };
};

export default connect(mapStateToProps, actions)(Sync);
