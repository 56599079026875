import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Navbar, Nav, Container } from 'react-bootstrap';
import UserMenu from './UserMenu';
import Logo from './Logo';
import Login from '../login/Login';

export default class AppBar extends Component {
  static propTypes = {
    features: PropTypes.object,
    email: PropTypes.string,
    pushPath: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    checkAuth: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    openAuthModal: PropTypes.func.isRequired,
    closeAuthModal: PropTypes.func.isRequired,
    maintenance: PropTypes.bool.isRequired,
    singleSignOn: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  componentDidMount() {
    this.props.checkAuth();
  }

  onRouteChange(value) {
    this.setState({ expanded: false });
    this.props.pushPath(value);
  }

  onNavItemClick(e) {
    if (e.target.getAttribute('href').indexOf('/app') !== -1 && !this.props.email) {
      this.props.openAuthModal({ mode: 'login' });
    }
    this.setState({ expanded: false });
  }

  renderNavItem(route) {
    return (
      <Nav.Item key={route.path}>
        <Nav.Link
          href={'#' + route.path}
          onClick={this.onNavItemClick}
        >
          {route.label}
        </Nav.Link>
      </Nav.Item>
    );
  }

  renderLeftNav() {
    return (
      <Nav>
        {this.renderNavItem({ path: '/app', label: 'App' })}
        {this.renderNavItem({ path: '/test-drive', label: 'Test Drive' })}
        {this.renderNavItem({ path: '/services', label: 'Services' })}
        {this.renderNavItem({ path: '/contact', label: 'Contact' })}
        {this.renderNavItem({ path: '/faq', label: 'FAQ' })}
        {this.renderNavItem({ path: '/payments', label: 'Payments' })}
        {this.renderAdmin()}
      </Nav>
    );
  }

  renderAdmin() {
    return this.props.features.admin && (
      <Nav.Link href='#/admin'>Admin</Nav.Link>
    );
  }

  onToggle() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  renderNav() {
    return (
      <Navbar
        bg='primary'
        data-bs-theme='dark'
        fixed='top'
        expand='lg'
        expanded={this.state.expanded}
        style={{ paddingTop: 0, paddingBottom: 0 }}
        onToggle={this.onToggle}
      >
        <Container>
          <Navbar.Brand href='#/'>
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            {this.renderLeftNav()}
            <UserMenu
              features={this.props.features}
              maintenance={this.props.maintenance}
              email={this.props.email}
              logout={this.props.logout}
              openAuthModal={this.props.openAuthModal}
              closeAuthModal={this.props.closeAuthModal}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  renderLogin() {
    return !this.props.maintenance && (
      <Login
        login={this.props.login}
        register={this.props.register}
        changePassword={this.props.changePassword}
        resetPassword={this.props.resetPassword}
        singleSignOn={this.props.singleSignOn}
        openAuthModal={this.props.openAuthModal}
        closeAuthModal={this.props.closeAuthModal}
        {...this.props.auth}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderNav()}
        {this.renderLogin()}
      </>
    );
  }
}
