import { handleActions } from 'redux-actions';
import _last from 'lodash/last';

import {
  AUTH_LOGIN_SUCCESS
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [AUTH_LOGIN_SUCCESS]: (state, { payload }) => (state || _last(payload.email.split('@')))

}, initialState);
