import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';
import { validatePDF } from '../../../utils/validate-pdf';

export default (doc, file, mode = 'replace') => (dispatch) => {
  const id = doc.id || doc.get('id');

  const data = new FormData();
  data.append('file', file);

  dispatch(addNotification({
    id: `upload-file-${id}-${mode}`,
    type: 'progress',
    message: mode === 'validate'
      ? `Validating "${file.name || doc.documentNumber || doc.get('documentNumber')}"...`
      : `Uploading "${file.name || doc.documentNumber || doc.get('documentNumber')}"...`
  }));

  return new Promise((resolve) => {
    if (mode === 'validate') {
      validatePDF(file, (isValid) => resolve(isValid));
    } else {
      resolve(true);
    }
  })
    .then((isValid) => {
      if (isValid) {
        return dispatch(callApi({
          url: `${documentsUrl}/${id}/upload-file/${mode}`,
          method: 'put',
          data
        }))
          .then((response) => ({ ...response, isValid }));
      }
      return { isValid };
    })
    .then((response) => {
      if (
        response.isValid &&
      (!response.embeddedFonts || !response.embeddedFonts.length) &&
      (!response.landscapePages || !response.landscapePages.length)
      ) {
        dispatch(addNotification({
          id: `upload-file-${id}-${mode}`,
          type: 'success',
          message: mode === 'validate' ? 'Validated' : 'Upload complete!'
        }));
      } else {
        dispatch(addNotification({
          id: `upload-file-${id}-${mode}`,
          type: 'error',
          message: mode === 'validate' ? 'Invalid' : 'Upload failed'
        }));
      }

      return response;
    });
};
