import { createAction } from 'redux-actions';
import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';
import { DELETE_DOCUMENT_SUCCESS } from '../../constants/actions';

const deleteDocument = createAction(DELETE_DOCUMENT_SUCCESS);

export default (clientNumber, documentNumber, docId) => (dispatch) => {
  dispatch(addNotification({
    id: `document-${documentNumber}`,
    type: 'progress',
    message: `Deleting document ${documentNumber} from client ${clientNumber}...`
  }));
  return dispatch(callApi({
    url: `${documentsUrl}/${docId}/delete-from-client`
  }))
    .catch(() => {
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'error',
        message: `Failed to delete ${documentNumber}.`
      }));
    })
    .then(() => {
      dispatch(deleteDocument({
        clientNumber,
        documentNumber
      }));
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'success',
        message: `Deleted document ${documentNumber} from client ${clientNumber}.`
      }));
    });
};
