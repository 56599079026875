import { shouldComponentUpdate as shouldPureComponentUpdate } from 'react-immutable-render-mixin';
import React, { Component } from 'react';
import autoBind from 'class-autobind';
import PropTypes from 'prop-types';
import CollapsiblePanel from '../shared/CollapsiblePanel';
import Immutable from 'immutable';
import ClientID from './ClientID';
import ClientForm from './ClientForm';

export default class ClientContainer extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    features: PropTypes.object.isRequired,
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    clientList: PropTypes.instanceOf(Immutable.List).isRequired,
    fetchClientListIfNeeded: PropTypes.func.isRequired,
    updateClient: PropTypes.func.isRequired,
    selectClient: PropTypes.func.isRequired,
    createClient: PropTypes.func.isRequired,
    toggleClientMatter: PropTypes.func.isRequired,
    selectedClientTab: PropTypes.string.isRequired,
    selectClientTab: PropTypes.func.isRequired,
    renameClient: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.props.fetchClientListIfNeeded();
  }

  shouldComponentUpdate = shouldPureComponentUpdate;

  onChange(changedFields) {
    this.props.updateClient({
      id: this.props.client.get('id'),
      ...changedFields
    }, this.props.client.get('clientNumber'));
  }

  renderHeader() {
    return (
      <ClientID
        client={this.props.client}
        clientList={this.props.clientList}
        selectClient={this.props.selectClient}
        createClient={this.props.createClient}
        updateClient={this.props.updateClient}
        renameClient={this.props.renameClient}
        features={this.props.features}
      />
    );
  }

  renderContent() {
    return Boolean(this.props.client.get('id')) && (
      <ClientForm
        features={this.props.features}
        selectedTab={this.props.selectedClientTab}
        onSelectTab={this.props.selectClientTab}
        record={this.props.client}
        onChange={this.onChange}
      />
    );
  }

  render() {
    return (
      <CollapsiblePanel
        id='client-panel'
        styleCollapse={{ padding: '6px 0' }}
        expanded={this.props.expanded}
        header={this.renderHeader()}
        onToggle={this.props.toggleClientMatter}
      >
        {this.renderContent()}
      </CollapsiblePanel>
    );
  }
}
