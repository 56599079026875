import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default class Tooltip extends Component {
  static propTypes = {
    id: PropTypes.string,
    tip: PropTypes.node,
    delay: PropTypes.number,
    placement: PropTypes.string.isRequired,
    stylePopover: PropTypes.object,
    style: PropTypes.object,
    children: PropTypes.node,
    popoverTitle: PropTypes.string,
    popoverClass: PropTypes.string
  };

  static defaultProps = {
    placement: 'top'
  };

  renderTooltip() {
    return (
      <Popover
        className={this.props.popoverClass}
        id={this.props.id || this.props.tip}
        style={this.props.stylePopover}
        title={this.props.popoverTitle}
      >
        <div style={{ padding: '5px 10px' }}>
          {this.props.tip}
        </div>
      </Popover>
    );
  }

  render() {
    if (!this.props.tip) {
      return (
        <span>{this.props.children}</span>
      );
    }

    return (
      <OverlayTrigger
        delay={this.props.delay}
        placement={this.props.placement}
        overlay={this.renderTooltip()}
      >
        <span style={this.props.style}>
          {this.props.children}
        </span>
      </OverlayTrigger>
    );
  }
};
