import updateDocumentCitedState from './updateDocumentCitedState';
import { getDuplicateCitedDocsFromMatter } from '../../../utils/get-duplicate-cited-docs-from-matter';

export default (matter) => (dispatch, getState) => {
  let promise = Promise.resolve();

  const duplicates = getDuplicateCitedDocsFromMatter(matter);

  duplicates.forEach(docNumber => {
    promise = promise.then(() => (
      dispatch(updateDocumentCitedState(matter, docNumber, true))
    ));
  });

  return promise;
};
