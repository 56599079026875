import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import selectClient from './selectClient';
import fetchClientList from './fetchClientList';
import { clientsUrl } from '../../constants/urls';

export default (client) => (dispatch, getState) => {
  const state = getState();
  const clientName = client.clientName;
  const clientNumber = client.clientNumber;
  const { auth: { firmName } } = state;
  if (!firmName || !clientNumber) {
    console.error('createClient - missing required fields - firmName, clientNumber');
    return;
  }
  const clientToCreate = { firmName, clientName, clientNumber };
  dispatch(selectClient());
  dispatch(addNotification({
    id: `client-${clientNumber}`,
    type: 'progress',
    message: `Creating new client ${clientNumber}...`
  }));

  return dispatch(callApi({
    url: clientsUrl,
    method: 'post',
    data: clientToCreate
  }))
    .then(response => {
      if (response.status === 'client-exists') {
        dispatch(addNotification({
          id: `client-${clientNumber}`,
          type: 'warn',
          message: `Client ${clientNumber} already exists.`
        }));
      } else {
        dispatch(addNotification({
          id: `client-${clientNumber}`,
          type: 'success',
          message: `Client ${clientNumber} created.`
        }));
        dispatch(selectClient(response.id));
        dispatch(fetchClientList(firmName));
      }
      return response;
    })
    .catch(() => {
      dispatch(addNotification({
        id: `client-${clientNumber}`,
        type: 'error',
        message: `Failed to create ${clientNumber}.`
      }));
    });
};
