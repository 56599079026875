import { createAction } from 'redux-actions';
import fetchClientListIfNeeded from '../client/fetchClientListIfNeeded';
import fetchMatterListIfNeeded from '../matter/fetchMatterListIfNeeded';
import addMatterTwoWayRelation from '../matter/addMatterTwoWayRelation';
import { splitCommaUnique } from '../../../utils/text-utils';

import {
  MERGE_SYNC_OVERLAP_BEGIN,
  MERGE_SYNC_OVERLAP_SUCCESS,
  MERGE_SYNC_OVERLAP_ERROR
} from '../../constants/actions';

const mergeSyncOverlapBegin = createAction(MERGE_SYNC_OVERLAP_BEGIN);
const mergeSyncOverlapSuccess = createAction(MERGE_SYNC_OVERLAP_SUCCESS);
const mergeSyncOverlapError = createAction(MERGE_SYNC_OVERLAP_ERROR);

export default (clientNumber, source, target) => (dispatch, getState) => {
  dispatch(mergeSyncOverlapBegin({ isRunning: true, clientNumber, source, target }));
  return dispatch(fetchClientListIfNeeded())
    .then(() => {
      const client = getState()
        .entities
        .get('clientList')
        .find(record => record.get('clientNumber') === clientNumber);
      if (client) {
        const sourceMatter = splitCommaUnique(source).shift();
        const targetMatter = splitCommaUnique(target).shift();
        return dispatch(fetchMatterListIfNeeded(client.get('id')))
          .then(() => {
            const matter = getState()
              .entities
              .get('matterList')
              .get(client.get('id'))
              .find(record => record.get('matterNumber') === sourceMatter);
            if (matter) {
              return dispatch((addMatterTwoWayRelation(matter.get('id'), targetMatter)));
            }
          });
      }
    })
    .then(() => dispatch(mergeSyncOverlapSuccess({ isRunning: false, clientNumber, source, target })))
    .catch(() => dispatch(mergeSyncOverlapError({ isRunning: false, clientNumber, source, target })));
};
