import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import autoBind from 'class-autobind';

const styleButton = {
  width: '80px'
};

export default class AlertModal extends Component {
  static propTypes = {
    size: PropTypes.string,
    title: PropTypes.node.isRequired,
    message: PropTypes.node.isRequired,
    onConfirm: PropTypes.func,
    focusConfirm: PropTypes.bool
  };

  static defaultProps = {
    title: 'Warning'
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true
    };
  }

  confirm() {
    this.setState({
      show: false
    });
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  render() {
    return (
      <Modal
        size={this.props.size}
        show={this.state.show}
        onHide={this.confirm}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.message}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={styleButton}
            autoFocus={this.props.focusConfirm}
            variant='primary'
            onClick={this.confirm}
          >
          OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
