import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RequestDemo extends Component {
  static propTypes = {
    href: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
  };

  static defaultProps = {
    href: '/#/test-drive',
    className: 'btn btn-xl btn-primary'
  };

  render() {
    return (
      <a style={this.props.style} className={this.props.className} href={this.props.href}>
        Test Drive
      </a>
    );
  }
}
