import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BatchMatterUpdateIntro extends Component {
  static propTypes = {
    title: PropTypes.bool
  };

  render() {
    return (
      <div>
        {this.props.title ? <h4>Batch Matter Update</h4> : <br />}
        <p>This migration tool is used to update a bunch of matters in batch.</p>
        <p>The input is a list of DB values to enter in to the DB. The fields are designated by a header line in the input file using the exact DB field names. The firm name is derived from the logged in user.</p>
      </div>
    );
  }
};
