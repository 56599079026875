import { handleActions } from 'redux-actions';
import {
  DOCUMENTS_SEARCH_TAB
} from '../../constants/actions';

const initialState = '';

export default handleActions({

  [DOCUMENTS_SEARCH_TAB]: (state, { payload }) => (payload)

}, initialState);
