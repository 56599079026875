import callApi from '../../utils/callApi';
import { documentsUrl } from '../../constants/urls';

export default ({ firmName, clientNumber, documentNumber }) => (dispatch, getState) => {
  return dispatch(callApi({
    url: documentsUrl + '/get-multiple',
    method: 'post',
    params: { firmName, clientNumber },
    data: { documentNumbers: [documentNumber] }
  }));
};
