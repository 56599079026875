import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { mattersUrl } from '../../constants/urls';

export default (options) => (dispatch, getState) => {
  if (!options.matterId) {
    return null;
  }

  const {
    matterId,
    matterNumber,
    subsetIDS,
    documentNumbers,
    noCite,
    immediateInvoice
  } = options;

  dispatch(addNotification({
    id: `matter-${matterId}`,
    type: 'progress',
    message: `Filing matter ${matterNumber}...`
  }));

  const params = {};

  if (subsetIDS) {
    params.documentNumbers = documentNumbers.join(',');
  }
  if (noCite) {
    params.noCite = '1';
  }
  if (immediateInvoice) {
    params.immediateInvoice = '1';
  }

  return dispatch(callApi({
    url: `${mattersUrl}/${matterId}/file`,
    params
  }))
    .then(r => {
      if (r.status === 'failed') {
        dispatch(addNotification({
          id: `matter-${matterId}`,
          type: 'warn',
          message: r.message
        }));
      } else {
        dispatch(addNotification({
          id: `matter-${matterId}`,
          type: 'success',
          message: `Matter filed: ${matterNumber}.`
        }));
      }
    })
    .catch(() => {
      dispatch(addNotification({
        id: `matter-${matterId}`,
        type: 'error',
        message: `Failed to file matter ${matterNumber}.`
      }));
    });
};
