import Immutable from 'immutable';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import AttorneyRecord from '../../models/AttorneyRecord';
import Attorney from './Attorney';

function mapStateToProps(state, ownProps) {
  const email = ownProps.email ? ownProps.email.toLowerCase() : undefined;
  const record = state.entities.getIn(['attorneys', email]) || Immutable.Map({ email });
  const attorney = new AttorneyRecord(record);
  return {
    attorney,
    attorneyList: state.attorneyList || Immutable.List(),
    context: ownProps.context,
    preventClear: Boolean(ownProps.preventClear)
  };
};

export default connect(mapStateToProps, actions)(Attorney);
