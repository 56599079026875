import { handleActions } from 'redux-actions';

import {
  AUTH_LOGIN_SUCCESS
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [AUTH_LOGIN_SUCCESS]: (state, { payload }) => (
    payload.firmName.toLowerCase()
  )

}, initialState);
