import { constructTodoSearchParams } from '../../../utils/todo-query';
import callApi from '../../utils/callApi';
import { todosUrl } from '../../constants/urls';

export default () => (dispatch, getState) => {
  const {
    todos: {
      term,
      filterType,
      show
    },
    entities,
    app: {
      selectedClientId
    },
    auth: {
      firmName
    }
  } = getState();

  const request = {
    url: todosUrl,
    params: constructTodoSearchParams({
      term,
      filterType,
      entities,
      selectedClientId,
      firmName,
      show
    })
  };

  return dispatch(callApi(request));
};
