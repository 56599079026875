import TodoRecord from '../models/TodoRecord';

export function getTodoRecord(matter, client, extra) {
  const attorneyEmail = matter.get('associateEmail') || client.get('partnerEmail');

  return new TodoRecord({
    id: matter.get('id'),
    matterId: matter.get('id'),
    clientId: client.get('id'),
    timing: matter.get('timing'),
    urgent: matter.get('urgent'),
    statement: matter.get('statement'),
    clientNumber: matter.get('clientNumber'),
    matterNumber: matter.get('matterNumber'),
    matterClosed: matter.get('matterClosed'),
    entitySize: matter.get('entitySize') || client.get('entitySize'),
    cited: matter.get('citedArt').size,
    uncited: matter.get('uncitedArt').size,
    filingDate: matter.get('filingDate'),
    artUnit: matter.get('artUnit'),
    applicationNumber: matter.get('applicationNumber'),
    confirmationNumber: matter.get('confirmationNumber'),
    firstInventor: matter.get('firstInventor'),
    approved: matter.get('approved'),
    alternateId: matter.get('alternateId'),
    within30Days: matter.get('within30Days'),
    fileReminder: matter.get('fileReminder'),
    pairStatus: matter.get('pairStatus'),
    pairStatusDate: matter.get('pairStatusDate'),
    pairStatusLookupDate: matter.get('pairStatusLookupDate'),
    patentCenterPairStatus: matter.get('patentCenterPairStatus'),
    patentCenterPairStatusDate: matter.get('patentCenterPairStatusDate'),
    patentCenterPairStatusLookupDate: matter.get('patentCenterPairStatusLookupDate'),
    attorneyEmail: attorneyEmail ? attorneyEmail.toLowerCase() : null,
    ...extra
  });
}
