import { combineReducers } from 'redux';

import newDocuments from './newDocuments';
import searchNpl from './searchNpl';
import batchUploadData from './batchUploadData';
import cited from './cited';
import notesTab from './notesTab';
import notesSearch from './notesSearch';
import citedFilter from './citedFilter';

export default combineReducers({
  batchUploadData,
  newDocuments,
  searchNpl,
  cited,
  notesTab,
  notesSearch,
  citedFilter
});
