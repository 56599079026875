import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import SimpleSuggest from '../shared/SimpleSuggest';
import Immutable from 'immutable';

export default class MatterInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    matterList: PropTypes.instanceOf(Immutable.List),
    onBlur: PropTypes.func,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    style: PropTypes.object,
    styleOuter: PropTypes.object,
    id: PropTypes.string,
    initialValue: PropTypes.string
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClear() {
    this.props.onClear(this.props.name);
  }

  onChange(newValue) {
    this.props.onChange(newValue, this.props.name);
  }

  onSubmit(newValue) {
    this.props.onSubmit(newValue, this.props.name);
  }

  onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e, this.props.name);
    }
  }

  render() {
    return (
      <SimpleSuggest
        id={this.props.id}
        name={this.props.name}
        style={this.props.style}
        styleOuter={this.props.styleOuter}
        value={this.props.value}
        initialValue={this.props.initialValue}
        disabled={this.props.disabled}
        className='form-control input-sm'
        placeholder='Ex. 0001US'
        allSuggestions={this.props.matterList.map(c => c.get('matterNumber'))}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onClear={this.onClear}
        onBlur={this.onBlur}
        spellCheck={false}
        preventSpaces
        autoSubmit
      />
    );
  }
};
