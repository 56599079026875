import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { AUTH_CANCEL_CLICK } from '../../constants/actions';

const authCancelClick = createAction(AUTH_CANCEL_CLICK);

export default () => (dispatch, getState) => {
  const state = getState();
  const currentPath = state.routing.locationBeforeTransitions && state.routing.locationBeforeTransitions.pathname;

  if (currentPath && currentPath.indexOf('/app') === 0) {
    dispatch(push({ pathname: '/' }));
  }
  dispatch(authCancelClick());
};
