import callApi from '../../utils/callApi';

export default (firmName) => (dispatch, getState) => {
  return dispatch(callApi({
    url: firmName
      ? `/api/firm-client-settings/${firmName}`
      : '/api/firm-client-settings',
    method: 'GET'
  }));
};
