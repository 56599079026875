import { handleActions } from 'redux-actions';
import {
  DOCUMENTS_NOTES_TAB
} from '../../constants/actions';

const initialState = 'notes';

export default handleActions({

  [DOCUMENTS_NOTES_TAB]: (state, { payload }) => (payload)

}, initialState);
