import callApi from '../../utils/callApi';
import { mattersUrl } from '../../constants/urls';

export default (matterId, relatedMatterId) => (dispatch) => {
  return dispatch(callApi({
    method: 'POST',
    url: `${mattersUrl}/${matterId}/parent-matters`,
    data: { relatedMatterId }
  }));
};
