import deleteDocumentFromMatter from './deleteDocumentFromMatter';

export default (matter, documentNumbers, response) => (dispatch, getState) => {
  if (!response || !matter.get('id')) {
    return Promise.resolve(response);
  }

  const missingDocs = [];
  const entities = response.entities;

  Object.keys(entities.documents).forEach(client => {
    Object.keys(entities.documents[client]).forEach(docNumber => {
      if (docNumber && entities.documents[client][docNumber] === null) {
        if (documentNumbers.includes(docNumber)) {
          missingDocs.push(docNumber);
          delete entities.documents[client][docNumber];
        }
      }
    });
  });

  let promise = Promise.resolve();

  missingDocs.forEach(docNumber => {
    promise = promise.then(() => {
      return dispatch(deleteDocumentFromMatter(matter.get('id'), docNumber, false));
    });
  });

  return promise.then(() => response);
};
