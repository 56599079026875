import viewCategory from './viewCategory';
import viewCategoryMatter from './viewCategoryMatter';
import closeCategory from './closeCategory';
import deleteCategoryMatterIfNeeded from './deleteCategoryMatterIfNeeded';
import createCategoryMatter from './createCategoryMatter';
import createCategory from './createCategory';
import removeCategory from './removeCategory';

export default {
  viewCategory,
  closeCategory,
  createCategory,
  removeCategory,
  createCategoryMatter,
  deleteCategoryMatterIfNeeded,
  viewCategoryMatter
};
