import React, { Component } from 'react';

export default class FacebookButton extends Component {
  render() {
    return (
      <a
        href='https://linkedin.com/company/10928944'
        target='_blank'
        className='pull-left btn btn-secondary'
        style={{
          width: '50px',
          marginLeft: '20px',
          backgroundColor: '#0072B1',
          borderColor: '#0072B1'
        }} rel='noreferrer'
      >
        <span className='fa fa-linkedin' style={{ fontSize: '20px' }} />
      </a>
    );
  }
}
