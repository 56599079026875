import callApi from '../../utils/callApi';

export default (item) => (dispatch, getState) => {
  return dispatch(callApi({
    url: '/api/admin/cheater-invoice',
    method: 'POST',
    data: {
      firmName: item.get('FirmName'),
      clientNumber: item.get('ClientNumber'),
      matterNumber: item.get('MatterNumber'),
      user: item.get('User')
    }
  }));
};
