import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Field from '../shared/Field';
import Form from '../shared/Form';
import HelpIcon from '../shared/HelpIcon';
import { Modal, Button } from 'react-bootstrap';
import Immutable from 'immutable';

export default class Client extends Form {
  static propTypes = {
    field: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
  };

  static defaultProps = {
    readOnly: false,
    label: 'Email Recipients',
    field: 'reminderRecipientEmails'
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.record, state.record)) {
      return {
        record: props.record,
        value: props.record.get(props.field)
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      showModal: false
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  onBlur() {
    if (this.isInvalid()) {
      this.setState({
        showModal: true
      });
    } else {
      this.onConfirm();
    }
  }

  onConfirm() {
    const value = this.getEmails().filter(item => item.valid).map(item => item.email).join(',');

    this.setState({
      value,
      showModal: false
    }, () => {
      if (this.props.record.get(this.props.field) !== value) {
        this.props.onChange({
          [this.props.field]: value
        });
      }
    });
  }

  onCancel() {
    this.setState({
      showModal: false
    });
  }

  renderLabel() {
    return (
      <div>
        {this.props.label}
        &nbsp;&nbsp;&nbsp;
        <HelpIcon help='Separate multiple address with commas' />
      </div>
    );
  }

  getEmails() {
    return this.state.value.split(/ *, */)
      .filter(str => !!str)
      .map(email => ({
        email: email.trim(),
        valid: /^[\w-\.\']+@([\w-]+\.)+[\w-]{2,10}$/.test(email.trim())
      }));
  }

  isInvalid() {
    const emails = this.getEmails();
    return emails.length && emails.some(item => !item.valid);
  }

  renderModal() {
    return (
      <Modal
        backdrop='static'
        show={this.state.showModal}
        onHide={this.cancel}
        container={this}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Please check the email recipients before saving:</p>
            <ul>
              {this.getEmails().map((item, index) => (
                <li key={index}>
                  {item.email}
                  &nbsp;&nbsp;&nbsp;
                  <span className={item.valid ? 'fa fa-check text-success' : 'fa fa-times text-danger'} />
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.props.footer}
          <Button onClick={this.onCancel}>
          Close & Fix
          </Button>
          <Button variant='primary' onClick={this.onConfirm}>
          Remove Invalid & Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <Field
        label={this.renderLabel()}
        style={{ width: '100%' }}
        className={this.isInvalid() ? 'has-error has-feedback' : ''}
      >
        <input
          type='text'
          readOnly={this.props.readOnly}
          disabled={this.props.readOnly}
          name={this.props.field}
          value={this.state.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          spellCheck={false}
          className='form-control'
        />
        {this.state.showModal && this.isInvalid() && this.renderModal()}
      </Field>
    );
  }
}
