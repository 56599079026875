import React from 'react';

export default class SecurityView extends React.Component {
  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  render() {
    return (
      <div style={{ backgroundColor: 'white', marginTop: '-15px', color: '#333' }}>
        <div className='container bigger-fonts'>
          <br />
          <br />
          <h2>SyncIDS Security Practices</h2>

          <p>Our commitment to information security is unwavering. SyncIDS continuously evolves security practices to address emerging threats and vulnerabilities, ensuring the protection of our organization, our customers, and our partners. Through diligent management, rigorous security measures, and a proactive stance on information security, we strive to maintain the highest standards of data protection and integrity.</p>

          <h4>Security Governance:</h4>

          <ul>
            <li>Robust governance structure for comprehensive security oversight.</li>
            <li>Clear definitions of roles and responsibilities to ensure accountability.</li>
            <li>SyncIDS maintains a culture of security awareness promoted throughout the organization.</li>
            <li>Security audits include regular 3rd party penetration testing and internal automated security scans.</li>
            <li>Use of automated security alerts and alarms are used to quickly detect and identify potential security incidents.</li>
          </ul>

          <h4>Data Access and Management:</h4>

          <ul>
            <li>Strict access control based on the principle of least privilege.</li>
            <li>Regular monitoring and auditing of data access to prevent unauthorized use.</li>
            <li>Implementation of access control lists and password-based access systems.</li>
          </ul>

          <h4>Data Protection and Encryption Strategies:</h4>

          <ul>
            <li>Our data protection strategy is fundamentally designed to secure sensitive information.</li>
            <li>We utilize state-of-the-art encryption for data both at rest and in transit to ensure confidentiality and security.</li>
            <li>SyncIDS conducts frequent evaluations of our encryption methods to confirm their effectiveness in protecting against unauthorized access.</li>
            <li>All in-transit data is encrypted using secure communication protocols, such as TLS or SSL, to prevent unauthorized interception or modification.</li>
          </ul>

          <h4>Secure Software:</h4>

          <p>Our approach to software development prioritizes security at every stage. From the initial design to the final deployment, we adhere to secure coding standards and best practices. This not only includes authentication and access control but also encompasses encryption, input validation, and secure communication protocols. Our development process is iterative, with regular security assessments to identify and address vulnerabilities promptly.</p>

          <h4>Cloud Infrastructure:</h4>

          <p>SyncIDS leverages cloud-based solutions to deliver its services globally, using advanced computing resources provided by industry leaders in cloud infrastructure. Our deployment on the cloud is configured for high availability and is secured with industry-standard practices, ensuring reliable access and data protection. We utilize a combination of scalable cloud resources which are meticulously managed and maintained to support our offerings.</p>

          <h4>Amazon Web Services (AWS) Infrastructure:</h4>

          <p>Our core infrastructure is hosted on Amazon Web Services (AWS), a trusted and versatile cloud platform that provides a robust framework for our applications and data. AWS offers a comprehensive set of services that we utilize to create and manage a controlled, secure environment tailored to our operational requirements. By employing AWS, SyncIDS ensures resilient infrastructure performance and integrates AWS’s security protocols to safeguard our processes and your data. For details on the security measures and compliance certifications of AWS, please visit the AWS Security Website.</p>

          <h4>Incident Management and Business Continuity:</h4>

          <p>In the event of a security incident or data breach, our incident management policy guides our response, aiming to quickly identify, contain, and mitigate the effects. Our dedicated incident response team is trained to handle such situations efficiently, minimizing impact and restoring normal operations as swiftly as possible. Furthermore, our business continuity and disaster recovery plans are designed to ensure the resilience of our operations, enabling us to maintain critical functions and quickly recover in the face of disruptions.</p>

          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
};
