import { createAction } from 'redux-actions';
import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { mattersUrl } from '../../constants/urls';
import { MATTER_CHECK_CONSISTENCY } from '../../constants/actions';

const checkConsistency = createAction(MATTER_CHECK_CONSISTENCY);

export default (matterId) => (dispatch) => {
  return dispatch(callApi({
    url: mattersUrl + '/' + matterId + '/incomplete-documents'
  }))
    .catch(() => {
      dispatch(addNotification({
        id: `check-consistency-${matterId}`,
        type: 'error',
        message: 'Failed to check consistency.'
      }));
    })
    .then((consistency) => {
      dispatch(checkConsistency({
        matterId,
        consistency
      }));
    });
};
