import { createAction } from 'redux-actions';
import addNotification from '../app/addNotification';
import {
  NPLMATCH_UPLOAD_BEGIN,
  NPLMATCH_UPLOAD_SUCCESS,
  NPLMATCH_UPLOAD_PROGRESS,
  NPLMATCH_UPLOAD_ERROR
} from '../../constants/actions';

import { writePDF } from '../../../utils/pdf';
import uploadFile from '../document/uploadFile';

const nplMatchUploadBegin = createAction(NPLMATCH_UPLOAD_BEGIN);
const nplMatchUploadSuccess = createAction(NPLMATCH_UPLOAD_SUCCESS);
const nplMatchUploadError = createAction(NPLMATCH_UPLOAD_ERROR);
const nplMatchUploadProgress = createAction(NPLMATCH_UPLOAD_PROGRESS);

export default (pdfDoc, bookmark, doc) => (dispatch) => new Promise((resolve, reject) => {
  const progressInterval = setInterval(() => {
    dispatch(nplMatchUploadProgress({ doc, bookmark }));
  }, 1000);
  dispatch(addNotification({
    id: 'write-pdf-' + doc.get('documentNumber'),
    type: 'progress',
    message: `Generating PDF "${doc.get('documentNumber')}"...`
  }));
  dispatch(nplMatchUploadBegin({ doc, bookmark }));
  writePDF(pdfDoc, bookmark.page, bookmark.endPage)
    .then(pdf => {
      if (pdf.length > 1024) {
        dispatch(addNotification({
          id: 'write-pdf-' + doc.get('documentNumber'),
          type: 'success',
          message: `Generated PDF "${doc.get('documentNumber')}"!`
        }));
        dispatch(uploadFile(doc, pdf.toBlob(), 'upload'));
      } else {
        dispatch(addNotification({
          id: 'write-pdf-' + doc.get('documentNumber'),
          type: 'error',
          message: `Corrupted PDF "${doc.get('documentNumber')}"!`
        }));
      }
    })
    .then((response) => {
      clearInterval(progressInterval);
      dispatch(nplMatchUploadSuccess({ doc, bookmark }));
      resolve(response);
    })
    .catch((reason) => {
      clearInterval(progressInterval);
      dispatch(nplMatchUploadError({ doc, bookmark }));
      reject(reason);
    });
});
