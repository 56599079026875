import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { shouldComponentUpdate as shouldPureComponentUpdate } from 'react-immutable-render-mixin';
import ClientInput from './ClientInput';
import Immutable from 'immutable';
import ConfirmModal from '../shared/ConfirmModal';
import PromptModal from '../shared/PromptModal';
import { Button } from 'react-bootstrap';
import _toLower from 'lodash/toLower';
import ClientRenameModal from './ClientRenameModal';

const styleOuter = {
  position: 'absolute',
  top: '10px',
  left: '28px',
  right: '10px',
  display: 'inline-block'
};

const styleInput = {
  position: 'relative',
  top: '-4px',
  height: '30px'
};

const formatValue = (client) => {
  const number = client.get('clientNumber');
  const name = client.get('clientName');
  return name ? number + ' (' + name + ')' : number;
};

export default class ClientID extends Component {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map),
    clientList: PropTypes.instanceOf(Immutable.List),
    selectClient: PropTypes.func.isRequired,
    createClient: PropTypes.func.isRequired,
    updateClient: PropTypes.func.isRequired,
    renameClient: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.client, state.client)) {
      return {
        client: props.client,
        value: formatValue(props.client)
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  shouldComponentUpdate = shouldPureComponentUpdate;

  parseValue(value) {
    const parts = (value || '').match(/(.+)\s\((.+)\)/);
    return {
      clientNumber: parts ? parts[1].trim() : value,
      clientName: parts ? parts[2].trim() : ''
    };
  }

  raiseChange() {
    const currentName = this.props.client.get('clientName');
    const currentNumber = this.props.client.get('clientNumber');
    const { value } = this.state;
    const client = this.parseValue(value);

    if (client.clientNumber !== currentNumber) {
      const selectedClient = this.props.clientList.find(m => (
        _toLower(m.get('clientNumber')) === _toLower(client.clientNumber))
      );
      if (selectedClient) {
        this.props.selectClient(selectedClient.get('id'));
      } else {
        this.setState({
          confirmNewClient: value
        });
      }
    } else if (client.clientName !== currentName) {
      this.props.updateClient({
        id: this.props.client.get('id'),
        ...client
      }, currentNumber);
    }
  }

  onClear() {
    this.setState({
      value: formatValue(this.props.client)
    });
  }

  onBlur() {
    this.raiseChange();
  }

  onSubmit(value) {
    this.setState({
      value
    }, this.raiseChange);
  }

  onChange(value) {
    this.setState({
      value
    });
  }

  cancelNew() {
    this.setState({
      value: formatValue(this.props.client),
      confirmNewClient: false
    });
  }

  confirmNew() {
    const client = this.parseValue(this.state.confirmNewClient);
    this.props.createClient(client);
    this.setState({
      confirmNewClient: false
    });
  }

  renderConfirmNew() {
    return this.state.confirmNewClient && (
      <ConfirmModal
        message={`Create new client - ${this.state.confirmNewClient}?`}
        onCancel={this.cancelNew}
        onConfirm={this.confirmNew}
        focusConfirm
      />
    );
  }

  renderInput() {
    return (
      <ClientInput
        id='client-id-input'
        style={styleInput}
        value={(this.state.value || '')}
        initialValue={formatValue(this.props.client)}
        clientList={this.props.clientList}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onClear={this.onClear}
        onBlur={this.onBlur}
      />
    );
  }

  newClient() {
    this.setState({
      promptNewClient: true
    });
  }

  closePromptNew() {
    this.setState({
      promptNewClient: false
    });
  }

  confirmPromptNew(newValue) {
    const client = this.parseValue(newValue);
    this.props.createClient(client);
    this.closePromptNew();
  }

  renderPromptNew() {
    return this.state.promptNewClient && (
      <PromptModal
        key='new'
        message='New client'
        placeholder='Ex. 012345'
        preventSpaces
        confirmOnEnter
        onCancel={this.closePromptNew}
        onConfirm={this.confirmPromptNew}
      />
    );
  }

  onCloseRename() {
    this.setState({
      promptRenameClient: false
    });
  }

  onClickRename() {
    this.setState({
      promptRenameClient: true
    });
  }

  renderRename() {
    return this.isRenameVisible() && (
      <Button
        size='sm'
        variant='primary'
        style={{
          position: 'absolute',
          top: '-4px',
          right: '46px',
          lineHeight: '1.3em',
          marginLeft: '10px',
          float: 'right',
          height: '30px',
          width: '60px',
          padding: 0
        }}
        onClick={this.onClickRename}
      >
        Rename
      </Button>
    );
  }

  renderPromptRename() {
    return this.state.promptRenameClient && (
      <ClientRenameModal
        client={this.props.client}
        onClose={this.onCloseRename}
        clientList={this.props.clientList}
        renameClient={this.props.renameClient}
      />
    );
  }

  renderNew() {
    return (
      <Button
        size='sm'
        variant='success'
        style={{
          position: 'absolute',
          top: '-4px',
          right: '0',
          lineHeight: '1.3em',
          marginLeft: '10px',
          float: 'right',
          height: '30px',
          width: '40px',
          padding: 0
        }}
        onClick={this.newClient}
      >
        New
      </Button>
    );
  }

  onFormClick(e) {
    e.stopPropagation();
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.raiseChange();
  }

  isRenameVisible() {
    return this.props.client.get('id') && this.props.features.renameClient;
  }

  renderForm() {
    const styleForm = {
      position: 'absolute',
      top: '0',
      left: '105px',
      right: this.isRenameVisible() ? '111px' : '46px'
    };

    return (
      <form
        style={styleForm}
        onClick={this.onFormClick}
        onSubmit={this.onFormSubmit}
      >
        {this.renderInput()}
      </form>
    );
  }

  render() {
    return (
      <div style={styleOuter}>
        <span>Client / BU ID</span>
        {this.renderForm()}
        {this.renderRename()}
        {this.renderNew()}
        {this.renderPromptRename()}
        {this.renderPromptNew()}
        {this.renderConfirmNew()}
      </div>
    );
  }
};
