import { handleActions } from 'redux-actions';

import {
  TODOS_FETCH_START,
  TODOS_FETCH_STOP
} from '../../constants/actions';

const initialState = false;

export default handleActions({

  [TODOS_FETCH_START]: (state) => (true),

  [TODOS_FETCH_STOP]: (state) => (false)

}, initialState);
