import { handleActions } from 'redux-actions';

import {
  TEST_DRIVE_SUBMIT,
  CITE_CHRON_SUBMIT,
  LOCATION_CHANGE,
  CITE_CHRON_UPDATE
} from '../../constants/actions';

const initialState = {
  errorMessage: null,
  successMessage: null
};

export default handleActions({
  [CITE_CHRON_UPDATE]: (state, { payload }) => {
    return Object.assign({}, state, initialState, {
      [payload.field]: payload.value
    });
  },

  [TEST_DRIVE_SUBMIT]: (state, { payload }) => {
    return payload;
  },
  [CITE_CHRON_SUBMIT]: (state, { payload }) => {
    return payload;
  },
  [LOCATION_CHANGE]: () => initialState

}, initialState);
