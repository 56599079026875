import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import MatterPromptNew from './MatterPromptNew';
import SimpleSuggest from '../shared/SimpleSuggest';
import ConfirmModal from '../shared/ConfirmModal';
import { isCategoryMatter } from '../../utils/categories';
import { stripMatterNumber } from '../../utils/matter';

export default class MatterRelationInput extends Component {
  static propTypes = {
    value: PropTypes.string,
    matterList: PropTypes.instanceOf(Immutable.List),
    matterNumber: PropTypes.string.isRequired,
    addNotification: PropTypes.func.isRequired,
    createMatter: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    confirmOnBlur: PropTypes.bool,
    confirmAdd: PropTypes.bool,
    confirmMessage: PropTypes.string,
    placeholder: PropTypes.string,
    preventSpaces: PropTypes.bool,
    features: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      value: '',
      confirmAdd: false,
      confirmCreate: false
    };
  }

  onSubmit(newValue) {
    this.onChange(newValue, this.onConfirm);
  }

  onBlur() {
    if (this.props.confirmOnBlur) {
      this.onConfirm();
    }
  }

  onConfirm() {
    if (this.state.createMatters && this.state.createMatters.length) {
      this.setState({
        confirmCreate: true
      });
      return;
    }
    this.addMatters();
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  onCancelNew() {
    this.setState({
      confirmCreate: false
    }, () => {
      this.onChange('');
    });
  }

  onConfirmNew(matterNumber) {
    this.createMatters(matterNumber);
    this.addMatters();
    this.setState({
      confirmCreate: false
    });
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  resetValue() {
    this.onChange('');
  }

  createMatters(createMatter) {
    this.props.createMatter({
      ...createMatter,
      shouldSelect: false
    }).then(() => {
      this.props.onAdd([createMatter.matterNumber], this.resetValue);
    });
  }

  addMatters() {
    const { addMatters, duplicateMatters } = this.state;
    if (duplicateMatters && duplicateMatters.length) {
      duplicateMatters.forEach(number => {
        this.props.addNotification({
          id: `matter-relation-duplicate-${number}`,
          type: 'warn',
          message: `Matter ${number} is already included.`
        });
      });
    }
    if (addMatters && addMatters.length) {
      if (this.props.confirmAdd) {
        this.setState({
          confirmAdd: addMatters[0]
        });
      } else {
        this.props.onAdd(addMatters, this.resetValue);
      }
    }
  }

  onCancelAdd() {
    this.setState({
      confirmAdd: false,
      value: ''
    });
  }

  onConfirmAdd() {
    this.props.onAdd([this.state.confirmAdd], this.resetValue);
    this.setState({
      confirmAdd: false
    });
  }

  onChange(newValue, callback) {
    const matterValue = stripMatterNumber(newValue);
    const { matterList, value } = this.props;
    const currentList = value ? [value] : [];
    const matterNumbers = matterValue ? [matterValue] : [];
    const createMatters = matterNumbers.filter(number => (
      !matterList.find(matter => matter.get('matterNumber') === number)
    ));
    const existingMatters = matterNumbers.filter(number => (
      !!matterList.find(matter => matter.get('matterNumber') === number)
    ));
    const addMatters = existingMatters.filter(number => (
      currentList.indexOf(number) === -1
    ));
    const duplicateMatters = existingMatters.filter(number => (
      currentList.indexOf(number) !== -1
    ));

    this.setState({
      value: matterValue,
      addMatters,
      createMatters,
      duplicateMatters
    }, callback);
  }

  getSuggestions() {
    return this.props.matterList
      .map(matter => matter.get('matterNumber'))
      .filter(number => number !== this.props.matterNumber)
      .filter(number => !isCategoryMatter(number));
  }

  renderConfirmNew() {
    return this.state.confirmCreate && (
      <MatterPromptNew
        key='new'
        features={this.props.features}
        matterNumber={this.state.createMatters.join(', ')}
        matterList={this.props.matterList}
        onCancel={this.onCancelNew}
        onConfirm={this.onConfirmNew}
      />
    );
  }

  renderConfirmAdd() {
    return this.state.confirmAdd && (
      <ConfirmModal
        message={this.props.confirmMessage.replace('$matterNumber$', this.state.confirmAdd)}
        onCancel={this.onCancelAdd}
        onConfirm={this.onConfirmAdd}
        focusConfirm
      />
    );
  }

  render() {
    return (
      <div>
        <SimpleSuggest
          className='form-control input-sm'
          autoSubmit={this.props.confirmOnBlur}
          preventSpaces={this.props.preventSpaces}
          submitOnEnter
          placeholder={this.props.placeholder}
          allSuggestions={this.getSuggestions()}
          value={this.state.value}
          spellCheck={false}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        />
        {this.renderConfirmNew()}
        {this.renderConfirmAdd()}
      </div>
    );
  }
}
