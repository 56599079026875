import { Record } from 'immutable';
import { generateGoogleDocViewerUrl } from '../utils/google-doc-viewer';
import { formatDate, isRecentMonths } from '../utils/date';

const defaultValues = {
  id: undefined,
  type: undefined,
  clientNumber: undefined,
  documentNumber: undefined,
  publicationDate: undefined,
  country: undefined,
  notes: undefined,
  publicNotes: undefined,
  cited: undefined,
  translated: undefined,
  pdfUrl: undefined,
  applicant: undefined,
  inventor: undefined,
  categories: undefined,
  stored: undefined,
  nonPatentBib: undefined,
  isOtherNpl: undefined,
  pageCount: undefined,
  firmName: undefined,
  priorityDate: undefined,
  nearDupIgnore: undefined,
  createdTime: undefined,
  alsoListedInMatters: undefined,
  matterNotes: undefined
};

export default class DocumentRecord extends Record(defaultValues) {
  get(field) {
    const value = super.get(field);
    if (field === 'inventor' && this.type === 'US' && typeof value === 'string') {
      return value.toUpperCase();
    }
    if (field === 'applicant' && this.type === 'Foreign' && typeof value === 'string') {
      return value.toUpperCase();
    }
    if (field === 'documentNumber' && this.type === 'Foreign' && typeof value === 'string') {
      return value.replace(/^0/, '');
    }
    return value;
  }

  setDocumentNumber(newValue) {
    let newDocNumber = newValue.replace(/\W/g, '');
    if (this.type === 'Foreign') {
      newDocNumber = '0' + newDocNumber;
    }
    return this.setRealDocumentNumber(newDocNumber);
  }

  setRealDocumentNumber(newValue) {
    return this.set('documentNumber', newValue);
  }

  getRealDocumentNumber() {
    let documentNumber = this.documentNumber;
    if (this.type === 'Foreign' && typeof documentNumber === 'string') {
      documentNumber = '0' + documentNumber;
    }
    return documentNumber && documentNumber.toUpperCase();
  }

  isSaved() {
    return !!this.id;
  }

  hasPDF() {
    return this.stored === 'Yes' || this.stored === 'NoPDF';
  }

  getDocViewerUrl() {
    return generateGoogleDocViewerUrl(this.getPDFUrl());
  }

  getPDFUrl() {
    if (this.pdfUrl) {
      return this.pdfUrl;
    }

    if (this.type === 'US') {
      return `http://patentimages.storage.googleapis.com/pdfs/US${this.documentNumber}.pdf`;
    }
  }

  getTextUrl() {
    if (this.type === 'US') {
      return `http://patents.google.com/patent/US${this.documentNumber}`;
    }
  }

  isRecentlyCreated(months) {
    const createdTime = this.get('createdTime');
    return Boolean(createdTime && isRecentMonths(createdTime, months));
  }

  getFirstEnteredNote() {
    const createdTime = this.get('createdTime');
    if (this.isRecentlyCreated(3)) {
      return `NEW! - 1st known to SyncIDS on ${formatDate(createdTime)} (<3m)`;
    } else if (createdTime) {
      return `First known to SyncIDS on ${formatDate(createdTime)}`;
    } else {
      return '';
    }
  }

  getAlsoListedNote() {
    const listed = this.get('alsoListedInMatters');
    if (listed && listed.count() > 1) {
      const count = listed.count() - 1;
      return count === 1
        ? 'Also listed in 1 other matter'
        : `Also listed in ${count} other matters`;
    }
    return '';
  }

  getNotes(field) {
    if (field === 'autoNotes') {
      return `${this.getFirstEnteredNote()} ${this.getAlsoListedNote()}`;
    }
    return this.get(field) || '';
  }

  canDocBeFiled() {
    switch (this.type) {
      case 'US':
        return Boolean(this.publicationDate && this.inventor);
      case 'Foreign':
        return Boolean(
          this.publicationDate &&
          this.country &&
          (this.stored === 'Yes' || this.stored === 'NoPDF')
        );
      case 'Other':
        return Boolean(
          this.nonPatentBib && (this.stored === 'Yes' || this.stored === 'NoPDF')
        );
      default:
        return false;
    }
  }

  shouldResolve() {
    switch (this.type) {
      case 'US':
        return !this.inventor || !this.publicationDate || !this.priorityDate;
      case 'Foreign':
        return !this.applicant || !this.publicationDate || !this.priorityDate;
      case 'other':
      default:
        return false;
    }
  }
};
