import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Form from '../../shared/Form';
import Fieldset from '../../shared/Fieldset';
import Field from '../../shared/Field';
import { Button } from 'react-bootstrap';

export default class ScriptForm extends Form {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    callApi: PropTypes.func.isRequired,
    scriptName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isRunning: false
    };
  }

  runScript() {
    this.setState({ isRunning: true });

    const id = `admin-database-script${this.props.scriptName}`;

    const data = this.props.fields.reduce((acc, field) => {
      acc[field.field] = this.state[field.field];
      return acc;
    }, {});

    this.props.addNotification({
      id,
      type: 'progress',
      message: 'Executing...'
    });

    this.props.callApi({
      method: 'POST',
      url: `/api/admin/${this.props.scriptName}`,
      data
    }).then((response) => {
      if (response.success) {
        this.props.addNotification({
          id,
          type: 'success',
          message: 'Finished'
        });
      } else {
        this.props.addNotification({
          id,
          type: 'error',
          message: response.error
        });
      }
    }, () => {
      this.props.addNotification({
        id,
        type: 'error',
        message: 'Error'
      });
    }).finally(() => {
      this.setState({ isRunning: false });
    });
  }

  onSubmit() {
    this.runScript();
  }

  render() {
    return (
      <Fieldset title={this.props.title}>
        <p>{this.props.description}</p>
        {this.props.fields.map(this.renderInputWith)}
        <Field label='' style={{ width: 'auto' }}>
          <Button
            size='small'
            variant='primary'
            disabled={this.props.isRunning}
            onClick={this.onSubmit}
          >
          Run
          </Button>
        </Field>
      </Fieldset>
    );
  }
};
