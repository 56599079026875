import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';

export default class SweepValue extends Component {
  static propTypes = {
    record: PropTypes.object,
    field: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <div>
        {this.props.record.get(this.props.field)}
      </div>
    );
  }
};
