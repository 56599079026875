import callApi from '../../utils/callApi';
import { reportsUrl } from '../../constants/urls';

export default (clientNumber) => (dispatch, getState) => {
  const params = { threshold: 0, filterMode: 'all', json: 1 };

  if (clientNumber) {
    params.q = `client:${clientNumber}`;
  }

  return dispatch(callApi({
    url: `${reportsUrl}/todos/overlap`,
    params
  }));
};
