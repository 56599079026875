import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import AttorneyForm from './AttorneyForm';
import AttorneyModal from './AttorneyModal';
import AttorneyRecord from '../../models/AttorneyRecord';

export default class Attorney extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    attorney: PropTypes.instanceOf(AttorneyRecord).isRequired,
    attorneyList: PropTypes.instanceOf(Immutable.List).isRequired,
    firmName: PropTypes.string.isRequired,
    updateAttorney: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    fetchAttorneyIfNeeded: PropTypes.func.isRequired,
    fetchAttorneyList: PropTypes.func.isRequired,
    preventClear: PropTypes.bool.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if ((props.email !== state.email) || !Immutable.is(props.attorney, state.attorney)) {
      return {
        email: props.email,
        attorney: props.attorney
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      showModal: false
    };
  }

  componentDidUpdate() {
    this.props.fetchAttorneyList();
    this.props.fetchAttorneyIfNeeded(this.props.email);
  }

  onChange(changedFields) {
    const attorney = this.props.attorney.merge(changedFields);
    this.setState({
      attorney
    });
    if (changedFields.email !== undefined && this.props.onChange) {
      this.props.onChange(null, changedFields.email);
      this.props.fetchAttorneyIfNeeded(changedFields.email);
    }
    if (attorney.get('email')) {
      const attorneyData = {
        email: attorney.get('email'),
        firmName: this.props.firmName,
        ...changedFields
      };

      this.props.updateAttorney(attorneyData, this.props.context).then((updatedData) => {
        if (
          !updatedData.fullName &&
          !updatedData.registrationNumber &&
          !updatedData.applyESignature
        ) {
          this.onEdit();
        }
      });
    }
  }

  onEdit() {
    console.log('onEdit')
    this.setState({
      showModal: true
    });
  }

  onCancelEdit() {
    this.setState({
      showModal: false
    });
  }

  onConfirmEdit(changedFields) {
    this.onChange(changedFields);
    this.setState({
      showModal: false
    });
  }

  renderModal() {
    return this.state.showModal && (
      <AttorneyModal
        record={this.props.attorney}
        onConfirm={this.onConfirmEdit}
        onCancel={this.onCancelEdit}
      />
    );
  }

  render() {
    return (
      <div>
        <AttorneyForm
          record={this.state.attorney || this.props.attorney}
          attorneyList={this.props.attorneyList}
          preventClear={this.props.preventClear}
          onEdit={this.onEdit}
          onChange={this.onChange}
        />
        {this.renderModal()}
      </div>
    );
  }
}
