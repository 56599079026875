import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

export default class DocumentsTitle extends Component {
  static propTypes = {
    docs: PropTypes.instanceOf(Immutable.List).isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node
  };

  getDocCount(docs) {
    const size = this.props.docs.size - 1;
    return size ? ` (${size})` : '';
  }

  render() {
    const showButtons = Boolean(this.props.docs.size - 1);

    return (
      <h4 style={{ marginTop: '25px' }}>
        <a style={{ fontSize: '19px', color: '#0ce3ac' }}>
          {this.props.title} {this.getDocCount()}
        </a>
        {showButtons && this.props.children}
      </h4>
    );
  }
}
