import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import Form from '../../shared/Form';
import ScriptForm from './ScriptForm';

export default class Database extends Form {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    callApi: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
    };
  }

  renderTransferMatters() {
    return (
      <ScriptForm
        title='Transfer Matter'
        description='Transfer the specified matter from a client to another existing client. NPL numbers are recreated following the sequence of the new client.'
        scriptName='transfer-matter'
        fields={[
          {
            label: 'Firm Name',
            field: 'firmName',
            width: '200px'
          },
          {
            label: 'Current Client Number',
            field: 'clientNumber',
            width: '200px'
          },
          {
            label: 'Matter Number',
            field: 'matterNumber',
            width: '200px'
          },
          {
            label: 'New Client Number',
            field: 'newClientNumber',
            width: '200px'
          }
        ]}
        {...this.props}
      />
    );
  }

  render() {
    return (
      <CollapsiblePanel id='database' header='Database'>
        <div className='row'>
          <div className='col-md-12'>
            {this.renderTransferMatters()}
            <hr />
          </div>
        </div>

      </CollapsiblePanel>
    );
  }
};
