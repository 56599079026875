import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SweepFilesIntro extends Component {
  static propTypes = {
    title: PropTypes.bool
  };

  render() {
    return (
      <div>
        {this.props.title ? <h4>Sweep Files</h4> : <br />}
        <p>This migration tool is used to find open matters (and their corresponding application numbers) that need a sweep.</p>
        <p>The input is a list of firm names and client numbers (e.g., fitchevencom; 8473 or “All”). The output is a list, for each entry in the input list, of client numbers, matter numbers, and application numbers (e.g., 8473;100004US;US13182418) in a text file. This text file may then be used as an input to MatterIntake.</p>
      </div>
    );
  }
};
