import { handleActions } from 'redux-actions';
import {
  BATCH_UPLOAD_DOCUMENTS_LOAD,
  BATCH_UPLOAD_DOCUMENTS_CLOSE,
  BATCH_UPLOAD_DOCUMENTS_CONFIRM
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [BATCH_UPLOAD_DOCUMENTS_LOAD]: (state, { payload }) => (payload),
  [BATCH_UPLOAD_DOCUMENTS_CLOSE]: () => initialState,
  [BATCH_UPLOAD_DOCUMENTS_CONFIRM]: () => initialState

}, initialState);
