import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Tooltip from '../shared/Tooltip';
import BatchInputLink from './BatchInputLink';

export default class BatchUploadLink extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    onUpload: PropTypes.func,
    docType: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      reloadInput: false
    };
  }

  assignRefUpload(element) {
    this.uploadRef = element;
  }

  onClickUpload() {
    this.uploadRef.click();
  }

  onInputChange(e) {
    const file = e.target.files[0];
    this.props.onUpload(this.props.docType, file);
    this.setState({
      reloadInput: true
    }, () => {
      this.setState({
        reloadInput: false
      });
    });
  }

  renderFileInput() {
    return this.state.reloadInput || (
      <input
        ref={this.assignRefUpload}
        type='file'
        accept='.zip'
        onChange={this.onInputChange}
        style={{
          display: 'none'
        }}
      />
    );
  }

  render() {
    return (
      <BatchInputLink
        features={this.props.features}
        docType={this.props.docType}
        onClick={this.onClickUpload}
      >
        <div>
          <Tooltip
            id='batch-upload'
            tip='Upload a ZIP file containing NPL numbered PDF files (e.g. NPL5, NPL99, etc.)'
            placement='top'
            stylePopover={{ marginTop: '-20px' }}
          >
            Batch upload
          </Tooltip>
          {this.renderFileInput()}
        </div>
      </BatchInputLink>
    );
  }
};
