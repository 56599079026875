import { connect } from 'react-redux';
import actions from '../../redux/actions';
import Client from './Client';
import Immutable from 'immutable';

function mapStateToProps(state) {
  const {
    app: {
      features,
      selectedClientId: clientId,
      expandedClientMatter: expanded,
      selectedClientTab
    },
    entities
  } = state;
  const client = entities.getIn(['clients', clientId]) || Immutable.Map();
  const clientList = entities.get('clientList') || Immutable.List();

  return {
    selectedClientTab,
    features,
    client,
    clientList,
    expanded: !!expanded
  };
};

export default connect(mapStateToProps, actions)(Client);
