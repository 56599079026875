import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import DocumentRecord from '../../models/DocumentRecord';

export default class DocumentAutoNotes extends Component {
  static propTypes = {
    doc: PropTypes.instanceOf(DocumentRecord).isRequired,
    backgroundColor: PropTypes.string.isRequired,
    showListed: PropTypes.func.isRequired
  };

  static defaultProps = {
    doc: new DocumentRecord()
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getFirstEntered() {
    const note = this.props.doc.getFirstEnteredNote();
    if (this.props.doc.isRecentlyCreated(3)) {
      return (
        <strong style={{ color: '#00a379' }}>
          {note}
        </strong>
      );
    } else {
      return note;
    }
  }

  onClickListed(e) {
    this.props.showListed(this.props.doc);
  }

  getAlsoListed() {
    const text = this.props.doc.getAlsoListedNote();
    if (text) {
      return (
        <span>
          {text}
          <span
            style={{
              position: 'relative',
              left: '3px',
              fontSize: '12px',
              cursor: 'pointer'
            }}
            className='fa fa-external-link'
            onClick={this.onClickListed}
          />
        </span>
      );
    }
  }

  isNPL() {
    return this.props.doc.get('type') === 'Other';
  }

  isSaved() {
    return this.props.doc.isSaved();
  }

  render() {
    return (
      <div
        className='form-group'
        style={{ backgroundColor: 'white', borderRadius: '3px', marginBottom: '0' }}
      >
        <div
          className={`form-control input-sm ${this.isNPL() ? '' : 'input-multi'}`}
          style={{
            backgroundColor: this.isSaved() ? this.props.backgroundColor : 'rgb(235, 235, 235)',
            height: this.isNPL() ? '72.5px' : '33.5px',
            cursor: this.isSaved() ? undefined : 'not-allowed',
            overflow: 'auto'
          }}
        >
          {this.getFirstEntered()}
          {this.isNPL() ? <br /> : ' - '}
          {this.getAlsoListed()}
        </div>
      </div>
    );
  }
}
