import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Button } from 'react-bootstrap';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import TableList, { Column } from '../../shared/TableList';
import SweepValue from './SweepValue';
import SweepOutput from './SweepOutput';
import SweepFilesIntro from './SweepFilesIntro';
import Field from '../../shared/Field';

export default class SweepFiles extends Component {
  static propTypes = {
    createSweepFile: PropTypes.func.isRequired,
    findOpenMatters: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      input: window.localStorage.getItem('sweepFilesInput') || '',
      rows: null,
      loading: false
    };
  }

  onChangeInput(e) {
    this.setState({
      input: e.target.value
    });
  }

  onClickReset() {
    this.setState(this.getInitialState());
  }

  onClickSweep() {
    window.localStorage.setItem('sweepFilesInput', this.state.input);

    const cols = ['firmName', 'client'];
    const rows = this.state.input.trim().split(/\n\r?/).map((line, index) => {
      const values = line.split(';');
      const row = {
        index,
        loading: true
      };
      cols.forEach((col, colIndex) => {
        row[col] = values[colIndex];
      });
      return row;
    });
    rows.forEach((row, rowIndex) => {
      this.props.findOpenMatters(row.firmName, row.client).then(sweep => {
        this.setState({
          rows: this.state.rows
            .setIn([rowIndex, 'loading'], false)
            .setIn([rowIndex, 'sweep'], sweep)
        });
      });
    });

    this.setState({
      rows: Immutable.fromJS(rows)
    });
  }

  createSweepFile(rows) {
    this.props.createSweepFile(rows, this.state.format);
  }

  onClickDownload() {
    this.createSweepFile(this.state.rows);
  }

  renderForm() {
    return (
      <Field label='Sweep input' style={{ paddingBottom: '15px' }}>
        <textarea
          className='form-control input-sm'
          rows={10}
          cols={50}
          value={this.state.input}
          onChange={this.onChangeInput}
          spellCheck={false}
          style={{ resize: 'none', width: '100%' }}
        />
      </Field>
    );
  }

  renderTable() {
    return (
      <TableList
        id='rows'
        forceUpdate={false}
        rows={this.state.rows}
        rowKey='index'
        rowAs='record'
      >
        <Column key='firmName' title='Firm'>
          <SweepValue field='firmName' />
        </Column>
        <Column key='client' title='Client'>
          <SweepValue field='client' />
        </Column>
        <Column key='output' title='' style={{ width: '200px' }}>
          <SweepOutput createSweepFile={this.createSweepFile} />
        </Column>
      </TableList>
    );
  }

  renderSweep() {
    return (
      <Button
        disabled={!this.state.input}
        variant='primary'
        onClick={this.onClickSweep}
      >
        Sweep
      </Button>
    );
  }

  renderDownload() {
    return (
      <Button
        variant='primary'
        onClick={this.onClickDownload}
      >
        Download
      </Button>
    );
  }

  renderReset() {
    return (
      <Button
        variant='secondary'
        style={{ marginRight: '15px' }}
        onClick={this.onClickReset}
      >
        Clear
      </Button>
    );
  }

  renderLoading() {
    return (
      <Button
        disabled
        variant='primary'
      >
        Processing...
      </Button>
    );
  }

  isLoading() {
    return this.state.rows && this.state.rows.some(row => row.get('loading'));
  }

  renderSubmit() {
    if (this.isLoading()) {
      return this.renderLoading();
    }
    if (this.state.rows) {
      return this.renderDownload();
    }
    return this.renderSweep();
  }

  onChangeFormat(e) {
    this.setState({ format: e.target.value });
  }

  renderFormat() {
    return (
      <Field label='Output format' style={{ paddingBottom: '10px' }}>
        <select
          className='form-control'
          onChange={this.onChangeFormat}
          value={this.state.format}
        >
          <option value='default'>clientNumber;matterNumber;applicationNumber</option>
          <option value='applicationNumber'>applicationNumber</option>
        </select>
      </Field>
    );
  }

  render() {
    return (
      <CollapsiblePanel id='sweep-files' header='Sweep Files'>
        <div className='row'>
          <div className='col-md-12'>
            <SweepFilesIntro />
            <hr />
          </div>
        </div>
        {this.renderFormat()}
        {this.state.rows ? null : this.renderForm()}
        {this.state.rows ? this.renderTable() : null}
        <div className='row'>
          <div className='col-md-12'>
            <div style={{ textAlign: 'right', padding: '0 12px 15px 12px' }}>
              {this.renderReset()}
              {this.renderSubmit()}
            </div>
          </div>
        </div>
      </CollapsiblePanel>
    );
  }
};
