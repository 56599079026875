import { API_ROOT } from '../../constants/urls';
import axios from '../../../utils/axios';

export default (matter) => (dispatch) => {
  return axios({
    url: `${API_ROOT}/sweeper-api/patent-family/search`,
    method: 'GET',
    params: {
      docs: matter.get('applicationNumber').replace(/[^0-9]/g, '')
    }
  }).then((response) => {
    return response.data;
  });
};
