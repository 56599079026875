import React, { Component } from 'react';
import autoBind from 'class-autobind';
import { DropdownButton } from 'react-bootstrap';
import { MenuItem } from '../shared/MenuItem';
import linkMigration from '../../files/Legacy_Data_Migration_Service_4.0.pdf';
import templateMigration from '../../files/SyncIDS_Data_Migration_Template.xlsx';

export default class TodosMigration extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  render() {
    return (
      <div className='pull-left' style={{ marginRight: '15px' }}>
        <DropdownButton
          size='sm'
          variant='primary'
          title='Migration'
          className='no-caret'
          id='dashboard-migration'
        >
          <MenuItem target='_blank' download href={linkMigration}>
            How to request a migration
          </MenuItem>
          <MenuItem target='_blank' download href={templateMigration}>
            Download Migration Template
          </MenuItem>
        </DropdownButton>
      </div>
    );
  }
}
