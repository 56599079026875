import { handleActions } from 'redux-actions';

import {
  NEAR_DUPLICATE_BEGIN,
  NEAR_DUPLICATE_ERROR,
  NEAR_DUPLICATE_SUCCESS,
  NPLMATCH_UPLOAD_BEGIN,
  NPLMATCH_UPLOAD_PROGRESS,
  NPLMATCH_UPLOAD_SUCCESS,
  NPLMATCH_UPLOAD_ERROR
} from '../../constants/actions';

const initialState = false;

export default handleActions({

  [NEAR_DUPLICATE_BEGIN]: () => true,
  [NEAR_DUPLICATE_SUCCESS]: () => false,
  [NEAR_DUPLICATE_ERROR]: () => false,

  [NPLMATCH_UPLOAD_BEGIN]: () => true,
  [NPLMATCH_UPLOAD_PROGRESS]: () => true,
  [NPLMATCH_UPLOAD_SUCCESS]: () => false,
  [NPLMATCH_UPLOAD_ERROR]: () => false

}, initialState);
