import { getBookmarks } from './pdf';

export function validatePDF(file, callback) {
  const reader = new FileReader();

  reader.onload = (loadEvent) => {
    getBookmarks(loadEvent.target.result)
      .then(({ pdfDoc }) => {
        callback(pdfDoc && pdfDoc.pdfInfo.numPages > 0);
      })
      .catch(() => {
        callback(false);
      });
  };

  reader.readAsDataURL(file);
}
