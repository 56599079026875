import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import CollapsiblePanel from '../shared/CollapsiblePanel';
import SyncTwoWay from './SyncTwoWay';
import SyncOneWay from './SyncOneWay';
import SyncCategory from './SyncCategory';
import { splitCommaUnique, joinCommaUnique } from '../../utils/text-utils';
import { isCategoryMatter } from '../../utils/categories';
import SyncOverlap from './SyncOverlap';
import HelpIcon from '../shared/HelpIcon';
import SyncParentPrune from './SyncParentPrune';

export default class Sync extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired,
    updateClient: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired,
    createMatter: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    addMatterTwoWayRelation: PropTypes.func.isRequired,
    deleteCategoryMatterIfNeeded: PropTypes.func.isRequired,
    removeMatterFromTwoWaySyncGroup: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    toggleSyncPanel: PropTypes.func.isRequired,
    selectMatter: PropTypes.func.isRequired,
    viewCategory: PropTypes.func.isRequired,
    refreshDocuments: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    removeCategory: PropTypes.func.isRequired,
    fetchSyncOverlap: PropTypes.func.isRequired,
    fetchFamily: PropTypes.func.isRequired,
    addFirstMatterTwoWayRelation: PropTypes.func.isRequired,
    fetchParentMatters: PropTypes.func.isRequired,
    addParentMatter: PropTypes.func.isRequired,
    deleteParentMatter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidUpdate() {
    this.fetchFamily(false);
  }

  isCategoryMatter() {
    return isCategoryMatter(this.props.matter.get('matterNumber'));
  }

  fetchFamily(forceRefresh = false, props = this.props) {
    if (!props.matter) {
      return;
    }

    this.props.fetchFamily(props.matter, forceRefresh);
  }

  toggleSyncPanel() {
    if (!this.isCategoryMatter()) {
      this.props.toggleSyncPanel();
    }
  }

  addOneWay(matterNumber, resetValue) {
    const id = this.props.matter.get('id');
    const currentValue = splitCommaUnique(this.props.matter.get('oneWayRelatedMatters'));
    const oneWayRelatedMatters = joinCommaUnique(currentValue.concat(matterNumber));

    this.props.updateMatter({ id, oneWayRelatedMatters }, this.props.matter.get('matterNumber'))
      .then(resetValue)
      .then(() => this.fetchFamily(true));
  }

  addTwoWay(matterNumbers, resetValue) {
    const matter = this.props.matter;
    if (!matter.get('twoWayRelatedMatters')) {
      this.props.addFirstMatterTwoWayRelation(matter.get('id'), matterNumbers.shift())
        .then(() => {
          this.addMatterTwoWayRelation(matterNumbers, resetValue);
        });
    } else {
      this.addMatterTwoWayRelation(matterNumbers, resetValue);
    }
  }

  addMatterTwoWayRelation(matterNumbers, resetValue) {
    const matterId = this.props.matter.get('id');
    let promise = Promise.resolve();
    matterNumbers.forEach(matterNumber => {
      promise = promise.then(() => this.props.addMatterTwoWayRelation(matterId, matterNumber));
    });
    promise
      .then(() => resetValue())
      .then(() => this.fetchFamily(true));
  }

  removeOneWay(matterNumber) {
    const id = this.props.matter.get('id');
    const currentValue = splitCommaUnique(this.props.matter.get('oneWayRelatedMatters'));
    const oneWayRelatedMatters = joinCommaUnique(currentValue.filter(number => number !== matterNumber));

    return this.props.updateMatter({ id, oneWayRelatedMatters }, this.props.matter.get('matterNumber'));
  }

  removeTwoWay(matterNumber) {
    const matter = this.props.matterList.find(matter => matter.get('matterNumber') === matterNumber);

    return this.props.removeMatterFromTwoWaySyncGroup(this.props.client.get('id'), matter.get('id'), matterNumber);
  }

  renderContent() {
    if (
      !this.props.matter ||
      !this.props.matter.get('id') ||
      !this.props.expanded ||
      this.isCategoryMatter()
    ) {
      return;
    }

    let cols = 4;
    if (this.props.features.syncOverlap) {
      cols++;
    }

    const styleRow = {
      width: `${100 / cols}%`,
      float: 'left',
      padding: '0 5px'
    };

    return (
      <div className='row' style={{ padding: '10px 10px' }}>
        <div style={styleRow}>
          <SyncTwoWay
            features={this.props.features}
            client={this.props.client}
            matter={this.props.matter}
            matterList={this.props.matterList}
            createMatter={this.props.createMatter}
            selectMatter={this.props.selectMatter}
            addNotification={this.props.addNotification}
            removeTwoWay={this.removeTwoWay}
            addTwoWay={this.addTwoWay}
          />
        </div>
        <div style={styleRow}>
          <SyncOneWay
            features={this.props.features}
            client={this.props.client}
            matter={this.props.matter}
            matterList={this.props.matterList}
            createMatter={this.props.createMatter}
            selectMatter={this.props.selectMatter}
            addNotification={this.props.addNotification}
            removeOneWay={this.removeOneWay}
            addOneWay={this.addOneWay}
          />
        </div>
        <div style={styleRow}>
          <SyncParentPrune
            features={this.props.features}
            client={this.props.client}
            matter={this.props.matter}
            matterList={this.props.matterList}
            addNotification={this.props.addNotification}
            createMatter={this.props.createMatter}
            selectMatter={this.props.selectMatter}
            addParentMatter={this.props.addParentMatter}
            deleteParentMatter={this.props.deleteParentMatter}
            fetchParentMatters={this.props.fetchParentMatters}
          />
        </div>
        {this.props.features.syncOverlap && (
          <div style={styleRow}>
            <SyncOverlap
              client={this.props.client}
              matter={this.props.matter}
              matterList={this.props.matterList}
              selectMatter={this.props.selectMatter}
              addOneWay={this.addOneWay}
              addTwoWay={this.addTwoWay}
              fetchSyncOverlap={this.props.fetchSyncOverlap}
            />
          </div>
        )}
        <div style={styleRow}>
          <SyncCategory
            client={this.props.client}
            matter={this.props.matter}
            matterList={this.props.matterList}
            updateClient={this.props.updateClient}
            updateMatter={this.props.updateMatter}
            viewCategory={this.props.viewCategory}
            createCategory={this.props.createCategory}
            removeCategory={this.props.removeCategory}
            deleteCategoryMatterIfNeeded={this.props.deleteCategoryMatterIfNeeded}
            refreshDocuments={this.props.refreshDocuments}
          />
        </div>
      </div>
    );
  }

  renderHelp() {
    return (
      <div>
        <ul>
          <li style={{ padding: '5px 0' }}>
            <strong>Two-Way Sync</strong> automatically cross-cites all of the references for a group of matters in both directions
          </li>
          <li style={{ padding: '5px 0' }}>
            <strong>One-Way Import</strong> automatically flows references from one group of matters to another, but not in reverse
          </li>
          {this.props.features.syncOverlap && (
            <li style={{ padding: '5px 0' }}>
              <strong>Subject Matter Suggestions</strong> are matters that cite many of the same documents as the current matter
            </li>
          )}
          <li style={{ padding: '5px 0' }}>
            <strong>Categories</strong> allows categorized documents to automatically flow to subscribing matters
          </li>
        </ul>
      </div>
    );
  }

  renderHeader() {
    return (
      <div>
        Sync Panel
        &nbsp;&nbsp;&nbsp;
        <HelpIcon
          id='sync-panel'
          stylePopover={{ maxWidth: '600px' }}
          help={this.renderHelp()}
        />
      </div>
    );
  }

  render() {
    return (
      <CollapsiblePanel
        id='sync-panel'
        disabled={this.isCategoryMatter()}
        expanded={this.props.expanded && !this.isCategoryMatter()}
        header={this.renderHeader()}
        onToggle={this.toggleSyncPanel}
      >
        {this.renderContent()}
      </CollapsiblePanel>
    );
  }
}
