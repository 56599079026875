import callApi from '../../utils/callApi';
import { mattersUrl } from '../../constants/urls';
import { PENDING_OFFICE_ACTIONS } from '../../constants/actions';
import { createAction } from 'redux-actions';

const pendingOfficeActions = createAction(PENDING_OFFICE_ACTIONS);

function parseJSON(text) {
  try {
    return JSON.parse(text);
  } catch (e) {
    return null;
  }
}

export default (matterId) => (dispatch) => {
  if (!matterId) {
    throw new Error('missing matterId');
  }

  dispatch(pendingOfficeActions({
    matterId,
    pendingOfficeActions: []
  }));

  return dispatch(callApi({
    url: mattersUrl + '/' + matterId + '/pending-office-actions'
  }))
    .then(response => {
      if (!response) {
        return;
      }

      return dispatch(pendingOfficeActions({
        matterId,
        pendingOfficeActions: response.map(item => Object.assign(item, {
          textractData: item.textractJson ? parseJSON(item.textractJson) : null
        }))
      }));
    });
};
