import Immutable from 'immutable';

export function getDocNumbersFromMatter(matter, includeDeleted) {
  const cited = matter.get('citedArt', Immutable.List());
  const uncited = matter.get('uncitedArt', Immutable.List());
  const deleted = matter.get('deletedArt', Immutable.List());
  const docNumbers = cited.concat(uncited).concat(includeDeleted ? deleted : []).toJS();

  return docNumbers.filter(docNumber => Boolean(docNumber));
}
