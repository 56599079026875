import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import app from './app';
import auth from './auth';
import categories from './categories';
import documents from './documents';
import entities from './entities';
import migration from './migration';
import nearDuplicate from './near-duplicate';
import notifications from './notifications';
import priorArt from './prior-art';
import testDrive from './test-drive';
import todos from './todos';
import admin from './admin';
import matterNotes from './matter-notes';
import attorneyList from './attorney-list';

const appReducer = combineReducers({
  admin,
  app,
  auth,
  categories,
  documents,
  entities,
  migration,
  nearDuplicate,
  notifications,
  priorArt,
  routing: routerReducer,
  testDrive,
  todos,
  matterNotes,
  attorneyList
});

export default (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = {
      routing: state.routing
    };
  }
  const st = appReducer(state, action);
  return st;
};
