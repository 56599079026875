import { createAction } from 'redux-actions';
import { MATTER_SELECT } from '../../constants/actions';
import fetchMatterIfNeeded from './fetchMatterIfNeeded';

const selectMatter = createAction(MATTER_SELECT);

export default (selectedMatterId) => (dispatch, getState) => {
  const currentMatterId = getState().app.selectedMatterId;
  if (selectedMatterId !== currentMatterId) {
    dispatch(fetchMatterIfNeeded(selectedMatterId));
    dispatch(selectMatter({ selectedMatterId }));
  }
};
