import callApi from '../../utils/callApi';
import { clientsUrl } from '../../constants/urls';

export default (clientId, firmArg) => (dispatch, getState) => {
  const { auth: { firmName } } = getState();

  const params = {};
  if (firmArg) {
    params.overrideFirmName = firmArg.replace('.', '');
  } else {
    params.firmName = firmName;
  }

  return dispatch(callApi({
    url: `${clientsUrl}/${clientId}/matters`
  }));
};
