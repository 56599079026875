import actions from '../../redux/actions';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import Documents from './Documents';
import { getVisibleDocuments } from '../../utils/get-visible-documents';

function mapStateToProps(state) {
  const {
    app: {
      selectedMatterId,
      selectedClientId,
      features
    },
    entities,
    documents: {
      notesTab,
      notesSearch,
      newDocuments,
      isLoadingDocuments,
      updateDoc,
      batchUploadData,
      citedFilter
    },
    nearDuplicate,
    matterNotes
  } = state;

  let categories = Immutable.List();
  let client = Immutable.Map();
  if (selectedClientId) {
    client = entities.getIn(['clients', selectedClientId]) || Immutable.Map();
    if (client.get('clientCategories')) {
      categories = Immutable.fromJS(client.get('clientCategories').split(/ *, */));
    }
  }
  const matter = entities.getIn(['matters', selectedMatterId]) || Immutable.Map();
  const documents = entities.getIn(['documents', matter.get('clientNumber')]) || Immutable.Map();
  const nplSuggestions = entities.getIn(['nplSuggestions', matter.get('clientNumber')]) || Immutable.Map();
  const pendingOfficeActions = entities.getIn(['pendingOfficeActions', selectedMatterId]);

  return {
    matterNotes: matterNotes[selectedMatterId] || [],
    pendingOfficeActions,
    features,
    client,
    matter,
    documents,
    categories,
    isLoadingDocuments,
    newDocuments,
    nplSuggestions,
    updateDoc,
    nearDuplicate,
    batchUploadData,
    notesTab,
    notesSearch,
    visibleDocuments: getVisibleDocuments(state, true),
    citedFilter
  };
}

export default connect(mapStateToProps, actions)(Documents);
