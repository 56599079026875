import callApi from '../../utils/callApi';
import { createAction } from 'redux-actions';
import { UPDATE_PAIR_STATUS } from '../../constants/actions';

const updatePairStatus = createAction(UPDATE_PAIR_STATUS);

export default (arg) => (dispatch, getState) => {
  const applicationNumber = arg.replace(/[\D]/g, '');
  const {
    todos: {
      pairStatus
    }
  } = getState();

  if (pairStatus[applicationNumber]) {
    return Promise.resolve(pairStatus[applicationNumber]);
  }

  dispatch(updatePairStatus({
    applicationNumber,
    pairStatus: {
      checking: true
    }
  }));

  return dispatch(callApi({
    url: `/api/public-data/uspto-application/pair-status/${encodeURIComponent(applicationNumber)}`
  }))
    .then(response => {
      const state = {
        ...response,
        checking: false
      };

      dispatch(updatePairStatus({
        applicationNumber,
        pairStatus: state
      }));

      return state;
    })
    .catch(() => {
      const state = {
        failed: true
      };

      dispatch(updatePairStatus({
        applicationNumber,
        pairStatus: state
      }));

      return state;
    });
};
