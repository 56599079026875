import { handleActions } from 'redux-actions';

import {
  AUTH_OPEN_MODAL,
  AUTH_CANCEL_CLICK,
  AUTH_PASSWORD_SUCCESS,
  AUTH_LOGIN_SUCCESS
} from '../../constants/actions';

const initialState = false;

export default handleActions({

  [AUTH_OPEN_MODAL]: () => (true),

  [AUTH_CANCEL_CLICK]: () => (false),

  [AUTH_PASSWORD_SUCCESS]: () => (false),

  [AUTH_LOGIN_SUCCESS]: () => (false)

}, initialState);
