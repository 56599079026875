import { createAction } from 'redux-actions';
import { TOGGLE_CLIENT_MATTER } from '../../constants/actions';

const toggleClientMatter = createAction(TOGGLE_CLIENT_MATTER);

export default (onlyFirstTime) => (dispatch, getState) => {
  const {
    app: {
      expandedClientMatter
    }
  } = getState();

  if (onlyFirstTime) {
    if (expandedClientMatter === null) {
      dispatch(toggleClientMatter());
    }
    return;
  }

  dispatch(toggleClientMatter());
};
