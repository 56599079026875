import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import TodoRecord from '../../models/TodoRecord';
import DropdownHelp from '../shared/DropdownHelp';
import CheckButton from '../shared/CheckButton';
import MenuItemHelp from '../shared/MenuItemHelp';
import Immutable from 'immutable';

export default class TodoStatement extends Component {
  static propTypes = {
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    updateMatter: PropTypes.func.isRequired,
    checkUncitedDocuments: PropTypes.func.isRequired
  };

  static defaultProps = {
    todo: new TodoRecord()
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.todo, state.todo)) {
      return {
        todo: props.todo,
        hasOldUncited: false,
        loadingDocs: false,
        statement: props.todo.statement,
        checked: props.todo.isWithin30Days()
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onOpen() {
    this.setState({ loadingDocs: true });
    this.props.checkUncitedDocuments(this.props.todo).then((hasOldUncited) => {
      this.setState({
        hasOldUncited,
        loadingDocs: false
      });
    });
  }

  onChange(statement) {
    if (statement === 'within30Days') {
      this.onChange30Days(!this.state.checked);
      return;
    }

    this.setState({ statement });

    const updateMatter = {
      id: this.props.todo.id,
      statement
    };
    if (statement === '2') {
      updateMatter.within30Days = false;
    }
    this.props.updateMatter(updateMatter);
  }

  is30DaysEnabled() {
    return this.props.todo.statement === '0' || this.props.todo.statement === '1';
  }

  onChange30Days(checked) {
    if (checked && !this.is30DaysEnabled()) {
      this.onChange30Days(false);
      return;
    }

    this.setState({ checked });
    this.props.updateMatter({
      id: this.props.todo.get('id'),
      within30Days: checked
    }, this.props.todo.get('matterNumber'));
  }

  render30Days() {
    return (
      <div className='pull-left'>
        <CheckButton
          disabled={!this.is30DaysEnabled()}
          stopPropagation={false}
          style={{
            position: 'relative',
            left: '0px',
            top: '-2px',
            width: '20px'
          }}
          className='pull-left'
          checked={this.props.todo.get('statement') === '2' ? false : this.state.checked}
          onChange={this.onChange30Days}
        />
        &lt; 30 Days
      </div>
    );
  }

  renderFooter() {
    return (
      <MenuItemHelp
        id='statement-30-days'
        styleLink={{
          height: '30px',
          backgroundColor: 'transparent'
        }}
        disabled={!this.is30DaysEnabled()}
        help='704(d) All items cited in a foreign counterpart < 30 days ago'
        label={this.render30Days()}
        eventKey='within30Days'
      />
    );
  }

  getOptions() {
    const statementOptions = [{
      value: '0',
      label: '§1.97(e)(1)',
      help: 'All items first cited in a foreign counterpart < 3 months ago',
      labelClass: this.state.hasOldUncited ? 'text-danger' : null,
      helpIcon: this.state.loadingDocs ? 'fa-spinner fa-spin' : undefined
    }, {
      value: '1',
      label: '§1.97(e)(2)',
      help: 'All items known < 3 months ago',
      labelClass: this.state.hasOldUncited ? 'text-danger' : null,
      helpIcon: this.state.loadingDocs ? 'fa-spinner fa-spin' : undefined
    }];

    if (this.props.todo.timing !== '2') {
      statementOptions.push({
        value: '2',
        label: 'None',
        help: this.props.todo.timing === '3'
          ? 'Neither of the above apply'
          : 'Neither of the above apply (so pay the fee)'
      });
    }

    return statementOptions;
  }

  render() {
    const { todo } = this.props;

    if (todo.timing === '0') {
      return (
        <span>None</span>
      );
    }

    return (
      <DropdownHelp
        id={`dropdown-statement-${todo.id}`}
        delayValues={['within30Days']}
        options={this.getOptions()}
        selected={this.state.statement}
        emptyLabel='Statement'
        footer={this.renderFooter()}
        onOpen={this.onOpen}
        onChange={this.onChange}
      />
    );
  }
};
