import React from 'react';
import PropTypes from 'prop-types';
import Form from '../shared/Form';
import Fieldset from '../shared/Fieldset';
import AttorneyRecord from '../../models/AttorneyRecord';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import Field from '../shared/Field';
import { Button } from 'react-bootstrap';
import { isValidEmail } from '../../utils/text-utils';
import SimpleSuggest from '../shared/SimpleSuggest';

export default class AttorneyForm extends Form {
  static propTypes = {
    title: PropTypes.node,
    record: PropTypes.instanceOf(AttorneyRecord).isRequired,
    attorneyList: PropTypes.instanceOf(Immutable.List).isRequired,
    onChange: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    preventClear: PropTypes.bool.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.record, state.record)) {
      return {
        record: props.record,
        ...props.record.toJS()
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  onClick(e) {
    let name = '';
    if (e.target.nodeName.toLowerCase() === 'label') {
      name = e.target.firstChild.name;
    } else {
      name = e.target.name || e.target.getAttribute('data-name');
    }
    console.log(e, name);
    if (this.state.email && ['registrationNumber', 'fullName', 'applyESignature'].includes(name)) {
      this.props.onEdit();
    }
  }

  onEmailBlur(e) {
    if (this.props.preventClear && !e.target.value) {
      return;
    }

    this.raiseChange('email');
  }

  onEmailChange(value) {
    this.setState({
      email: this.props.preventClear && !value
        ? this.props.record.get('email')
        : this.getNewEmailValue(value)
    });
  }

  onEmailSubmit(value) {
    this.setState({
      email: this.props.preventClear && !value
        ? this.props.record.get('email')
        : this.getNewEmailValue(value)
    }, () => {
      this.raiseChange('email');
    });
  }

  getNewEmailValue(value) {
    const attorney = this.props.attorneyList.find(attorney => (
      value === this.formatAttorneySuggestion(attorney)
    ));
    return attorney ? attorney.get('email') : value;
  }

  getEmailValue() {
    return (this.state.email || '').toLowerCase().replace(/\s/g, '');
  }

  getSuggestions() {
    return this.props.attorneyList
      .filter(attorney => isValidEmail(attorney.get('email')))
      .filter(attorney => attorney.get('email').includes(this.getEmailValue()))
      .map(this.formatAttorneySuggestion);
  }

  formatAttorneySuggestion(attorney) {
    if (attorney.get('fullName')) {
      return `${attorney.get('email')} - ${attorney.get('fullName')} `;
    }
    return attorney.get('email');
  }

  renderEmail() {
    return (
      <Field label='Attorney Email' style={{ float: 'left', width: '73%' }}>
        <SimpleSuggest
          className='form-control'
          id='attorney-email-input'
          name='email'
          placeholder=''
          allSuggestions={this.getSuggestions()}
          value={this.getEmailValue()}
          onChange={this.onEmailChange}
          onBlur={this.onEmailBlur}
          onFocus={this.onFocus}
          onSubmit={this.onEmailSubmit}
          spellCheck={false}
          autoSubmit
          submitOnBlur
          preventSpaces
          preventCommas
        />
      </Field>
    );
  }

  renderRegistration() {
    return (
      <Field label='Reg #' style={{ float: 'left', width: '20%' }}>
        <input
          disabled
          type='text'
          name='registrationNumber'
          placeholder=''
          value={this.state.registrationNumber || ''}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </Field>
    );
  }

  renderFullName() {
    return (
      <Field label='Full Name' style={{ float: 'left', width: '53%' }}>
        <input
          disabled
          type='text'
          name='fullName'
          placeholder=''
          value={this.state.fullName || ''}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </Field>
    );
  }

  renderSignature() {
    return (
      <div
        data-name='applyESignature'
        style={{ float: 'left', width: '27%', marginTop: '32px', paddingLeft: '2px', cursor: 'not-allowed' }}
      >
        <span
          data-name='applyESignature'
          className={this.state.applyESignature ? 'text-success fa fa-check-square-o' : 'fa fa-square-o'}
        />
        &nbsp; Apply E-Signature
      </div>
    );
  }

  renderEdit() {
    return (
      <div className='checkbox' style={{ float: 'left', width: '27%', marginTop: '25px' }}>
        <Button
          onClick={this.props.onEdit}
          variant='primary'
          size='sm'
          style={{ width: '100%', height: '33.5px' }}
        >
          Edit Profile
        </Button>
      </div>
    );
  }

  render() {
    return (
      <Fieldset onClick={this.onClick}>
        {this.renderEmail()}
        {this.renderEdit()}
        {this.renderFullName()}
        {this.renderRegistration()}
        {this.renderSignature()}
      </Fieldset>
    );
  }
}
