import Immutable, { Record } from 'immutable';

const entitySizes = {
  Large: '$260',
  Small: '$104',
  Micro: '$52'
};

const defaultValues = {
  alternateId: undefined,
  applicationNumber: undefined,
  approved: undefined,
  artUnit: undefined,
  attorneyEmail: undefined,
  cited: undefined,
  clientId: undefined,
  clientNumber: undefined,
  confirmationNumber: undefined,
  consistency: undefined,
  entitySize: undefined,
  fileReminder: undefined,
  filingDate: undefined,
  firstInventor: undefined,
  id: undefined,
  matterClosed: undefined,
  matterId: undefined,
  matterNumber: undefined,
  pairStatus: undefined,
  pairStatusDate: undefined,
  pairStatusLookupDate: undefined,
  patentCenterPairStatus: undefined,
  patentCenterPairStatusDate: undefined,
  patentCenterPairStatusLookupDate: undefined,
  ptoFee: undefined,
  statement: undefined,
  timing: undefined,
  uncited: undefined,
  urgent: undefined,
  within30Days: undefined
};

export default class TodoRecord extends Record(defaultValues) {
  get(field) {
    switch (field) {
      case 'ptoFee':
        return this.getPtoFee();
      default:
        return super.get(field);
    }
  }

  getPtoFee() {
    let ptoFee = '$0';
    if (entitySizes[this.entitySize]) {
      switch (this.timing) {
        case '1':
          switch (this.statement) {
            case '2':
              ptoFee = entitySizes[this.entitySize];
              break;
          }
          break;
        case '2':
          ptoFee = entitySizes[this.entitySize];
          break;
      }
    }
    return ptoFee;
  }

  getUploadRecord() {
    const inventor = (this.firstInventor || '').split(/\s+/);
    const firstName = inventor.shift();
    const lastName = inventor.pop();
    const middleName = inventor.join(' ');

    return Immutable.Map({
      'identity.holder': true,
      'identity.firstName': '',
      'identity.lastName': '',
      'identity.email': '',
      'application.type': '',
      'application.existing': Boolean(this.applicationNumber && this.confirmationNumber),
      'application.applicationNumber': this.applicationNumber || '',
      'application.confirmationNumber': this.confirmationNumber || '',
      'application.ignoreTransmittalLetter': false,
      'application.ignoreIDSForm': false,
      'application.syncidsForm': false,
      'details.firstName': firstName || '',
      'details.middleName': middleName || '',
      'details.lastName': lastName || '',
      'details.docketNumber': `${this.clientNumber}-${this.matterNumber}`,
      'fee.entitySize': this.entitySize,
      'fee.ptoFee': parseInt(this.getPtoFee().replace(/^\$/, ''), 10)
    });
  }

  isClosed() {
    return this.matterClosed === 'ON';
  }

  isApproved() {
    return !!this.approved;
  }

  isWithin30Days() {
    return !!this.within30Days;
  }

  canGenerateIds() {
    return Boolean(
      /[0-9]/.test(this.timing) &&
      /[0-9]/.test(this.statement)
    );
  }

  canFileMatter() {
    return Boolean(
      this.firstInventor &&
      this.applicationNumber &&
      this.filingDate &&
      this.artUnit
    );
  }

  getIncompleteDocsCount() {
    return this.consistency ? this.consistency.incompleteDocs.length : null;
  }

  getFiledSort() {
    if (this.fileReminder) {
      return 1;
    }
    if (this.isClosed() || this.uncited === 0) {
      return 3;
    }
    return 2;
  }
};
