import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import { Button } from 'react-bootstrap';
import TableList, { Column } from '../shared/TableList';
import DocumentRecord from '../../models/DocumentRecord';
import ExpandableTextarea from '../shared/ExpandableTextarea';
import DateInput from '../shared/DateInput';
import CountryInput from '../shared/CountryInput';
import DocumentActions from './DocumentActions';
import DocumentInput from './DocumentInput';
import DocumentPDF from './DocumentPDF';
import DocumentCategories from './DocumentCategories';
import DocumentCheckbox from './DocumentCheckbox';
import DocumentDescription from './DocumentDescription';
import HelpIcon from '../shared/HelpIcon';
import BatchInputLink from './BatchInputLink';
import Tooltip from '../shared/Tooltip';
import BatchUploadLink from './BatchUploadLink';
import BatchCategoryIcon from './BatchCategoryIcon';
import NotesHeader from './NotesHeader';
import { isCategoryMatter } from '../../utils/categories';
import DocumentAutoNotes from './DocumentAutoNotes';
import Loading from '../shared/Loading';
import CitedHeader from './CitedHeader';
import { getDocNumbersFromMatter } from '../../utils/get-doc-numbers-from-matter';
import DocumentsTitle from './DocumentsTitle';

const iconGear = (
  <span className='fa fa-gear' />
);

const styleButton = {
  width: '1%',
  padding: '4px',
  textAlign: 'center'
};

const getCellStyle = (width) => {
  return { width: '1%', minWidth: width, maxWidth: width };
};

const validateUSDocNumber = (docNumber) => {
  if (/^[0-9]{10}$/.test(docNumber)) {
    return false;
  }
  return true;
};

export default class DocumentsList extends Component {
  static propTypes = {
    batchUploadDocument: PropTypes.func.isRequired,
    categories: PropTypes.instanceOf(Immutable.List).isRequired,
    citedFilter: PropTypes.string.isRequired,
    clearNplDocuments: PropTypes.func.isRequired,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    features: PropTypes.object.isRequired,
    fileMatter: PropTypes.func.isRequired,
    loadMatterNotes: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    newDocuments: PropTypes.instanceOf(Immutable.Map).isRequired,
    notesSearch: PropTypes.string.isRequired,
    notesTab: PropTypes.string.isRequired,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map).isRequired,
    onAction: PropTypes.func.isRequired,
    onBatchInput: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    refreshDocuments: PropTypes.func.isRequired,
    resolveForeign: PropTypes.func.isRequired,
    searchNplDocuments: PropTypes.func.isRequired,
    selectNotesTab: PropTypes.func.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    showListed: PropTypes.func.isRequired,
    showNPLNumber: PropTypes.bool.isRequired,
    updateDoc: PropTypes.instanceOf(DocumentRecord),
    updateDocumentCategories: PropTypes.func.isRequired,
    viewCategory: PropTypes.func.isRequired,
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired,
    lookUpDocuments: PropTypes.func.isRequired,
    appendTranslationsToDocument: PropTypes.func.isRequired,
    showPriorityDate: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  componentDidUpdate() {
    const id = this.props.matter.get('id');

    if (this.state.loadMatterNotes !== id) {
      this.setState({ loadMatterNotes: id }, () => { // eslint-disable-line
        this.props.loadMatterNotes(id);
      });
    }
  }

  isCategoryMatter() {
    return isCategoryMatter(this.props.matter.get('matterNumber'));
  }

  onLookUp(doc, skipCache) {
    this.props.lookUpDocuments(this.props.matter, [doc], skipCache);
  }

  onChange(doc, field, value) {
    return this.props.onChange(doc, field, value);
  }

  onSearchDescription(value, doc) {
    const firmName = this.props.matter.get('firmName');
    const clientNumber = this.props.matter.get('clientNumber');
    this.props.searchNplDocuments(firmName, clientNumber, value);
  }

  onToggleNotes() {
    this.setState({
      showPublicNotes: !this.state.showPublicNotes
    });
  }

  onClickBatchInput(docType) {
    this.props.onBatchInput(docType);
  }

  onNumberKeyDown(e, doc) {
    // 186 = semi-colon
    if (e.which === 186 && doc.get('type') === 'US') {
      e.preventDefault();
      this.props.onBatchInput(doc.get('type'));
    }
  }

  onResolveForeign() {
    this.props.resolveForeign();
  }

  onRefreshDocuments(e) {
    e.stopPropagation();
    this.props.refreshDocuments(this.props.matter);
  }

  getUpdateRow() {
    return this.props.updateDoc;
  }

  getLoadingDocs() {
    const docNumbers = getDocNumbersFromMatter(this.props.matter);

    return {
      total: docNumbers.length,
      loaded: docNumbers.filter(documentNumber => Boolean(this.props.documents.get(documentNumber))).length
    };
  }

  getDocsByType(type) {
    const newDoc = this.props.newDocuments.get(type);
    let docs = this.props.visibleDocuments.filter(doc => doc.get('type') === type);
    docs = docs.push(new DocumentRecord(newDoc));
    return docs;
  }

  getColumns(type) {
    switch (type) {
      case 'US':
        return [
          'cited',
          'PDF',
          'documentNumber',
          this.props.showPriorityDate ? 'priorityDate' : 'publicationDate',
          'inventor',
          this.props.notesTab,
          'categories',
          'actions'
        ];

      case 'Foreign':
        return [
          'cited',
          'PDF',
          'documentNumber',
          'country',
          this.props.showPriorityDate ? 'priorityDate' : 'publicationDate',
          'applicant',
          this.props.notesTab,
          'translated',
          'categories',
          'actions'
        ];

      case 'Other':
        return [
          'cited',
          'PDF',
          ...(this.props.showNPLNumber ? ['documentNumber'] : []),
          'description',
          this.props.notesTab,
          'translated',
          'categories',
          'actions'
        ];
    }
  }

  renderCategoryIcon(type) {
    return (
      <BatchCategoryIcon
        docType={type}
        features={this.props.features}
        notesFilter={this.props.notesSearch}
        notesTab={this.props.notesTab}
        docFilter={this.props.citedFilter}
        matter={this.props.matter}
        documents={this.props.documents}
        categories={this.props.categories}
        updateDocumentCategories={this.props.updateDocumentCategories}
        viewCategory={this.props.viewCategory}
      />
    );
  }

  renderColumns(type) {
    return this.getColumns(type).map(column => this.renderColumn(type, column));
  }

  onSelectNotesTab(notesTab) {
    if (notesTab === 'matterNotes') {
      this.props.loadMatterNotes(this.props.matter.get('id'));
    }
    this.props.selectNotesTab(notesTab);
  }

  getNotesInputColor() {
    switch (this.props.notesTab) {
      case 'autoNotes':
        return 'rgba(0, 188, 140, 0.2)';
      case 'publicNotes':
        return 'rgba(241, 177, 178, 1)';
      case 'matterNotes':
        return '#ffffcc';
      default:
        return '';
    }
  }

  renderCited() {
    return (
      <CitedHeader
        client={this.props.client}
        features={this.props.features}
        fileMatter={this.props.fileMatter}
        logAction={this.props.logAction}
        matter={this.props.matter}
        notesSearch={this.props.notesSearch}
        setFileReminder={this.props.setFileReminder}
        visibleDocuments={this.props.visibleDocuments}
      />
    );
  }

  renderColumn(type, column) {
    const key = `${type}:${column}`;
    /* eslint no-fallthrough: 0 */
    switch (key) {
      case 'US:documentNumber':
        return (
          <Column key={column} title='Doc Number' style={getCellStyle('170px')}>
            <DocumentInput
              features={this.props.features}
              field='documentNumber'
              type='input'
              onChange={this.onChange}
              onKeyDown={this.onNumberKeyDown}
              changeOnEnter
              spellCheck={false}
              validate={validateUSDocNumber}
              invalidText='Valid US Publication Numbers must contain 11 digits'
              isRequired
            />
          </Column>
        );

      case 'US:actions':
        return (
          <Column key={column} title={iconGear} style={styleButton}>
            <DocumentActions
              features={this.props.features}
              matter={this.props.matter}
              logAction={this.props.logAction}
              showPatentBots
              onAction={this.props.onAction}
            />
          </Column>
        );

      case 'US:inventor':
        return (
          <Column key={column} title='Inventor' style={getCellStyle('260px')}>
            <DocumentInput
              features={this.props.features}
              field='inventor'
              type='input'
              onChange={this.onChange}
              disableUnsaved
              spellCheck={false}
              changeOnEnter
              isRequired
            />
          </Column>
        );

      case 'Foreign:documentNumber':
        return (
          <Column
            key={column} title={(
              <div>
                <span>Doc. No. / Kind Code</span>
                <HelpIcon
                  id='foreign-doc-number-help'
                  help='Country codes and kind codes may be included (e.g., 2015055555, WO2015055555, 2015055555A1, or WO2015055555A1).'
                  styleIcon={{ marginLeft: '5px' }}
                />
              </div>
            )} style={getCellStyle('170px')}
          >
            <DocumentInput
              features={this.props.features}
              field='documentNumber'
              type='input'
              onChange={this.onChange}
              onKeyDown={this.onNumberKeyDown}
              changeOnEnter
              spellCheck={false}
              isRequired
            />
          </Column>
        );

      case 'Foreign:country':
        return (
          <Column key={column} title='CC' style={getCellStyle('60px')}>
            <DocumentInput
              features={this.props.features}
              field='country'
              type={CountryInput}
              onChange={this.onChange}
              disableUnsaved
              changeOnEnter
              spellCheck={false}
              isRequired
            />
          </Column>
        );

      case 'Foreign:applicant':
        return (
          <Column key={column} title='Applicant' style={getCellStyle('200px')}>
            <DocumentInput
              features={this.props.features}
              field='applicant'
              type='input'
              onChange={this.onChange}
              disableUnsaved
              spellCheck={false}
              changeOnEnter
            />
          </Column>
        );

      case 'Foreign:actions':
        return (
          <Column key={column} title={iconGear} style={styleButton}>
            <DocumentActions
              features={this.props.features}
              matter={this.props.matter}
              logAction={this.props.logAction}
              onAction={this.props.onAction}
            />
          </Column>
        );

      case 'Other:documentNumber':
        return (
          <Column key={column} title='NPL Number' style={getCellStyle('170px')}>
            <DocumentInput
              features={this.props.features}
              field='documentNumber'
              type='input'
              onChange={this.onChange}
              onKeyDown={this.onNumberKeyDown}
              changeOnEnter
              spellCheck={false}
              isRequired
            />
          </Column>
        );

      case 'Other:description':
        return (
          <Column
            key={column}
            title='Description'
            style={this.props.showNPLNumber ? getCellStyle('380px') : getCellStyle('550px')}
          >
            <DocumentDescription
              features={this.props.features}
              field='nonPatentBib'
              dropdownTop
              nplSuggestions={this.props.nplSuggestions}
              clearNplDocuments={this.props.clearNplDocuments}
              onChange={this.onChange}
              onSearch={this.onSearchDescription}
              updateDoc={this.props.updateDoc}
              changeOnSelect
            />
          </Column>
        );

      case 'Other:autoNotes':
        return (
          <Column
            key={column}
            title={(
              <NotesHeader
                showAutoNotes
                showPublicNotes={false}
                selectedTab={this.props.notesTab}
                onSelectTab={this.onSelectNotesTab}
              />
            )}
          >
            <DocumentAutoNotes
              features={this.props.features}
              showListed={this.props.showListed}
              backgroundColor={this.getNotesInputColor()}
            />
          </Column>
        );

      case 'Other:notes':
      case 'Other:publicNotes':
      case 'Other:matterNotes':
        return (
          <Column
            key={column}
            title={(
              <NotesHeader
                showAutoNotes
                showPublicNotes={false}
                selectedTab={this.props.notesTab === 'publicNotes' ? 'notes' : this.props.notesTab}
                onSelectTab={this.onSelectNotesTab}
              />
            )}
          >
            <DocumentInput
              features={this.props.features}
              backgroundColor={this.props.notesTab === 'publicNotes' ? null : this.getNotesInputColor()}
              field={this.props.notesTab === 'publicNotes' ? 'notes' : this.props.notesTab}
              multiLineCompact={false}
              type={ExpandableTextarea}
              rows={3}
              onChange={this.onChange}
              spellCheck={false}
              disableUnsaved
            />
          </Column>
        );

      case 'Other:actions':
        return (
          <Column key={column} title={iconGear} style={styleButton}>
            <DocumentActions
              features={this.props.features}
              matter={this.props.matter}
              logAction={this.props.logAction}
              onAction={this.props.onAction}
              showNPLNumber={this.props.showNPLNumber}
              showOtherRef
              showDeduplicate
            />
          </Column>
        );
    }

    switch (column) {
      case 'PDF':
        return (
          <Column key={column} title='PDF' style={getCellStyle('40px')}>
            <DocumentPDF
              appendTranslationsToDocument={this.props.appendTranslationsToDocument}
              features={this.props.features}
              onLookUp={this.onLookUp}
              onUpload={this.props.onUpload}
              onChange={this.onChange}
            />
          </Column>
        );

      case 'cited':
        return (
          <Column key={column} title={this.renderCited()} style={styleButton}>
            <DocumentCheckbox
              features={this.props.features}
              field='cited'
              disabled={this.isCategoryMatter()}
              onChange={this.onChange}
            />
          </Column>
        );

      case 'priorityDate':
      case 'publicationDate':
        return (
          <Column key={column} title={column === 'priorityDate' ? 'Priority Date' : 'Issue/Pub Date'} style={getCellStyle('120px')}>
            <DocumentInput
              features={this.props.features}
              field={column}
              backgroundColor={column === 'priorityDate' ? 'rgba(55, 90, 127, .3)' : null}
              type={DateInput}
              onChange={this.onChange}
              changeOnEnter
              disableUnsaved
              spellCheck={false}
              isRequired
            />
          </Column>
        );

      case 'autoNotes':
        return (
          <Column
            key={column}
            title={(
              <NotesHeader
                showAutoNotes
                showPublicNotes
                selectedTab={this.props.notesTab}
                onSelectTab={this.onSelectNotesTab}
              />
            )}
            style={{ width: type === 'US' ? '350px' : '350px' }}
          >
            <DocumentAutoNotes
              showListed={this.props.showListed}
              backgroundColor={this.getNotesInputColor()}
            />
          </Column>
        );

      case 'matterNotes':
      case 'notes':
      case 'publicNotes':
        return (
          <Column
            key={column}
            style={{ width: type === 'US' ? '350px' : '350px' }}
            title={(
              <NotesHeader
                showAutoNotes
                showPublicNotes
                selectedTab={this.props.notesTab}
                onSelectTab={this.onSelectNotesTab}
              />
            )}
          >
            <DocumentInput
              features={this.props.features}
              multiLineCompact
              backgroundColor={this.getNotesInputColor()}
              field={this.props.notesTab}
              type={ExpandableTextarea}
              expandRows={5}
              onChange={this.onChange}
              spellCheck={false}
              disableUnsaved
            />
          </Column>
        );

      case 'categories':
        return (
          <Column key={column} title={this.renderCategoryIcon(type)} style={styleButton}>
            <DocumentCategories
              features={this.props.features}
              field='categories'
              matter={this.props.matter}
              categories={this.props.categories}
              onChange={this.onChange}
              onAction={this.props.onAction}
              viewCategory={this.props.viewCategory}
            />
          </Column>
        );

      case 'translated':
        return (
          <Column key={column} title='T' style={styleButton}>
            <DocumentCheckbox
              features={this.props.features}
              field='translated'
              onChange={this.onChange}
            />
          </Column>
        );
    }
  }

  renderBatchLink(docType) {
    return (
      <BatchInputLink
        label='Batch input'
        features={this.props.features}
        docType={docType}
        onClick={this.onClickBatchInput}
      />
    );
  }

  renderBatchUpload(docType) {
    return (
      <BatchUploadLink
        features={this.props.features}
        docType={docType}
        onUpload={this.props.batchUploadDocument}
      />
    );
  }

  onClickPriorityDate() {
    this.props.onAction(null, 'showPriorityDate');
  }

  renderShowPriorityDate() {
    return (
      <Button
        disabled={this.props.features.limitedDocs}
        variant='primary'
        size='sm'
        style={{ marginLeft: '10px' }}
        onClick={this.onClickPriorityDate}
      >
        <span
          style={{ marginRight: '5px' }}
          className={`fa ${this.props.showPriorityDate ? 'text-success fa-check-square-o' : 'fa-square-o'}`}
        />
        Show Priority Date
      </Button>
    );
  }

  onClickShowNumber() {
    this.props.onAction(null, 'showNPLNumber');
  }

  renderShowNumber() {
    return (
      <Button
        disabled={this.props.features.limitedDocs}
        variant='primary'
        size='sm'
        style={{ marginLeft: '10px' }}
        onClick={this.onClickShowNumber}
      >
        <span
          style={{ marginRight: '5px' }}
          className={`fa ${this.props.showNPLNumber ? 'text-success fa-check-square-o' : 'fa-square-o'}`}
        />
        Show NPL Number
      </Button>
    );
  }

  onLookupDocuments(e) {
    const docType = e.target.getAttribute('data-doctype');
    const skipCache = e.target.hasAttribute('data-skipcache');
    if (docType) {
      const documents = this.getDocsByType(docType)
        .filter(doc => doc.get('id'));

      this.props.lookUpDocuments(this.props.matter, documents, skipCache);
    }
  }

  renderLookUp(docType, skipCache) {
    return (
      <Button
        disabled={docType !== 'US' && this.props.features.limitedDocs}
        onClick={this.onLookupDocuments}
        data-doctype={docType}
        data-skipcache={skipCache}
        variant='primary'
        size='sm'
        style={{ marginLeft: '10px' }}
      >
        {skipCache ? 'Re Look Up (no cache)' : 'Re Look Up'}
      </Button>
    );
  }

  renderRefresh(type) {
    const help = {
      Other: 'Check for PDF updates in all of the following NPL documents.',
      Foreign: 'Check for PDF, Publication Date and Applicant updates in all of the following foreign documents.'
    }[type];

    return (
      <Button
        disabled={this.props.features.limitedDocs}
        onClick={this.onRefreshDocuments}
        variant='primary'
        size='sm'
        style={{ marginLeft: '10px' }}
      >
        <Tooltip
          id='refresh-docs'
          placement='right'
          tip={help}
        >
          <span className='fa fa-refresh' style={{ marginRight: '5px' }} />
          Check for updates
        </Tooltip>
      </Button>
    );
  }

  renderUSTitle(docs) {
    return (
      <DocumentsTitle
        docs={docs}
        title='US Patents and Publications'
      >
        {this.renderLookUp('US')}
        {!this.props.features.limitedDocs && this.renderShowPriorityDate()}
      </DocumentsTitle>
    );
  }

  renderForeignTitle(docs) {
    return (
      <DocumentsTitle
        docs={docs}
        title='Foreign Patents'
      >
        {!this.props.features.limitedDocs && this.renderRefresh('Foreign')}
        {!this.props.features.limitedDocs && this.renderLookUp('Foreign')}
        {this.props.features.admin && this.renderLookUp('Foreign', true)}
        {!this.props.features.limitedDocs && this.renderShowPriorityDate()}
      </DocumentsTitle>
    );
  }

  renderOtherTitle(docs) {
    return (
      <DocumentsTitle
        docs={docs}
        title='Non-Patent Literature Documents'
      >
        {!this.props.features.limitedDocs && this.renderRefresh('Other')}
        {!this.props.features.limitedDocs && this.renderShowNumber()}
      </DocumentsTitle>
    );
  }

  renderUSList(docs) {
    return (
      <TableList
        id='table-docs-us'
        rows={docs}
        updateRow={this.getUpdateRow()}
        rowKey='id'
        rowAs='doc'
      >
        {this.renderColumns('US')}
      </TableList>
    );
  }

  renderForeignList(docs) {
    return (
      <TableList
        id='table-docs-foreign'
        rows={docs}
        updateRow={this.getUpdateRow()}
        rowKey='id'
        rowAs='doc'
      >
        {this.renderColumns('Foreign')}
      </TableList>
    );
  }

  renderOtherList(docs) {
    return (
      <TableList
        id='table-docs-other'
        rows={docs}
        updateRow={this.getUpdateRow()}
        rowKey='id'
        rowAs='doc'
      >
        {this.renderColumns('Other')}
      </TableList>
    );
  }

  renderLimitedFeature() {
    return (
      <p>
        <span className='fa fa-lock' />
        This is a premium feature. Please contact SyncIDS to unlock it.
      </p>
    );
  }

  render() {
    const loadingDocs = this.getLoadingDocs();
    if (loadingDocs.loaded < loadingDocs.total) {
      return (
        <Loading text='Loading documents...' />
      );
    }

    const rows = {
      US: this.getDocsByType('US'),
      Foreign: this.getDocsByType('Foreign'),
      Other: this.getDocsByType('Other')
    };

    const limited = this.props.features.limitedDocs;

    return (
      <div>
        {this.renderUSTitle(rows.US)}
        {this.renderUSList(rows.US)}
        {this.renderBatchLink('US')}
        {this.renderForeignTitle(rows.Foreign)}
        {limited ? this.renderLimitedFeature() : this.renderForeignList(rows.Foreign)}
        {limited ? null : this.renderBatchLink('Foreign')}
        {this.renderOtherTitle(rows.Other)}
        {limited ? this.renderLimitedFeature() : this.renderOtherList(rows.Other)}
        {limited ? null : this.renderBatchLink('Other')}
        {limited ? null : this.renderBatchUpload('Other')}
      </div>
    );
  }
}
