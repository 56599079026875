import { handleActions } from 'redux-actions';

import {
  CLIENT_MATTER_SELECT,
  CLIENT_SELECT,
  MATTER_SELECT,
  TODOS_FETCH_PARAMS
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [CLIENT_MATTER_SELECT]: (state, { payload }) => (payload.matterId || initialState),

  [MATTER_SELECT]: (state, { payload }) => (payload.selectedMatterId || initialState),

  [CLIENT_SELECT]: () => initialState,

  [TODOS_FETCH_PARAMS]: () => initialState

}, initialState);
