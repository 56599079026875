import { handleActions } from 'redux-actions';
import {
  NEAR_DUPLICATE_BEGIN
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [NEAR_DUPLICATE_BEGIN]: (state, { payload: { workerId } }) => (workerId)

}, initialState);
