import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trackDemo } from '../../utils/track';

export default class RequestDemo extends Component {
  static propTypes = {
    href: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    target: PropTypes.string
  };

  static defaultProps = {
    href: '/#/demo',
    className: 'btn btn-xl btn-primary'
  };

  onClick() {
    trackDemo();
  }

  render() {
    return (
      <a
        onClick={this.onClick}
        style={this.props.style}
        className={this.props.className}
        href={this.props.href}
        target={this.props.target}
      >
        Book Demo
      </a>
    );
  }
}
