import { handleActions } from 'redux-actions';

import {
  TOGGLE_WIDE_LAYOUT
} from '../../constants/actions';

const initialState = false;

export default handleActions({

  [TOGGLE_WIDE_LAYOUT]: (state) => (!state)

}, initialState);
