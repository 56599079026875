import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import selectMatter from './selectMatter';
import fetchMatterList from './fetchMatterList';
import { mattersUrl } from '../../constants/urls';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import { stripMatterNumber } from '../../../utils/matter';

export default (arg) => (dispatch, getState) => {
  const state = getState();
  const {
    auth: { firmName },
    app: { selectedClientId }
  } = state;

  const params = (typeof arg === 'string') ? { matterNumber: arg } : arg;

  let { matterNumber, clientNumber } = params;
  if (!clientNumber) {
    clientNumber = state.entities.getIn(['clients', selectedClientId, 'clientNumber']);
  }
  matterNumber = stripMatterNumber(matterNumber);

  if (!matterNumber || !firmName || !clientNumber) {
    console.error('createMatter - missing required fields - firmName, clientNumber, matterNumber');
    return;
  };

  if (params.shouldSelect) {
    dispatch(selectMatter());
  }
  dispatch(addNotification({
    id: `matter-${clientNumber}-${matterNumber}`,
    type: 'progress',
    message: `Creating new matter ${matterNumber}...`
  }));

  const createMatter = (matterToCreate) => {
    const data = Object.assign({}, matterToCreate);
    if (params.migrateLegacy) {
      data.migrateLegacyData = true;
    }
    if (params.applicationNumber) {
      data.applicationNumber = params.applicationNumber;
    }
    return dispatch(callApi({
      url: mattersUrl,
      method: 'post',
      data
    }))
      .catch(() => {
        dispatch(addNotification({
          id: `matter-${clientNumber}-${matterNumber}`,
          type: 'error',
          message: `Failed to create ${matterNumber}.`
        }));
      })
      .then(r => {
        dispatch(addNotification({
          id: `matter-${clientNumber}-${matterNumber}`,
          type: 'success',
          message: `Matter ${matterNumber} created.`
        }));
        return dispatch(fetchMatterList(selectedClientId)).then(() => r);
      })
      .then(r => {
        if (params.shouldSelect) {
          dispatch(selectMatter(r.id));
        }
        return r;
      });
  };

  if (!params.copyMatter) {
    return createMatter({
      firmName,
      clientNumber,
      matterNumber,
      matterClosed: params.matterClosed,
      twoWayRelatedMatters: params.twoWayRelatedMatters
    });
  }

  return dispatch(callApi({
    url: mattersUrl + '/' + params.copyMatter
  }))
    .catch(() => {
      dispatch(addNotification({
        id: `matter-${clientNumber}-${matterNumber}`,
        type: 'error',
        message: `Failed to copy ${matterNumber}.`
      }));
    })
    .then(response => {
      const matter = _get(response, `entities.matters.${params.copyMatter}`);
      if (!matter) {
        throw new Error('matter not found');
      }
      const matterToCreate = _omit(matter, [
        'id',
        'matterId',
        'clientId',
        'applicationNumber',
        'confirmationNumber',
        'matterClosed',
        'filingDate',
        'artUnit',
        'citedArt',
        'uncitedArt',
        'deletedArt',
        'pairStatus',
        'alternateId'
      ]);

      if (params.copyDeletedArt) {
        matterToCreate.deletedArt = matter.deletedArt.join(',');
      }

      if (matterToCreate.twoWayRelatedMatters) {
        matterToCreate.twoWayRelatedMatters = _uniq([
          matterToCreate.twoWayRelatedMatters,
          matterToCreate.matterNumber
        ]).join(',');
      } else {
        matterToCreate.twoWayRelatedMatters = matterToCreate.matterNumber;
      }

      matterToCreate.matterCategories = matter.matterCategories.join(',');
      matterToCreate.clientNumber = clientNumber;
      matterToCreate.matterNumber = matterNumber;
      matterToCreate.matterClosed = params.matterClosed;

      return createMatter(matterToCreate);
    });
};
