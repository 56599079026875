import { createAction } from 'redux-actions';
import { ADD_NOTIFICATION } from '../../constants/actions';

const addNotification = createAction(ADD_NOTIFICATION);

export default (payload, duration = 3000) => (dispatch) => {
  if (payload.type !== 'progress' && !payload.removed) {
    const remove = {
      id: payload.id,
      type: payload.type,
      message: payload.message,
      removed: true
    };
    if (duration) {
      setTimeout(() => dispatch(addNotification(remove)), duration);
    }
  }
  dispatch(addNotification(payload));
  return Promise.resolve();
};
