import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';

export default class FieldGroup extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    help: PropTypes.string,
    bsSize: PropTypes.string,
    validationState: PropTypes.string,
    autoFocus: PropTypes.bool
  };

  render(props) {
    const {
      id,
      label,
      help,
      validationState,
      ...formControlProps
    } = this.props;

    return (
      <FormGroup controlId={id} autoFocus>
        {label && <FormLabel>{label}</FormLabel>}
        <FormControl {...formControlProps} />
        {help && <Form.Text>{help}</Form.Text>}
      </FormGroup>
    );
  }
}
