import { handleActions } from 'redux-actions';

import {
  TODOS_SET_SORT,
  TODOS_FETCH_PARAMS
} from '../../constants/actions';

const initialState = {
  sortField: 'clientNumber',
  sortDirection: 1
};

export default handleActions({

  [TODOS_FETCH_PARAMS]: () => initialState,

  [TODOS_SET_SORT]: (state, { payload }) => {
    return {
      sortField: payload,
      sortDirection: state.sortField === payload
        ? state.sortDirection === 1 ? -1 : 1
        : payload === 'pairStatus' ? -1 : 1
    };
  }

}, initialState);
