import React from 'react';
import AppContainer from '../app/AppContainer';

export default class AppView extends React.Component {
  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  render() {
    return (
      <AppContainer />
    );
  }
}
