import { createAction } from 'redux-actions';
import { renderPage } from '../../../utils/pdf';
import { MIGRATION_RENDER_NPLMATCH } from '../../constants/actions';

const renderNPLMatchMigration = createAction(MIGRATION_RENDER_NPLMATCH);

export default (pdfDoc, page, canvas) => (dispatch) => {
  return renderPage(pdfDoc, page, canvas)
    .then(() => dispatch(renderNPLMatchMigration({})));
};
