import autoBind from 'class-autobind';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BatchSweepDocuments from './BatchSweepDocuments';
import { mergeDeepReplacingLists } from '../../utils/merge-deep-replacing-lists';

const isTrue = (val) => val === 'True' || val === true;

export default class BatchSweepModal extends Component {
  static propTypes = {
    addBatchDocuments: PropTypes.func.isRequired,
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    categories: PropTypes.instanceOf(Immutable.List).isRequired,
    viewCategory: PropTypes.func.isRequired,
    pendingOfficeActions: PropTypes.array.isRequired,
    markOfficeActionsComplete: PropTypes.func.isRequired,
    fetchPendingOfficeActions: PropTypes.func.isRequired,
    fetchFamily: PropTypes.func.isRequired,
    getFirmClientSettings: PropTypes.func.isRequired
  };

  static defaultProps = {
    pendingOfficeActions: []
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      documents: null,
      settings: {}
    };
  }

  componentDidMount() {
    this.props.getFirmClientSettings()
      .then((settings) => this.setState({ settings }))
      .then(() => this.fetchOfficeActions());
  }

  fetchOfficeActions() {
    this.props.fetchPendingOfficeActions(this.props.matter.get('id'))
      .then(() => this.updateDocuments())
      .then(() => {
        if (this.isLoading()) {
          setTimeout(this.fetchOfficeActions, 30_000);
        }
      });
  }

  updateDocuments() {
    const data = this.getTextractData();
    if (data) {
      let documents = Immutable.fromJS({
        US: [],
        Foreign: [],
        Other: []
      });

      documents = mergeDeepReplacingLists(documents, data);

      this.setState({
        documents
      });
    }
  }

  getPendingOfficeAction() {
    return this.props.pendingOfficeActions.filter(item => (
      ['IDS.3P', 'NTC.CITE.IMP', '892'].includes(item.gd_docCode)
    )).shift();
  }

  isLoading() {
    const officeAction = this.getPendingOfficeAction();
    return officeAction && officeAction.textractStatus !== 1;
  }

  getOfficeActionIds() {
    const ids = [];
    const pendingOfficeAction = this.getPendingOfficeAction();
    if (pendingOfficeAction) {
      ids.push(pendingOfficeAction.id);
    }
    const officeAction = this.getOfficeAction();
    if (officeAction) {
      ids.push(officeAction.id);
    }
    return ids;
  }

  getOfficeAction() {
    const { client, pendingOfficeActions } = this.props;
    if (!pendingOfficeActions || !client.get('sweepUsOfficeActionsFlag')) {
      return;
    }

    const pendingItem = this.getPendingOfficeAction();
    const codes = [
      'CTFR', 'CTNF', 'NOA', 'NRES', 'CTEQ',
      'OA.FAI.PRELM', 'OA.FAI.PRE', 'OA.FAI'
    ];

    return pendingOfficeActions.filter(item => (
      (!pendingItem || item.gd_docDate === pendingItem.gd_docDate) &&
      codes.includes(item.gd_docCode)
    )).shift();
  }

  getClientNotes(doc) {
    if (!this.state.settings.sweepClientNotes) {
      return '';
    }

    const pendingOfficeAction = this.getPendingOfficeAction();

    let { docType, docDate } = doc;

    if (!docType && pendingOfficeAction) {
      docType = pendingOfficeAction.gd_docCode;
    }

    if (!docDate && pendingOfficeAction) {
      docDate = pendingOfficeAction.gd_docDate;
    }

    if (!docType || !docDate) {
      return '';
    }

    const appNumber = this.props.matter.get('applicationNumber').replace(/[^0-9\.]/g, '');

    return `cited in US ${docType} (${appNumber}) dtd ${docDate}`;
  }

  getTextractData() {
    const getNumber = (doc) => {
      if (doc.correctedDocumentNumber && doc.correctedDocumentNumber !== 'Unneeded' && doc.correctedDocumentNumber !== 'N/A') {
        return doc.correctedDocumentNumber;
      }
      if (doc.OCRDocNum && doc.OCRDocNum !== 'Unneeded' && doc.OCRDocNum !== 'N/A') {
        return doc.OCRDocNum;
      }
      return doc.textractDocumentNumber;
    };

    const pendingOfficeAction = this.getPendingOfficeAction();

    if (pendingOfficeAction && pendingOfficeAction.textractData && pendingOfficeAction.textractStatus === 1) {
      const data = pendingOfficeAction.textractData;
      return {
        US: (data.US || []).map(doc => {
          return {
            date: doc.publicationDate,
            number: getNumber(doc).substr(2),
            inventor: doc.publicationInventor,
            checked: isTrue(doc.datesMatch) && isTrue(doc.inventorsMatch),
            notes: this.getClientNotes(doc)
          };
        }),
        Foreign: (data.F || []).map(doc => {
          return {
            date: doc.publicationDate,
            number: getNumber(doc).substr(2),
            country: getNumber(doc).substr(0, 2),
            applicant: doc.publicationApplicant,
            checked: isTrue(doc.datesMatch),
            notes: this.getClientNotes(doc)
          };
        }),
        Other: (data.NPL || []).map(item => {
          const doc = typeof item === 'string' ? { description: item } : item;
          return {
            description: doc.description,
            checked: true,
            notes: this.getClientNotes(doc)
          };
        })
      };
    }
  }

  onConfirm(docsAdded) {
    return this.props.markOfficeActionsComplete(this.getOfficeActionIds(), docsAdded)
      .then(() => this.props.fetchPendingOfficeActions(this.props.matter.get('id')))
      .then(() => this.props.fetchFamily(this.props.matter, true))
      .then(this.onClose);
  }

  getPDFs() {
    const officeAction = this.getOfficeAction();
    const pendingOfficeAction = this.getPendingOfficeAction();

    const pdfs = [{
      label: '892',
      href: pendingOfficeAction ? pendingOfficeAction.s3PublicPath : null
    }];

    if (officeAction) {
      pdfs.unshift({
        label: 'OA',
        href: officeAction.s3PublicPath
      });
    }

    return pdfs;
  }

  render() {
    return (
      <BatchSweepDocuments
        showClientNotes={this.state.settings.sweepClientNotes}
        officeAction={this.getPendingOfficeAction()}
        categories={this.props.categories}
        viewCategory={this.props.viewCategory}
        addBatchDocuments={this.props.addBatchDocuments}
        matter={this.props.matter}
        onClose={this.props.onClose}
        onCancel={this.props.onCancel}
        onConfirm={this.onConfirm}
        documents={this.state.documents}
        pdfs={this.getPDFs()}
      />
    );
  }
}
