import changePassword from './changePassword';
import checkAuth from './checkAuth';
import closeAuthModal from './closeAuthModal';
import login from './login';
import logout from './logout';
import openAuthModal from './openAuthModal';
import register from './register';
import resetPassword from './resetPassword';
import singleSignOn from './singleSignOn';

export default {
  changePassword,
  checkAuth,
  closeAuthModal,
  login,
  logout,
  openAuthModal,
  register,
  resetPassword,
  singleSignOn
};
