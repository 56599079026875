import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import DocumentRecord from '../../models/DocumentRecord';
import HelpIcon from '../shared/HelpIcon';
import Immutable from 'immutable';

export default class DocumentInput extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    doc: PropTypes.instanceOf(DocumentRecord).isRequired,
    field: PropTypes.string.isRequired,
    rows: PropTypes.number,
    forceUpdate: PropTypes.bool, // eslint-disable-line
    expandRows: PropTypes.number,
    type: PropTypes.any,
    validate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    spellCheck: PropTypes.bool,
    changeOnEnter: PropTypes.bool,
    disableUnsaved: PropTypes.bool,
    invalidText: PropTypes.string,
    backgroundColor: PropTypes.string,
    multiLineCompact: PropTypes.bool
  };

  static defaultProps = {
    doc: new DocumentRecord()
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.forceUpdate ||
      !Immutable.is(props.doc, state.doc) ||
      (state.changed && !props.doc.isSaved()) ||
      props.field !== state.field
    ) {
      return {
        doc: props.doc,
        field: props.field,
        value: props.doc.get(props.field) || '',
        changed: false
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  isDisabled() {
    if (this.props.doc.get('type') !== 'US' && this.props.features.limitedDocs) {
      return true;
    }

    return this.props.disableUnsaved && !this.props.doc.isSaved();
  }

  isValid() {
    return this.props.validate
      ? this.props.validate(this.state.value)
      : true;
  }

  shouldMarkRequired() {
    return (
      this.props.doc.isSaved() &&
        !this.props.isDisabled && (
        (this.props.isRequired && !this.state.value) ||
          !this.isValid()
      )
    );
  }

  onChange(e, value = e.target.value) {
    this.setState({ value });
  }

  raiseChange() {
    const { value } = this.state;
    const { doc, field } = this.props;
    const currentValue = doc.get(field) || '';
    if (currentValue !== value) {
      this.setState({ changed: true });
      this.props.onChange(doc, field, value);
    }
  }

  onBlur(e, value) {
    if (typeof value === 'string') {
      this.setState({ value }, () => this.raiseChange());
    } else {
      this.raiseChange();
    }
  }

  onKeyDown(e) {
    if (this.props.onKeyDown) {
      const { doc, field } = this.props;
      this.props.onKeyDown(e, doc, field);
    }
    if (this.props.changeOnEnter && e.which === 13) {
      const value = e.target.value;
      setTimeout(() => {
        this.setState({
          value
        }, () => {
          this.raiseChange();
        });
      });
    }
  }

  renderIcon() {
    if (!this.shouldMarkRequired() || this.isDisabled()) {
      return;
    }
    if (this.isValid()) {
      return (
        <span
          style={{ top: '8px', right: '5px', position: 'absolute' }}
          className='fa fa-remove text-danger'
        />
      );
    }
    return (
      <HelpIcon
        icon='exclamation-triangle'
        help={this.props.invalidText}
        style={{ position: 'absolute', color: '#e74c3c', right: '5px', top: '8px' }}
        id={`document-icon-${this.props.field}-${this.props.doc.id}`}
      />
    );
  }

  render() {
    const markRequired = this.shouldMarkRequired();
    const inputStyle = markRequired ? { paddingRight: 0 } : {};

    if (this.props.backgroundColor) {
      inputStyle.backgroundColor = this.props.backgroundColor;
    }

    const inputProps = {
      ref: 'input',
      name: this.props.field,
      className: 'form-control input-sm document-input',
      value: this.state.value,
      style: inputStyle,
      rows: this.props.rows,
      disabled: this.isDisabled(),
      spellCheck: this.props.spellCheck,
      required: this.props.isRequired,
      onChange: this.onChange,
      lang: 'en'
    };

    if (!inputProps.disabled) {
      Object.assign(inputProps, {
        onBlur: this.onBlur,
        onKeyDown: this.onKeyDown,
        onKeyUp: this.onKeyUp
      });
      if (this.props.expandRows) {
        inputProps.expandRows = this.props.expandRows;
      }
    }

    if (this.props.multiLineCompact) {
      inputProps.multiLineCompact = true;
    }

    const inputField = React.createElement(this.props.type, inputProps);
    const className = markRequired ? 'has-error has-feedback' : '';

    return (
      <div
        className={'form-group ' + className}
        style={{
          marginBottom: 0,
          backgroundColor: 'white',
          borderRadius: '6px',
          position: 'relative'
        }}
        id={`document-input-${this.props.field}-${this.props.doc.id}`}
      >
        {this.renderIcon()}
        {inputField}
      </div>
    );
  }
}
