import { connect } from 'react-redux';
import actions from '../../../redux/actions';
import InvoiceReminder from './InvoiceReminder';

const mapStateToProps = (state, props) => {
  const { token } = state.auth;

  return {
    token
  };
};

export default connect(mapStateToProps, actions)(InvoiceReminder);
