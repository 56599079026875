import { shouldComponentUpdate as shouldPureComponentUpdate } from 'react-immutable-render-mixin';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Toaster from './Toaster';
import actions from '../../redux/actions';

function mapStateToProps({ notifications }) {
  return { notifications };
}

const TOASTER_HEIGHT = 60;
const TOASTER_WIDTH = 350;

class ToastersContainer extends Component {
  static propTypes = {
    notifications: PropTypes.object.isRequired,
    removeNotification: PropTypes.func.isRequired
  };

  shouldComponentUpdate = shouldPureComponentUpdate;

  renderToaster(notification, index) {
    return (
      <Toaster
        key={notification.get('id')}
        index={index}
        height={TOASTER_HEIGHT}
        notification={notification}
        removeNotification={this.props.removeNotification}
      />
    );
  }

  render() {
    const style = {
      position: 'fixed',
      right: 0,
      bottom: 0,
      width: '100%',
      maxWidth: TOASTER_WIDTH,
      height: this.props.notifications.size * TOASTER_HEIGHT,
      zIndex: 2000
    };
    return (
      <div className='toasters-container' style={style}>
        {this.props.notifications.map(this.renderToaster, this)}
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(ToastersContainer);
