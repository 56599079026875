import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createHashHistory } from 'history';
import { LOCATION_CHANGE } from './constants/actions';
import { parseQuery } from '../utils/parse-query';
import { trackView, trackInit } from '../utils/track';
import Cookies from 'js-cookie';
import navigateApp from './actions/app/navigateApp';

const history = createHashHistory();

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, routerMiddleware(history)]
});

history.listen(({ location, action }) => {
  trackView();

  store.dispatch({
    type: LOCATION_CHANGE,
    payload: {
      location,
      action,
      query: parseQuery(location.pathname, '/')
    }
  });
});

const hash = window.location.hash || '';
const href = window.location.href || '';
const params = new URLSearchParams(window.location.search);
const ref = params.get('ref');
const token = params.get('token');

if (token) {
  Cookies.set('syncids-session', token);
  window.history.replaceState({}, document.title, window.location.pathname);
}

if (ref) {
  window.sessionStorage.setItem('syncids-ref', ref);
}

if (/\?/.test(hash)) {
  store.dispatch(navigateApp(parseQuery(hash, '?')));
} else if (/\/#\/app\//.test(href)) {
  store.dispatch(navigateApp(parseQuery(href.split('/#/app').pop(), '/')));
}

trackInit();

export default store;
