import addNotification from '../app/addNotification';
import { getBookmarks } from '../../../utils/pdf';

export default (content) => (dispatch) => {
  dispatch(addNotification({
    id: 'reading-pdf',
    type: 'progress',
    message: 'Loading PDF'
  }));
  return getBookmarks(content).then(({ pdfDoc, bookmarks }) => {
    dispatch(addNotification({
      id: 'reading-pdf',
      type: 'success',
      message: 'Loaded PDF'
    }));
    return {
      pdfDoc,
      bookmarks
    };
  });
};
