import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button } from 'react-bootstrap';

export default class InvoiceInput extends Component {
  static propTypes = {
    rowIndex: PropTypes.number,
    onRemove: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClick(e) {
    this.props.onRemove(this.props.rowIndex);
  }

  render() {
    return (
      <Button size='sm' onClick={this.onClick}>
        <span className='fa fa-trash' />
      </Button>
    );
  }
};
