import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';
import fetchPDF from './fetchPDF';
import DocumentRecord from '../../../models/DocumentRecord';
import fetchDocuments from './fetchDocuments';

export default (updatedFields, documentNumber, matterId) => (dispatch, getState) => {
  if (!updatedFields.id) {
    return;
  }

  dispatch(addNotification({
    id: `document-${documentNumber}`,
    type: 'progress',
    message: `Updating document ${documentNumber}...`
  }));
  return dispatch(callApi({
    url: documentsUrl + '/' + updatedFields.id,
    method: 'put',
    data: updatedFields
  }))
    .catch(() => {
      dispatch(addNotification({
        id: `document-${documentNumber}`,
        type: 'error',
        message: `Failed to update ${documentNumber}.`
      }));
    })
    .then((response) => {
      if (response && response.status === 'duplicate') {
        dispatch(addNotification({
          id: `document-${documentNumber}`,
          type: 'warn',
          message: 'Duplicate description.'
        }));
      } else {
        dispatch(addNotification({
          id: `document-${documentNumber}`,
          type: 'success',
          message: `Updated ${documentNumber}.`
        }));

        if (updatedFields.country && matterId) {
          let doc;

          Object.values(response.entities.documents).forEach(clientDocs => {
            Object.values(clientDocs).forEach(clientDoc => {
              if (clientDoc.id === updatedFields.id) {
                doc = clientDoc;
              }
            });
          });

          if (doc && doc.type === 'Foreign') {
            const record = new DocumentRecord(doc);
            dispatch(fetchPDF(record)).then(() => {
              const matter = getState().entities.getIn(['matters', matterId]);
              return dispatch(fetchDocuments(matter, [record.getRealDocumentNumber()]));
            });
          }
        }
      }
      return response;
    });
};
