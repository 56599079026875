import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import TodoRecord from '../../models/TodoRecord';
import ConfirmFileModal from './ConfirmFileModal';
import { isCategoryMatter } from '../../utils/categories';
import Tooltip from '../shared/Tooltip';
import Immutable from 'immutable';

export default class TodoFile extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    fileMatter: PropTypes.func.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired,
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    subsetIDSEnabled: PropTypes.bool
  };

  static defaultProps = {
    todo: new TodoRecord()
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      confirmFile: false
    };
  }

  closeFile() {
    this.setState({
      confirmFile: false
    });
  }

  renderConfirm() {
    return this.state.confirmFile && (
      <ConfirmFileModal
        origin='FileMatter'
        features={this.props.features}
        todo={this.props.todo}
        logAction={this.props.logAction}
        setFileReminder={this.props.setFileReminder}
        fileMatter={this.props.fileMatter}
        visibleDocuments={this.props.visibleDocuments}
        subsetIDSEnabled={this.props.subsetIDSEnabled}
        onClose={this.closeFile}
      />
    );
  }

  onClick() {
    this.setState({ confirmFile: true });
  }

  hasReminder() {
    return Boolean(this.props.todo.fileReminder && !isCategoryMatter(this.props.todo.matterNumber));
  }

  isVisible() {
    return Boolean(this.props.todo.uncited > 0 && !this.props.todo.isClosed());
  }

  render() {
    return this.isVisible() && (
      <div id={`todo-file-${this.props.todo.matterId}`}>
        <Tooltip
          id={`todo-file-${this.props.todo.matterId}`}
          tip='Marks references, that have complete data, as cited and generates a SyncIDS invoice.'
          delay={700}
          placement='right'
        >
          <button
            style={{ width: '50px', paddingLeft: 0, paddingRight: 0 }}
            className={'btn btn-sm btn-' + (this.hasReminder() ? 'danger' : 'secondary')}
            name='file-matter'
            onClick={this.onClick}
          >
            Cited?
          </button>
        </Tooltip>
        {this.renderConfirm()}
      </div>
    );
  }
};
