import selectClientAndMatter from '../app/selectClientAndMatter';
import refreshCategoryMatter from './refreshCategoryMatter';

export default (category) => (dispatch, getState) => {
  return dispatch(refreshCategoryMatter(category))
    .then((matter) => dispatch(selectClientAndMatter({
      clientId: matter.get('clientId'),
      matterId: matter.get('id')
    })));
};
