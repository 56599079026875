import { handleActions } from 'redux-actions';

import {
  SELECT_MATTER_TAB
} from '../../constants/actions';

const initialState = 'required';

export default handleActions({
  [SELECT_MATTER_TAB]: (state, { payload }) => (payload)
}, initialState);
