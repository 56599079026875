import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import SimpleSuggest from '../shared/SimpleSuggest';
import Field from '../shared/Field';
import HelpCopyMatter from './HelpCopyMatter';

export default class CopyFromMatter extends Component {
  static propTypes = {
    matterNumber: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <Field style={{ width: '250px' }} styleLabel={{ width: 'auto' }} label='Copy details from matter'>
        <HelpCopyMatter />
        <SimpleSuggest
          placeholder='Enter matter number...'
          value={this.props.matterNumber}
          className='form-control input-sm'
          allSuggestions={this.props.matterList.map(m => m.get('matterNumber'))}
          onChange={this.props.onChange}
        />
      </Field>
    );
  }
};
