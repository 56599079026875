import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';

export default (file) => (dispatch) => {
  const data = new FormData();
  data.append('file', file);
  return dispatch(callApi({
    url: '/api/migrations/auto-gold/upload',
    method: 'post',
    data
  }))
    .then(() => {
      dispatch(addNotification({
        id: 'upload-auto-gold-data',
        type: 'success',
        message: 'An email will be sent when AutoGold completes.'
      }));
    });
};
