import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';
import removeCategoryFromDocumentIfNeeded from '../category/removeCategoryFromDocumentIfNeeded';
import fetchDocument from './fetchDocument';

export default (matterId, documentNumber, showNotification = true) => (dispatch, getState) => {
  const matter = getState().entities.getIn(['matters', matterId]);

  if (showNotification) {
    dispatch(addNotification({
      id: `document-${documentNumber}`,
      type: 'progress',
      message: `Deleting document ${documentNumber}...`
    }));
  }

  return dispatch(callApi({
    url: documentsUrl + '/delete-from-matter',
    params: { matterId, documentNumber }
  }))
    .then(() => {
      dispatch(removeCategoryFromDocumentIfNeeded(matterId, documentNumber));

      if (showNotification) {
        dispatch(addNotification({
          id: `document-${documentNumber}`,
          type: 'success',
          message: `Deleted document ${documentNumber}.`
        }));
      }
      dispatch(fetchDocument({
        firmName: matter.get('firmName'),
        clientNumber: matter.get('clientNumber'),
        documentNumber
      }));
    })
    .catch(() => {
      if (showNotification) {
        dispatch(addNotification({
          id: `document-${documentNumber}`,
          type: 'error',
          message: `Failed to delete ${documentNumber}.`
        }));
      }
    });
};
