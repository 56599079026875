import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
} from '../../constants/actions';

const initialState = Immutable.List();

export default handleActions({

  [ADD_NOTIFICATION]: (notifications, { payload }) => {
    const existing = notifications.findEntry((notification) => (
      notification.get('id') === payload.id
    ));
    if (!payload.removed || existing) {
      const notification = Immutable.Map({
        id: payload.id,
        type: payload.type,
        message: payload.message,
        removed: !!payload.removed
      });
      notifications = existing
        ? notifications.mergeIn([existing[0]], notification)
        : notifications.push(notification);
    }
    return notifications;
  },

  [REMOVE_NOTIFICATION]: (notifications, { payload }) => (
    notifications.filter((notification) => (
      notification.get('id') !== payload.id
    ))
  )

}, initialState);
