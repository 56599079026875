import React from 'react';
import PropTypes from 'prop-types';
import actions from '../../../redux/actions';
import { connect } from 'react-redux';
import HomeView from '../HomeView';
import imageUrl from '../../../files/maintenance.png';

export default function requireAuth(Component) {
  const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    maintenance: state.app.maintenance
  });

  class AuthenticatedComponent extends React.Component {
    static propTypes = {
      isLoggedIn: PropTypes.bool,
      maintenance: PropTypes.bool
    };

    render() {
      if (this.props.maintenance) {
        return (
          <div className='container text-center'>
            <h2>We apologize for the inconvenience. SyncIDS is currently undergoing some minor maintenance. Like you, our day is simply not complete without the excitement of filing an IDS, so we hope to be back up soon!</h2>
            <br />
            <br />
            <img src={imageUrl} style={{ maxWidth: '90%', maxHeight: '500px' }} />
          </div>
        );
      }

      if (!this.props.isLoggedIn) {
        return (
          <HomeView />
        );
      }
      return (
        <Component {...this.props} />
      );
    }
  }
  return connect(mapStateToProps, actions)(AuthenticatedComponent);
}
