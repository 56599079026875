import Immutable from 'immutable';
import callApi from '../../utils/callApi';
import { documentsUrl } from '../../constants/urls';

export default (matterIds) => (dispatch, getState) => {
  const matters = matterIds
    .map(matterId => getState().entities.getIn(['matters', matterId]))
    .filter(matter => !!matter);

  let firmName;
  let clientNumber;
  const documentNumbers = [];

  matters.forEach(matter => {
    const cited = matter.get('citedArt', Immutable.List());
    const uncited = matter.get('uncitedArt', Immutable.List());
    cited.concat(uncited).forEach(docNumber => {
      if (!documentNumbers.includes(docNumber)) {
        documentNumbers.push(docNumber);
      }
    });
    if (!firmName) {
      firmName = matter.get('firmName');
      clientNumber = matter.get('clientNumber');
    }
  });

  if (!documentNumbers.length) {
    return Promise.resolve();
  }

  return dispatch(callApi({
    url: documentsUrl + '/get-multiple',
    method: 'post',
    params: { firmName, clientNumber },
    data: { documentNumbers }
  }));
};
