import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import NearDuplicateDocument from './NearDuplicateDocument';

export default class NearDuplicateGroup extends Component {
  static propTypes = {
    winner: PropTypes.string,
    group: PropTypes.instanceOf(Immutable.Map).isRequired,
    groupIndex: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onClickIgnore: PropTypes.func.isRequired,
    onSaveEdit: PropTypes.func.isRequired,
    onCancelEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  isComplete() {
    return !!this.props.winner && this.getDocuments().size === 1;
  }

  getDocuments() {
    return this.props.group.get('documents').filter(doc => (
      !doc.get('removed') &&
      !doc.get('deduped') &&
      !doc.get('loser')
    ));
  }

  renderDocument(doc) {
    const group = this.props.group;
    const winner = this.props.winner;
    const docNumber = doc.get('documentNumber');
    const isWinner = docNumber === winner;

    return (
      <NearDuplicateDocument
        key={docNumber}
        groupIndex={this.props.groupIndex}
        showEdit={isWinner}
        showRemove={!isWinner}
        showIgnore={!isWinner}
        input='radio'
        document={doc}
        isMuted={!!winner && docNumber !== winner}
        isChecked={isWinner}
        isRunning={!!group.get('deduping')}
        isComplete={this.isComplete()}
        onSelect={this.props.onSelect}
        onSaveEdit={this.props.onSaveEdit}
        onCancelEdit={this.props.onCancelEdit}
        onClickIgnore={this.props.onClickIgnore}
        onClickEdit={this.props.onEdit}
        onClickRemove={this.props.onRemove}
      />
    );
  }

  render() {
    return (
      <div style={{ textAlign: 'left', flex: 1 }}>
        <div style={{ marginBottom: '15px' }}>
          {`Group #${this.props.groupIndex + 1}`}
        </div>
        {this.getDocuments().map(this.renderDocument)}
      </div>
    );
  }
}
