import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Modal, Button } from 'react-bootstrap';
import HelpIcon from './HelpIcon';

export default class ConfirmModal extends Component {
  static propTypes = {
    help: PropTypes.string,
    title: PropTypes.node.isRequired,
    message: PropTypes.node.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    focusConfirm: PropTypes.bool,
    focusCancel: PropTypes.bool,
    footer: PropTypes.node,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    confirmDisabled: PropTypes.bool,
    onHide: PropTypes.func,
    size: PropTypes.string
  };

  static defaultProps = {
    title: 'Confirmation Required',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    confirmDisabled: false
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true
    };
  }

  onCancel() {
    this.setState({
      show: false
    });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onConfirm() {
    if (this.props.onConfirm) {
      Promise.resolve(this.props.onConfirm()).then(() => {
        this.setState({
          show: false
        });
      });
    } else {
      this.setState({
        show: false
      });
    }
  }

  onArrowRight(ev) {
    if (ev.keyCode === 39) {
      ev.target.parentNode.querySelector('data-confirm')?.focus();
    }
  }

  onArrowLeft(ev) {
    if (ev.keyCode === 37) {
      ev.target.parentNode.querySelector('data-cancel')?.focus();
    }
  }

  onClickModal(e) {
    e.stopPropagation();
  }

  onHide() {
    this.setState({
      show: false
    });
    if (this.props.onHide) {
      this.props.onHide();
    } else if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        size={this.props.size}
        onHide={this.onHide}
        onClick={this.onClickModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ textAlign: 'left' }}>
              {this.props.title}
              &nbsp;&nbsp;
              {this.props.help ? <HelpIcon help={this.props.help} /> : null}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'left' }}>
            {this.props.message}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.props.footer}
          <Button
            data-cancel
            variant='secondary'
            autoFocus={this.props.focusCancel}
            onClick={this.onCancel}
            onKeyUp={this.onArrowRight}
          >
            {this.props.cancelLabel}
          </Button>
          <Button
            data-confirm
            disabled={this.props.confirmDisabled}
            autoFocus={this.props.focusConfirm}
            variant='primary'
            onClick={this.onConfirm}
            onKeyUp={this.onArrowLeft}
          >
            {this.props.confirmLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
