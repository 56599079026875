import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import CitedFilter from './CitedFilter';
import SearchNotesInput from './SearchNotesInput';
import DropdownHelp from '../shared/DropdownHelp';

export default class DocumentsFilter extends Component {
  static propTypes = {
    selectCitedFilter: PropTypes.func.isRequired,
    citedFilter: PropTypes.string.isRequired,
    searchNotes: PropTypes.func.isRequired,
    notesSearch: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      filterType: 'notes'
    };
  }

  onChangeCitedFilter(filter) {
    this.props.selectCitedFilter(filter);
  }

  renderCitedFilter() {
    return (
      <CitedFilter
        filter={this.props.citedFilter}
        onChange={this.onChangeCitedFilter}
      />
    );
  }

  onChangeSearchNotes(value) {
    this.props.searchNotes(value);
  }

  renderSearchNotes() {
    return (
      <SearchNotesInput
        value={this.props.notesSearch}
        onChange={this.onChangeSearchNotes}
      />
    );
  }

  onChangeFilterType(filterType) {
    this.setState({ filterType });
  }

  renderDropdown() {
    const filterOptions = [{
      value: 'notes',
      label: 'Search Doc Notes'
    }];

    return (
      <DropdownHelp
        id='documents-dropdown-filter'
        variant='secondary'
        size='sm'
        style={{
          flex: '0',
          height: '30px',
          marginLeft: '10px'
        }}
        options={filterOptions}
        selected={this.state.filterType}
        onChange={this.onChangeFilterType}
      />
    );
  }

  render() {
    return (
      <div style={{
        margin: '12px 0',
        backgroundColor: '#464545',
        padding: '6px',
        borderRadius: '3px',
        display: 'flex'
      }}
      >
        {this.renderCitedFilter()}
        {this.renderDropdown()}
        {this.renderSearchNotes()}
      </div>
    );
  }
};
