import Immutable from 'immutable';
import createMatter from '../matter/createMatter';
import updateMatter from '../matter/updateMatter';
import { getMatterNumberFromCategory } from '../../../utils/categories';
import fetchMatterIfNeeded from '../matter/fetchMatterIfNeeded';

export default (category) => (dispatch, getState) => {
  const state = getState();
  const {
    app: { selectedClientId }
  } = state;

  const matterList = state.entities.getIn(['matterList', selectedClientId]) || Immutable.List();
  const matterNumber = getMatterNumberFromCategory(category);
  const matter = matterList.find(m => m.get('matterNumber') === matterNumber);

  const getMatter = (matterId) => getState().entities.getIn(['matters', matterId]);

  if (matter) {
    const matterId = matter.get('id');

    return dispatch(fetchMatterIfNeeded(matterId))
      .then(() => getMatter(matterId));
  }

  return dispatch(createMatter(matterNumber))
    .then((createResponse) => {
      const matterId = createResponse.id;
      const notes = 'This dummy matter is used to manage a "bucket" of categorized prior art.';

      return dispatch(updateMatter({ id: matterId, matterCategories: [category] }, matterNumber))
        .then(() => dispatch(updateMatter({ id: matterId, matterClosed: 'ON', notes }, matterNumber)))
        .then(() => getMatter(matterId));
    });
};
