import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { NavDropdown, Nav } from 'react-bootstrap';
import linkManual from '../../files/SyncIDS_User_Manual_4.0.pdf';
import linkReference from '../../files/SyncIDS_Quick_Tips_4.0.pdf';
import { generateViewPDFUrl } from '../../utils/google-doc-viewer';
import { trackDemo } from '../../utils/track';
import SurveyModal from '../login/SurveyModal';

export default class AppBar extends Component {
  static propTypes = {
    email: PropTypes.string,
    logout: PropTypes.func.isRequired,
    openAuthModal: PropTypes.func.isRequired,
    maintenance: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onClickPassword() {
    this.props.openAuthModal({ mode: 'changePassword' });
  }

  onClickLogin() {
    this.props.openAuthModal({ mode: 'login' });
  }

  onClickRegister() {
    this.props.openAuthModal({ mode: 'register' });
  }

  onClickSurvey() {
    this.setState({ showSurvey: true });
  }

  onCloseSurvey() {
    this.setState({ showSurvey: false });
  }

  renderEmail() {
    return (
      <span style={{
        maxWidth: '180px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        float: 'left',
        whiteSpace: 'nowrap'
      }}
      >
        {this.props.email}
      </span>
    );
  }

  renderIcon() {
    return (
      <span style={{ marginLeft: '10px' }} className='fa fa-user' />
    );
  }

  renderTitle() {
    return (
      <div>
        {this.renderEmail()}
        {this.renderIcon()}
      </div>
    );
  }

  renderMenu() {
    if (!this.props.email) {
      return;
    }

    return (
      <NavDropdown
        id='userDropdown'
        className='no-caret'
        title={this.renderTitle()}
      >
        <Nav.Item>
          <Nav.Link active>
            <span className='text-muted'>{this.props.email}</span>
          </Nav.Link>
        </Nav.Item>
        <NavDropdown.Divider />
        <Nav.Item>
          <Nav.Link active onClick={this.onClickPassword}>
            Change Password
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active target='_blank' href={generateViewPDFUrl(linkReference)}>
            Quick Reference
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active target='_blank' href={generateViewPDFUrl(linkManual)}>
            User Manual
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active onClick={this.onClickSurvey}>
            Send Feedback
          </Nav.Link>
        </Nav.Item>
        <NavDropdown.Divider />
        <Nav.Item>
          <Nav.Link active onClick={this.props.logout}>
            Logout
          </Nav.Link>
        </Nav.Item>
      </NavDropdown>
    );
  }

  renderButton({ style, onClick, label, theme }) {
    return (
      <li
        role='presentation'
        style={{
          marginTop: '7px',
          marginRight: '10px'
        }}
      >
        <button
          className={`btn btn-sm btn-${theme || 'primary'}`}
          style={style || {
            backgroundColor: '#28415b',
            borderColor: '#28415b'
          }}
          onClick={onClick}
        >
          {label}
        </button>
      </li>
    );
  }

  renderRegister() {
    if (this.props.email) {
      return;
    }

    return this.renderButton({
      label: 'Create Account',
      onClick: this.onClickRegister
    });
  }

  renderLogin() {
    if (this.props.maintenance || this.props.email) {
      return;
    }

    return this.renderButton({
      label: 'Login',
      onClick: this.onClickLogin
    });
  }

  onClickDemo(e) {
    trackDemo();

    if (document.location.hash.indexOf('demo') !== -1) {
      window.open('https://www.syncids.com/meetjulie');
    } else {
      window.location.hash = '/demo';
    }
  }

  renderDemo() {
    return !this.props.email && this.renderButton({
      label: 'Request a Demo',
      onClick: this.onClickDemo,
      theme: 'warning',
      style: {
        backgroundColor: '#c87f0a',
        borderColor: '#c87f0a'
      }
    });
  }

  renderSurvey() {
    return this.state.showSurvey && (
      <SurveyModal onClose={this.onCloseSurvey} />
    );
  }

  render() {
    return (
      <Nav className='pull-right' style={{ marginLeft: 'auto' }}>
        {this.renderMenu()}
        {this.renderDemo()}
        {this.renderRegister()}
        {this.renderLogin()}
        {this.renderSurvey()}
      </Nav>
    );
  }
};
