import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { clientsUrl } from '../../constants/urls';
import fetchDocuments from '../document/fetchDocuments';
import Immutable from 'immutable';
import fetchMatter from '../matter/fetchMatter';

export default (client, newClientNumber) => (dispatch, getState) => {
  const id = client.get('id');
  const firmName = client.get('firmName');
  const clientNumber = client.get('clientNumber');
  const entities = getState().entities.toJS();
  const matterIds = (entities.matterList[id] || [])
    .map(m => m.id)
    .filter(matterId => entities.matters[matterId]);
  const docNumbers = Object.keys(entities.documents[clientNumber] || {});

  dispatch(addNotification({
    id: `client-${clientNumber}`,
    type: 'progress',
    message: `Renaming client ${clientNumber}...`
  }));
  return dispatch(callApi({
    url: `${clientsUrl}/rename/${id}`,
    method: 'post',
    data: { newClientNumber }
  }))
    .then(() => Promise.all(matterIds.map(matterId => dispatch(fetchMatter(matterId)))))
    .then(() => dispatch(fetchDocuments(Immutable.fromJS({ clientNumber: newClientNumber, firmName }), docNumbers)))
    .then(() => {
      dispatch(addNotification({
        id: `client-${clientNumber}`,
        type: 'success',
        message: `Renamed client ${clientNumber}.`
      }));
    })
    .catch((error) => {
      dispatch(addNotification({
        id: `client-${clientNumber}`,
        type: 'error',
        message: `Failed to rename ${clientNumber}.`
      }));

      throw error;
    });
};
