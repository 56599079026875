import logoAbbott from '../files/customers/Abbott.png';
import logoActivision from '../files/customers/Activision.png';
import logoApple from '../files/customers/Apple.png';
import logoBeatsAudio from '../files/customers/BeatsAudio.svg';
import logoBlackDecker from '../files/customers/BlackDecker.png';
import logoBostonScientific from '../files/customers/Boston_Scientific.png';
import logoChamberlain from '../files/customers/Chamberlain.png';
import logoChevron from '../files/customers/Chevron.svg';
import logoCisco from '../files/customers/Cisco.svg';
import logoCrayola from '../files/customers/Crayola.png';
import logoDigimarc from '../files/customers/Digimarc.png';
import logoDisney from '../files/customers/Disney.png';
import logoDolby from '../files/customers/Dolby.svg';
import logoDuPont from '../files/customers/DuPont.png';
import logoElectrolux from '../files/customers/Electrolux.png';
import logoEletronicArts from '../files/customers/EletronicArts.svg';
import logoFresenius from '../files/customers/Fresenius.png';
import logoGE from '../files/customers/GE.svg';
import logoGilead from '../files/customers/Gilead.png';
import logoGoogle from '../files/customers/Google.svg';
import logoHoneywell from '../files/customers/Honeywell.png';
import logoIBM from '../files/customers/IBM.png';
import logoIntel from '../files/customers/Intel.png';
import logoiRobot from '../files/customers/iRobot.png';
import logoJohnHopkins from '../files/customers/John_Hopkins.png';
import logoKraft from '../files/customers/Kraft.png';
import logoLG from '../files/customers/LG.svg';
import logoMedtronic from '../files/customers/Medtronic.png';
import logoMicron from '../files/customers/Micron.png';
import logoMIT from '../files/customers/MIT.png';
import logoMitsubishi from '../files/customers/Mitsubishi.png';
import logoNorthropGrumman from '../files/customers/NorthropGrumman.png';
import logoOracle from '../files/customers/Oracle.png';
import logoPanasonic from '../files/customers/Panasonic.svg';
import logoSony from '../files/customers/Sony.svg';
import logoTesla from '../files/customers/Tesla.svg';
import logoToyota from '../files/customers/Toyota.png';
import logoTradingTechnologies from '../files/customers/TradingTechnologies.png';
import logoTW from '../files/customers/TW.png';
import logoVisa from '../files/customers/Visa.svg';
import logoWalmart from '../files/customers/Walmart.png';
import logoXerox from '../files/customers/Xerox.svg';

export function getCustomers() {
  return [
    logoAbbott,
    logoActivision,
    logoApple,
    logoBeatsAudio,
    logoBlackDecker,
    logoBostonScientific,
    logoChamberlain,
    logoChevron,
    logoCisco,
    logoCrayola,
    logoDigimarc,
    logoDisney,
    logoDolby,
    logoDuPont,
    logoElectrolux,
    logoEletronicArts,
    logoFresenius,
    logoGE,
    logoGilead,
    logoGoogle,
    logoHoneywell,
    logoIBM,
    logoIntel,
    logoiRobot,
    logoJohnHopkins,
    logoKraft,
    logoLG,
    logoMedtronic,
    logoMicron,
    logoMIT,
    logoMitsubishi,
    logoNorthropGrumman,
    logoOracle,
    logoPanasonic,
    logoSony,
    logoTesla,
    logoToyota,
    logoTradingTechnologies,
    logoTW,
    logoVisa,
    logoWalmart,
    logoXerox
  ];
}
