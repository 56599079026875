import { handleActions } from 'redux-actions';
import {
  UPDATE_CITED,
  SET_FILE_REMINDER
} from '../../constants/actions';

const initialState = [];

export default handleActions({
  [UPDATE_CITED]: (state, { payload }) => (state.concat(payload)),
  [SET_FILE_REMINDER]: (state, { payload }) => {
    if (payload.fileReminder) {
      return state;
    }
    return state.filter(item => item.matterId !== payload.matterId);
  }
}, initialState);
