import { handleActions } from 'redux-actions';

import {
  AUTH_CANCEL_CLICK,
  AUTH_RESET_PROGRESS,
  AUTH_RESET_MESSAGE,
  AUTH_RESET_SUCCESS,
  AUTH_REGISTER_PROGRESS,
  AUTH_REGISTER_MESSAGE,
  AUTH_REGISTER_SUCCESS,
  AUTH_PASSWORD_PROGRESS,
  AUTH_PASSWORD_MESSAGE,
  AUTH_PASSWORD_SUCCESS,
  AUTH_LOGIN_PROGRESS,
  AUTH_LOGIN_MESSAGE,
  AUTH_LOGIN_SUCCESS
} from '../../constants/actions';

const initialState = false;

export default handleActions({

  [AUTH_CANCEL_CLICK]: () => (false),

  [AUTH_RESET_PROGRESS]: () => (true),

  [AUTH_RESET_MESSAGE]: () => (false),

  [AUTH_RESET_SUCCESS]: () => (false),

  [AUTH_REGISTER_PROGRESS]: () => (true),

  [AUTH_REGISTER_MESSAGE]: () => (false),

  [AUTH_REGISTER_SUCCESS]: () => (false),

  [AUTH_PASSWORD_PROGRESS]: () => (true),

  [AUTH_PASSWORD_MESSAGE]: () => (false),

  [AUTH_PASSWORD_SUCCESS]: () => (false),

  [AUTH_LOGIN_PROGRESS]: () => (true),

  [AUTH_LOGIN_MESSAGE]: () => (false),

  [AUTH_LOGIN_SUCCESS]: () => (false)

}, initialState);
