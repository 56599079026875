import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import fetchFamily from './fetchFamily';

export default (matter, noFetchFamily) => (dispatch) => {
  const matterId = matter.get('id');
  const notificationId = `check-espace-${matterId}`;

  dispatch(addNotification({
    id: notificationId,
    type: 'progress',
    message: 'Checking Espace...'
  }));

  return dispatch(callApi({
    url: `/api/migrations/auto-gold/matter/${matterId}`
  })).then((response) => {
    if (response[0].notFound) {
      dispatch(addNotification({
        id: notificationId,
        type: 'error',
        message: 'Application not found.'
      }));
    } else {
      dispatch(addNotification({
        id: notificationId,
        type: 'success',
        message: response[0].status
      }));
      if (!noFetchFamily) {
        return dispatch(fetchFamily(matter, true));
      }
    }
  }).catch(() => {
    dispatch(addNotification({
      id: notificationId,
      type: 'error',
      message: 'Something wrong...'
    }));
  });
};
