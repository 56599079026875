import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Modal, Button, Table } from 'react-bootstrap';
import Loading from '../shared/Loading';
import ConfirmModal from '../shared/ConfirmModal';

export default class SyncOverlapModal extends Component {
  static propTypes = {
    report: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    closeSyncOverlap: PropTypes.func.isRequired,
    mergeSyncOverlap: PropTypes.func.isRequired,
    navigateApp: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      show: true,
      merged: [],
      openFamilies: []
    };
  }

  onClickClose() {
    this.setState({ show: false });
    this.props.closeSyncOverlap();
  }

  getItems() {
    const items = [];
    this.props.report.forEach((report) => {
      report.overlaps.forEach((overlap) => {
        const key = `${report.clientNumber}-${overlap.source.family}-${overlap.target.family}`;
        if (!this.state.merged.includes(key)) {
          items.push({
            clientNumber: report.clientNumber,
            source: overlap.source.family,
            target: overlap.target.family,
            overlap: overlap.overlap,
            key
          });
        }
      });
    });
    return items;
  }

  renderTitle() {
    return (
      <span>Sync Overlap</span>
    );
  }

  renderClose() {
    return (
      <Button
        variant='primary'
        onClick={this.onClickClose}
      >
        Close
      </Button>
    );
  }

  onClickFamily(e) {
    const key = e.target.getAttribute('data-key');
    if (this.state.openFamilies.indexOf(key) === -1) {
      this.setState({
        openFamilies: this.state.openFamilies.concat(key)
      });
    } else {
      this.setState({
        openFamilies: this.state.openFamilies.filter(k => k !== key)
      });
    }
  }

  onClickMatter(e) {
    const matterNumber = e.target.getAttribute('data-matter');
    this.props.navigateApp({ term: matterNumber });
    this.onClickClose();
  }

  onClickMerge(e) {
    const clientNumber = e.target.getAttribute('data-client');
    const source = e.target.getAttribute('data-source');
    const target = e.target.getAttribute('data-target');

    this.setState({
      confirmMerge: {
        clientNumber,
        source,
        target
      }
    });
  }

  onCancelMerge() {
    this.setState({
      confirmMerge: null
    });
  }

  onConfirmMerge() {
    const {
      clientNumber,
      source,
      target
    } = this.state.confirmMerge;

    this.props.mergeSyncOverlap(clientNumber, source, target).then(() => {
      this.setState({
        merged: this.state.merged.concat(`${clientNumber}-${source}-${target}`)
      });
    });
  }

  renderFamily(family, key) {
    const numbers = family.split(',');
    const expand = this.state.openFamilies.indexOf(key) !== -1;
    const styleButton = { margin: '3px 6px 3px 0' };
    const showDropdown = family.length > 20;
    return (
      <div
        key={key}
        style={{
          overflow: 'hidden',
          height: expand ? 'auto' : '35px'
        }}
      >
        <span
          data-key={key}
          className='btn btn-sm btn-secondary'
          style={styleButton}
          onClick={this.onClickFamily}
        >
          {showDropdown && <span className={`fa fa-caret-${expand ? 'down' : 'right'}`} />}
          {' ' + numbers.length + ' ' + (numbers.length > 1 ? 'matters' : 'matter')}
        </span>
        {numbers.map(matterNumber => (
          <span
            key={matterNumber}
            data-matter={matterNumber}
            onClick={this.onClickMatter}
            className='btn btn-sm btn-secondary'
            style={styleButton}
          >
            {matterNumber}
          </span>
        ))}
      </div>
    );
  }

  renderOverlap(item) {
    const styleCaption = { width: '200px', textAlign: 'right' };
    return (
      <div key={item.key} style={{ padding: '10px' }}>
        <Table key={item.key} striped bordered hover>
          <tbody>
            <tr>
              <td style={styleCaption}>Client</td>
              <td>{item.clientNumber}</td>
            </tr>
            <tr>
              <td style={styleCaption}>Matter/Family</td>
              <td>{this.renderFamily(item.target, `${item.key}-target`)}</td>
            </tr>
            <tr>
              <td style={styleCaption}>Similar Matter/Family</td>
              <td>{this.renderFamily(item.source, `${item.key}-source`)}</td>
            </tr>
            <tr>
              <td style={styleCaption}>Overlap</td>
              <td>
                {item.overlap.percentage + '% - ' + item.overlap.count + ' documents'}
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <Button
                  data-client={item.clientNumber}
                  data-source={item.source}
                  data-target={item.target}
                  onClick={this.onClickMerge}
                  variant='primary'
                  size='sm'
                >
                  Merge
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  renderBody() {
    if (this.props.isLoading) {
      return (
        <Loading
          padding='10px 15px'
          text='Loading sync overlap report...'
        />
      );
    }

    const parts = [];
    this.getItems().forEach((item, index, list) => {
      parts.push(this.renderOverlap(item));
      if (index === list.length - 1) {
        parts.push(<br key={index} />);
      } else {
        parts.push(<hr key={index} />);
      }
    });

    return (
      <div>
        {parts}
      </div>
    );
  }

  renderConfirmMerge() {
    return this.state.confirmMerge && (
      <ConfirmModal
        message='Are you sure you want to merge these two groups and all of the associated documents together? This operation CANNOT BE UNDONE.'
        onCancel={this.onCancelMerge}
        onConfirm={this.onConfirmMerge}
      />
    );
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.onClickClose}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.renderTitle()}
          </Modal.Title>
        </Modal.Header>
        {this.renderBody()}
        {this.renderConfirmMerge()}
        <Modal.Footer>
          {this.renderClose()}
        </Modal.Footer>
      </Modal>
    );
  }
}
