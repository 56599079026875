import fetchMatter from './fetchMatter';
import fetchMatterIfNeeded from './fetchMatterIfNeeded';
import { splitCommaUnique } from '../../../utils/text-utils';
import Immutable from 'immutable';
import fetchFamilyDocuments from '../document/fetchFamilyDocuments';

export default (matter, forceRefresh) => (dispatch, getState) => {
  const { entities } = getState();
  const clientId = matter.get('clientId');
  const matterList = entities.getIn(['matterList', clientId]) || Immutable.List();

  const family = [].concat(
    splitCommaUnique(matter.get('oneWayRelatedMatters') || ''),
    splitCommaUnique(matter.get('twoWayRelatedMatters') || '')
  );

  const matterIds = family.map(matterNumber => {
    const matter = matterList.find(matter => matter.get('matterNumber') === matterNumber);
    return matter ? matter.get('id') : null;
  });

  if (forceRefresh) {
    matterIds.push(matter.get('id') || matter.get('matterId'));
  }

  let promise = Promise.resolve();

  matterIds.forEach(matterId => {
    if (matterId) {
      if (forceRefresh) {
        promise = promise.then(() => dispatch(fetchMatter(matterId)));
      } else {
        promise = promise.then(() => dispatch(fetchMatterIfNeeded(matterId)));
      }
    }
  });

  if (forceRefresh) {
    promise = promise.then(() => dispatch(fetchFamilyDocuments(matterIds)));
  }

  return promise;
};
