import { handleActions } from 'redux-actions';

import {
  TODOS_SET_PAGE_SIZE
} from '../../constants/actions';

const initialState = 10;

export default handleActions({

  [TODOS_SET_PAGE_SIZE]: (state, { payload }) => (payload)

}, initialState);
