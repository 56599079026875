import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import TodoRecord from '../../models/TodoRecord';
import CheckButton from '../shared/CheckButton';
import ConfirmModal from '../shared/ConfirmModal';
import Immutable from 'immutable';
import Help from '../shared/Help';

const iconWarning = (
  <span className='fa fa-exclamation-triangle text-warning' />
);

const iconChecked = (
  <span className='fa fa-check text-success' />
);

const iconChecking = (
  <span className='fa fa-spinner fa-spin' />
);

const styleHelp = {
  position: 'relative',
  top: '5px'
};

export default class TodoApproved extends Component {
  static propTypes = {
    attorneys: PropTypes.instanceOf(Immutable.Map).isRequired,
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired,
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    updateMatter: PropTypes.func.isRequired,
    checkConsistency: PropTypes.func.isRequired,
    clearConsistency: PropTypes.func.isRequired,
    selectedMatterId: PropTypes.string,
    subsetIDSEnabled: PropTypes.bool,
    fetchAttorneyIfNeeded: PropTypes.func.isRequired
  };

  static defaultProps = {
    todo: new TodoRecord()
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.todo, state.todo)) {
      return {
        todo: props.todo,
        checked: props.todo.isApproved()
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  updateChecked(checked) {
    this.setState({
      confirm: false
    });

    this.props.updateMatter({
      id: this.props.todo.get('id'),
      approved: checked
    }, this.props.todo.get('matterNumber')).then((response) => {
      if (response) {
        this.setState({
          checked
        });
      }
    });
  }

  isSubsetEnabled() {
    return this.props.todo.matterId === this.props.selectedMatterId && this.props.subsetIDSEnabled;
  }

  getAttorney() {
    return this.props.attorneys.get(this.props.todo.attorneyEmail);
  }

  isSignatorComplete() {
    if (!this.props.todo.attorneyEmail) {
      return false;
    }

    const attorney = this.getAttorney();

    return Boolean(
      attorney &&
      ['registrationNumber', 'fullName', 'applyESignature'].every(field => attorney.get(field))
    );
  }

  renderSignatorWarning() {
    if (this.isSignatorComplete()) {
      return;
    }

    return (
      <div style={{ marginTop: '15px' }}>
        {iconWarning}
        <span style={{ marginLeft: '10px' }}>Matter Signator is incomplete. Are you sure you want to proceed?</span>
        <Help style={styleHelp} text='Required: Attorney Email, Full Name, Reg #, Apply E-Signature.' />
      </div>
    );
  }

  renderTimingWarning() {
    return (
      <div style={{ marginTop: '15px' }}>
        <span className='fa fa-bell text-warning' />
        <span style={{ marginLeft: '10px' }}>
          Reminder. Set Timing and Statement before proceeding.
        </span>
      </div>
    );
  }

  getIncompleteDocs() {
    if (this.isSubsetEnabled()) {
      return this.props.visibleDocuments.filter(doc => !doc.canDocBeFiled()).size;
    } else {
      return this.props.todo.getIncompleteDocsCount();
    }
  }

  renderDocsWarning() {
    const counter = this.getIncompleteDocs();
    const icon = counter > 0 ? iconWarning : counter === 0 ? iconChecked : iconChecking;
    const message = counter > 0
      ? `${counter} uncited references missing required fields.`
      : counter === 0 ? 'All uncited references are valid.' : 'Validating uncited references...';
    return (
      <div style={{ marginTop: '15px' }}>
        {icon}
        <span style={{ marginLeft: '10px' }}>{message}</span>
        {counter > 0 && (
          <Help style={styleHelp} text='Required: PDF, Date, Inventor or CC' />
        )}
      </div>
    );
  }

  renderConfirmMessage() {
    return (
      <div>
        Are you sure you want to mark this IDS as approved?
        {this.renderDocsWarning()}
        {this.renderSignatorWarning()}
        {this.renderTimingWarning()}
      </div>
    );
  }

  renderConfirm() {
    return this.state.confirm && (
      <ConfirmModal
        focusConfirm
        message={this.renderConfirmMessage()}
        onCancel={this.onClose}
        onConfirm={this.onConfirm}
      />
    );
  }

  onChange(checked) {
    if (checked) {
      this.setState({ confirm: true }, () => {
        this.props.fetchAttorneyIfNeeded(this.props.todo.attorneyEmail);
        this.props.checkConsistency(this.props.todo.id);
      });
    } else {
      this.updateChecked(false);
    }
  }

  onClose() {
    this.props.clearConsistency(this.props.todo.id);
    this.setState({
      checked: false,
      confirm: false
    });
  }

  onConfirm() {
    this.updateChecked(true);
  }

  render() {
    return (
      <div>
        <CheckButton
          checked={this.state.checked}
          onChange={this.onChange}
          onClick={this.onClick}
        />
        {this.renderConfirm()}
      </div>
    );
  }
};
