import callApi from '../../utils/callApi';
import { mattersUrl } from '../../constants/urls';
import _get from 'lodash/get';

export default (matterId) => (dispatch, getState) => {
  return dispatch(callApi({
    url: `${mattersUrl}/${matterId}/update-pair-status`
  })).then(response => {
    const doc = _get(response, ['entities', 'matters', matterId]);
    return doc;
  });
};
