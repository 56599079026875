import autoBind from 'class-autobind';
import CustomInput from './CustomInput';
import { formatDate } from '../../utils/date';

const dateFormat = 'MM/DD/YYYY';

export default class DateInput extends CustomInput {
  static defaultProps = {
    placeholder: dateFormat
  };

  static getDerivedStateFromProps = CustomInput.getDerivedStateFromProps;

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getValue() {
    return formatDate(this.state.value);
  }
};
