import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TodoRecord from '../../models/TodoRecord';

export default class TodoField extends Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    style: PropTypes.object
  };

  static defaultProps = {
    todo: new TodoRecord(),
    style: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  };

  render() {
    const { todo, field } = this.props;
    const value = todo.get(field);
    return (
      <div title={value} style={this.props.style}>{value}</div>
    );
  }
};
