import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';
import fetchDocuments from './fetchDocuments';
import _get from 'lodash/get';

export default (matter, documents, skipCache) => (dispatch) => {
  let promise = Promise.resolve();
  const updatedDocs = [];

  documents.forEach(doc => {
    promise = promise.then(() => {
      dispatch(addNotification({
        id: 'look-up-documents',
        type: 'progress',
        message: `Looking up ${doc.documentNumber}...`
      }));

      return resolveDoc(doc)
        .then(update => {
          if (update) {
            updatedDocs.push(doc.getRealDocumentNumber());
          }
        })
        .catch((e) => {});
    });
  });

  documents.forEach(doc => {
    promise = promise.then(() => {
      dispatch(addNotification({
        id: 'look-up-documents',
        type: 'progress',
        message: `Fetching ${doc.documentNumber}...`
      }));

      return fetchDoc(doc)
        .then(update => {
          if (update) {
            updatedDocs.push(doc.getRealDocumentNumber());
          }
        })
        .catch((e) => {});
    });
  });

  promise
    .then(() => {
      dispatch(addNotification({
        id: 'look-up-documents',
        type: 'progress',
        message: 'Reloading documents...'
      }));
    })
    .then(() => dispatch(fetchDocuments(matter, updatedDocs)))
    .then(() => {
      dispatch(addNotification({
        id: 'look-up-documents',
        type: 'success',
        message: 'Done!'
      }));
    });

  function fetchDoc(doc) {
    if (doc.get('type') !== 'Foreign') {
      return Promise.resolve();
    }

    if (doc.hasPDF() && !skipCache) {
      return dispatch(callApi({
        url: `/api/fetch-pdf/doc/${doc.id}/retry-missing-pages`
      })).then((response) => Boolean(
        !response.error &&
        _get(response, ['processedDocs', doc.id, 'status'], '').includes('Completed')
      ));
    }

    return dispatch(callApi({
      url: `/api/fetch-pdf/doc/${doc.id}`,
      params: skipCache ? { skipCache: true } : {}
    })).then((response) => Boolean(
      !response.error &&
      _get(response, ['processedDocs', doc.id, 'status'], '').includes('Completed')
    ));
  }

  function resolveDoc(doc) {
    if (!doc.shouldResolve()) {
      return Promise.resolve();
    }

    return dispatch(callApi({
      url: `${documentsUrl}/${doc.id}/resolve`,
      params: {
        forceUpdate: true
      }
    }, null, true)).then(response => Boolean(
      _get(response, ['entities', 'documents', doc.clientNumber, doc.getRealDocumentNumber()])
    ));
  }
};
