import React, { Component } from 'react';
import RequestDemo from '../shared/RequestDemo';
import CiteChronContainer from '../cite-chron/CiteChronContainer';
import citeChronImage from '../../files/cite-chron.png';

const styleImage = {
  backgroundColor: 'white',
  textAlign: 'center'
};

export default class CiteChronView extends Component {
  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  render() {
    return (
      <div>
        <div className='container bigger-fonts'>
          <h1>CiteChron</h1>
          <hr />
          <ul>
            <li>CiteChron analyzes related file histories to identify prior art for unenforceability and invalidity defenses.</li>
            <li>CiteChron finds prior art prosecutors knew about but failed to cite.</li>
            <li>We deliver this “withheld art” along with proof that it was known before issuance of the target patent.</li>
          </ul>
          <br />
          <CiteChronContainer />
          <br />
        </div>
        <div style={styleImage}>
          <br />
          <img src={citeChronImage} style={{ maxWidth: '80%' }} />
          <br />
          <br />
        </div>
        <div className='container'>
          <br />
          <p>What is withheld art?</p>
          <ul>
            <li>Not cited in the target patent</li>
            <li>U.S. equivalent not cited</li>
            <li>Foreign equivalent not cited</li>
            <li>But, was cited in a related case prior to issuance of the target patent</li>
          </ul>
          <p>CiteChron is a service provided by SyncIDS. SyncIDS has been providing software and services to the IP community since 2010, with thousands of users from law firms and companies around the world.</p>
          <hr />
          <div className='text-center'>
            <RequestDemo />
          </div>
          <br />
        </div>
      </div>
    );
  }
}
