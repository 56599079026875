export const API_ROOT = process.env.API_ROOT;
export const usersUrl = API_ROOT + '/api/auth';
export const attorneyUrl = '/api/attorneys';
export const changePasswordUrl = usersUrl + '/change-password';
export const statsUrl = '/api/stats';
export const clientsUrl = '/api/clients';
export const documentsUrl = '/api/documents';
export const loginUrl = usersUrl + '/login';
export const mattersUrl = '/api/matters';
export const priorArtUrl = '/api/prior-art';
export const registerUrl = usersUrl + '/register';
export const reportsUrl = '/api/reports';
export const resetPasswordUrl = usersUrl + '/reset-password';
export const singleSignOnUrl = usersUrl + '/saml';
export const testDriveUrl = '/api/test-drive';
export const todosUrl = '/api/todos';
export const citeChronUrl = '/api/test-drive/cite-chron';
export const sweepUrl = '/sweeper-api/sweep/matter';
export const officeActionsCompleteUrl = '/api/office-actions/mark-complete';
export const matterNotesUrl = '/api/documents/matter-notes';
