import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { documentsUrl } from '../../constants/urls';

export default (matterId) => (dispatch) => {
  dispatch(addNotification({
    id: `sort-docs-${matterId}`,
    type: 'progress',
    message: 'Sorting documents...'
  }));
  return dispatch(callApi({
    url: documentsUrl + '/sort',
    params: { matterId }
  }))
    .then(() => {
      dispatch(addNotification({
        id: `sort-docs-${matterId}`,
        type: 'success',
        message: 'Sorting completed.'
      }));
    });
};
