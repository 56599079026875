import autoBind from 'class-autobind';
import CustomInput from './CustomInput';

export default class CountryInput extends CustomInput {
  static defaultProps = {
    maxLength: 2
  };

  static getDerivedStateFromProps = CustomInput.getDerivedStateFromProps;

  constructor(props) {
    super(props);
    autoBind(this);
  }

  setValue(value) {
    this.setState({ value: value.toUpperCase() });
  }
};
