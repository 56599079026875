import { handleActions } from 'redux-actions';

import { VIEW_CATEGORY, CLOSE_CATEGORY } from '../../constants/actions';

const initialState = {};

export default handleActions({

  [VIEW_CATEGORY]: (state, { payload }) => (payload),

  [CLOSE_CATEGORY]: () => (initialState)

}, initialState);
