import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import Field from '../../shared/Field';
import SimpleSuggest from '../../shared/SimpleSuggest';
import Form from '../../shared/Form';
import { Button } from 'react-bootstrap';
import ReminderEmail from '../../client/ReminderEmail';
import HelpIcon from '../../shared/HelpIcon';

const attorneyFields = [
  'FeeLevel',
  'StaffFeeLevel'
];

const clientFields = [
  'approvedDateIds',
  'billingPeriod',
  'depositAccountNumber',
  'generalReminderFrequency',
  'groupMonthlyInvoicePerClient',
  'hideClientNumbers',
  'invoiceEmail',
  'noTransmittalLetterOnIdsPackage',
  'pastDueReminderFrequency',
  'patsAndPubsFlag',
  'reminderRecipientEmails',
  'sweepCitationsFlag',
  'sweepClientNotes',
  'sweepISRFlag',
  'sweepUsOfficeActionsFlag',
  'transmittalLetterAdditionalComments',
  'transmittalLetterGeneralText',
  'urgentReminderFrequency',
  'hideClientNumberIdsFiles',
  'findNewMatters'
];

const fields = [
  ...attorneyFields,
  ...clientFields
];

export default class FirmClientSettings extends Form {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    getFirmAttorneySettings: PropTypes.func.isRequired,
    getFirmClientSettings: PropTypes.func.isRequired,
    saveFirmClientSettings: PropTypes.func.isRequired,
    saveFirmAttorneySettings: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      firmName: '',
      isLoaded: false,
      isSaving: false
    };
  }

  onClickReset() {
    fields.forEach(field => {
      this.setState({ [field]: null });
    });

    this.setState({
      firmName: '',
      isLoaded: false,
      isSaving: false
    });
  }

  getAttorneyData() {
    const data = {};
    attorneyFields.forEach(field => {
      data[field] = this.state[field];
    });
    return data;
  }

  getClientData() {
    const data = {};
    clientFields.forEach(field => {
      data[field] = this.state[field];
    });
    return data;
  }

  onClickSave() {
    this.setState({
      isSaving: true
    });

    this.props.addNotification({
      id: 'firm-client-settings',
      type: 'progress',
      message: 'Saving...'
    });

    Promise.all([
      this.props.saveFirmClientSettings(this.state.firmName, this.getClientData()),
      this.props.saveFirmAttorneySettings(this.state.firmName, this.getAttorneyData())
    ]).then(values => {
      this.setState({
        isSaving: false
      });
      this.props.addNotification({
        id: 'firm-client-settings',
        type: 'success',
        message: 'Saved!'
      });
    }).catch(() => {
      this.props.addNotification({
        id: 'firm-client-settings',
        type: 'error',
        message: 'Failed!'
      });
    });
  }

  onChangeFirmName(firmName) {
    this.setState({
      firmName,
      isLoaded: false,
      isSaving: false
    });
  }

  onSubmitFirmName(firmName) {
    fields.forEach(field => {
      this.setState({ [field]: null });
    });

    this.setState({
      firmName
    }, () => {
      Promise.all([
        this.props.getFirmAttorneySettings(firmName).then(values => this.setState(values)),
        this.props.getFirmClientSettings(firmName).then(values => this.setState(values))
      ]).then(() => {
        this.setState({
          isLoaded: true
        });
      });
    });
  }

  onChangeEmail(values) {
    this.setState(values);
  }

  renderFirm() {
    return (
      <div style={{ marginTop: '15px', width: '25%' }}>
        <Field label='Firm Name'>
          <SimpleSuggest
            submitOnEnter
            name='firmName'
            value={this.state.firmName}
            onChange={this.onChangeFirmName}
            onSubmit={this.onSubmitFirmName}
            spellCheck={false}
            className='form-control'
          />
        </Field>
      </div>
    );
  }

  renderSweep() {
    return (
      <div style={{ marginTop: '15px' }}>
        <Field label='Sweep'>
          {this.renderCheckbox('892 Citations', 'sweepCitationsFlag', [false, true], '100%')}
          {this.renderCheckbox('US Office Actions', 'sweepUsOfficeActionsFlag', [false, true], '100%', !this.state.sweepCitationsFlag)}
          {this.renderCheckbox('ISR Citations and OAs', 'sweepISRFlag', [false, true], '100%')}
          {this.renderCheckbox('Patents and Publications', 'patsAndPubsFlag', [false, true], '100%')}
          {this.renderCheckbox('Client Notes', 'sweepClientNotes', [false, true], '100%')}
        </Field>
      </div>
    );
  }

  renderIDS() {
    return (
      <div style={{ marginTop: '15px' }}>
        <Field label='IDS'>
          {this.renderCheckbox('Do not include Transmittal Letter on IDS package', 'noTransmittalLetterOnIdsPackage', [false, true], '100%')}
          {this.renderCheckbox('Use approved date on Transmittal Letter and IDS Forms', 'approvedDateIds', [false, true], '100%')}
          {this.renderCheckbox('Hide client number on IDS ZIP and PDF files', 'hideClientNumberIdsFiles', [false, true], '100%')}
        </Field>
      </div>
    );
  }

  renderInvoice() {
    return (
      <div style={{ marginTop: '15px' }}>
        <Field label='Invoice'>
          {this.renderCheckbox('Group monthly invoices by client', 'groupMonthlyInvoicePerClient', [false, true], '100%')}
        </Field>
      </div>
    );
  }

  renderCronJobs() {
    return (
      <div style={{ marginTop: '15px' }}>
        <Field label='Cron Jobs'>
          {this.renderCheckbox('Find New Matters', 'findNewMatters', [false, true], '100%')}
        </Field>
      </div>
    );
  }

  renderPastDueInvoiceReminderFrequency() {
    return (
      <div style={{ marginTop: '5px' }}>
        <Field label='Past Due Invoice Reminder Frequency'>
          <select
            name='pastDueReminderFrequency'
            value={this.state.pastDueReminderFrequency}
            onChange={this.onChange}
            className='form-control'
          >
            <option value={0}>Disabled</option>
            <option value={30}>30 days</option>
            <option value={60}>60 days</option>
            <option value={90}>90 days</option>
            <option value={120}>120 days</option>
          </select>
        </Field>
      </div>
    );
  }

  renderInvoiceFrequency() {
    return (
      <div style={{ marginTop: '5px' }}>
        <Field label='Invoice Frequency'>
          <select
            name='billingPeriod'
            value={this.state.billingPeriod || ''}
            onChange={this.onChange}
            className='form-control'
          >
            <option value=''>(empty)</option>
            <option value='Immediately'>Immediately</option>
            <option value='Monthly'>Monthly</option>
          </select>
        </Field>
      </div>
    );
  }

  renderReminderFrequency({ label, field }) {
    return (
      <div style={{ marginTop: '5px' }}>
        <Field label={label}>
          <select
            name={field}
            disabled={!this.state.reminderRecipientEmails}
            value={this.state[field]}
            onChange={this.onChange}
            className='form-control'
          >
            <option value=''>(empty)</option>
            <option value='None'>None</option>
            <option value='Daily'>Daily</option>
            <option value='Weekly'>Weekly</option>
            <option value='SemiMonthly'>Semi-monthly</option>
            <option value='Monthly'>Monthly</option>
          </select>
        </Field>
      </div>
    );
  }

  renderReminderEmail() {
    const record = Immutable.Map({ reminderRecipientEmails: this.state.reminderRecipientEmails || '' });

    return (
      <div style={{ marginTop: '5px' }}>
        <ReminderEmail
          label='Reminder Email Recipients'
          record={record}
          onChange={this.onChangeEmail}
        />
      </div>
    );
  }

  renderInvoiceEmail() {
    const record = Immutable.Map({ invoiceEmail: this.state.invoiceEmail || '' });

    return (
      <div style={{ marginTop: '5px' }}>
        <ReminderEmail
          field='invoiceEmail'
          label='Invoice Email'
          record={record}
          onChange={this.onChangeEmail}
        />
      </div>
    );
  }

  renderSubmit() {
    return (
      <div style={{ margin: '15px 0' }}>
        <Button
          variant='secondary'
          onClick={this.onClickReset}
          style={{ marginRight: '12px' }}
        >
          Cancel
        </Button>
        <Button
          variant='primary'
          style={{ marginRight: '12px' }}
          disabled={this.state.isSaving}
          onClick={this.onClickSave}
        >
          {this.state.isSaving ? 'Saving...' : 'Save'}
        </Button>
      </div>
    );
  }

  renderFeeLevel(field, label) {
    return (
      <div style={{ marginTop: '5px' }}>
        {this.renderInput(label, field)}
      </div>
    );
  }

  renderAttorney() {
    return (
      <div className='row'>
        <div className='col-md-6'>
          {this.renderFeeLevel('FeeLevel', 'Fee Level')}
        </div>
        <div className='col-md-6'>
          {this.renderFeeLevel('StaffFeeLevel', 'Staff Fee Level (syncids@)')}
        </div>
      </div>
    );
  }

  renderHideClientNumbers() {
    const label = (
      <div>
        Hide Client Numbers
        &nbsp;&nbsp;&nbsp;
        <HelpIcon help='Specify client numbers that should not flow into IDS Forms and Transmittal Letters. Separate multiple client numbers with commas. Enter "ALL" for hiding all client numbers.' />
      </div>
    );

    return (
      this.renderInput(label, 'hideClientNumbers')
    );
  }

  renderClient() {
    return (
      <div className='row'>
        <div className='col-md-6'>
          {this.renderInput('Deposit Account Number', 'depositAccountNumber', '100%')}
          {this.renderInvoiceEmail()}
          {this.renderInvoiceFrequency()}
          {this.renderPastDueInvoiceReminderFrequency()}
          {this.renderSweep()}
        </div>
        <div className='col-md-6'>
          {this.renderHideClientNumbers()}
          {this.renderReminderEmail()}
          {this.renderReminderFrequency({
            label: 'Reminder Pending Frequency',
            field: 'generalReminderFrequency'
          })}
          {this.renderReminderFrequency({
            label: 'Reminder Urgent Frequency',
            field: 'urgentReminderFrequency'
          })}
          {this.renderIDS()}
          {this.renderInvoice()}
          {this.renderCronJobs()}
        </div>
      </div>
    );
  }

  renderTransmittalLetter() {
    return (
      <div className='row'>
        <div className='col-md-6'>
          {this.renderTextarea('Transmittal Letter General Text', 'transmittalLetterGeneralText', 6)}
        </div>
        <div className='col-md-6'>
          {this.renderTextarea('Transmittal Letter Additional Comments', 'transmittalLetterAdditionalComments', 6)}
        </div>
      </div>
    );
  }

  renderForm() {
    return this.state.isLoaded && (
      <div>
        <h4>Attorney Settings</h4>
        {this.renderAttorney()}
        <hr />
        <h4>Client Settings</h4>
        {this.renderClient()}
        <hr />
        <h4>Transmittal Letter</h4>
        {this.renderTransmittalLetter()}
        <div className='row'>
          <div className='col-md-12 text-right'>
            <hr />
            {this.renderSubmit()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <CollapsiblePanel id='firm-client-settings' header='Firm Settings'>
        <div className='row'>
          <div className='col-md-12'>
            {this.renderFirm()}
            <hr />
          </div>
        </div>
        {this.renderForm()}
      </CollapsiblePanel>
    );
  }
};
