import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import { DOCUMENT_ADD, DOCUMENT_ADD_SUCCESS } from '../../constants/actions';

const initialState = Immutable.fromJS({
  US: { type: 'US' },
  Other: { type: 'Other' },
  Foreign: { type: 'Foreign' }
});

export default handleActions({

  [DOCUMENT_ADD]: (state, { payload: { type, field, value } }) => {
    return state.setIn([type, field], value);
  },

  [DOCUMENT_ADD_SUCCESS]: () => (initialState)

}, initialState);
