import Immutable from 'immutable';
import { mergeDeepReplacingLists } from '../../../utils/merge-deep-replacing-lists';

import {
  NPL_SUGGESTIONS,
  MATTER_CHECK_CONSISTENCY,
  MATTER_CLEAR_CONSISTENCY,
  DELETE_DOCUMENT_SUCCESS,
  MATTER_RENAME_SUCCESS,
  DOCUMENT_REPLACE_SUCCESS,
  CLEAR_NPL_DOCUMENTS,
  PENDING_OFFICE_ACTIONS
} from '../../constants/actions';

const initialState = Immutable.fromJS({
  matterConsistency: {},
  matters: {}, // by id
  clients: {}, // by id
  // clientList: null, // all clients for currently logged in user by firmName
  documents: {}, // by clientNumber->docNumber
  nplSuggestions: {}, // by clientNumber->docNumber
  attorneys: {}, // by email.toLowerCase,
  matterList: {}, // by clientId,
  todoList: [], // array of matter ids
  pendingOfficeActions: {} // by matter ids
});

export default (state = initialState, action) => {
  let entities = state;

  if (action.payload && action.payload.entities) {
    entities = mergeDeepReplacingLists(state, action.payload.entities);
  }

  switch (action.type) {
    case NPL_SUGGESTIONS: {
      const { clientNumber, doc } = action.payload;
      entities = entities.setIn(['nplSuggestions', clientNumber, doc.documentNumber], Immutable.fromJS(doc));
      break;
    }

    case MATTER_CHECK_CONSISTENCY: {
      const { matterId, consistency } = action.payload;
      entities = entities.setIn(['matterConsistency', matterId], consistency);
      break;
    }

    case MATTER_CLEAR_CONSISTENCY:
      entities = entities.deleteIn(['matterConsistency', action.payload.matterId]);
      break;

    case DOCUMENT_REPLACE_SUCCESS: {
      const { clientNumber, documentNumber } = action.payload;
      entities = entities.deleteIn(['documents', clientNumber, documentNumber]);
      break;
    }

    case MATTER_RENAME_SUCCESS: {
      const matter = entities.getIn(['matters', action.payload.matterId]);
      entities = entities.updateIn(['matterList', matter.get('clientId')], (matterList) => {
        return matterList
          .filter(m => m.get('id') !== matter.get('id'))
          .push(Immutable.Map({
            id: matter.get('id'),
            matterNumber: action.payload.matterNumber
          }));
      });
      break;
    }

    case DELETE_DOCUMENT_SUCCESS:
      entities = entities.deleteIn(['matterList', action.payload.clientNumber, action.payload.documentNumber]);
      break;

    case CLEAR_NPL_DOCUMENTS:
      entities = entities.set('nplSuggestions', Immutable.Map());
      break;

    case PENDING_OFFICE_ACTIONS:
      entities = entities.setIn(['pendingOfficeActions', action.payload.matterId], action.payload.pendingOfficeActions);
      break;
  }
  return entities;
};
