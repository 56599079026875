import React from 'react';
import PageFooter from '../shared/PageFooter';
import { Table } from 'react-bootstrap';

export default class PaymentsView extends React.Component {
  render() {
    const email = 'accounting@syncids.com';
    const url = 'https://buy.stripe.com/3cs00YdDudjlawE3cc';

    return (
      <div className='container bigger-fonts'>
        <div>
          <h1>How to Pay</h1>
          <p>We are pleased to offer the following convenient ways of making payments to SyncIDS:</p>
        </div>

        <Table bordered>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <p><strong>Credit/Debit Card</strong></p>
                <p><strong>ACH</strong></p>
              </td>
              <td>
                <p><strong>One-Time Payments:</strong> Please click <a href={url}>here</a> to make a one-time payment for one or more invoices.</p>
                <p><strong>Recurring Payments:</strong> Please email <a href={`mailto:${email}`}>{email}</a> for set up details.</p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <p><strong>Check</strong></p>
              </td>
              <td>
                <p>Please make checks payable and remit to:</p>
                <p>
                  <strong>SyncIDS</strong>
                  <br />
                  1341 W Mockingbird LN STE 600W
                  <br />
                  Dallas, TX 75247
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <p><strong>Important:</strong> Please remit payment within 30 days of the date of the invoice unless other arrangements have been made. For wire transfers, please email <a href={`mailto:${email}`}>{email}</a> for details.</p>
        <p>If you have any questions or comments, please contact us at <a href={`mailto:${email}`}>{email}</a> or 469-382-8144.</p>
        <p>Thank you for your business.</p>
        <hr />
        <PageFooter />
      </div>
    );
  }
}
