import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NplMatchIntro extends Component {
  static propTypes = {
    title: PropTypes.bool
  };

  render() {
    return (
      <div>
        {this.props.title ? <h4>NPL/PDF Match</h4> : <br />}
        <p>This migration tool is used to match NPL descriptions (already in the database) with corresponding PDFs (uploaded to this tool in one large PDF separated by bookmarks).</p>
        {!this.props.title && (
          <div>
            <br />
            <h4>Loading</h4>
            <p>To load the list of NPL descriptions, enter a Firm Name, a Client BU/ID, and a Matter ID. Optionally, leave the Matter ID blank, if you want NPL descriptions for the entire client.</p>
            <p>To load the the large PDF containing the individual PDFs press the “Load PDF” button.</p>
            <br />
            <h4>Fast Matching</h4>
            <p>If the list of NPL descriptions "lines up" with the PDF bookmarks, you are in luck! Simply press the Enter Key to confirm the match. The tool will automatically advance to the next PDF bookmark and the next NPL description. If they match again, press enter again!</p>
            <p>If the "line up" gets a little off, use the Arrow Keys to realign. Up/Down for PDF bookmarks. Left/Right for NPL descriptions.</p>
            <br />
            <h4>Ad Hoc Matching</h4>
            <p>If fast matching isn't working, enter the PDF title/description (partials give pop ups) or the NPL#. When you have the correct match, press the “Match” button.</p>
          </div>
        )}
      </div>
    );
  }
};
