import callApi from '../../utils/callApi';
import { officeActionsCompleteUrl } from '../../constants/urls';
import addNotification from '../app/addNotification';

export default (ids, docs) => (dispatch, getState) => {
  const joinedIds = ids.join(',');

  dispatch(addNotification({
    id: `office-action-${joinedIds}`,
    type: 'progress',
    message: 'Marking sweep as complete...'
  }));

  return dispatch(callApi({
    url: officeActionsCompleteUrl,
    method: 'POST',
    noQuery: true,
    data: {
      ids,
      docs
    }
  }))
    .then(response => {
      dispatch(addNotification({
        id: `office-action-${joinedIds}`,
        type: 'success',
        message: 'Sweep complete!'
      }));
      return response;
    })
    .catch(() => {
      dispatch(addNotification({
        id: `office-action-${joinedIds}`,
        type: 'error',
        message: 'Failed to mark sweep as completed.'
      }));
    });
};
