import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';

export default class InvoiceInput extends Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    record: PropTypes.any,
    rowIndex: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    isNumber: PropTypes.bool,
    example: PropTypes.string
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getValue() {
    return this.props.record.get(this.props.field) || '';
  }

  onChange(e) {
    let value = e.target.value;
    if (this.props.isNumber && value !== '' && !isNaN(Number(value))) {
      value = Number(value);
    }
    this.props.onChange(this.props.rowIndex, this.props.field, value);
  }

  render() {
    return (
      <div className='form-group' style={{ marginBottom: 0 }}>
        <input
          className='form-control input-sm'
          name={this.props.field}
          value={this.getValue()}
          placeholder={this.props.example}
          onBlur={this.props.onBlur}
          onChange={this.onChange}
        />
      </div>
    );
  }
};
