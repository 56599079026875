import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentRecord from '../../models/DocumentRecord';
import { Button, Modal } from 'react-bootstrap';
import autoBind from 'class-autobind';
import { splitCommaUnique } from '../../utils/text-utils';
import CheckButton from '../shared/CheckButton';

export default class DocumentListedModal extends Component {
  static propTypes = {
    doc: PropTypes.instanceOf(DocumentRecord),
    matter: PropTypes.instanceOf(Immutable.Map),
    client: PropTypes.instanceOf(Immutable.Map),
    onClose: PropTypes.func.isRequired,
    navigateApp: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true,
      cited: true,
      uncited: true
    };
  }

  onCancel() {
    this.props.onClose();
  }

  searchTodos(includeFamily) {
    const query = [`client:${this.props.client.get('clientNumber')}`];
    if (includeFamily) {
      query.push(`matterfamily:${this.props.matter.get('matterNumber')}`);
    }
    if (this.state.cited && this.state.uncited) {
      query.push(`doc:${this.props.doc.documentNumber}`);
    } else if (this.state.cited) {
      query.push(`citeddoc:${this.props.doc.documentNumber}`);
    } else if (this.state.uncited) {
      query.push(`unciteddoc:${this.props.doc.documentNumber}`);
    }

    window.scrollTo(0, 0);

    this.props.navigateApp({
      term: query.join(' '),
      filterType: 'query',
      show: 'all'
    }, true);

    this.props.onClose();
  }

  onClickClient() {
    this.searchTodos(false);
  }

  onClickFamily() {
    this.searchTodos(true);
  }

  filterCited(matter) {
    if (this.state.cited && matter.get('isCited')) {
      return true;
    }
    if (this.state.uncited && !matter.get('isCited')) {
      return true;
    }
    return false;
  }

  getClientCount() {
    return this.props.doc.alsoListedInMatters
      .filter(this.filterCited)
      .count();
  }

  getFamilyCount() {
    const family = [].concat(
      splitCommaUnique(this.props.matter.get('oneWayRelatedMatters') || ''),
      splitCommaUnique(this.props.matter.get('twoWayRelatedMatters') || '')
    );
    return this.props.doc.alsoListedInMatters
      .filter(this.filterCited)
      .filter(matter => family.includes(matter.get('matterNumber')))
      .count();
  }

  onChangeCited(cited) {
    this.setState({ cited });
  }

  onChangeUncited(uncited) {
    this.setState({ uncited });
  }

  renderCited() {
    return (
      <div style={{ marginRight: 'auto' }}>
        <div>
          Listed as:
        </div>
        <div style={{ padding: '10px 0' }}>
          <CheckButton
            checked={this.state.cited}
            onChange={this.onChangeCited}
          />
          &nbsp;&nbsp;
          Cited
        </div>
        <div>
          <CheckButton
            checked={this.state.uncited}
            onChange={this.onChangeUncited}
          />
          &nbsp;&nbsp;
          Uncited
        </div>
      </div>
    );
  }

  render() {
    return this.state.show && (
      <Modal
        show={this.state.show}
        onHide={this.onCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          Confirmation Required
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'left' }}>
          View matters that list document {this.props.doc.documentNumber}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'flex-start' }}>
          {this.renderCited()}
          <Button
            onClick={this.onCancel}
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            style={{ width: '110px' }}
            variant='primary'
            onClick={this.onClickFamily}
          >
            Family ({this.getFamilyCount()})
          </Button>
          <Button
            style={{ width: '110px' }}
            autoFocus
            variant='primary'
            onClick={this.onClickClient}
          >
            Client ({this.getClientCount()})
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
