import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Table } from 'react-bootstrap';
import Field from '../shared/Field';
import { splitCommaUnique } from '../../utils/text-utils';
import ConfirmModal from '../shared/ConfirmModal';
import RemoveCategoryModal from '../categories/RemoveCategoryModal';
import HelpIcon from '../shared/HelpIcon';

const KEY_RETURN = 13;
const KEY_ENTER = 14;
const KEY_SPACE = 32;

export default class SyncCategory extends Component {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    updateMatter: PropTypes.func.isRequired,
    viewCategory: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    removeCategory: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (
      !Immutable.is(props.client, state.client) ||
      !Immutable.is(props.matter, state.matter)
    ) {
      return {
        client: props.client,
        matter: props.matter,
        value: '',
        selectedCategories: props.matter.get('matterCategories') || Immutable.List()
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  getCategories() {
    return splitCommaUnique(this.props.client.get('clientCategories') || '');
  }

  getSelectedCategories() {
    const categories = this.getCategories();

    return (this.props.matter.get('matterCategories') || Immutable.List()).filter(category => (
      categories.includes(category)
    ));
  }

  isSelected(category) {
    return this.state.selectedCategories && this.state.selectedCategories.includes(category);
  }

  onClickView(e) {
    this.props.viewCategory(e.target.getAttribute('data-category'));
  }

  onClickRemove(e) {
    this.setState({
      confirmRemove: e.target.getAttribute('data-category')
    });
  }

  onCloseRemove() {
    this.setState({
      confirmRemove: false
    });
  }

  onClick(e) {
    const matter = this.props.matter;
    const category = e.target.getAttribute('data-category');
    const selected = this.isSelected(category);

    if (selected) {
      let matterCategories = this.getSelectedCategories();
      matterCategories = matterCategories.filter(item => item !== category);
      this.setState({ selectedCategories: matterCategories });
      this.props.updateMatter({
        id: matter.get('id'),
        matterCategories: matterCategories.toJS()
      }, matter.get('matterNumber'));
    } else {
      this.setState({
        confirmCheck: category
      });
    }
  }

  onCancelCheck() {
    this.setState({
      confirmCheck: false
    });
  }

  onConfirmCheck() {
    const matter = this.props.matter;
    const category = this.state.confirmCheck;

    let matterCategories = this.getSelectedCategories();
    matterCategories = matterCategories.push(category);
    this.setState({
      selectedCategories: matterCategories,
      confirmCheck: false
    });
    this.props.updateMatter({
      id: matter.get('id'),
      matterCategories: matterCategories.toJS()
    }, matter.get('matterNumber'));
  }

  onChange(e) {
    this.setState({
      value: e.target.value.replace(/\s/, '')
    });
  }

  onSubmit() {
    if (!this.state.value) {
      return;
    }

    this.props.createCategory(this.props.client.get('id'), this.state.value);

    this.setState({ value: '' });
  }

  onBlur() {
    this.onSubmit();
  }

  onKeyDown(e) {
    switch (e.keyCode) {
      case KEY_ENTER:
      case KEY_RETURN:
        this.onSubmit();
        break;
      case KEY_SPACE:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  renderCheck(category) {
    return (
      <span
        onClick={this.onClick}
        data-category={category}
        style={{ cursor: 'pointer', margin: '3px' }}
        className={this.isSelected(category) ? 'fa fa-check-square-o text-success' : 'fa fa-square-o'}
      />
    );
  }

  renderInput() {
    return (
      <input
        type='text'
        name='category'
        placeholder='Enter a New Category'
        value={this.state.value}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        onBlur={this.onBlur}
        spellCheck={false}
        className='form-control'
      />
    );
  }

  renderConfirmRemove() {
    return this.state.confirmRemove && (
      <RemoveCategoryModal
        onClose={this.onCloseRemove}
        removeCategory={this.props.removeCategory}
        category={this.state.confirmRemove}
        client={this.props.client}
      />
    );
  }

  renderConfirmCheck() {
    return this.state.confirmCheck && (
      <ConfirmModal
        focusCancel
        message={`Are you sure you want to add all of the documents from the "${this.state.confirmCheck}" category to this group? This operation CANNOT BE UNDONE.`}
        onCancel={this.onCancelCheck}
        onConfirm={this.onConfirmCheck}
      />
    );
  }

  renderHelp() {
    return (
      <div>
        {this.getLabel()}
        &nbsp;&nbsp;&nbsp;
        <HelpIcon
          id='sync-panel'
          help='Allows categorized documents to automatically flow to subscribing matters.'
        />
      </div>
    );
  }

  getLabel() {
    const total = this.getCategories().length;
    const count = this.getSelectedCategories().size;

    if (!total) {
      return 'Categories';
    }

    return `Categories (${count}/${total})`;
  }

  render() {
    return (
      <Field label={this.renderHelp()}>
        <div>
          <div style={{ maxHeight: '320px', overflow: 'auto', marginBottom: '15px' }}>
            <Table striped bordered hover style={{ marginBottom: 0 }}>
              <tbody>
                {this.getCategories().map((category, index) => (
                  <tr key={index}>
                    <td style={{ width: '20px' }}>
                      {this.renderCheck(category)}
                    </td>
                    <td style={{ paddingLeft: '5px' }}>
                      {category}
                    </td>
                    <td style={{ width: '20px', textAlign: 'center' }}>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={this.onClickView}
                        data-category={category}
                        className='fa fa-external-link'
                      />
                    </td>
                    <td style={{ width: '20px', textAlign: 'center' }}>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={this.onClickRemove}
                        data-category={category}
                        className='fa fa-times'
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {this.renderInput()}
          {this.renderConfirmRemove()}
          {this.renderConfirmCheck()}
        </div>
      </Field>
    );
  }
}
