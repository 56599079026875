import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Button } from 'react-bootstrap';
import NearDuplicateDocument from './NearDuplicateDocument';
import HelpIcon from '../shared/HelpIcon';

export default class NearDuplicateRegroup extends Component {
  static propTypes = {
    documents: PropTypes.instanceOf(Immutable.List).isRequired,
    onSelect: PropTypes.func.isRequired,
    onRestore: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  isEnabled() {
    return this.props.documents.filter(doc => doc.get('regroup')).size > 1;
  }

  renderDocument(doc) {
    return (
      <NearDuplicateDocument
        key={doc.get('documentNumber')}
        groupIndex={doc.get('groupIndex')}
        showUndo
        input='checkbox'
        document={doc}
        isMuted={false}
        isChecked={!!doc.get('regroup')}
        isRunning={false}
        isComplete={false}
        onSelect={this.props.onSelect}
        onClickUndo={this.props.onRestore}
      />
    );
  }

  renderButton() {
    return (
      <Button
        size='sm'
        variant={this.isEnabled() ? 'primary' : 'secondary'}
        disabled={!this.isEnabled()}
        onClick={this.props.onConfirm}
      >
        Create Group
      </Button>
    );
  }

  render() {
    return (
      <div style={{ textAlign: 'left', flex: 1 }}>
        <div style={{ marginBottom: '15px' }}>
          Removed Documents
          <HelpIcon
            icon='question-circle'
            help='Select documents to create a new group from removed documents.'
            styleIcon={{ marginLeft: '5px' }}
          />
        </div>
        {this.props.documents.map(this.renderDocument)}
        {this.renderButton()}
      </div>
    );
  }
}
