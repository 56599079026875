import _get from 'lodash/get';
import { createAction } from 'redux-actions';
import { DOCUMENT_REPLACE_SUCCESS } from '../../constants/actions';
import { documentsUrl } from '../../constants/urls';
import addNotification from '../app/addNotification';
import callApi from '../../utils/callApi';
import Immutable from 'immutable';
import updateDocumentCategories from './updateDocumentCategories';
import DocumentRecord from '../../../models/DocumentRecord';
import updateDocument from './updateDocument';
import fetchDocument from './fetchDocument';

const documentReplaceSuccess = createAction(DOCUMENT_REPLACE_SUCCESS);

export default (doc, newDocumentNumber) => (dispatch) => {
  const id = doc.get('id');

  function updateDocumentAfterDedup(fetchResponse, dedupResponse) {
    const winnerBeforeDedupData = _get(fetchResponse, ['entities', 'documents', doc.get('clientNumber'), newDocumentNumber]);
    const winnerBeforeDedup = new DocumentRecord(Immutable.fromJS(winnerBeforeDedupData));

    const winnerAfterDedupData = _get(dedupResponse, ['entities', 'documents', doc.get('clientNumber'), newDocumentNumber]);
    const winnerAfterDedup = new DocumentRecord(Immutable.fromJS(winnerAfterDedupData));
    const beforeCategories = winnerBeforeDedup.get('categories') || Immutable.List();
    const afterCategories = winnerAfterDedup.get('categories') || Immutable.List();
    const newCategories = beforeCategories.concat(afterCategories);

    const shouldUpdateTranslated = (
      doc.get('translated') &&
        doc.get('stored') === 'Yes' &&
        winnerBeforeDedup.get('stored') === 'No' &&
        !winnerAfterDedup.get('translated')
    );

    const promise = !shouldUpdateTranslated
      ? Promise.resolve()
      : dispatch(updateDocument({
        id: winnerAfterDedup.get('id'),
        translated: true
      }));

    return promise.then(() => (
      dispatch(updateDocumentCategories(winnerAfterDedup, newCategories))
    ));
  }

  dispatch(addNotification({
    id: `document-${id}`,
    type: 'progress',
    message: 'Deduplicating documents...'
  }));

  return dispatch(fetchDocument({
    firmName: doc.get('firmName'),
    clientNumber: doc.get('clientNumber'),
    documentNumber: newDocumentNumber
  }))
    .then((fetchResponse) => {
      return dispatch(callApi({
        url: `${documentsUrl}/${id}/replace-doc-number/${newDocumentNumber}`
      }))
        .then((dedupResponse) => {
          updateDocumentAfterDedup(fetchResponse, dedupResponse);
        })
        .then(() => {
          dispatch(documentReplaceSuccess({
            clientNumber: doc.get('clientNumber'),
            documentNumber: doc.get('documentNumber')
          }));
        })
        .then(() => {
          dispatch(addNotification({
            id: `document-${id}`,
            type: 'success',
            message: 'Deduplicated successfully.'
          }));
        });
    })
    .catch(() => {
      dispatch(addNotification({
        id: `document-${id}`,
        type: 'error',
        message: 'Failed to deduplicate.'
      }));
    });
};
