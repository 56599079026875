const strip = (term) => (term || '').replace(/[,;\s]/g, '');

export const constructTodoSearchParams = ({
  term,
  filterType,
  entities,
  selectedClientId,
  firmName,
  show
}) => {
  let q;

  const clientNumber = entities.getIn([
    'clients',
    selectedClientId,
    'clientNumber'
  ]);

  switch (filterType) {
    case 'category':
      q = `client:${clientNumber} category:${strip(term)}`;
      break;
    case 'query':
      q = term;
      break;
    case 'smart':
      q = strip(term);
      break;
    case 'document':
      q = `client:${clientNumber} doc:${strip(term)}`;
      break;
    default:
      q = term.replace(new RegExp(`^${filterType}:`, 'i'), '');
      q = `${filterType.toLowerCase()}:${strip(term)}`;
      break;
  }

  return {
    firmName,
    q,
    filterMode: show
  };
};
