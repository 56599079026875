import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Modal, Button } from 'react-bootstrap';
import Loading from '../shared/Loading';

export default class CleanUpTwoWayModal extends Component {
  static propTypes = {
    isRunning: PropTypes.bool.isRequired,
    wrongMatters: PropTypes.array,
    closeCleanUpTwoWayRelations: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      show: true
    };
  }

  onClickClose() {
    this.setState({ show: false });
    this.props.closeCleanUpTwoWayRelations();
  }

  renderBody() {
    if (this.props.isRunning) {
      return (
        <Loading
          padding='10px 15px'
          text='Checking two-way relations...'
        />
      );
    }
    if (this.props.wrongMatters) {
      return (
        <div>
          {this.props.wrongMatters.map((item, i, list) => {
            return (
              <div key={i}>
                <div style={{ padding: '0 20px' }}>
                  <h5>Matter: {item.matter.matterNumber}</h5>
                  {item.relatedMatters.map(matterNumber => (
                    <span
                      key={matterNumber}
                      className='btn btn-xs btn-secondary'
                      style={{ margin: '5px 10px 5px 0', cursor: 'default' }}
                    >
                      {matterNumber}
                    </span>
                  ))}
                </div>
                {i === list.length - 1 ? <br /> : <hr />}
              </div>
            );
          })}
        </div>
      );
    }
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.onClickClose}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>
          Clean Two-Way Relations
          </Modal.Title>
        </Modal.Header>
        {this.renderBody()}
        <Modal.Footer>
          <div style={{ textAlign: 'right' }}>
            <Button variant='primary' onClick={this.onClickClose}>
            Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
