import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Button } from 'react-bootstrap';
import Field from '../../shared/Field';
import SimpleSuggest from '../../shared/SimpleSuggest';

export default class FirmClientMatter extends Component {
  static propTypes = {
    client: PropTypes.string.isRequired,
    fetchClientList: PropTypes.func.isRequired,
    fetchMatterList: PropTypes.func.isRequired,
    firm: PropTypes.string.isRequired,
    matter: PropTypes.string.isRequired,
    onClearPDF: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onUploadPDF: PropTypes.func.isRequired,
    pdf: PropTypes.object,
    applicationNumber: PropTypes.string
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      clientList: Immutable.List(),
      matterList: Immutable.List(),
      reloadInput: false
    };
  }

  componentDidMount() {
    if (this.props.firm) {
      this.onBlurFirm();
    }
  }

  componentDidUpdate() {
    /* eslint react/no-did-update-set-state: 0 */
    if (this.state.reloadInput) {
      this.setState({ reloadInput: false });
    }
  }

  assignRefUpload(element) {
    this.uploadRef = element;
  }

  onClickUploadPDF() {
    this.uploadRef.click();
  }

  onClickClearPDF() {
    this.props.onClearPDF();
  }

  onUploadPDF(uploadEvent) {
    const file = uploadEvent.target.files[0];
    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      this.props.onUploadPDF(loadEvent.target.result);
      this.setState({
        reloadInput: true
      });
    };

    reader.readAsDataURL(file);
  }

  renderUploadInput() {
    if (this.state.reloadInput) {
      return;
    }
    return (
      <input
        ref={this.assignRefUpload}
        name='file'
        type='file'
        accept='.pdf'
        onChange={this.onUploadPDF}
        style={{ display: 'none' }}
      />
    );
  }

  onSubmitClient(clientNumber) {
    this.onChangeClient(clientNumber);

    const client = this.state.clientList.find(client => client.get('clientNumber') === clientNumber);
    if (client) {
      this.props.fetchMatterList(client.get('id'), this.props.firm).then((response) => {
        this.setState({
          client: clientNumber,
          matterList: Immutable.fromJS(response.entities.matterList[client.get('id')])
        });
      });
    }
  }

  onChangeClient(client) {
    this.props.onUpdate({
      client
    });
  }

  onClearClient() {
    this.props.onUpdate({
      client: ''
    });
  }

  onSubmitMatter(matterNumber) {
    this.onChangeMatter(matterNumber);

    const matter = this.state.matterList.find(matter => matter.get('matterNumber') === matterNumber);
    if (matter) {
      this.props.onUpdate({
        matter: matter.get('matterNumber')
      });
      this.props.onSubmit(matter);
    }
  }

  onChangeMatter(matter) {
    this.props.onUpdate({
      matter
    });
  }

  onClearMatter() {
    this.props.onUpdate({
      matter: ''
    });
  }

  onChangeFirm(e) {
    this.props.onUpdate({
      firm: e.target.value
    });
  }

  onBlurFirm() {
    this.props.fetchClientList(this.props.firm).then((response) => {
      this.setState({
        clientList: Immutable.fromJS(response.entities.clientList)
      });
      if (this.props.client) {
        this.onSubmitClient(this.props.client);
      }
    });
  }

  getClientList() {
    return this.state.clientList.map(this.formatClientSuggestion);
  }

  getMatterList() {
    return this.state.matterList.map(this.formatMatterSuggestion);
  }

  formatMatterSuggestion(matter) {
    return matter.get('matterNumber');
  }

  formatClientSuggestion(client) {
    const number = client.get('clientNumber');
    const name = client.get('clientName');
    return name ? number + ' (' + name + ')' : number;
  }

  renderApplicationNumber() {
    return (
      <span style={{ position: 'absolute', marginTop: '5px' }}>
        Application # {this.props.applicationNumber}
      </span>
    );
  }

  render() {
    return (
      <div style={{ padding: '10px 0 30px 0' }}>
        {this.renderUploadInput()}
        <Field label='Firm Name' style={{ width: '30%' }}>
          <input
            type='text'
            name='firm'
            value={this.props.firm || ''}
            onChange={this.onChangeFirm}
            onBlur={this.onBlurFirm}
            spellCheck={false}
            className='form-control'
          />
        </Field>
        <Field label='Client/BU ID' style={{ width: '30%' }}>
          <SimpleSuggest
            id='client-id-input'
            value={this.props.client || ''}
            className='form-control input-sm'
            allSuggestions={this.getClientList()}
            onChange={this.onChangeClient}
            onClear={this.onClearClient}
            onSubmit={this.onSubmitClient}
            preventSpaces
            autoSubmit
            submitOnBlur
            submitOnEnter
            spellCheck={false}
          />
        </Field>
        <Field label='Matter ID' style={{ width: '30%' }}>
          <div>
            <SimpleSuggest
              id='matter-id-input'
              value={this.props.matter || ''}
              className='form-control input-sm'
              allSuggestions={this.getMatterList()}
              onChange={this.onChangeMatter}
              onClear={this.onClearMatter}
              onSubmit={this.onSubmitMatter}
              preventSpaces
              autoSubmit
              submitOnBlur
              submitOnEnter
              spellCheck={false}
            />
            {this.props.applicationNumber && this.renderApplicationNumber()}
          </div>
        </Field>
        <Field label='' style={{ width: '10%' }}>
          {this.props.pdf
            ? (
              <Button
                tabIndex={-1}
                variant='primary'
                size='sm'
                onClick={this.onClickClearPDF}
              >
              Clear
              </Button>
            )
            : (
              <Button
                tabIndex={-1}
                variant='primary'
                size='sm'
                onClick={this.onClickUploadPDF}
              >
              Load PDF
              </Button>
            )}
        </Field>
      </div>
    );
  }
}
