import Immutable from 'immutable';
import forIn from 'lodash/forIn';
import isObject from 'lodash/isObject';

function replaceArrays(state, update, basePath) {
  forIn(update, (value, key) => {
    const path = basePath.concat([key]);

    if (Array.isArray(value)) {
      state = state.setIn(path, Immutable.fromJS(value));
    } else if (isObject(value)) {
      replaceArrays(state, value, path);
    } else if (value !== undefined && value !== null) {
      state = state.setIn(path, value);
    }
  });
};

export function mergeDeepReplacingLists(target, update) {
  return target
    .withMutations(state => {
      replaceArrays(state, update, []);
    });
}
