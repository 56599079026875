import { isCategoryMatter } from '../../../utils/categories';
import updateMatter from '../matter/updateMatter';
import { createAction } from 'redux-actions';

import { SET_FILE_REMINDER } from '../../constants/actions';

const setFileReminder = createAction(SET_FILE_REMINDER);

export default (todo, fileReminder) => (dispatch, getState) => {
  if (!todo) {
    return;
  }
  if (isCategoryMatter(todo.get('matterNumber'))) {
    return;
  }

  const matterId = todo.get('matterId', todo.get('id'));

  return dispatch(updateMatter({
    id: matterId,
    fileReminder
  })).then(() => dispatch(setFileReminder({
    matterId,
    fileReminder
  })));
};
