import autoBind from 'class-autobind';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BatchSweepDocuments from './BatchSweepDocuments';

export default class BatchPDFScraperModal extends Component {
  static propTypes = {
    documents: PropTypes.object.isRequired,
    addBatchDocuments: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    categories: PropTypes.instanceOf(Immutable.List).isRequired,
    viewCategory: PropTypes.func.isRequired,
    fetchFamily: PropTypes.func.isRequired
  };

  static defaultProps = {
    pendingOfficeActions: []
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
    };
  }

  getDocuments() {
    return Immutable.fromJS(this.props.documents);
  }

  onConfirm(docsAdded) {
    return this.props.fetchFamily(this.props.matter, true)
      .then(this.onClose);
  }

  getPDFs() {
    return [
      {
        label: 'PDF',
        href: null
      }
    ];
  }

  render() {
    return (
      <BatchSweepDocuments
        categories={this.props.categories}
        viewCategory={this.props.viewCategory}
        addBatchDocuments={this.props.addBatchDocuments}
        matter={this.props.matter}
        onClose={this.props.onClose}
        onCancel={this.props.onCancel}
        onConfirm={this.onConfirm}
        documents={this.getDocuments()}
        pdfs={this.getPDFs()}
      />
    );
  }
}
