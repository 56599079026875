import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Loading extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    padding: PropTypes.string.isRequired
  };

  static defaultProps = {
    padding: '10px 0',
    text: 'Loading...'
  };

  render() {
    return (
      <div style={{ padding: this.props.padding }}>
        <span style={{ marginRight: '5px' }} className='fa fa-spinner fa-spin' />
        {this.props.text}
      </div>
    );
  }
};
