import Immutable from 'immutable';
import { connect } from 'react-redux';
import actions from '../../../redux/actions';
import TwoWayMigration from './TwoWayMigration';

const mapStateToProps = (state, props) => {
  const {
    admin: {
      twoWayMigration
    },
    entities
  } = state;

  return {
    ...twoWayMigration,
    matterLists: entities.get('matterList', Immutable.Map()),
    clientList: entities.get('clientList', Immutable.List())
  };
};

export default connect(mapStateToProps, actions)(TwoWayMigration);
