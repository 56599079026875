import { handleActions } from 'redux-actions';

import {
  CLEANUP_TWOWAY_BEGIN,
  CLEANUP_TWOWAY_SUCCESS,
  CLEANUP_TWOWAY_ERROR,
  CLEANUP_TWOWAY_CLOSE
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [CLEANUP_TWOWAY_BEGIN]: (state, { payload }) => payload,
  [CLEANUP_TWOWAY_SUCCESS]: (state, { payload }) => payload,
  [CLEANUP_TWOWAY_ERROR]: (state, { payload }) => payload,
  [CLEANUP_TWOWAY_CLOSE]: () => initialState

}, initialState);
