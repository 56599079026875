export function parseQuery(url = '', char = '/') {
  const cleanUrl = url.replace(/^\/[\w]+\//, '').replace(/^\//g, '');

  const params = cleanUrl.split('&').reduce((obj, val) => {
    const parts = val.split('=');
    obj[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    return obj;
  }, {});

  return params;
}
