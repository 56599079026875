import React from 'react';
import PropTypes from 'prop-types';
import Form from '../shared/Form';
import Fieldset from '../shared/Fieldset';
import AttorneyRecord from '../../models/AttorneyRecord';
import Immutable from 'immutable';
import { Modal, Button } from 'react-bootstrap';
import autoBind from 'class-autobind';
import Field from '../shared/Field';

export default class AttorneyForm extends Form {
  static propTypes = {
    title: PropTypes.node,
    record: PropTypes.instanceOf(AttorneyRecord).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.record, state.record)) {
      return {
        record: props.record,
        ...props.record.toJS()
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  hasChangedEmail() {
    return this.state.email !== this.props.record.get('email');
  }

  getFields() {
    return ['fullName', 'registrationNumber', 'applyESignature'];
  }

  hasChangedFields() {
    return this.getFields().some(
      field => this.state[field] !== this.props.record.get(field)
    );
  }

  isEmpty() {
    return (
      !this.props.record.fullName &&
      !this.props.record.registrationNumber &&
      !this.props.record.applyESignature
    );
  }

  onCancel() {
    this.props.onCancel();
  }

  onConfirm() {
    if (this.hasChangedEmail()) {
      this.props.onConfirm({
        email: this.state.email
      });
    }

    if (this.hasChangedFields()) {
      const values = {};
      this.getFields().forEach((key) => values[key] = this.state[key]);
      this.props.onConfirm(values);
    }
  }

  renderEmail() {
    return (
      <Field label='Attorney Email' style={{ float: 'left', width: '100%', marginBottom: '15px' }}>
        <input
          type='text'
          name='email'
          placeholder=''
          autoComplete='something-chrome-dont-understand'
          value={(this.state.email || '').toLowerCase().replace(/\s/g, '')}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </Field>
    );
  }

  renderRegistration() {
    return (
      <Field label='Reg #' style={{ float: 'left', width: '20%' }}>
        <input
          type='text'
          name='registrationNumber'
          placeholder=''
          autoComplete='something-chrome-dont-understand'
          disabled={this.hasChangedEmail()}
          value={this.state.registrationNumber || ''}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </Field>
    );
  }

  renderFullName() {
    return (
      <Field label='Full Name' style={{ float: 'left', width: '53%' }}>
        <input
          type='text'
          name='fullName'
          placeholder=''
          autoComplete='something-chrome-dont-understand'
          disabled={this.hasChangedEmail()}
          value={this.state.fullName || ''}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </Field>
    );
  }

  renderSignature() {
    return (
      <div style={{ float: 'left', width: '27%', marginTop: '26px' }}>
        {this.renderCheckbox('Apply E-Signature', 'applyESignature', [false, true], null, this.hasChangedEmail())}
      </div>
    );
  }

  renderBody() {
    return (
      <Fieldset>
        {this.renderEmail()}
        {this.renderFullName()}
        {this.renderRegistration()}
        {this.renderSignature()}
      </Fieldset>
    );
  }

  renderFooter() {
    return (
      <div>
        <Button
          variant='secondary'
          onClick={this.onCancel}
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Button>
        <Button
          variant='primary'
          onClick={this.onConfirm}
          disabled={!this.hasChangedEmail() && !this.hasChangedFields()}
        >
          Confirm
        </Button>
      </div>
    );
  }

  renderTitle() {
    if (this.hasChangedEmail()) {
      return 'Are you sure you want to change this matter\'s signator?';
    }
    if (this.isEmpty()) {
      return 'Please complete this new signator\'s profile';
    }
    return 'Are you sure you want to modify this signator\'s profile?';
  }

  render() {
    return (
      <Modal
        onHide={this.onCancel}
        keyboard={false}
        size='lg'
        show
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.renderTitle()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderFooter()}
        </Modal.Footer>
      </Modal>
    );
  }
}
