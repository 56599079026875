import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { attorneyUrl } from '../../constants/urls';
import logAction from '../app/logAction';
import Immutable from 'immutable';
import _get from 'lodash/get';

function logChange(dispatch, getState, fields, context) {
  const {
    app: {
      selectedMatterId,
      selectedClientId
    },
    entities
  } = getState();

  const client = entities.getIn(['clients', selectedClientId]) || Immutable.Map();
  const matter = entities.getIn(['matters', selectedMatterId]) || Immutable.Map();

  dispatch(logAction({
    action: fields.applyESignature ? 'ApplyESignature ON' : 'ApplyESignature OFF',
    misc: fields.email,
    clientNumber: context === 'client' || context === 'matter' ? client.get('clientNumber') : null,
    matterNumber: context === 'matter' ? matter.get('matterNumber') : null
  }));
}

export default (updatedFields, context) => (dispatch, getState) => {
  if (!updatedFields.email || !updatedFields.firmName) {
    console.error('updateAttorney - missing required fields - email, firmName');
    return;
  };
  dispatch(addNotification({
    id: `attorney-${updatedFields.email}`,
    type: 'progress',
    message: `Updating ${updatedFields.email}...`
  }));
  return dispatch(callApi({
    url: attorneyUrl + '/' + updatedFields.email,
    method: 'put',
    data: { ...updatedFields }
  }))
    .then((response) => {
      if (typeof updatedFields.applyESignature === 'boolean') {
        logChange(dispatch, getState, updatedFields, context);
      }
      dispatch(addNotification({
        id: `attorney-${updatedFields.email}`,
        type: 'success',
        message: `Updated ${updatedFields.email}.`
      }));
      return _get(response, ['entities', 'attorneys', updatedFields.email]);
    })
    .catch(() => {
      dispatch(addNotification({
        id: `attorney-${updatedFields.email}`,
        type: 'error',
        message: `Failed to udpate ${updatedFields.email}.`
      }));
    });
};
