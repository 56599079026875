import React from 'react';

export default class TermsAndConditionsView extends React.Component {
  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  render() {
    return (
      <div style={{ backgroundColor: 'white', marginTop: '-15px', color: '#333' }}>
        <div className='container bigger-fonts'>
          <br />
          <br />

          <h2>Terms and Conditions</h2>

          <p>Use of SyncIDS (“SyncIDS”) is governed by the following Terms and Conditions ("Terms"). By using SyncIDS, you agree to be bound by these Terms, and you may not use SyncIDS unless you have accepted the Terms. Your acceptance of the Terms creates a binding legal agreement between you and SyncIDS. Please read these Terms carefully.  The user is deemed to have accepted and agreed to the following Terms upon accessing the SyncIDS website, downloading or using any apps, tools or other services provided by SyncIDS.</p>

          <h4>Content</h4>

          <p>The web-based content, downloadable apps, services and/or tools (the “Content”) offered by SyncIDS are meant to help prepare Information Disclosure Statements (“IDSs”) and other intellectual property information, analysis, and/or operations. Using the Content does not create any attorney-client relationship or any other professional relationship with SyncIDS unless there is an explicit agreement between the user and SyncIDS. Any results and/or outputs produced by the Content are meant to be used along with professional consultation; users should not act upon or rely upon any results and/or outputs without consulting their legal counsel or subject matter expert.</p>

          <p>SyncIDS strives to ensure that its Content is designed to provide accurate information, results, and/or output. However, all the Content is provided “as is” and the accuracy and/or functionality of the Content cannot be guaranteed and it is the users’ responsibility to verify the information, results, and/or output generated by this Content. Additionally, SyncIDS is not responsible for any personal, privileged, confidential, private, trade secret, material that may be submitted, received, or inferred by SyncIDS through the Content offered unless otherwise stated. Please refer to SyncIDS’s Privacy Policy for more information.</p>

          <p>SyncIDS operates a fair usage policy to ensure reasonable access for all users. Automatic (robot) access is prohibited on our site. </p>

          <p>Except as expressly permitted hereunder, users shall not directly or indirectly:</p>

          <p>Reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas or algorithms of the Content or any software, documentation or data related to or provided with the Content (whether SyncIDS-proprietary or third party-proprietary) (“Software”); modify, translate, or create derivative works based on the Content or Software; or copy, rent, lease, distribute, pledge, assign, or otherwise transfer or encumber rights to the Content or Software; knowingly or negligently use or access the Content to build or support, and/or assist a third party in building or supporting, products or services competitive to SyncIDS; remove any proprietary notices or labels from the Content or Software; knowingly or willfully use the Content in any manner that is intended to damage, disable, overburden, impair or otherwise interfere with SyncIDS’s provision of the Content.</p>

          <p>User shall use the Content and Software only for its own internal business operations, and not for the operation of a service bureau or timesharing service, or otherwise for the benefit of a third party.</p>

          <h4>Copyright</h4>

          <p>This website, including without limitation the design, text, graphics, software compilations, and underlying source code, is the property of SyncIDS and is protected by copyright in the United States and other countries.</p>

          <p>Permission is granted to make electronic copies and to print in hard copy, portions of this website for the sole purpose of researching or using the Content of SyncIDS. The authors of the materials on this website and SyncIDS should always be acknowledged. Any other use of materials on this website without the prior written permission of SyncIDS is strictly prohibited; this includes modifying, copying, distributing, transmitting, displaying, publishing, selling, licensing, creating derivative works or using any materials available on or through this website for commercial or public purposes. All rights not expressly granted herein are reserved by SyncIDS.</p>

          <p>The Content, including without limitation the design, text, graphics, software compilations, and underlying source code, are the property of SyncIDS and are protected by copyright in the United States and other countries.</p>

          <h4>Trademark</h4>

          <p>The trademarks, logos, and service marks (Marks) displayed on this website, or any Content provided by SyncIDS are the property of SyncIDS or of other parties as acknowledged. Users of this website and any Content provided by SyncIDS are prohibited from using any Marks for any purpose (other than those permitted by law) without the express written permission of SyncIDS.</p>

          <h4>Terms and Conditions Disclaimer</h4>

          <p>SyncIDS may unilaterally change or discontinue any aspect of this website at any time, including its Content or features. SyncIDS may also unilaterally change or discontinue Content or any aspect of the Content at any time.</p>

          <p>Contracts for the supply of products, services or information initiated by, or formed through this website or as a result of visits made by the user to this website are governed by the terms and conditions of supply applicable to the relevant products, services or information. Paid Content and services are subject to their own privacy policies and terms of use.</p>

          <p>SyncIDS may provide certain links within this website that connect to other websites maintained by third parties over whom SyncIDS has no control, and such links are provided for reference and information purposes only. Specifically, SyncIDS is not responsible for the availability of, and content provided on third party websites. Furthermore, such a link to a third party website does not constitute authorization to access such other website; nor does it constitute any endorsement by SyncIDS of any material held on such other website, nor any representation as to the accuracy of information contained within such other website. SyncIDS is not responsible for any loss or damage of any sort that the user may incur from dealing with any third party.</p>

          <h4>Terms of Use</h4>

          <p>By accessing this website and/or using any of the Content, the User undertakes and agrees with SyncIDS that all reasonable endeavors will be exercised and observed to perform the terms and conditions contained herein in particular, the user agrees:</p>

          <p>To use the website and/or the Content in accordance with relevant laws in any applicable jurisdiction and the user shall not use this website and/or Content for any illegal purpose;</p>

          <p>To not upload or transmit by other means anything designed to interfere with or disrupt the normal operating procedures of the website, the Content, a computer, or a network;</p>

          <p>To not conduct activities that may cause this website and/or Content to be interrupted, damaged or rendered less efficient, or may impair the effectiveness or functionality of this website and/or Content;</p>

          <p>To not use this website and/or Content which violates or infringes the rights or any person, firm or company (including but not limited to, rights of intellectual property, rights of confidentiality or rights of privacy);</p>

          <p>To not interfere with or diminish (whether by act or omission) SyncIDS’s right, title and interest (or that of our licensors) in the website or Content available on it.</p>

          <p>AS SUCH, AND OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS CONDITIONS OR THOSE EXPRESSLY STATED ON THE WEBSITE, SYNCIDS DOES NOT MAKE ANY SPECIFIC PROMISES ABOUT THE CONTENT OR SERVICES PROVIDED.  THIS INCLUDES THE ACCURACY OF THE CONTENT, THE SPECIFIC FUNCTIONS OF THE CONTENT, OR ITS RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS OR THE NEEDS OF ANY OTHER.  SYNCIDS EXPRESLY PROVIDES THE SERVICES “AS IS”.</p>

          <p>TO THE EXTENT PERMITTED BY LAW, SYNCIDS EXCLUDES ALL WARRANTIES INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY.</p>

          <p>SYNCIDS WILL NOT, AS PERMITTED BY LAW, BE RESPONSIBLE FOR LOST PROFITS, REVENUES, FINANCIAL OR ANY OTHER TYPE OF LOSSES (SUCH AS DATA, SERVICES, OUTAGES, ETC.) OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.</p>

          <p>TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF SYNCIDS FOR ANY CLAIMS UNDER THESE TERMS AND CONDITIONS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT PAID TO SYNCIDS TO USE THE CONTENT.</p>

          <p>IN ALL CASES, SYNCIDS WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.</p>

          <h4>Termination</h4>

          <p>We may terminate your access to SyncIDS for any violation of these Terms or applicable law without notice or warning and without any refund of amounts paid.</p>

          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
};
