import { createAction } from 'redux-actions';
import { TOGGLE_SYNC_PANEL } from '../../constants/actions';

const toggleSyncPanel = createAction(TOGGLE_SYNC_PANEL);

export default (value) => (dispatch, getState) => {
  const {
    app: {
      expandedSyncPanel
    }
  } = getState();

  const expanded = typeof value === 'boolean' ? value : !expandedSyncPanel;

  dispatch(toggleSyncPanel(expanded));
};
