import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

export default class AppFooter extends Component {
  render() {
    return (
      <Container>
        <div style={{ textAlign: 'right', padding: '10px' }}>
          © Copyright {new Date().getFullYear()} SyncIDS | All Rights Reserved | <a href='#/terms-and-conditions'>Terms</a> | <a href='#/privacy-policy'>Privacy Policy</a> | <a href='#/security'>Security</a>
        </div>
      </Container>
    );
  }
};
