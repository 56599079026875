import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';
import { mattersUrl } from '../../constants/urls';
import fetchMatter from './fetchMatter';
import _get from 'lodash/get';
import { splitCommaUnique } from '../../../utils/text-utils';

export default (matterId, matterNumber) => (dispatch, getState) => {
  const notificationId = `matter-two-way-${matterId}`;

  dispatch(addNotification({
    id: notificationId,
    type: 'progress',
    message: `Adding to family ${matterNumber}...`
  }));

  return dispatch(callApi({
    url: mattersUrl + '/' + matterId,
    method: 'put',
    data: {
      id: matterId,
      twoWayRelatedMatters: matterNumber
    }
  })).finally(() => {
    return dispatch(fetchMatter(matterId))
      .then((response) => {
        const twoWayRelatedMatters = _get(response, ['entities', 'matters', matterId, 'twoWayRelatedMatters']);

        if (splitCommaUnique(twoWayRelatedMatters).includes(matterNumber)) {
          dispatch(addNotification({
            id: notificationId,
            type: 'success',
            message: `Added to family ${matterNumber}.`
          }));
        } else {
          dispatch(addNotification({
            id: notificationId,
            type: 'error',
            message: `Failed to add to family ${matterNumber}.`
          }));
        }
      })
      .catch(() => {
        dispatch(addNotification({
          id: notificationId,
          type: 'error',
          message: `Failed to add to family ${matterNumber}.`
        }));
      });
  });
};
