import React, { Component } from 'react';
import autoBind from 'class-autobind';
import PageFooter from '../shared/PageFooter';

export default class FAQView extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      open: null
    };
  }

  componentDidMount() {
    document.getElementById('scroll').scrollTop = 0;
  }

  getItems() {
    return [
      {
        question: 'Do I need to install any special software to use SyncIDS?',
        answer: 'No. SyncIDS is hosted in the cloud and can be accessed from anywhere.The only software you need is a web browser.'
      },
      {
        question: 'How do I get the data from my old IDSs and 892s into SyncIDS?',
        answer: 'Our data team uses our proprietary software to enter virtually all of your legacy data for you. We even normalize your NPL descriptions.'
      },
      {
        question: 'How does my data stay up to date?',
        answer: 'SyncIDS scans PAIR every week for new U.S. data. Simply forward your foreign correspondence to our data team for same-day entry.'
      },
      {
        question: 'Where is my data stored, and is it safe?',
        answer: 'Your data is encrypted and backed up using the same servers that power Amazon.com and thousands of other web services.'
      },
      {
        question: 'How does SyncIDS know what to cross-cite?',
        answer: 'Family relationships are automatic. In addition, you can tell SyncIDS about subject matter relationships, and it will never miss a cross-site. Not sure about some of your subject matter relationships? SyncIDS can help.'
      },
      {
        question: 'How does SyncIDS tell me when I have an IDS to file?',
        answer: 'SyncIDS includes a simple to-do list that can be filtered by client, patent family, or attorney. Optionally, SyncIDS can send you email reminders when urgent IDS are pending.'
      },
      {
        question: 'What do I need to do to file an IDS?',
        answer: 'Select the correct time period and certification statement choices. Check the approval box. Your confirmation copy is in the email!'
      },
      {
        question: 'Where are my IDS forms and prior art PDFs?',
        answer: 'The to-do list includes a folder for each matter with everything needed to file your IDS, including a completed IDS form, uncited foreign PDFs, and uncited non-patent literature PDFs. If anything changes, the folder is automatically updated.'
      },
      {
        question: 'How are my IDSs filed?',
        answer: 'SyncIDS completes every portion of the EFS form and uploads it to EFS along with PDFs of foreign and NPL references.'
      },
      {
        question: 'What about foreign language references?',
        answer: 'SyncIDS automatically attaches English language abstracts when needed.'
      },
      {
        question: 'Does SyncIDS keep track of what I filed with the PTO?',
        answer: 'Yes, SyncIDS automatically checks a box next to each reference that was cited when the IDS is filed with the USPTO.'
      },
      {
        question: 'How do I print out a report?',
        answer: 'Simply set your view to a particular client, patent family, or attorney. Then, choose one of the reports in the report menu.'
      },
      {
        question: 'How much does SyncIDS cost?',
        answer: 'There are no upfront fees, no monthly fees, no storage fees, and no paralegal fees. SyncIDS simply charges a flat fee each time an IDS is filed.'
      }
    ];
  }

  onClick(index) {
    this.setState({
      open: index === this.state.open ? null : index
    });
  }

  renderItem(item, index) {
    return (
      <div key={index} id={`faq-${index}`} style={{ marginBottom: '5px' }} className='panel'>
        <div className='panel-heading clearfix' onClick={() => this.onClick(index)}>
          {item.question}
        </div>
        {this.state.open === index
          ? (
            <div className='panel-body' style={{ padding: '15px' }}>
              {item.answer}
            </div>
          )
          : null}
      </div>
    );
  }

  renderFAQs() {
    return (
      <>
        {this.getItems().map(this.renderItem)}
      </>
    );
  }

  render() {
    return (
      <div id='faq' className='container bigger-fonts'>
        <div style={{ marginTop: '20px' }}>
          <h1>Frequently Asked Questions</h1>
        </div>
        <hr />
        {this.renderFAQs()}
        <hr />
        <PageFooter />
      </div>
    );
  }
}
