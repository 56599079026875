import { mattersUrl } from '../../constants/urls';
import addNotification from '../app/addNotification';
import callApi from '../../utils/callApi';
import fetchPendingOfficeActions from './fetchPendingOfficeActions';

export default (matterId) => (dispatch, getState) => {
  const notificationId = `matter-${matterId}`;

  dispatch(addNotification({
    id: notificationId,
    type: 'progress',
    message: 'Deleting pending office actions...'
  }));

  return dispatch(callApi({
    method: 'delete',
    url: `${mattersUrl}/${matterId}/delete-pending-office-actions`
  }))
    .then(() => {
      dispatch(addNotification({
        id: notificationId,
        type: 'success',
        message: 'Pending office actions deleted.'
      }));
      return dispatch(fetchPendingOfficeActions(matterId));
    })
    .catch(() => {
      dispatch(addNotification({
        id: notificationId,
        type: 'error',
        message: 'Failed to delete pending office actions.'
      }));
    });
};
