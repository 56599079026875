import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import autoBind from 'class-autobind';
import Form from '../shared/Form';

const styleButton = {
  marginTop: '25px'
};

export default class TestDrive extends Form {
  static propTypes = {
    record: PropTypes.instanceOf(Immutable.Map).isRequired,
    submitTestDrive: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string
  };

  static defaultProps = {
    record: Immutable.Map()
  };

  static getDerivedStateFromProps(props, state) {
    if (props.successMessage && !state.successMessage) {
      return {
        successMessage: props.successMessage,
        email: '',
        applicationNumber: ''
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onSubmit() {
    this.setState({ successMessage: null });
    const { email, applicationNumber } = this.state;
    this.props.submitTestDrive(email, applicationNumber);
  }

  renderError() {
    return this.props.errorMessage && (
      <p>
        <span className='fa fa-times text-danger' />
        &nbsp;
        {this.props.errorMessage}
      </p>
    );
  }

  renderSuccess() {
    return this.props.successMessage && (
      <p>
        <span className='fa fa-check text-success' />
        &nbsp;
        {this.props.successMessage}
      </p>
    );
  }

  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12'>
            {this.renderInput('Business Email', 'email', '250px')}
            {this.renderInput('Application Number', 'applicationNumber', '250px')}
            <button className='btn btn-sm btn-primary' style={styleButton} onClick={this.onSubmit}>
              Submit
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            {this.renderError()}
            {this.renderSuccess()}
          </div>
        </div>
      </div>
    );
  }
};
