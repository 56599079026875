import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ResetPasswordForm from './ResetPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';
import _toLower from 'lodash/toLower';
import BrowserModal from './BrowserModal';
import PatentBotsModal from './PatentBotsModal';
import SingleSignOnForm from './SingleSignOnForm';
import SurveyModal from './SurveyModal';

export default class Login extends Component {
  static propTypes = {
    inProgress: PropTypes.bool,
    isOpen: PropTypes.bool,
    mode: PropTypes.string,
    messageType: PropTypes.string,
    message: PropTypes.string,
    rememberEmail: PropTypes.string,
    login: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    openAuthModal: PropTypes.func.isRequired,
    closeAuthModal: PropTypes.func.isRequired,
    singleSignOn: PropTypes.func.isRequired
  };

  static defaultProps = {
    mode: 'login',
    rememberEmail: ''
  };

  static getDerivedStateFromProps(props, state) {
    if (props.isOpen !== state.isOpen) {
      return {
        email: _toLower(props.rememberEmail),
        isOpen: props.isOpen,
        showBrowser: !window.chrome
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  onRegister() {
    this.props.openAuthModal({ mode: 'register' });
  }

  onResetPassword() {
    this.props.openAuthModal({ mode: 'resetPassword' });
  }

  onLogin() {
    this.props.openAuthModal({ mode: 'login' });
  }

  onSingleSignOn() {
    this.props.openAuthModal({ mode: 'sso' });
  }

  onChangeEmail({ email }) {
    this.setState({ email: _toLower(email) });
  }

  onSubmitLogin(...args) {
    this.props.login(...args).then((data) => {
      if (data && data.partnership === 'patentbots') {
        this.setState({ showPatentBots: true });
      } else {
        this.setState({ showSurvey: this.checkSurvey() });
      }
    });
  }

  checkSurvey() {
    return false;

    // const key = 'syncids-survey-2023-07-11';
    // if (window.localStorage.getItem(key) === 'true') {
    //   return false;
    // }

    // window.localStorage.setItem(key, 'true');
    // return true;
  }

  renderLogin() {
    return (
      <LoginForm
        email={this.state.email}
        rememberMe={!!this.props.rememberEmail}
        inProgress={this.props.inProgress}
        message={this.props.message}
        messageType={this.props.messageType}
        onClose={this.props.closeAuthModal}
        onSubmit={this.onSubmitLogin}
        onRegister={this.onRegister}
        onChangeEmail={this.onChangeEmail}
        onSingleSignOn={this.onSingleSignOn}
        onResetPassword={this.onResetPassword}
      />
    );
  }

  renderRegister() {
    return (
      <RegisterForm
        email={this.state.email}
        inProgress={this.props.inProgress}
        message={this.props.message}
        messageType={this.props.messageType}
        onClose={this.onLogin}
        onChangeEmail={this.onChangeEmail}
        onSubmit={this.props.register}
      />
    );
  }

  renderResetPassword() {
    return (
      <ResetPasswordForm
        email={this.state.email}
        inProgress={this.props.inProgress}
        message={this.props.message}
        messageType={this.props.messageType}
        onClose={this.onLogin}
        onChangeEmail={this.onChangeEmail}
        onSubmit={this.props.resetPassword}
      />
    );
  }

  renderChangePassword() {
    return (
      <ChangePasswordForm
        inProgress={this.props.inProgress}
        onClose={this.props.closeAuthModal}
        onSubmit={this.props.changePassword}
        message={this.props.message}
        messageType={this.props.messageType}
      />
    );
  }

  renderSingleSignOn() {
    return (
      <SingleSignOnForm
        email={this.state.email}
        inProgress={this.props.inProgress}
        message={this.props.message}
        messageType={this.props.messageType}
        onClose={this.onLogin}
        onChangeEmail={this.onChangeEmail}
        onSubmit={this.props.singleSignOn}
      />
    );
  }

  onCloseBrowser() {
    this.setState({ showBrowser: false });
  }

  renderBrowser() {
    return (
      <BrowserModal onClose={this.onCloseBrowser} />
    );
  }

  onClosePatentBots() {
    this.setState({ showPatentBots: false });
  }

  renderPatentBots() {
    return (
      <PatentBotsModal onClose={this.onClosePatentBots} />
    );
  }

  onCloseSurvey() {
    this.setState({ showSurvey: false });
  }

  renderSurvey() {
    return (
      <SurveyModal onClose={this.onCloseSurvey} />
    );
  }

  render() {
    let modal = <div />;
    if (this.state.showPatentBots) {
      modal = this.renderPatentBots();
    } else if (this.state.showSurvey) {
      modal = this.renderSurvey();
    } else if (this.props.isOpen) {
      if (this.state.showBrowser) {
        modal = this.renderBrowser();
      } else {
        switch (this.props.mode) {
          case 'sso':
            modal = this.renderSingleSignOn();
            break;
          case 'login':
            modal = this.renderLogin();
            break;
          case 'register':
            modal = this.renderRegister();
            break;
          case 'resetPassword':
            modal = this.renderResetPassword();
            break;
          case 'changePassword':
            modal = this.renderChangePassword();
            break;
        }
      }
    }
    return modal;
  }
};
