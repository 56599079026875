import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HelpIcon from '../shared/HelpIcon';
import autoBind from 'class-autobind';

export default class ExpandLayoutIcon extends Component {
  static propTypes = {
    wideLayout: PropTypes.bool.isRequired,
    toggleWideLayout: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClickExpand(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.toggleWideLayout();
  }

  render() {
    return (
      <div
        onClick={this.onClickExpand} style={{
          position: 'absolute',
          right: '15px',
          top: '6px',
          cursor: 'pointer',
          padding: '5px'
        }}
      >
        <HelpIcon
          icon={this.props.wideLayout ? 'compress' : 'expand'}
          help='Click to expand/collapse layout.'
          placement='left'
        />
      </div>
    );
  }
}
