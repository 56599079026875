import { handleActions } from 'redux-actions';
import {
  CLEAR_NPL_DOCUMENTS,
  SEARCH_NPL_DOCUMENTS
} from '../../constants/actions';

const initialState = {};

export default handleActions({

  [CLEAR_NPL_DOCUMENTS]: () => (initialState),

  [SEARCH_NPL_DOCUMENTS]: (state, { payload }) => {
    return {
      ...state,
      [payload.clientNumber]: {
        ...state[payload.clientNumber],
        [payload.nonPatentBib]: true
      }
    };
  }

}, initialState);
