import Immutable from 'immutable';
import fetchDocuments from './fetchDocuments';
import updateDuplicateCitedDocuments from './updateDuplicateCitedDocuments';
import { getDocNumbersFromMatter } from '../../../utils/get-doc-numbers-from-matter';

export default (matter, includeDeleted) => (dispatch, getState) => {
  const docNumbers = getDocNumbersFromMatter(matter, includeDeleted);
  const docsInState = getState().entities.getIn(['documents', matter.get('clientNumber')], Immutable.Map());
  const documentNumbers = docNumbers.filter(docNumber => !docsInState.get(docNumber));

  if (!documentNumbers.length) {
    return Promise.resolve();
  }

  return dispatch(updateDuplicateCitedDocuments(matter))
    .then(() => dispatch(fetchDocuments(matter, docNumbers)));
};
