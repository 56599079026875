import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Field from '../shared/Field';
import DateInput from '../shared/DateInput';
import _toLower from 'lodash/toLower';

export default class Form extends Component {
  static propTypes = {
    record: PropTypes.object,
    onChange: PropTypes.func,
    forceChange: PropTypes.bool
  };

  constructor(props) {
    super(props);
    autoBind(this, Form.prototype);
  }

  raiseChange(field) {
    const shouldChange = this.props.forceChange ||
      (this.props.record && this.props.record.get(field) !== this.state[field]);
    if (shouldChange && this.props.onChange) {
      this.props.onChange({
        [field]: this.getChangedValue(field)
      });
    }
  }

  getChangedValue(field) {
    return this.state[field];
  }

  renderRequiredIcon() {
    return (
      <span
        style={{ top: 27, right: 5 }}
        className='glyphicon glyphicon-remove form-control-feedback'
      />
    );
  }

  onChange(ev, overrideValue) {
    let { name, value, type, nodeName } = ev.target;
    if (overrideValue) {
      value = overrideValue;
    } else if (type === 'checkbox') {
      if (ev.target.checked) {
        value = ev.target.getAttribute('data-value-on');
      } else {
        value = ev.target.getAttribute('data-value-off');
      }
      if (value === 'true') {
        value = true;
      } else if (value === 'false') {
        value = false;
      }
    } else if (type === 'radio') {
      if (value === 'true') {
        value = true;
      } else if (value === 'false') {
        value = false;
      }
    }
    if (this.state[name] === value) {
      return;
    }
    this.setState({ [name]: value, changedField: name }, () => {
      const shouldRaiseChange = overrideValue || (
        type !== 'text' && _toLower(nodeName) !== 'textarea'
      );
      if (shouldRaiseChange) {
        this.raiseChange(name);
      }
    });
  }

  onKeyDown(ev) {
    if (ev.which === 13) {
      this.onBlur(ev);
    }
  }

  onFocus(ev) {
    this.setState({ focusedField: ev.target.name });
  }

  onBlur(ev) {
    if (this.state && this.state.focusedField === ev.target.name) {
      this.setState({ focusedField: null });
    }
    this.raiseChange(ev.target.name);
  }

  renderTextarea(label, field, rows = 3) {
    return (
      <Field label={label}>
        <textarea
          rows={rows}
          name={field}
          value={this.state[field] || ''}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </Field>
    );
  }

  renderInputWith({ label, field, width, required = false, disabled = false, placeholder = '', help = null }) {
    return this.renderInput(label, field, width, required, disabled, placeholder, help);
  }

  renderInput(label, field, width, required = false, disabled = false, placeholder = '', help = null) {
    const markRequired = required && !this.state[field];
    const style = {
      width: width || '100%',
      float: width && width !== '100%' ? 'left' : 'none'
    };
    return (
      <Field
        key={field}
        label={label}
        style={style}
        className={markRequired ? 'has-error has-feedback' : ''}
      >
        <input
          type='text'
          name={field}
          placeholder={placeholder}
          value={this.state[field] || ''}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          required={required}
          disabled={disabled}
          className='form-control'
        />
        {markRequired && this.renderRequiredIcon()}
        {help}
      </Field>
    );
  }

  renderDateInput(label, field, width, required) {
    const markRequired = required && !this.state[field];
    const style = {
      width: width || '100%',
      float: width && width !== '100%' ? 'left' : 'none'
    };
    return (
      <Field
        label={label}
        style={style}
        className={markRequired ? 'has-error has-feedback' : ''}
      >
        <DateInput
          name={field}
          className='form-control'
          value={this.state[field] || ''}
          required={!!required}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
        />
        {markRequired && this.renderRequiredIcon()}
      </Field>
    );
  }

  renderCheckbox(label, field, values, width, disabled = false) {
    const styleCheckbox = {
      margin: '6px 0'
    };

    const style = width
      ? { ...styleCheckbox, display: 'inline-block', width }
      : styleCheckbox;

    return (
      <div className='checkbox' style={style}>
        <label htmlFor={field}>
          <input
            onChange={this.onChange}
            type='checkbox'
            style={{ marginRight: '7px' }}
            name={field}
            disabled={disabled}
            data-value-on={values[1]}
            data-value-off={values[0]}
            checked={this.state[field] === values[1]}
          />
          {disabled && (
            <div
              data-name={field} style={{
                position: 'absolute',
                backgroundColor: 'transparent',
                cursor: 'not-allowed',
                width: '20px',
                height: '20px',
                marginLeft: '-20px'
              }}
            />
          )}
          {label}
        </label>
      </div>
    );
  }

  renderRadio(label, field, value, width) {
    const styleCheckbox = {
      margin: '6px 0'
    };

    const style = width
      ? { ...styleCheckbox, display: 'inline-block', width }
      : styleCheckbox;

    return (
      <div className='radio' style={style}>
        <label>
          <input
            onChange={this.onChange}
            style={{ marginRight: '7px' }}
            type='radio'
            name={field}
            value={value}
            checked={this.state[field] === value}
          />
          {label}
        </label>
      </div>
    );
  }

  render() {
    return null;
  }
};
