import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { constructTodoSearchParams } from '../../utils/todo-query';
import { downloadUrl } from '../../utils/download-url';
import { DropdownButton } from 'react-bootstrap';
import { MenuItem } from '../shared/MenuItem';
import { API_ROOT } from '../../redux/constants/urls';

export default class TodosFooter extends Component {
  static propTypes = {
    term: PropTypes.string,
    show: PropTypes.string,
    firmName: PropTypes.string,
    callApi: PropTypes.func.isRequired,
    filterType: PropTypes.string,
    selectedClientId: PropTypes.string,
    entities: PropTypes.instanceOf(Immutable.Map),
    loadSyncOverlap: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  todosExportUrl(type, withToken) {
    const { term, filterType, selectedClientId, entities, firmName, show } = this.props;

    const todoSearchParams = constructTodoSearchParams({
      term,
      filterType,
      selectedClientId,
      entities,
      firmName,
      show
    });

    const params = new URLSearchParams(todoSearchParams);

    if (withToken) {
      params.set('token', this.props.authToken);
    }

    const urlSearchString = params.toString();

    return (
      `${API_ROOT}/api/reports/todos/${type}?${urlSearchString}`
    );
  }

  generateReport(type) {
    this.props.addNotification({
      id: `excel-report-${type}`,
      type: 'progress',
      message: 'Generating report...'
    });

    this.props.callApi({
      url: this.todosExportUrl(type),
      noQuery: true
    })
      .then(({ url }) => {
        downloadUrl(url);
        this.props.addNotification({
          id: `excel-report-${type}`,
          type: 'success',
          message: 'Downloading...'
        });
      })
      .catch(() => {
        this.props.addNotification({
          id: `excel-report-${type}`,
          type: 'error',
          message: 'Failed to generate report.'
        });
      });
  }

  onClickExcelReport() {
    this.generateReport('excel/s3');
  }

  onClickSummaryReport() {
    this.generateReport('summary/s3');
  }

  onClickSyncOverlap() {
    this.props.loadSyncOverlap({
      showModal: true
    });
  }

  render() {
    return (
      <div className='pull-left' style={{ marginRight: '15px' }}>
        <DropdownButton
          variant='primary'
          size='sm'
          title='Reports'
          className='no-caret'
          id='dashboard-report'
        >
          <MenuItem target='_blank' onClick={this.onClickSummaryReport}>
            Download Master Excel Report
          </MenuItem>
          <MenuItem onClick={this.onClickExcelReport}>
            Download Dashboard as Excel
          </MenuItem>
          <MenuItem
            target='_blank' download
            href={this.todosExportUrl('overlap', true)}
          >
            Download Documents Overlap Report
          </MenuItem>
          <MenuItem onClick={this.onClickSyncOverlap}>
            View Subject Matter Suggestions
          </MenuItem>
        </DropdownButton>
      </div>
    );
  }
}
