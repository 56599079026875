import axios from '../../utils/axios';
import { createAction } from 'redux-actions';
import { API_ROOT } from '../constants/urls';
import checkAuth from '../actions/auth/checkAuth';
import refreshAuth from '../actions/auth/refreshAuth';

const API_REQUEST = 'API_REQUEST';
const API_RESPONSE = 'API_RESPONSE';

const apiRequest = createAction(API_REQUEST);
const apiResponse = createAction(API_RESPONSE);

export default (request, requestActionPayload, preventDispatch) => (dispatch, getState) => {
  if (requestActionPayload) {
    dispatch(apiRequest(requestActionPayload));
  }

  return dispatch(refreshAuth())
    .then((isAuthenticated) => {
      if (isAuthenticated) {
        return axios(prepareRequest(request, getState))
          .then(({ data }) => {
            if (!preventDispatch) {
              dispatch(apiResponse(data));
            }
            return data;
          })
          .catch(error => {
            if (error.response.status === 401) {
              dispatch(checkAuth());
            }
            return Promise.reject(error);
          });
      } else {
        return Promise.reject(new Error('Not authenticated'));
      }
    });
};

function prepareRequest(request, getState) {
  if (!/^http[s]?:/.test(request.url)) {
    request.url = API_ROOT + request.url;
  }

  // append firmName/todo filter to every call
  const {
    auth: { token },
    todos: { term, show: filterMode, filterType }
  } = getState();

  let q = term ? term.replace(/[,;\s]/g, '') : '';
  if (q && filterType !== 'smart') {
    q = q.replace(new RegExp(`^${filterType}:`, 'i'), '');
    q = `${filterType.toLowerCase()}:${q}`;
  }

  const params = request.noQuery
    ? {}
    : {
      q,
      filterMode
    };

  return {
    url: request.url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...request,
    params: {
      ...params,
      ...request.params
    }
  };
}
