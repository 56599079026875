import { connect } from 'react-redux';
import actions from '../../redux/actions';
import App from './App';

const mapStateToProps = (state, props) => {
  return {
    features: state.app.features,
    wideLayout: state.app.wideLayout,
    preventUnload: state.app.preventUnload
  };
};

export default connect(mapStateToProps, actions)(App);
