import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Dropdown } from 'react-bootstrap';

const styleIconView = {
  float: 'right',
  fontSize: '13px',
  position: 'relative',
  top: '5px'
};

export default class CategoryItem extends Component {
  static propTypes = {
    selected: PropTypes.bool,
    greyed: PropTypes.bool,
    category: PropTypes.string,
    onSelect: PropTypes.func,
    onClickView: PropTypes.func
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderCheck() {
    if (this.props.selected) {
      return (
        <span className='fa fa-check-square-o text-success' />
      );
    }
    if (this.props.greyed) {
      return (
        <span className='fa fa-check-square-o text-muted' />
      );
    }
    return (
      <span className='fa fa-square-o' />
    );
  }

  onClick(event) {
    event.preventDefault();
    this.props.onSelect(event, this.props.category);
  }

  onClickView(event) {
    this.props.onClickView(event, this.props.category);
  }

  render() {
    return (
      <Dropdown.Item onClick={this.onClick}>
        {this.renderCheck()}
        <span>{' ' + this.props.category}</span>
        <span
          className='fa fa-external-link'
          style={styleIconView}
          onClick={this.onClickView}
        />
      </Dropdown.Item>
    );
  }
};
