import callApi from '../../utils/callApi';
import { mattersUrl } from '../../constants/urls';

export default (matter) => (dispatch) => {
  const matterId = matter.get('matterId');

  return dispatch(callApi({
    url: `${mattersUrl}/${matterId}/get-uncited-equivalents`
  }));
};
