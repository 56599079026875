import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Button } from 'react-bootstrap';
import DropdownHelp from '../shared/DropdownHelp';
import Immutable from 'immutable';
import Tooltip from '../shared/Tooltip';
import TodosFilterInput from './TodosFilterInput';

export default class TodosFilter extends Component {
  static propTypes = {
    defaultTerm: PropTypes.string,
    setTerm: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
    setFilterType: PropTypes.func.isRequired,
    term: PropTypes.string.isRequired,
    show: PropTypes.string.isRequired,
    filterType: PropTypes.string.isRequired,
    selectedClientId: PropTypes.string,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map).isRequired,
    clients: PropTypes.instanceOf(Immutable.Map).isRequired,
    searchNplDocuments: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (props.term !== state.defaultTerm) {
      return {
        term: props.term,
        defaultTerm: props.term
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  onChangeFilter(filterType) {
    this.props.setFilterType(filterType);

    const input = document.getElementById('todos-filter-input');
    input.focus();
    input.select();
  }

  onClickAll() {
    this.props.setShow('all');
  }

  onClickTodo() {
    this.props.setShow('todo');
  }

  onClickUrgent() {
    this.props.setShow('urgent');
  }

  onClickEmpty() {
    this.props.setShow('empty');
  }

  onClickSweeps() {
    this.props.setShow('all');
    this.props.setTerm('sweepready:true');
  }

  renderIconChecked() {
    return (
      <span
        className='fa fa-check text-warning'
        style={{ marginRight: '3px' }}
      />
    );
  }

  renderButton({ label, onClick, value, tip }) {
    return (
      <Button
        onClick={onClick}
        variant='primary'
        size='sm'
        style={{
          marginRight: '6px',
          height: '30px',
          flex: '0',
          whiteSpace: 'nowrap'
        }}
        active={this.props.show === value}
      >
        <Tooltip
          delay={600}
          id={`btn-${value}`}
          tip={tip}
        >
          {this.props.show === value && this.renderIconChecked()}
          {label}
        </Tooltip>
      </Button>
    );
  }

  renderButtonAll() {
    return this.renderButton({
      label: 'All',
      onClick: this.onClickAll,
      value: 'all',
      tip: 'Includes all matters that match the filter criteria, even if an IDS is not needed'
    });
  }

  renderButtonTodo() {
    return this.renderButton({
      label: 'To-Do',
      onClick: this.onClickTodo,
      value: 'todo',
      tip: 'Only includes open matters with at least one uncited reference (IDS needed)'
    });
  }

  renderButtonEmpty() {
    return this.renderButton({
      label: 'No Art',
      onClick: this.onClickEmpty,
      value: 'empty',
      tip: 'Only includes open matters with no uncited and no cited references'
    });
  }

  renderButtonSweeps() {
    return this.renderButton({
      label: 'Sweeps',
      onClick: this.onClickSweeps,
      value: 'sweeps',
      tip: 'Selects "All" and "sweepready:true" filters'
    });
  }

  renderDropdown() {
    const filterOptions = [{
      value: 'smart',
      label: 'Smart Filter'
    }, {
      divider: true
    }, {
      value: 'client',
      label: 'Client ID'
    }, {
      value: 'matter',
      label: 'Matter ID'
    }, {
      value: 'alternateid',
      label: 'Alternate ID'
    }, {
      divider: true
    }, {
      value: 'appnumber',
      label: 'Application #'
    }, {
      value: 'document',
      label: 'Document #',
      help: 'This filter requires a client to be selected',
      disabled: !this.props.selectedClientId
    }];

    filterOptions.push({
      divider: true
    }, {
      value: 'category',
      label: 'Category',
      help: 'This filter requires a client to be selected',
      disabled: !this.props.selectedClientId
    });

    filterOptions.push({
      divider: true
    }, {
      value: 'clientnotes',
      label: 'Client Notes'
    }, {
      value: 'matternotes',
      label: 'Matter Notes'
    });

    filterOptions.push({
      divider: true
    }, {
      value: 'reminderrecipient',
      label: 'Reminder Recipient'
    }, {
      value: 'query',
      label: 'Advanced Query'
    });

    return (
      <DropdownHelp
        id='dropdown-filter'
        variant='secondary'
        size='sm'
        style={{
          flex: '0',
          height: '30px'
        }}
        options={filterOptions}
        selected={this.props.filterType}
        onChange={this.onChangeFilter}
      />
    );
  }

  getSelectedClient() {
    return this.props.clients.get(this.props.selectedClientId);
  }

  renderInput() {
    return (
      <TodosFilterInput
        filterType={this.props.filterType}
        term={this.props.term}
        defaultTerm={this.props.defaultTerm}
        setTerm={this.props.setTerm}
        searchNplDocuments={this.props.searchNplDocuments}
        nplSuggestions={this.props.nplSuggestions}
        client={this.getSelectedClient()}
      />

    );
  }

  render() {
    return (
      <div style={{
        margin: '12px 0',
        backgroundColor: '#464545',
        padding: '6px',
        borderRadius: '3px',
        display: 'flex'
      }}
      >
        {this.renderButtonAll()}
        {this.renderButtonTodo()}
        {this.renderButtonEmpty()}
        {this.props.features.admin && this.renderButtonSweeps()}
        {this.renderDropdown()}
        {this.renderInput()}
      </div>
    );
  }
};
