import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from './Tooltip';
import HelpIcon from './HelpIcon';

const style = {
  width: '100%',
  display: 'inline-block',
  marginBottom: '6px',
  paddingRight: '12px'
};

const styleHelp = {
  float: 'right',
  marginRight: '2px'
};

const styleLabel = {
  width: '100%',
  display: 'inline-block',
  marginBottom: '3px'
};

export default class Field extends Component {
  static propTypes = {
    help: PropTypes.string,
    tip: PropTypes.string,
    label: PropTypes.node,
    children: PropTypes.node,
    style: PropTypes.object,
    styleLabel: PropTypes.object,
    className: PropTypes.string
  };

  renderLabel() {
    return (
      <Tooltip tip={this.props.tip}>
        {this.props.label}
      </Tooltip>
    );
  }

  renderHelp() {
    return this.props.help && (
      <div style={styleHelp}>
        <HelpIcon
          help={this.props.help}
          placement='left'
        />
      </div>
    );
  }

  render() {
    return (
      <div
        style={{ ...style, ...this.props.style }}
        className={cx({ 'form-group form-group-sm': true, [this.props.className]: true })}
      >
        <label className='control-label' style={{ ...styleLabel, ...this.props.styleLabel }}>
          {this.renderLabel()}
          {this.renderHelp()}
        </label>
        {this.props.children}
      </div>
    );
  }
};
