import jwtDecode from 'jwt-decode';
import checkAuth from './checkAuth';

export default () => (dispatch, getState) => {
  const { auth: { token } } = getState();

  if (token) {
    let decodedToken;
    try {
      decodedToken = jwtDecode(token);
    } catch (e) {
    }

    if (decodedToken) {
      const currentTime = Math.floor(Date.now() / 1000);
      const expiresIn = decodedToken.exp - currentTime;
      const expiration = 80 * 60; // 1h20min
      if (expiresIn < expiration) {
        return dispatch(checkAuth('/refresh'));
      } else {
        return Promise.resolve(true);
      }
    }
  }

  return Promise.resolve(false);
};
