import { handleActions } from 'redux-actions';
import {
  DOCUMENTS_CITED_FILTER
} from '../../constants/actions';

const initialState = 'all';

export default handleActions({

  [DOCUMENTS_CITED_FILTER]: (state, { payload }) => (payload)

}, initialState);
