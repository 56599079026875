import { API_ROOT } from '../../constants/urls';

export default ({ client, matter }) => (dispatch, getState) => {
  const { auth: { token } } = getState();

  const params = new URLSearchParams({
    q: client.get('clientNumber') + '|' + matter.get('matterNumber'),
    filterMode: 'all',
    token
  });

  const url = `${API_ROOT}/api/reports/todos/summary?${params.toString()}`;

  window.open(url);
};
