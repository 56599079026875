import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import PromptModal from '../shared/PromptModal';
import Form from '../shared/Form';
import AlertModal from '../shared/AlertModal';
import ClientInput from './ClientInput';

export default class ClientRenameModal extends Form {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map),
    onClose: PropTypes.func.isRequired,
    renameClient: PropTypes.func.isRequired,
    clientList: PropTypes.instanceOf(Immutable.List).isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      requiredOne: false,
      requiredTwo: false,
      value: this.props.client.get('clientNumber')
    };
  }

  onConfirm() {
    if (this.isDisabled()) {
      return;
    }

    this.props.renameClient(this.props.client, this.state.value)
      .then(this.props.onClose)
      .catch((error) => {
        this.setState({
          errorMessage: error.response && error.response.data
            ? error.response.data
            : `Failed to rename ${this.props.client.get('clientNumber')}.`
        });
      });
  }

  renderCheckboxes() {
    return (
      <div style={{ padding: '5px' }}>
        {this.renderCheckbox(
          'I acknowledge that renaming a Client ID will impact all associated Matter IDs.',
          'requiredOne',
          [false, true]
        )}
        {this.renderCheckbox(
          'I understand that renaming a Client ID may take several minutes, particularly if a large amount of data is involved. Your patient is appreciated.',
          'requiredTwo',
          [false, true]
        )}
        <p>To rename a Client ID for a <strong>specific Matter ID</strong> and its synced group only, please send a request to <a href='mailto:data@syncids.com'>data@syncids.com</a>.</p>
        <p><strong>SYNCIDS ASSIST:</strong> Users are always welcome to contact SyncIDS at <a href='mailto:data@syncids.com'>data@syncids.com</a> for any questions or assistance with completing this update.</p>
      </div>
    );
  }

  renderError() {
    return (
      <div>
        <p>Oops! {this.state.errorMessage}</p>
        <p>Please send a request to <a href='mailto:data@syncids.com'>data@syncids.com</a> so we can take a closer look.</p>
      </div>
    );
  }

  onClearClientNumber() {
    this.setState({
      value: this.props.client.get('clientNumber')
    });
  }

  onSubmitClientNumber(value) {
    this.setState({
      value
    });
  }

  onChangeClientNumber(value) {
    this.setState({
      value
    });
  }

  renderInput() {
    return (
      <ClientInput
        id='rename-client-input'
        value={(this.state.value || '')}
        initialValue={this.props.client.get('clientNumber')}
        clientList={this.props.clientList}
        onChange={this.onChangeClientNumber}
        onSubmit={this.onSubmitClientNumber}
        onClear={this.onClearClientNumber}
      />
    );
  }

  isDisabled() {
    return !(
      this.state.requiredOne &&
      this.state.requiredTwo &&
      this.state.value &&
      this.state.value !== this.props.client.get('clientNumber')
    );
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <AlertModal
          key='error'
          focusConfirm
          title='Error Renaming Client ID'
          message={this.renderError()}
          onConfirm={this.props.onClose}
        />
      );
    }

    return (
      <PromptModal
        key='rename'
        preventSpaces
        autoSelect
        focusCancel
        message='Enter new client number'
        body={this.renderCheckboxes()}
        renderInput={this.renderInput}
        defaultValue={this.props.client.get('clientNumber')}
        confirmDisabled={this.isDisabled()}
        onCancel={this.props.onClose}
        onConfirm={this.onConfirm}
      />
    );
  }
};
