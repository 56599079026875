import Immutable from 'immutable';
import { getMatterNumberFromCategory } from '../../../utils/categories';
import deleteMatter from '../matter/deleteMatter';

export default (clientId, category) => (dispatch, getState) => {
  const state = getState();
  const matterList = state.entities.getIn(['matterList', clientId]) || Immutable.List();
  const matterNumber = getMatterNumberFromCategory(category);
  if (matterNumber) {
    const matter = matterList.find(m => m.get('matterNumber') === matterNumber);
    if (matter) {
      return dispatch(deleteMatter(matter.get('id'), matter.get('matterNumber'), true));
    }
  }
};
