import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Modal, Button } from 'react-bootstrap';
import DocumentRecord from '../../models/DocumentRecord';

const styleButton = {
  width: '100%',
  marginLeft: 0,
  marginBottom: '15px'
};
const styleLastButton = Object.assign({}, styleButton, {
  marginBottom: 0
});

export default class DeduplicateModal extends Component {
  static propTypes = {
    document: PropTypes.instanceOf(DocumentRecord).isRequired,
    onCancel: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClickCancel() {
    this.props.onCancel();
  }

  onClickMatter() {
    this.props.onAction(this.props.document, 'deleteFromMatter');
  }

  onClickFamily() {
    this.props.onAction(this.props.document, 'deleteFromFamily');
  }

  onClickClient() {
    this.props.onAction(this.props.document, 'deleteFromClient');
  }

  render() {
    return (
      <Modal show size='sm' onHide={this.onClickCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button onClick={this.onClickMatter} style={styleButton} variant='primary' autoFocus>
            Delete from just this Matter
          </Button>
          <Button variant='secondary' onClick={this.onClickFamily} style={styleButton}>
            Delete from Family
          </Button>
          <Button variant='secondary' onClick={this.onClickClient} style={styleLastButton}>
            Delete from <strong>ALL</strong> Matters
          &nbsp;<span className='fa fa-exclamation-triangle' />
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={this.onClickCancel} style={styleLastButton}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
