import LinkedInTag from 'react-linkedin-insight';
import ReactGA from 'react-ga';

/* eslint-disable no-unused-expressions */
function loadFacebook() {
  !(function(f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function() {
      n.callMethod
        ? n.callMethod.apply(n, arguments)
        : n.queue.push(arguments);
    };
    if (!f._fbq)f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  }(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js'));
}

export function trackInit() {
  if (process.env.FACEBOOK_TRACKING_ID) {
    loadFacebook();
    window.fbq('init', process.env.FACEBOOK_TRACKING_ID);
    window.fbq('track', 'PageView');
  }

  if (process.env.LINKEDIN_PARTNER_ID) {
    LinkedInTag.init(process.env.LINKEDIN_PARTNER_ID);
    LinkedInTag.track();
  }

  ReactGA.initialize(process.env.GA_MEASUREMENT_ID);

  ReactGA.pageview(window.location.href);
}

export function trackView() {
  ReactGA.pageview(window.location.href);
}

export function trackDemo() {
  if (process.env.LINKEDIN_PARTNER_ID) {
    LinkedInTag.track(2356860);
  }

  if (process.env.FACEBOOK_TRACKING_ID) {
    window.fbq('track', 'Lead');
  }
}
