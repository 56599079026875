import { handleActions } from 'redux-actions';

import {
  TOGGLE_CLIENT_MATTER
} from '../../constants/actions';

const initialState = null;

export default handleActions({
  [TOGGLE_CLIENT_MATTER]: (state) => (!state)
}, initialState);
