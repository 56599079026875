import { Record } from 'immutable';

const defaultValues = {
  attorneyId: undefined,
  email: undefined,
  fullName: undefined,
  registrationNumber: undefined,
  applyESignature: undefined
};

export default class AttorneyRecord extends Record(defaultValues) {
};
