import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import DocumentRecord from '../../models/DocumentRecord';
import Tooltip from '../shared/Tooltip';
import ConfirmModal from '../shared/ConfirmModal';
import MenuItemHelp from '../shared/MenuItemHelp';

export default class DocumentPDF extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    doc: PropTypes.instanceOf(DocumentRecord).isRequired,
    onChange: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    onLookUp: PropTypes.func.isRequired,
    appendTranslationsToDocument: PropTypes.func.isRequired
  };

  static defaultProps = {
    doc: new DocumentRecord()
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.inputRefs = {};

    this.state = {
      open: false,
      renderInput: false,
      confirmEmbeddedFonts: null,
      confirmInvalidUpload: null
    };
  }

  isDisabled() {
    return this.props.doc.get('type') !== 'US' && this.props.features.limitedDocs;
  }

  inputRef(elm) {
    if (elm) {
      const mode = elm.getAttribute('data-mode');
      this.inputRefs[mode] = elm;
    }
  }

  reloadInput() {
    this.setState({ renderInput: false }, () => {
      this.setState({ renderInput: true });
    });
  }

  closeDropdown() {
    this.setState({ open: false });
  }

  onToggle(open) {
    this.setState({
      open,
      renderInput: true
    });
  }

  onClickAppendTranslations() {
    this.props.appendTranslationsToDocument(this.props.doc);
  }

  onClickNoPDF() {
    this.props.onChange(this.props.doc, 'stored', 'NoPDF');
    this.reloadInput();
  }

  onClickClear() {
    this.props.onChange(this.props.doc, 'stored', 'No');
    this.reloadInput();
  }

  onClickAppend() {
    this.inputRefs.append.click();
  }

  onClickUpload() {
    this.inputRefs.upload.click();
  }

  onClickLookUp() {
    this.props.onLookUp(this.props.doc, false);
  }

  onClickLookUpNoCache() {
    this.props.onLookUp(this.props.doc, true);
  }

  renderDropdown({ toggle, menu }) {
    return (
      <DropdownButton
        size='sm'
        variant='secondary'
        className='document-pdf no-caret'
        disabled={this.isDisabled()}
        show={this.state.open}
        title={(
          <>
            {toggle}
            {this.renderFileInput('append')}
            {this.renderFileInput('upload')}
            {this.renderConfirmEmbeddedFonts()}
            {this.renderConfirmInvalidUpload()}
            {this.renderConfirmLandscapePages()}
          </>
        )}
        onToggle={this.onToggle}
        id={`doc-icon-${this.props.doc.get('documentNumber')}`}
      >
        {menu}
      </DropdownButton>
    );
  }

  renderFileUS() {
    return this.renderDropdown({
      toggle: (
        <span className='fa text-danger fa-file-pdf-o' />
      ),
      menu: this.state.open && (
        <>
          <Dropdown.Item target='_blank' href={this.props.doc.getDocViewerUrl()}>
            View PDF
          </Dropdown.Item>
          <Dropdown.Item target='_blank' href={this.props.doc.getPDFUrl()}>
            Download PDF
          </Dropdown.Item>
          <Dropdown.Item target='_blank' href={this.props.doc.getTextUrl()}>
            View at Google Patents
          </Dropdown.Item>
        </>
      )
    });
  }

  renderFile() {
    return this.renderDropdown({
      toggle: (
        <span className='fa text-danger fa-file-pdf-o' />
      ),
      menu: this.state.open && (
        <>
          <Dropdown.Item target='_blank' href={this.props.doc.getDocViewerUrl()}>
            View PDF
          </Dropdown.Item>
          <Dropdown.Item target='_blank' href={this.props.doc.getPDFUrl()}>
            Download PDF
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={this.onClickAppend}>
            Append PDF
          </Dropdown.Item>
          <Dropdown.Item onClick={this.onClickUpload}>
            Replace PDF
          </Dropdown.Item>
          <Dropdown.Divider />
          {this.props.doc.get('type') === 'Foreign' && (
            <>
              <MenuItemHelp
                label='Append Full Translation'
                help='Click to fetch/append a full English translation of Descriptions and Claims according to Espacenet.'
                onClick={this.onClickAppendTranslations}
                id={`append-translation-help-${this.props.doc.get('documentNumber')}`}
              />
              <Dropdown.Divider />
            </>
          )}
          <MenuItemHelp
            label='No PDF'
            help='Click "No PDF" to allow an NPL description to flow to the IDS Form without its required PDF.'
            onClick={this.onClickNoPDF}
            id={`no-pdf-help-${this.props.doc.get('documentNumber')}`}
          />
          <MenuItemHelp
            label='Clear PDF'
            help='Click "Clear PDF" to restore empty state.'
            onClick={this.onClickClear}
            id={`clear-pdf-help-${this.props.doc.get('documentNumber')}`}
          />
        </>
      )
    });
  }

  renderNoPDFButton() {
    return (
      <Tooltip
        id={`tip-icon-${this.props.doc.get('documentNumber')}`}
        placement='right'
        tip='NPL description will flow to the IDS Form without its required PDF'
      >
        <span
          style={{ position: 'relative', top: '-3px' }}
          className='fa text-danger fa-file-o'
        />
        <span style={{
          fontSize: '8px',
          position: 'absolute',
          left: 0,
          right: 0,
          display: 'block',
          bottom: 0,
          height: '12px'
        }}
        >No PDF
        </span>
      </Tooltip>
    );
  }

  renderFileNoPDF() {
    return this.renderDropdown({
      toggle: this.renderNoPDFButton(),
      menu: this.state.open && (
        <>
          <Dropdown.Item onClick={this.onClickUpload}>
            Upload PDF
          </Dropdown.Item>
          <MenuItemHelp
            label='Clear PDF'
            help='Click "Clear PDF" to restore empty state.'
            onClick={this.onClickClear}
            id={`clear-pdf-help-${this.props.doc.get('documentNumber')}`}
          />
        </>
      )
    });
  }

  renderUploadButton() {
    const fetchFailed = this.props.doc.get('stored') === 'FetchFailed';

    return (
      <div>
        <span
          className='fa fa-file-o' style={{
            position: 'relative',
            top: '-3px'
          }}
        />
        <span
          className={fetchFailed ? 'fa fa-times text-danger' : 'fa fa-arrow-up'} style={{
            fontSize: '6px',
            position: 'absolute',
            left: 0,
            right: 0,
            display: 'block',
            top: '11px',
            height: '12px'
          }}
        />
        <span style={{
          fontSize: '8px',
          position: 'absolute',
          left: 0,
          right: 0,
          display: 'block',
          bottom: 0,
          height: '12px'
        }}
        >PDF
        </span>
      </div>
    );
  }

  renderUpload() {
    const isForeign = this.props.doc.get('type') === 'Foreign';

    return this.renderDropdown({
      toggle: this.renderUploadButton(),
      menu: this.state.open && (
        <>
          <Dropdown.Item onClick={this.onClickUpload}>
            Upload PDF
          </Dropdown.Item>
          <MenuItemHelp
            label='No PDF'
            help='Click "No PDF" to allow an NPL description to flow to the IDS Form without its required PDF.'
            onClick={this.onClickNoPDF}
            id={`no-pdf-help-${this.props.doc.get('documentNumber')}`}
          />
          {isForeign && <Dropdown.Divider />}
          {isForeign && (
            <Dropdown.Item onClick={this.onClickLookUp}>
              Re Look Up
            </Dropdown.Item>
          )}
          {isForeign && (
            <Dropdown.Item onClick={this.onClickLookUpNoCache}>
              Re Look Up (no cache)
            </Dropdown.Item>
          )}
        </>
      )
    });
  }

  onInputChange(e) {
    const file = e.target.files[0];
    const mode = e.target.getAttribute('data-mode');
    if (file && mode) {
      if (this.props.features.validatePDF) {
        this.validateUpload(file, mode);
      } else {
        this.props.onUpload(this.props.doc, file, mode)
          .then(this.reloadInput)
          .then(this.closeDropdown);
      }
    }
  }

  validateUpload(file, mode) {
    this.props.onUpload(this.props.doc, file, 'validate').then((response) => {
      if (!response.isValid) {
        this.setState({
          confirmInvalidUpload: { file, mode },
          open: false
        });
        return;
      }

      if (response.embeddedFonts.length > 0) {
        this.setState({
          confirmEmbeddedFonts: {
            file,
            mode,
            fonts: response.embeddedFonts
          },
          open: false
        });
        return;
      }

      if (response.landscapePages.length > 0) {
        this.setState({
          confirmLandscapePages: {
            file,
            mode,
            pages: response.landscapePages
          },
          open: false
        });
        return;
      }

      this.props.onUpload(this.props.doc, file, mode)
        .then(this.reloadInput)
        .then(this.closeDropdown);
    });
  }

  renderFileInput(mode, onChange) {
    return this.state.renderInput && (
      <input
        ref={this.inputRef}
        type='file'
        accept='.pdf'
        data-mode={mode}
        onChange={this.onInputChange}
        style={{
          display: 'none'
        }}
      />
    );
  }

  onCancelInvalidUpload() {
    this.setState({
      confirmInvalidUpload: null,
      confirmEmbeddedFonts: null,
      confirmLandscapePages: null,
      open: false
    }, () => {
      this.reloadInput();
    });
  }

  onConfirmInvalidUpload() {
    this.closeDropdown();
    this.props.onUpload(this.props.doc, this.state.confirmInvalidUpload.file, this.state.confirmInvalidUpload.mode)
      .then(this.onCancelInvalidUpload);
  }

  renderConfirmInvalidUpload() {
    return this.state.confirmInvalidUpload && (
      <ConfirmModal
        focusCancel
        message={(
          <div>
            {`Looks like "${this.state.confirmInvalidUpload.file.name}" is not a valid PDF.`}
            <br /><br />
            Are you sure you want to upload it?
          </div>
        )}
        onCancel={this.onCancelInvalidUpload}
        onConfirm={this.onConfirmInvalidUpload}
      />
    );
  }

  onConfirmLandscapePages() {
    this.closeDropdown();
    this.props.onUpload(this.props.doc, this.state.confirmLandscapePages.file, this.state.confirmLandscapePages.mode)
      .then(this.onCancelInvalidUpload);
  }

  renderConfirmLandscapePages() {
    return this.state.confirmLandscapePages && (
      <ConfirmModal
        focusCancel
        message={(
          <div>
            {`Looks like "${this.state.confirmLandscapePages.file.name}" contains some pages with landscape orientation.`}
            <ul style={{ margin: '15px 0' }}>
              <li>Pages: {this.state.confirmLandscapePages.pages.join(', ')}.</li>
            </ul>
            Are you sure you want to upload it?
          </div>
        )}
        onCancel={this.onCancelInvalidUpload}
        onConfirm={this.onConfirmLandscapePages}
      />
    );
  }

  onConfirmEmbeddedFonts() {
    this.closeDropdown();
    this.props.onUpload(this.props.doc, this.state.confirmEmbeddedFonts.file, this.state.confirmEmbeddedFonts.mode)
      .then(this.onCancelInvalidUpload);
  }

  renderConfirmEmbeddedFonts() {
    return this.state.confirmEmbeddedFonts && (
      <ConfirmModal
        focusCancel
        message={(
          <div>
            {`Looks like "${this.state.confirmEmbeddedFonts.file.name}" contains some embedded fonts.`}
            <ul style={{ margin: '15px 0' }}>
              {this.state.confirmEmbeddedFonts.fonts.map(font => (
                <li key={font}>{font}</li>
              ))}
            </ul>
            Are you sure you want to upload it?
          </div>
        )}
        onCancel={this.onCancelInvalidUpload}
        onConfirm={this.onConfirmEmbeddedFonts}
      />
    );
  }

  render() {
    if (!this.props.doc.isSaved()) {
      return null;
    }

    if (this.props.doc.get('type') === 'US') {
      return this.renderFileUS();
    }

    switch (this.props.doc.get('stored')) {
      case 'Yes':
        return this.renderFile();
      case 'NoPDF':
        return this.renderFileNoPDF();
      default:
        return this.renderUpload();
    }
  }
};
