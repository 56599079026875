import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import MatterRelationInput from '../matter/MatterRelationInput';
import Field from '../shared/Field';
import SyncFamily from './SyncFamily';
import { splitCommaUnique } from '../../utils/text-utils';
import HelpIcon from '../shared/HelpIcon';

export default class SyncOneWay extends Component {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired,
    createMatter: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    addOneWay: PropTypes.func.isRequired,
    removeOneWay: PropTypes.func.isRequired,
    selectMatter: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getCount() {
    return splitCommaUnique(this.props.matter.get('oneWayRelatedMatters') || '').length;
  }

  renderHelp() {
    return (
      <div>
        One-Way Imports ({this.getCount()})
        &nbsp;&nbsp;&nbsp;
        <HelpIcon
          id='sync-panel'
          help='Automatically flows references from one group of matters to another, but not in reverse.'
        />
      </div>
    );
  }

  render() {
    return (
      <Field label={this.renderHelp()}>
        <div>
          <SyncFamily
            showEmpty
            showRemove
            field='oneWayRelatedMatters'
            client={this.props.client}
            matter={this.props.matter}
            matterList={this.props.matterList}
            selectMatter={this.props.selectMatter}
            onRemove={this.props.removeOneWay}
          />
          <MatterRelationInput
            confirmOnBlur
            confirmAdd
            preventSpaces
            features={this.props.features}
            confirmMessage={'Are you sure you want to add all of the documents from matter "$matterNumber$" to this group? This operation CANNOT BE UNDONE.'}
            placeholder='Enter a New/Existing Matter ID'
            onAdd={this.props.addOneWay}
            createMatter={this.props.createMatter}
            addNotification={this.props.addNotification}
            matterNumber={this.props.matter.get('matterNumber') || ''}
            matterList={this.props.matterList}
          />
        </div>
      </Field>
    );
  }
}
