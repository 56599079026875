import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import {
  LOAD_ATTORNEY_LIST_BEGIN,
  LOAD_ATTORNEY_LIST_SUCCESS,
  LOAD_ATTORNEY_LIST_ERROR
} from '../../constants/actions';

const initialState = null;

export default handleActions({
  [LOAD_ATTORNEY_LIST_BEGIN]: () => Immutable.List(),
  [LOAD_ATTORNEY_LIST_SUCCESS]: (state, { payload }) => Immutable.fromJS(payload),
  [LOAD_ATTORNEY_LIST_ERROR]: () => Immutable.List()
}, initialState);
