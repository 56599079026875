import { createAction } from 'redux-actions';
import axios from 'axios';
import { changePasswordUrl } from '../../constants/urls';
import {
  AUTH_PASSWORD_SUCCESS,
  AUTH_PASSWORD_MESSAGE,
  AUTH_PASSWORD_PROGRESS
} from '../../constants/actions';

const authPasswordSuccess = createAction(AUTH_PASSWORD_SUCCESS);
const authPasswordMessage = createAction(AUTH_PASSWORD_MESSAGE);
const authPasswordProgress = createAction(AUTH_PASSWORD_PROGRESS);

export default (currentPassword, newPassword, confirmPassword) => (dispatch, getState) => {
  if (newPassword !== confirmPassword) {
    return dispatch(authPasswordMessage({
      type: 'error',
      message: 'New password was not confirmed correctly.'
    }));
  }

  const { auth: { email } } = getState();
  const data = { email, currentPassword, newPassword };

  dispatch(authPasswordProgress());

  axios
    .post(changePasswordUrl, data)
    .then(() => dispatch(authPasswordSuccess()))
    .catch(err => dispatch(authPasswordMessage({
      type: 'error',
      message: err.response.data.status || err.response.data.error
    })));
};
