import { push as pushPath } from 'react-router-redux';
import app from './app';
import admin from './admin';
import attorney from './attorney';
import auth from './auth';
import client from './client';
import doc from './document';
import matter from './matter';
import todo from './todo';
import category from './category';
import migration from './migration';
import testDrive from './test-drive';
import submitPriorArt from './prior-art/submitPriorArt';
import uploadPDFScraper from './pdf-scraper/uploadPDFScraper';
import callApi from '../utils/callApi';

export default {
  callApi,
  pushPath,
  submitPriorArt,
  uploadPDFScraper,
  ...testDrive,
  ...admin,
  ...app,
  ...attorney,
  ...auth,
  ...client,
  ...doc,
  ...matter,
  ...todo,
  ...category,
  ...migration
};
